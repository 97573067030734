import React from 'react'
import { Drawer } from 'src/components/Drawer'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import { useLazyGetSurvivorsQuickJotsQuery } from 'src/store/APIs/quickJots'

import { columns } from './columns'
import styles from './quickJots.module.scss'
import { strings } from './strings'
import { ViewQuickJot } from './ViewQuickJot'

interface QuickJotsProps {}

export const QuickJots: React.FC<QuickJotsProps> = () => {
  const { open, openView, selectedId, onCloseDrawer } = useDrawer()
  return (
    <div className={styles.container}>
      <Drawer
        open={open}
        title={strings.resource}
        onClose={onCloseDrawer}
        size="large">
        <ViewQuickJot jotId={selectedId} />
      </Drawer>
      <PaginatedTable
        columns={columns(openView)}
        useLazyQuery={useLazyGetSurvivorsQuickJotsQuery}
        hasBadge
      />
    </div>
  )
}
