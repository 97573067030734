import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { Tabs } from 'src/components/Tabs'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddSurvivorQTAMutation,
  useDeleteSurvivorQTAMutation,
  useGetActiveSurvivorsQTAsQuery,
  useLazyGetActiveSurvivorsQTAsQuery,
  useLazyGetDeletedSurvivorsQTAsQuery,
  useLazyGetSurvivorQTAQuery,
  useNotifyUncompletedQTAMutation,
  useUpdateSurvivorQTAMutation,
} from 'src/store/APIs/questionsToAsk'

import { AddQTA } from './AddQTA'
import { columns } from './columns'
import styles from './qtas.module.scss'
import { strings } from './strings'
import { ViewQTA } from './ViewQTA'

interface QTAsProps {}

enum TabKeys {
  active = 'active',
  deleted = 'deleted',
}

export const QuestionsToAsk: React.FC<QTAsProps> = () => {
  const { id } = useParams<{ id: string }>()

  const [triggerNotifyUncompletedQTAMutation, { isLoading }] =
    useNotifyUncompletedQTAMutation()

  const [triggerAddQTA, { isLoading: isLoadingAddQTA }] =
    useAddSurvivorQTAMutation()
  const [triggerUpdateQTA, { isLoading: isLoadingUpdateQTA }] =
    useUpdateSurvivorQTAMutation()
  const [triggerDeleteQTA, { isLoading: isLoadingDeleteQTA }] =
    useDeleteSurvivorQTAMutation()

  const isLoadingRightButton =
    isLoadingAddQTA || isLoadingUpdateQTA || isLoadingDeleteQTA

  const onClickUncompletedQTA = () => {
    triggerNotifyUncompletedQTAMutation({
      id,
    })
  }

  const { open, onCloseDrawer, showDrawer, selectedId, openView } = useDrawer()

  const { data } = useGetActiveSurvivorsQTAsQuery({
    id,
    page: 1,
  })

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          disabled={!data?.model_permissions?.can_create}
          className={styles.addButton}
          onClick={showDrawer}>
          {strings.addQTAButtonLabel}
        </Button>
        <Button
          type="primary"
          loading={isLoading}
          onClick={onClickUncompletedQTA}>
          {strings.notifyUncompletedButton}
        </Button>
      </div>
      <DrawerWithState
        open={open}
        selectedId={selectedId}
        RenderView={ViewQTA}
        useLazyGetResource={useLazyGetSurvivorQTAQuery}
        resourceName={strings.questionLabel}
        RenderAdd={AddQTA}
        triggerAddResourceMutation={triggerAddQTA}
        triggerEditResourceMutation={triggerUpdateQTA}
        triggerDeleteResourceMutation={triggerDeleteQTA}
        onCloseDrawer={onCloseDrawer}
        isLoadingRightButton={isLoadingRightButton}
      />
      <Tabs
        type="card"
        items={[
          {
            children: (
              <PaginatedTable
                columns={columns(openView)}
                useLazyQuery={useLazyGetActiveSurvivorsQTAsQuery}
              />
            ),
            key: TabKeys.active,
            label: strings.activeQuestionsTabLabel,
          },
          {
            children: (
              <PaginatedTable
                columns={columns(openView)}
                useLazyQuery={useLazyGetDeletedSurvivorsQTAsQuery}
              />
            ),
            key: TabKeys.deleted,
            label: strings.deletedQuestionsTabLabel,
          },
        ]}
      />
    </>
  )
}
