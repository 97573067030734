import { baseApi } from 'src/store/APIs'

import {
  addPurchaseTodo,
  deletePurchaseTodo,
  getPurchaseTodo,
  getRetailProducts,
  getSurvivorsPurchaseTodos,
  updatePurchaseTodo,
} from './actions'

export const purchaseTodosApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addPurchaseTodo: addPurchaseTodo(builder),
    deletePurchaseTodo: deletePurchaseTodo(builder),
    getPurchaseTodo: getPurchaseTodo(builder),
    getRetailProducts: getRetailProducts(builder),
    getSurvivorsPurchaseTodos: getSurvivorsPurchaseTodos(builder),
    updatePurchaseTodo: updatePurchaseTodo(builder),
  }),
})

export const {
  useGetSurvivorsPurchaseTodosQuery,
  useGetRetailProductsQuery,
  useLazyGetSurvivorsPurchaseTodosQuery,
  useLazyGetPurchaseTodoQuery,
  useLazyGetRetailProductsQuery,
  useAddPurchaseTodoMutation,
  useUpdatePurchaseTodoMutation,
  useDeletePurchaseTodoMutation,
} = purchaseTodosApi
