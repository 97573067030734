import {
  DatePicker as AntdDatePicker,
  DatePickerProps as AntdDatePickerProps,
  Tooltip,
} from 'antd'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'

import styles from './datePicker.module.scss'

export type DatePickerProps = AntdDatePickerProps & {
  label?: string
  required?: boolean
  format?: string | string[]
  subtitle?: string
  optionalLabel?: boolean
  tooltipTitle?: string
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  required,
  format = ['MM-DD-YYYY', 'MMDDYYYY'],
  subtitle,
  optionalLabel,
  tooltipTitle = '',
  ...props
}) => {
  return (
    <>
      <div>
        {label}
        {required && '*'}
        {optionalLabel && (
          <span className={styles.optionalLabel}>
            {generalStrings.optionalLabel}
          </span>
        )}
      </div>
      <Tooltip title={tooltipTitle}>
        <AntdDatePicker format={format} changeOnBlur {...props} />
      </Tooltip>
      <div>{subtitle}</div>
    </>
  )
}
