import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LearnView } from 'src/components/LearnView'
import { LearnTrackResponse } from 'src/components/LearnView/types'
import { AudienceOptions } from 'src/pages/Learn/Articles/types'
import {
  useGetSurvivorQuery,
  useGetSurvivorsUnitsQuery,
  useLazyGetSurvivorsAlertsByTabQuery,
  useLazyGetSurvivorsUnitsQuery,
  useNotifySurvivorUncompletedLessonMutation,
  useUpdateSurvivorLearnTrackMutation,
} from 'src/store/APIs/survivors'

interface LearnProps {}

export const Learn: React.FC<LearnProps> = () => {
  const { id } = useParams<{ id: string }>()
  const [triggerGetAlertsByTabQuery] = useLazyGetSurvivorsAlertsByTabQuery()
  const [triggerNotifyUncompletedLessonMutation, { isLoading }] =
    useNotifySurvivorUncompletedLessonMutation()
  const onClickUncompletedPackage = () => {
    triggerNotifyUncompletedLessonMutation({
      id,
    })
  }

  const { data } = useGetSurvivorsUnitsQuery({ id, page: 1 })

  const { data: survivorInfo } = useGetSurvivorQuery(id)
  const [triggerUpdateLearnTrack, { isLoading: isLoadingLearnTrack }] =
    useUpdateSurvivorLearnTrackMutation()

  const onChangeLearnTrack = (learn_track: LearnTrackResponse) => {
    triggerUpdateLearnTrack({ id, learn_track })
  }

  const audienceOptions = [
    { label: AudienceOptions.Survivor, value: AudienceOptions.Survivor },
    { label: AudienceOptions.Shared, value: AudienceOptions.Shared },
  ]

  useEffect(() => {
    if (data) {
      triggerGetAlertsByTabQuery(id)
    }
  }, [data])

  return (
    <LearnView
      audienceOptions={audienceOptions}
      user={survivorInfo}
      data={data}
      isLoadingNotify={isLoading}
      useLazyQuery={useLazyGetSurvivorsUnitsQuery}
      onClickUncompletedPackage={onClickUncompletedPackage}
      onChangeLearnTrack={onChangeLearnTrack}
      initialLearnTrack={survivorInfo?.learn_track}
      isLoadingLearnTrack={isLoadingLearnTrack}
    />
  )
}
