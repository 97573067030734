import React from 'react'

import { UserHyperlink } from '../UserHyperlink'

interface LinksProps {
  items: any[] | undefined
  separator?: string
}

export const Links: React.FC<LinksProps> = ({ items, separator = ', ' }) => {
  return (
    <div>
      {items?.map((item, index) => (
        <>
          <UserHyperlink key={item.id ?? index} user={item} />
          {index !== items.length - 1 && <span>{separator}</span>}
        </>
      ))}
    </div>
  )
}
