import React from 'react'
import { useParams } from 'react-router-dom'
import { AssessmentsView } from 'src/components/AssessmentsView'
import {
  useDeleteCarePartnerAssessmentMutation,
  useLazyGetCarePartnerAssessmentsQuery,
} from 'src/store/APIs/assessments'
import { useGetCarePartnerQuery } from 'src/store/APIs/carePartners'

interface AssessmentsProps {}

export const Assessments: React.FC<AssessmentsProps> = () => {
  const { id } = useParams<{ id: string }>()
  const { data: carePartnerInfo } = useGetCarePartnerQuery(id)

  return (
    <AssessmentsView
      useLazyQuery={useLazyGetCarePartnerAssessmentsQuery}
      useDeleteMutation={useDeleteCarePartnerAssessmentMutation}
      user={carePartnerInfo}
      isCarePartner
    />
  )
}
