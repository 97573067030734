import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  hospitalTreatmentDeserializer,
  HospitalTreatmentsData,
  hospitalTreatmentsDeserializer,
  SurvivorHospitalTreatmentData,
  survivorHospitalTreatmentsDeserializer,
} from './types'

export const getHospitalTreatments = (builder: Builder) =>
  builder.query<PaginatedItems<HospitalTreatmentsData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.HospitalTreatments),
    query: endpointWithFilters(Endpoints.GetHospitalTreatments),
    transformResponse: hospitalTreatmentsDeserializer,
  })

export const getSurvivorsHospitalTreatments = (builder: Builder) =>
  builder.query<SurvivorHospitalTreatmentData[], string>({
    providesTags: (_, __, id) => [{ id, type: Tags.HospitalTreatments }],
    query: id => format(Endpoints.GetSurvivorsHospitalTreatments, id),
    transformResponse: ({ hospital_treatments }) =>
      survivorHospitalTreatmentsDeserializer(hospital_treatments),
  })

export const updateSurvivorsHospitalTreatments = (builder: Builder) =>
  builder.mutation<void, { hospital_treatments: string[]; id: string }>({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.HospitalTreatments }],
    query: ({ id, hospital_treatments }) => ({
      body: { hospital_treatments },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorsHospitalTreatments, id),
    }),
  })

export const getHospitalTreatment = (builder: Builder) =>
  builder.query<HospitalTreatmentsData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.HospitalTreatments }],
    query: ({ id }) => format(Endpoints.GetHospitalTreatment, id),
    transformResponse: ({ hospital_treatment }) =>
      hospitalTreatmentDeserializer(hospital_treatment),
  })

export const addHospitalTreatment = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.HospitalTreatments),
    query: ({ body }) => ({
      body: { hospital_treatment: body },
      method: HttpMethod.Post,
      url: Endpoints.AddHospitalTreatment,
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteHospitalTreatment = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.HospitalTreatments),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteHospitalTreatment, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const editHospitalTreatment = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.HospitalTreatments }],
    query: ({ body, id }) => ({
      body: { hospital_treatment: body },
      method: HttpMethod.Put,
      url: format(Endpoints.EditHospitalTreatment, id),
    }),
    transformResponse: performActionTransformResponse,
  })
