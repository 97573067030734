import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  GetOtherEventsResponse,
  otherEventDeserializer,
  OtherEventsData,
  otherEventsDeserializer,
  SurvivorOtherEventData,
  survivorOtherEventsDeserializer,
} from './types'

export const getOtherEvents = (builder: Builder) =>
  builder.query<PaginatedItems<OtherEventsData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.OtherEvents),
    query: endpointWithFilters(Endpoints.GetOtherEvents),
    transformResponse: (response: GetOtherEventsResponse) =>
      otherEventsDeserializer(response),
  })

export const getSurvivorsOtherEvents = (builder: Builder) =>
  builder.query<SurvivorOtherEventData[], string>({
    providesTags: () => [{ type: Tags.OtherEvents }],
    query: id => format(Endpoints.GetSurvivorsOtherEvents, id),
    transformResponse: ({ complications }) =>
      survivorOtherEventsDeserializer(complications),
  })

export const updateSurvivorsOtherEvents = (builder: Builder) =>
  builder.mutation<void, { complications: string[]; id: string }>({
    invalidatesTags: () => [{ type: Tags.OtherEvents }],
    query: ({ id, complications }) => ({
      body: { complications },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorsOtherEvents, id),
    }),
  })

export const addOtherEvent = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.OtherEvents),
    query: ({ body }) => ({
      body: { complication: body },
      method: HttpMethod.Post,
      url: Endpoints.AddOtherEvent,
    }),
    transformResponse: performActionTransformResponse,
  })

export const getOtherEvent = (builder: Builder) =>
  builder.query<OtherEventsData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.OtherEvents }],
    query: ({ id }) => format(Endpoints.GetOtherEvent, id),
    transformResponse: ({ complication }) =>
      otherEventDeserializer(complication),
  })

export const editOtherEvent = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.OtherEvents }],
    query: ({ body, id }) => ({
      body: { complication: body },
      method: HttpMethod.Put,
      url: format(Endpoints.EditOtherEvent, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteOtherEvent = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.OtherEvents),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteOtherEvent, id),
    }),
    transformResponse: performActionTransformResponse,
  })
