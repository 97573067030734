import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { DrawerWithStateProps } from 'src/components/DrawerWithState/types'
import { DrawerWithStateLearn } from 'src/components/DrawerWithStateLearn'
import {
  useAddArticleMutation,
  useDeleteArticleMutation,
  useEditArticleMutation,
  useLazyGetArticleQuery,
  useRemoveArticleMutation,
} from 'src/store/APIs/articles'

import { modalIconStyle } from '../../styles'
import { AddArticle } from '../AddArticle'
import { strings } from '../strings'
import { ViewArticle } from '../ViewArticle'

interface ArticlesDrawerProps extends Partial<DrawerWithStateProps> {
  setSelectedId: (id: string) => void
  open: boolean
  onCloseDrawer: () => void
  selectedId?: string
}

export const ArticlesDrawer: React.FC<ArticlesDrawerProps> = ({
  setSelectedId,
  open,
  onCloseDrawer,
  selectedId,
  ...props
}) => {
  const [triggerAddArticle, { isLoading: isLoadingAdd, data }] =
    useAddArticleMutation()

  const [triggerEditArticle, { isLoading: isLoadingEdit }] =
    useEditArticleMutation()

  const isLoading = isLoadingAdd || isLoadingEdit

  const [triggerDeleteArticle, { isLoading: isLoadingDelete }] =
    useDeleteArticleMutation()

  const [triggerRemoveArticleMutation] = useRemoveArticleMutation()

  const removeArticle = (canUnassign: boolean, closeDrawer: () => void) => {
    if (selectedId) {
      Modal.confirm({
        cancelText: generalStrings.cancel,
        icon: <ExclamationCircleOutlined style={modalIconStyle} />,
        okText: canUnassign ? strings.unassignLabel : strings.removeLabel,
        okType: 'danger',
        onCancel() {},
        async onOk() {
          await triggerRemoveArticleMutation({ id: selectedId })
          closeDrawer()
        },
        title: canUnassign
          ? strings.unassignModalTitle
          : strings.removeModalTitle,
      })
    }
  }

  useEffect(() => {
    if (data) {
      setSelectedId(data.id)
    }
  }, [data])

  return (
    <DrawerWithStateLearn
      removeResource={removeArticle}
      addData={data}
      isLoadingRightButton={isLoading}
      isLoadingLeftButton={isLoadingDelete}
      selectedId={selectedId}
      open={open}
      onClose={onCloseDrawer}
      resourceName={strings.resourceName}
      RenderAdd={AddArticle}
      RenderEdit={AddArticle}
      RenderView={ViewArticle}
      useLazyGetResource={useLazyGetArticleQuery}
      onCloseDrawer={onCloseDrawer}
      hasSave
      triggerAddResourceMutation={triggerAddArticle}
      triggerEditResourceMutation={triggerEditArticle}
      triggerDeleteResourceMutation={triggerDeleteArticle}
      deleteModalTitle={strings.deleteArticleModalTitle}
      disableModalTitle={strings.disableArticleModalTitle}
      {...props}
    />
  )
}
