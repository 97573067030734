import 'react-quill/dist/quill.snow.css'

import React, { RefObject } from 'react'
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill'

import styles from './richTextEditor.module.scss'
import { Toolbar } from './types'

const Block = Quill.import('blots/block')
const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

interface RichTextEditorProps extends ReactQuillProps {
  label?: string | React.ReactNode
  toolbar?: Toolbar
  required?: boolean
  subtitle?: string
  quillRef?: RefObject<ReactQuill>
}

Block.tagName = 'DIV'
Quill.register(Block, true)

const Link = Quill.import('formats/link')
Link.sanitize = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }
  return url
}

export const richTextDefaultText = '<div><br></div>'

export const RichTextEditor: React.FC<RichTextEditorProps> = props => {
  const modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: props.toolbar ?? [
      [
        { header: [1, 2, 3, false] },
        { color: [] },
        { background: [] },
        { align: [] },
      ],
      [],
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [],
      [
        'blockquote',
        'code-block',
        { list: 'bullet' },
        { list: 'ordered' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [],
      [], // TODO: Add optional [image] when we create Learn unit
      [],
      ['clean'],
    ],
  }

  const formats = [
    'header',
    'color',
    'background',
    'align',
    'bold',
    'italic',
    'underline',
    'strike',
    'link',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'ordered',
    'indent',
    '-1',
    'indent',
    '+1',
    'image',
    'video',
    'clean',
  ]

  return (
    <>
      <div>
        {props.label}
        {props.required && '*'}
      </div>
      <ReactQuill
        {...props}
        ref={props.quillRef}
        modules={modules}
        theme="snow"
        formats={formats}
      />
      <div className={styles.subtitle}>{props.subtitle}</div>
    </>
  )
}
