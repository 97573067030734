import React from 'react'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddImpairmentMutation,
  useDeleteImpairmentMutation,
  useLazyGetImpairmentQuery,
  useLazyGetImpairmentsQuery,
  useUpdateImpairmentMutation,
} from 'src/store/APIs/impairments'

import styles from '../survivorProfile.module.scss'
import { AddImpairment } from './AddImpaiment'
import { columns } from './columns'
import { strings } from './strings'
import { ViewImpairment } from './ViewImpairment'

export const Impairments: React.FC = () => {
  const { open, onCloseDrawer, openView, selectedId, showDrawer } = useDrawer()

  const [triggerAddImpairmentMutation, { isLoading: loadingAdd }] =
    useAddImpairmentMutation()
  const [triggerEditImpairmentMutation, { isLoading: loadingEdit }] =
    useUpdateImpairmentMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  const [triggerDeleteImpairmentMutation, { isLoading: loadingDelete }] =
    useDeleteImpairmentMutation()

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.impairmentsTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addImpairmentButton}
        </Button>
      </div>
      <DrawerWithState
        selectedId={selectedId}
        open={open}
        resourceName={strings.impairmentDrawerTitle}
        RenderView={ViewImpairment}
        RenderAdd={AddImpairment}
        useLazyGetResource={useLazyGetImpairmentQuery}
        onCloseDrawer={onCloseDrawer}
        triggerDeleteResourceMutation={triggerDeleteImpairmentMutation}
        isLoadingLeftButton={loadingDelete}
        deleteModalTitle={strings.deleteModalTitle}
        triggerAddResourceMutation={triggerAddImpairmentMutation}
        triggerEditResourceMutation={triggerEditImpairmentMutation}
        isLoadingRightButton={isLoadingRightButton}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetImpairmentsQuery}
      />
    </div>
  )
}
