import { Form, FormInstance, FormProps, InputProps } from 'antd'
import React, { useEffect } from 'react'
import colors from 'src/assets/stylesheets/colors.scss'
import {
  PhoneNumberTypes,
  PreferredPhoneFields,
  timezoneFromIANAToOffset,
} from 'src/common/types'
import { Select } from 'src/components/Select'
import { TextInput } from 'src/components/TextInput'
import { constants } from 'src/config/constants'
import { usePhone } from 'src/hooks/usePhone'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { languageOptions } from 'src/store/APIs/types'
import { format } from 'util'

import { Checkbox } from '../Checkbox'
import { Radio } from '../Radio'
import styles from './carePartnerForm.module.scss'
import { selectOptions } from './selectOptions'
import { strings } from './strings'
import { yupSync } from './validations'

interface Props {
  name: string
  initialValues?: CarePartner
  form: FormInstance<CarePartner | undefined>
  children?: React.ReactNode
  onFormFinish?: FormProps['onFinish']
}

export const CarePartnerForm: React.FC<Props> = ({
  initialValues,
  name,
  form,
  children,
  onFormFinish,
}) => {
  const enableAddUser = constants.addUserEnabled
  const tooltipTitle = enableAddUser ? '' : strings.disabledInputTooltip

  const homePhoneFormValue = Form.useWatch('home_phone', form)
  const phoneNumberFormValue = Form.useWatch('phone_number', form)

  const { updatePhone } = usePhone(
    initialValues?.phone_number,
    form,
    'phone_number',
  )

  const { updatePhone: updateHomePhone } = usePhone(
    initialValues?.home_phone,
    form,
    'home_phone',
  )

  const { preferredPronounOptions, legalSexOptions } = selectOptions()

  const onChangePhone: InputProps['onChange'] = event => {
    updatePhone(event.target.value)
  }

  const onChangeHomePhone: InputProps['onChange'] = event => {
    updateHomePhone(event.target.value)
  }

  useEffect(() => {
    form.validateFields()
  }, [form])

  return (
    <Form
      name={name}
      initialValues={initialValues}
      form={form}
      onFinish={onFormFinish ?? undefined}>
      <Form.Item name="first_name">
        <TextInput
          required
          label={strings.firstNameLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="last_name">
        <TextInput
          required
          label={strings.lastNameLabel}
          tooltipTitle={tooltipTitle}
          disabled={!enableAddUser}
        />
      </Form.Item>
      <Form.Item name="preferred_name" rules={[yupSync]}>
        <TextInput
          label={strings.preferredNameLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="preferred_pronoun" rules={[yupSync]}>
        <Select
          label={strings.preferredPronounLabel}
          options={preferredPronounOptions}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="biological_sex" rules={[yupSync]}>
        <Select
          label={strings.legalSexLabel}
          options={legalSexOptions}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="content_language" rules={[yupSync]}>
        <Select
          label={strings.languageLabel}
          options={languageOptions}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
          required
        />
      </Form.Item>
      <h4>{strings.phoneNumberLabel}</h4>
      <p>{strings.phoneNumberSubtitle}</p>
      <div className={styles.row}>
        <Form.Item
          name="phone_number"
          className={styles.phoneInput}
          rules={[yupSync]}>
          <TextInput
            required
            label={strings.mobilePhoneLabel}
            tooltipTitle={tooltipTitle}
            maxLength={14}
            disabled={!enableAddUser}
            onChange={onChangePhone}
          />
        </Form.Item>
        <Form.Item
          name={PreferredPhoneFields.MobilePhonePreferred}
          className={styles.preferredPhone}>
          <Radio.Group
            defaultValue={
              initialValues?.preferred_phone !== PhoneNumberTypes.Home
                ? PreferredPhoneFields.MobilePhonePreferred
                : undefined
            }>
            <Radio value={PreferredPhoneFields.MobilePhonePreferred} disabled>
              <span
                style={{
                  color: !phoneNumberFormValue ? colors.surface_50 : undefined,
                }}>
                {strings.preferredPhoneLabel}
              </span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={styles.row}>
        <Form.Item
          name="home_phone"
          className={styles.phoneInput}
          rules={[yupSync]}>
          <TextInput
            label={strings.homePhoneLabel}
            onChange={onChangeHomePhone}
            maxLength={14}
            disabled={!enableAddUser}
            tooltipTitle={tooltipTitle}
          />
        </Form.Item>
        <Form.Item
          name={PreferredPhoneFields.HomePhonePreferred}
          className={styles.preferredPhone}>
          <Radio.Group
            defaultValue={
              initialValues?.preferred_phone === PhoneNumberTypes.Home
                ? PreferredPhoneFields.HomePhonePreferred
                : undefined
            }>
            <Radio value={PreferredPhoneFields.HomePhonePreferred} disabled>
              <span
                style={{
                  color: !homePhoneFormValue ? colors.surface_50 : undefined,
                }}>
                {strings.preferredPhoneLabel}
              </span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <Form.Item name="email" rules={[yupSync]}>
        <TextInput
          required
          label={strings.emailLabel}
          tooltipTitle={tooltipTitle}
          disabled={!enableAddUser}
        />
      </Form.Item>
      <p>
        {format(
          strings.timezoneLabel,
          initialValues?.time_zone
            ? timezoneFromIANAToOffset(initialValues.time_zone)
            : strings.undefinedTimezone,
        )}
      </p>
      <Form.Item name="limitations" rules={[yupSync]}>
        <TextInput label={strings.limitationsLabel} />
      </Form.Item>
      <Form.Item name="test_user" valuePropName="checked" rules={[yupSync]}>
        <Checkbox disabled={!enableAddUser} tooltipTitle={tooltipTitle}>
          {strings.testUserLabel}
        </Checkbox>
      </Form.Item>
      {children}
    </Form>
  )
}
