import {
  Entity,
  PaginatedItems,
  PaginationResponse,
  Permissions,
} from '../types'

export enum Endpoints {
  GetHospitals = '/cms/v1/global_settings/hospitals.json',
  GetHospital = '/cms/v1/global_settings/hospitals/%s.json',
  DeleteHospital = '/cms/v1/global_settings/hospitals/%s.json',
  AddHospital = '/cms/v1/global_settings/hospitals.json',
  UpdateHospital = '/cms/v1/global_settings/hospitals/%s.json',
}

export interface GetHospitalsResponse {
  hospitals: HospitalResponse[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

export interface HospitalResponse extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export type HospitalData = HospitalResponse

export const hospitalsDeserializer = (
  data: GetHospitalsResponse,
): PaginatedItems<HospitalData> => ({
  ...data,
  items: data.hospitals,
})
