export interface MenuInfo {
  key: string
  keyPath: string[]
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
}

export enum BasicItemDataIndex {
  'name' = 'name',
  'created_at' = 'created_at',
  'updated_at' = 'updated_at',
}

export enum ImpairmentsDataIndex {
  'name' = 'name',
  'clinical_name' = 'clinical_name',
  'created_at' = 'created_at',
  'updated_at' = 'updated_at',
}
