import { baseApi } from 'src/store/APIs'

import {
  addQTA,
  deleteQTA,
  getActiveSurvivorsQTAs,
  getDeletedSurvivorsQTAs,
  getQTA,
  notifyUncompletedQTA,
  updateQTA,
} from './actions'

export const questionsToAskApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addSurvivorQTA: addQTA(builder),
    deleteSurvivorQTA: deleteQTA(builder),
    getActiveSurvivorsQTAs: getActiveSurvivorsQTAs(builder),
    getDeletedSurvivorsQTAs: getDeletedSurvivorsQTAs(builder),
    getSurvivorQTA: getQTA(builder),
    notifyUncompletedQTA: notifyUncompletedQTA(builder),
    updateSurvivorQTA: updateQTA(builder),
  }),
})

export const {
  useGetActiveSurvivorsQTAsQuery,
  useLazyGetActiveSurvivorsQTAsQuery,
  useLazyGetDeletedSurvivorsQTAsQuery,
  useLazyGetSurvivorQTAQuery,
  useUpdateSurvivorQTAMutation,
  useDeleteSurvivorQTAMutation,
  useAddSurvivorQTAMutation,
  useNotifyUncompletedQTAMutation,
} = questionsToAskApi
