/* eslint-disable react/no-danger */
import React from 'react'
import {
  formatDateToRender,
  formatDateWithTimeToRender,
} from 'src/common/types'
import { InfoTable } from 'src/components/InfoTable'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { TodoDetail } from 'src/store/APIs/todos/types'

import { strings } from '../strings'

interface ViewTodoProps {
  data?: TodoDetail
}

export const ViewTodo: React.FC<ViewTodoProps> = ({ data }) => {
  const rows = [
    {
      content: (
        <div dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
      ),
      label: strings.todoLabel,
    },
    {
      content: data?.additional_details || undefined,
      label: strings.descriptionLabel,
    },
    {
      content: data?.completed ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag color="default">{strings.tagNo}</Tag>
      ),
      label: strings.completedColumnLabel,
    },
    {
      content: data?.type_of?.replaceAll('_', ' '),
      label: strings.recurringLabel,
    },
    {
      content: data?.occurrence?.replaceAll('_', ' '),
      label: strings.repeatScheduleLabel,
    },
    {
      content: formatDateWithTimeToRender(data?.due_datetime),
      label: strings.firstReminderDateColumnLabel,
    },
    {
      content: data?.created_by ? (
        <UserHyperlink user={data?.created_by} />
      ) : undefined,
      label: strings.createdByLabel,
    },
    {
      content: data?.completed_by ? (
        <UserHyperlink user={data?.completed_by} />
      ) : undefined,
      label: strings.completedByLabel,
    },
    {
      content: formatDateToRender(data?.completed_date),
      label: strings.completedDateColumnLabel,
    },
    {
      content: data?.deleted_by ? data?.deleted_by?.full_name : undefined,
      label: strings.discardedByLabel,
    },
  ]
  return <InfoTable rows={rows} contentStyle={{ width: '75%' }} />
}
