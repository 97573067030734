import { message } from 'antd'
import React, { useEffect, useRef } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { LocalStorageKey } from 'src/common/types'

import { Paths } from './routes'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const isLoggedIn = localStorage.getItem(LocalStorageKey.isLoggedIn)
  const alreadyDisplayedTheMessage = useRef<boolean>(false)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!isLoggedIn && !alreadyDisplayedTheMessage.current) {
      alreadyDisplayedTheMessage.current = true
      message.info(generalStrings.loggeoutOutForExpiration)
      timeout.current = setTimeout(() => {
        alreadyDisplayedTheMessage.current = false
      }, 1000)
    }
  }, [isLoggedIn])

  const Component = component as React.ElementType
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: Paths.Login,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
