import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { formatDateWithTimeToRender } from 'src/common/types'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import { SurvivorPurchaseTodoIndexData } from 'src/store/APIs/purchaseTodos/types'

import { strings } from './strings'
import { PurchaseTodosDataIndex } from './types'

export const columns: (
  onClickRow?: (id: string) => () => void,
) => ColumnsType<SurvivorPurchaseTodoIndexData> = onClickRow => [
  {
    ...generateColumnTitle(
      PurchaseTodosDataIndex.todo,
      strings.todosColumnLabel,
    ),
    render: (purchase_todo, record) => {
      return record.permissions?.can_read && onClickRow ? (
        <Button type="link" onClick={onClickRow(record.id)}>
          {purchase_todo}
        </Button>
      ) : (
        purchase_todo
      )
    },
  },
  {
    ...generateColumnTitle(
      PurchaseTodosDataIndex.retail_products,
      strings.retailProductsColumnLabel,
    ),
    render: (_, record) => {
      const products = record.retail_products.map(product => product.name)
      return products.join(', ')
    },
  },
  {
    ...generateColumnTitle(
      PurchaseTodosDataIndex.completed,
      strings.completedColumnLabel,
    ),
    render(value) {
      return value ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag>{strings.tagNo}</Tag>
      )
    },
  },
  {
    ...generateColumnTitle(
      PurchaseTodosDataIndex.completed_date,
      strings.completedDateColumnLabel,
    ),
    render: formatDateWithTimeToRender,
  },
  {
    ...generateColumnTitle(
      PurchaseTodosDataIndex.due_datetime,
      strings.dueDateColumnLabel,
    ),
    render: formatDateWithTimeToRender,
  },
]
