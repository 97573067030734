import { Rule } from 'antd/es/form'
import { generalStrings } from 'src/common/generalStrings'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

const schema = yup.object({
  description: yup.string().required(generalStrings.requiredFieldValidation),
  goal_date: yup
    .number()
    .required(generalStrings.requiredFieldValidation)
    .nullable(),
  goal_date_type: yup.string().required(generalStrings.requiredFieldValidation),
  position: yup.string().required(generalStrings.requiredFieldValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
