import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Modal } from 'antd'
import React, { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import colors from 'src/assets/stylesheets/colors.scss'
import { generalStrings } from 'src/common/generalStrings'
import { Button, ButtonProps } from 'src/components/Button'
import { PerformActionResponse } from 'src/store/APIs/types'

interface DeleteButtonProps extends ButtonProps {
  onClickYes?: MutationTrigger<
    MutationDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      any,
      PerformActionResponse,
      any
    >
  >
  modalTitle: string
  id?: string
  cancelText?: string
  okText?: string
  ownerId?: string
  onOk?: () => void
  modalIcon?: ReactNode
}

const modalIconStyle = {
  color: colors.red_7,
}

export const deleteModalIcon = (
  <ExclamationCircleOutlined style={modalIconStyle} />
)

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClickYes,
  modalTitle,
  id,
  okText = generalStrings.delete,
  cancelText = generalStrings.cancel,
  ownerId,
  modalIcon,
  onOk,
  ...props
}) => {
  const history = useHistory()
  const { confirm } = Modal

  const modalIconDefault = modalIcon ?? deleteModalIcon

  const onClickDelete = () => {
    confirm({
      cancelText,
      icon: modalIconDefault,
      okText,
      okType: 'danger',
      onCancel() {},
      onOk() {
        if (onClickYes) {
          if (ownerId) {
            onClickYes({ idElem: id, idUser: ownerId })
          } else {
            onClickYes(id).then(history.goBack)
          }
        } else if (onOk) onOk()
      },
      title: modalTitle,
    })
  }

  return (
    <Button {...props} danger onClick={onClickDelete}>
      {props.children}
    </Button>
  )
}
