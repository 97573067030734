enum MDP {
  mdp1 = '1',
  mdp2 = '2',
  mdp3 = '3',
  mdp4 = '4',
  mdp5 = '5',
  mdp6 = '',
}

enum MRS {
  mrs0 = '0',
  mrs1 = '1',
  mrs2 = '2',
  mrs3 = '3',
  mrs4 = '4',
  mrs5 = '5',
  mrs6 = '6',
  mrsNA = 'N/A',
}

export const selectOptions = () => {
  const mdpOptions = [
    { label: MDP.mdp1, value: 1 },
    { label: MDP.mdp2, value: 2 },
    { label: MDP.mdp3, value: 3 },
    { label: MDP.mdp4, value: 4 },
    { label: MDP.mdp5, value: 5 },
    { label: MDP.mdp6, value: null },
  ]
  const mrsOptions = [
    { label: MRS.mrs0, value: MRS.mrs0 },
    { label: MRS.mrs1, value: MRS.mrs1 },
    { label: MRS.mrs2, value: MRS.mrs2 },
    { label: MRS.mrs3, value: MRS.mrs3 },
    { label: MRS.mrs4, value: MRS.mrs4 },
    { label: MRS.mrs5, value: MRS.mrs5 },
    { label: MRS.mrs6, value: MRS.mrs6 },
    { label: MRS.mrsNA, value: MRS.mrsNA },
  ]
  return {
    mdpOptions,
    mrsOptions,
  }
}
