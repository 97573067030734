import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { SubHeader } from 'src/components/SubHeader'
import { goToPage, RouteName } from 'src/routes'
import {
  useGetCommunityGroupsQuery,
  useLazyGetCommunityGroupsQuery,
} from 'src/store/APIs/communityGroups'

import { useCommunityColumns } from './columns'
import styles from './communityGroups.module.scss'
import { strings } from './strings'

interface CommunityGroupsProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: 'Community Groups', path: RouteName.CommunityGroups },
]

export const CommunityGroups: React.FC<CommunityGroupsProps> = () => {
  const { data } = useGetCommunityGroupsQuery({ page: 1 })

  const { resetFilters, ...columnsAndFilters } = useCommunityColumns()

  const onClickAddGroup = () => goToPage(RouteName.CommunityGroupNew)

  return (
    <div className={styles.container}>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.CommunityGroups}
        title={strings.title}
        className={styles.subHeader}>
        <div className={styles.buttonsContainer}>
          <Button onClick={resetFilters}>
            {generalStrings.clearFiltersButtonLabel}
          </Button>
          <Button
            type="primary"
            disabled={!data?.model_permissions?.can_create}
            onClick={onClickAddGroup}>
            {strings.addGroup}
          </Button>
        </div>
      </SubHeader>
      <PaginatedTable
        useLazyQuery={useLazyGetCommunityGroupsQuery}
        {...columnsAndFilters}
        className={styles.table}
      />
    </div>
  )
}
