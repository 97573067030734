/* eslint-disable react/no-danger */
import React from 'react'
import { useParams } from 'react-router-dom'
import { InfoTable } from 'src/components/InfoTable'
import { Table } from 'src/components/Table'
import { TableTitle } from 'src/components/TableTitle'
import { useGetSurvivorsHospitalTreatmentsQuery } from 'src/store/APIs/hospitalTreatments'
import { useGetSurvivorsOtherEventsQuery } from 'src/store/APIs/otherEvents'
import { useGetSurvivorsStrokeCausesQuery } from 'src/store/APIs/strokeCauses'
import { Survivor } from 'src/store/APIs/survivors/types'

import { EditableTab } from '../types'
import {
  hospitalTreatmentsColumns,
  otherHealthEventsColumns,
  strokeCausesColumns,
} from './columns'
import { strings } from './strings'
import styles from './strokeDetails.module.scss'
import { StrokeDetailsEdit } from './StrokeDetailsEdit'

interface StrokeDetailsProps extends EditableTab {
  survivorInfo?: Survivor
}

export const StrokeDetails: React.FC<StrokeDetailsProps> = ({
  survivorInfo,
  editMode,
  toggleEditMode,
}) => {
  const { id } = useParams<{ id: string }>()
  const { data: hospitalTreatments, isFetching: isFetchingHospitalTreatments } =
    useGetSurvivorsHospitalTreatmentsQuery(id)
  const { data: strokeCauses, isFetching: isFetchingStrokeCauses } =
    useGetSurvivorsStrokeCausesQuery(id)
  const { data: otherHealthEvents, isFetching: isFetchingOtherHealthEvents } =
    useGetSurvivorsOtherEventsQuery(id)

  const strokeDetailsTable = [
    {
      content: survivorInfo?.stroke_summary ? (
        <div
          dangerouslySetInnerHTML={{
            __html: survivorInfo.stroke_summary,
          }}
        />
      ) : undefined,
      label: strings.internalStrokeSummary,
    },
    {
      content: survivorInfo?.stroke_story ?? undefined,
      label: strings.strokeStory,
    },
    {
      content: survivorInfo?.type_of_stroke ?? undefined,
      label: strings.typeOfStroke,
    },
    { content: survivorInfo?.stroke_subtype, label: strings.strokeSubtype },
    {
      content: survivorInfo?.anatomical_location ?? undefined,
      label: strings.anatomicalLocation,
    },
    {
      content: survivorInfo?.occurrence_date?.format('MMMM D, YYYY'),
      label: strings.occurrenceDate,
    },
    {
      content: survivorInfo?.discharge_date?.format('MMMM D, YYYY'),
      label: strings.dischargeDate,
    },
  ]

  if (editMode && survivorInfo)
    return (
      <StrokeDetailsEdit
        survivor={survivorInfo}
        toggleEditMode={toggleEditMode}
      />
    )

  return (
    <div>
      <InfoTable rows={strokeDetailsTable} className={styles.spaceBwTables} />
      <Table
        className={styles.spaceBwTables}
        columns={strokeCausesColumns}
        dataSource={strokeCauses}
        caption={<TableTitle title={strings.strokeCausesTitle} />}
        pagination={false}
        loading={isFetchingStrokeCauses}
      />
      <Table
        className={styles.spaceBwTables}
        columns={hospitalTreatmentsColumns}
        dataSource={hospitalTreatments}
        caption={<TableTitle title={strings.hospitalTreatmentsTitle} />}
        pagination={false}
        loading={isFetchingHospitalTreatments}
      />
      <Table
        className={styles.spaceBwTables}
        columns={otherHealthEventsColumns}
        dataSource={otherHealthEvents}
        caption={<TableTitle title={strings.otherEventsTitle} />}
        pagination={false}
        loading={isFetchingOtherHealthEvents}
      />
    </div>
  )
}
