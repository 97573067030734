import React from 'react'
import { InfoTable } from 'src/components/InfoTable'
import { Links } from 'src/components/Links'
import { CarePartner } from 'src/store/APIs/carePartners/types'

import { strings } from './strings'

interface SurvivorsProps {
  carePartner?: CarePartner
}

export const Survivors: React.FC<SurvivorsProps> = ({ carePartner }) => {
  const rows = [
    {
      content: <Links items={carePartner?.survivors} />,
      label: strings.survivorsLabel,
    },
  ]

  return <InfoTable rows={rows} />
}
