import {
  MutationDefinition,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query'
import {
  MutationTrigger,
  UseLazyQuery,
} from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { ComponentType } from 'react'

import { StyledDrawerProps } from '../Drawer'
import { strings } from './strings'

export enum DrawerState {
  Edit = 'edit',
  View = 'view',
  Add = 'add',
}

export interface DrawerRenderProps {
  selectedId?: string
  data?: any
  setCanSubmitForm?: (value: boolean) => void
  setCanSaveDraft?: (value: boolean) => void
}

export interface ViewDrawerRenderProps {
  data?: any
  goBack?: () => void
  openAnotherDrawer?: (id: string) => () => void
}

export interface DrawerWithStateProps extends StyledDrawerProps {
  RenderEdit?: ComponentType<DrawerRenderProps>
  RenderAdd: ComponentType<DrawerRenderProps>
  RenderView: ComponentType<ViewDrawerRenderProps>
  selectedId?: string
  onCloseDrawer: () => void
  resourceName: string
  triggerAddResourceMutation?: MutationTrigger<
    MutationDefinition<any, any, any, any>
  >
  triggerEditResourceMutation?: MutationTrigger<
    MutationDefinition<any, any, any, any>
  >
  triggerDeleteResourceMutation?: MutationTrigger<
    MutationDefinition<any, any, any, any>
  >
  useLazyGetResource: UseLazyQuery<QueryDefinition<any, any, any, any, any>>
  handleSubmit?: (values: any) => void
  hasSave?: boolean
  isDraftKey?: string
  isLoading?: boolean
  deleteModalTitle?: string
  disableModalTitle?: string
  isLearn?: boolean
  goBack?: boolean
  addData?: any
  openAnotherDrawer?: (id: string) => () => void
  initialDrawerState?: DrawerState
  removeResource?: (
    canUnassign: boolean,
    closeDrawer: () => void,
    articleIds?: string[],
  ) => void
}

export const getDrawerTitle = (
  drawerState: DrawerState,
  resourceTitle: string,
) => {
  switch (drawerState) {
    case DrawerState.Add:
      return `${strings.addPrefix} ${resourceTitle}`
    case DrawerState.Edit:
      return `${strings.editPrefix} ${resourceTitle}`
    case DrawerState.View:
      return resourceTitle
    default:
      return ''
  }
}
