import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { DocumentsData } from 'src/store/APIs/documents/types'

import { strings } from './strings'
import { DocumentsDataIndex } from './types'

export const columns: (
  onClickRow?: (id: string) => () => void,
) => ColumnsType<DocumentsData> = onClickRow => [
  {
    ...generateColumnTitle(DocumentsDataIndex.name, strings.nameLabel),
    render: (title, record) => {
      return record.permissions?.can_read ? (
        <Button type="link" onClick={onClickRow && onClickRow(record.id)}>
          {title}
        </Button>
      ) : (
        <span>{title}</span>
      )
    },
  },
  {
    ...generateColumnTitle(
      DocumentsDataIndex.description,
      strings.descriptionLabel,
    ),
  },
  {
    ...generateColumnTitle(DocumentsDataIndex.file, strings.fileLabel),
    render(_, record) {
      return (
        <Link to={{ pathname: record.url }} target="_blank">
          {record.fileName}
        </Link>
      )
    },
  },
]
