import { MenuOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import { format } from 'util'

import styles from './defaultNavigatorTask.module.scss'
import { strings } from './strings'
import {
  DefaultNavigatorTask as DefaultNavigatorTaskType,
  defaultNavigatorTaskTypeProps,
  getNavigatorTypeLabel,
} from './types'

interface DefaultNavigatorTaskProps {
  defaultTask: DefaultNavigatorTaskType
  index: number
  sortable?: boolean
  isAdding?: boolean
  onClickEdit: (position: number) => () => void
}

export const DefaultNavigatorTask: React.FC<DefaultNavigatorTaskProps> = ({
  defaultTask,
  index,
  sortable,
  isAdding,
  onClickEdit,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: defaultTask.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <div ref={setNodeRef} style={style} className={styles.navigatorTaskCard}>
      <div className={styles.topSection}>
        <div className={styles.positionAndTitle}>
          {sortable && (
            <MenuOutlined
              className={styles.menuIcon}
              ref={setActivatorNodeRef}
              {...attributes}
              {...listeners}
            />
          )}
          <div className={styles.positionNumber}>
            {sortable ? index + 1 : defaultTask?.position}
          </div>
          {defaultTask?.description}
        </div>
        {!sortable && (
          <Button
            type="link"
            onClick={onClickEdit(defaultTask.position)}
            disabled={isAdding}>
            {strings.editLabel}
          </Button>
        )}
      </div>
      <div className={styles.bottomSection}>
        <Tag
          color={
            defaultNavigatorTaskTypeProps(defaultTask.goal_date_type)?.color
          }
          className={styles.statusLabel}>
          {defaultNavigatorTaskTypeProps(defaultTask.goal_date_type)?.title}
        </Tag>
        {format(
          strings.daysFrom,
          getNavigatorTypeLabel(defaultTask.goal_date_type),
          defaultTask.goal_date,
        )}
      </div>
    </div>
  )
}
