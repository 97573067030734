import { Input, InputProps, Tooltip } from 'antd'
import React from 'react'

interface TextInputProps extends InputProps {
  label?: string
  tooltipTitle?: string
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  required,
  tooltipTitle = '',
  ...props
}: TextInputProps) => {
  return (
    <>
      <div>
        {label}
        {required && '*'}
      </div>
      <Tooltip title={tooltipTitle}>
        <Input
          {...props}
          required={required}
          placeholder={placeholder ?? label}
        />
      </Tooltip>
    </>
  )
}
