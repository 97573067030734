import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    closeLabel: 'Close',
    filterLabel: 'Filters',
    resetLabel: 'Reset',
    searchLabel: 'Search',
    searchPlaceholder: 'Search {0}',
  },
})
