import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    actionsLabel: 'Actions',
    articles: 'Articles',
    articlesCompleted: 'Completion',
    assignPackageLabel: 'Assign Package',
    cpTooltipMessage: `In care partner's timezone`,
    createNewLabel: 'Create new package',
    // eslint-disable-next-line sort-keys
    createdAt: 'Created Date',
    deploymentDate: 'Deployment Date',
    editButton: 'Edit',
    errorMessage: `You can't save with errors`,
    learnTrackBasic: 'Basic',
    learnTrackBasicSpanish: 'Basic Spanish',
    learnTrackExtended: 'Extended',
    learnTrackLabel: 'Learn Track:',
    learnTrackModerate: 'Moderate',
    learnTrackNone: 'None',
    packageStatus: 'Status',
    packageTitle: 'Package',
    radioGroupTitle: 'Existing or new package*',
    requiredValidation: 'This field is required',
    resourceName: 'Package',
    selectExistingLabel: 'Select existing package',
    selectPackageLabel: 'Select Package',
    survivorTooltipMessage: `In survivor's timezone`,
    unassignButton: 'Unassign',
    unassignPackageTitle:
      'Are you sure you want to unassign this package? It will be removed for the survivor/care partner.',
    uncompletedPackageButton: 'Notify Uncompleted Package',
    userCompletedDate: 'Completed Date',
  },
})
