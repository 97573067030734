import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addButtonLabel: 'Add Note',
    createdAtColumnLabel: 'Created At',
    createdByColumnLabel: 'Created By',
    deleteLink: 'Delete',
    descriptionLabel: 'Description',
    documentsTableTitle: 'Documents',
    encounterDateColumnLabel: 'Encounter Date',
    escalationColumnLabel: 'Escalation',
    fileLabel: 'File',
    isDraftColumnLabel: 'Note Status',
    lastUpdatedAtColumnLabel: 'Last Updated Date',
    lastUpdatedByColumnLabel: 'Last Updated By',
    nameLabel: 'Name',
    notesPlaceholder: 'Add a note',
    participantsColumnLabel: 'Participants',
    requiredValidation: 'This field is required',
    resourceName: 'Note',
    selectPlaceholder: 'Select',
    statusColumnLabel: 'Visit Status',
    summaryColumnLabel: 'Note',
    tagDraftNo: 'Submitted',
    tagDraftYes: 'Draft',
    tagNo: 'No',
    tagYes: 'Yes',
    titleColumnLabel: 'Title',
    topicColumnLabel: 'Topic',
    typeColumnLabel: 'Channel',
    updatedAtColumnLabel: 'Updated At',
    updatedByColumnLabel: 'Updated By',
  },
})
