import { ReactNode } from 'react'
import { TagProps } from 'src/components/Tag/types'
import { Filters, LearnStatus } from 'src/store/APIs/types'

import { strings } from './strings'

export enum LearnTabNames {
  articles = 'Articles',
  packages = 'Packages',
}

export interface LearnTab {
  content: ReactNode
  tabName: LearnTabNames
}

export interface LearnStatusProps {
  title: LearnStatusOptions | string
  color: TagProps['color']
}

export enum LearnStatusOptions {
  Submitted = 'Submitted',
  Draft = 'Draft',
  Disabled = 'Disabled',
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
  ParentCopy = 'Parent Copy',
  HasAssignedChildren = 'Has Assigned Children',
}

export const learnStatusProps = (status: LearnStatus): LearnStatusProps => {
  switch (status) {
    case LearnStatus.submitted:
      return {
        color: 'green',
        title: LearnStatusOptions.Submitted,
      }
    case LearnStatus.draft:
      return {
        color: 'magenta',
        title: LearnStatusOptions.Draft,
      }
    case LearnStatus.disabled:
      return {
        color: 'orange',
        title: LearnStatusOptions.Disabled,
      }
    case LearnStatus.parentCopy:
      return {
        color: 'lime',
        title: LearnStatusOptions.ParentCopy,
      }
    case LearnStatus.assigned:
      return {
        color: 'cyan',
        title: LearnStatusOptions.Assigned,
      }
    case LearnStatus.unassigned:
      return {
        color: 'volcano',
        title: LearnStatusOptions.Unassigned,
      }
    case LearnStatus.hasAssignedChildren:
      return {
        color: 'geekblue',
        title: LearnStatusOptions.HasAssignedChildren,
      }
    default:
      return {
        color: 'default',
        title: strings.defaultTagLabel,
      }
  }
}

export const articleStatusFilters = [
  {
    text: LearnStatusOptions.Submitted,
    value: LearnStatus.submitted,
  },
  {
    text: LearnStatusOptions.Draft,
    value: LearnStatus.draft,
  },
  {
    text: LearnStatusOptions.Assigned,
    value: LearnStatus.assigned,
  },
  {
    text: LearnStatusOptions.Unassigned,
    value: LearnStatus.unassigned,
  },
  {
    text: LearnStatusOptions.ParentCopy,
    value: LearnStatus.parentCopy,
  },
  {
    text: LearnStatusOptions.HasAssignedChildren,
    value: LearnStatus.hasAssignedChildren,
  },
]

export const packageStatusFilters = [
  {
    text: LearnStatusOptions.Submitted,
    value: LearnStatus.submitted,
  },
  {
    text: LearnStatusOptions.Disabled,
    value: LearnStatus.disabled,
  },
  {
    text: LearnStatusOptions.Assigned,
    value: LearnStatus.assigned,
  },
  {
    text: LearnStatusOptions.Unassigned,
    value: LearnStatus.unassigned,
  },
  {
    text: LearnStatusOptions.HasAssignedChildren,
    value: LearnStatus.hasAssignedChildren,
  },
]

export const areFiltersOn = (filters: Filters | undefined) => {
  if (filters) {
    return Object.values(filters).some(value => value !== null)
  }
  return false
}
