import React from 'react'
import { CustomDate, Status } from 'src/common/types'
import { AccountManagementView } from 'src/components/AccountManagementView'
import {
  useChangeSurvivorStatusMutation,
  useSendSurvivorGraduationEmailMutation,
  useSendSurvivorsAppInstallEmailMutation,
  useSendSurvivorsAppInstallSMSMutation,
  useSendSurvivorsSignUpEmailMutation,
  useSendSurvivorsSignUpSMSMutation,
  useSendSurvivorWelcomeEmailMutation,
} from 'src/store/APIs/survivors'

interface AccountManagementProps {
  userStatus?: Status
  mobileUserId?: string
  welcomeEmailSentDate?: CustomDate
  graduationEmailSentDate?: CustomDate
}

export const AccountManagement: React.FC<AccountManagementProps> = ({
  userStatus,
  mobileUserId,
  welcomeEmailSentDate,
  graduationEmailSentDate,
}) => (
  <AccountManagementView
    userStatus={userStatus}
    mobileUserId={mobileUserId}
    welcomeEmailSentDate={welcomeEmailSentDate}
    graduationEmailSentDate={graduationEmailSentDate}
    useSendGraduationEmail={useSendSurvivorGraduationEmailMutation}
    useSendWelcomeEmail={useSendSurvivorWelcomeEmailMutation}
    useSendAppInstallEmail={useSendSurvivorsAppInstallEmailMutation}
    useSendAppInstallSMS={useSendSurvivorsAppInstallSMSMutation}
    useSendSignUpEmail={useSendSurvivorsSignUpEmailMutation}
    useSendSignUpSMS={useSendSurvivorsSignUpSMSMutation}
    useChangeUserStatus={useChangeSurvivorStatusMutation}
  />
)
