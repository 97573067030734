import { ColumnsType } from 'antd/es/table'
import { formatDateToRender } from 'src/common/types'
import { generateColumnTitle } from 'src/helpers/utils'
import { SurvivorHealthEventData } from 'src/store/APIs/healthEvents/types'

import { strings } from './strings'

export enum HealthEventsDataIndex {
  'major_health_event' = 'major_health_event',
  'occurrence_date' = 'occurrence_date',
  'description' = 'description',
}

export const columns: ColumnsType<SurvivorHealthEventData> = [
  {
    ...generateColumnTitle(
      HealthEventsDataIndex.major_health_event,
      strings.healthEventsColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      HealthEventsDataIndex.occurrence_date,
      strings.occurrenceDateLabel,
    ),
    render: formatDateToRender,
  },
  {
    ...generateColumnTitle(
      HealthEventsDataIndex.description,
      strings.descriptionLabel,
    ),
  },
]
