import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { PackageInfo } from 'src/store/APIs/packages/types'

import { audienceFilters } from '../Articles/types'
import { learnStatusProps, packageStatusFilters } from '../types'
import { strings } from './strings'
import { PackagesDataIndex } from './types'

export const usePackagesColumns = (onClickRow: (id: string) => () => void) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()
  const columns: ColumnsType<PackageInfo> = [
    {
      ...generateColumnTitle(PackagesDataIndex.title, strings.titleLabel),
      ...generateColumnSearchProps(PackagesDataIndex.title),
      filteredValue: filteredInfo.filters?.title || null,
      render: (title, record) => {
        return onClickRow ? (
          <Button type="link" onClick={onClickRow(record.id)}>
            {title}
          </Button>
        ) : (
          title
        )
      },
      sorter: true,
    },
    {
      ...generateColumnTitle(
        PackagesDataIndex.internal_name,
        strings.internalNameLabel,
      ),
      ...generateColumnSearchProps(PackagesDataIndex.internal_name),
      filteredValue: filteredInfo.filters?.internal_name || null,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        PackagesDataIndex.internal_id,
        strings.internalIdLabel,
      ),
      ...generateColumnSearchProps(PackagesDataIndex.internal_id),
      filteredValue: filteredInfo.filters?.internal_id || null,
      sorter: true,
    },
    {
      ...generateColumnTitle(PackagesDataIndex.audience, strings.audienceLabel),
      filteredValue: filteredInfo.filters?.audience || null,
      filters: audienceFilters,
      sorter: true,
    },
    {
      ...generateColumnTitle(PackagesDataIndex.created_at, strings.createdDate),
      render: deserializeShortFormattedDate,
      sorter: true,
    },
    {
      ...generateColumnTitle(PackagesDataIndex.status, strings.statusLabel),
      filteredValue: filteredInfo.filters?.status || null,
      filters: packageStatusFilters,
      render(value) {
        return (
          <Tag color={learnStatusProps(value).color}>
            {learnStatusProps(value).title}
          </Tag>
        )
      },
      sorter: true,
    },
  ]

  return {
    filteredInfo,
    ...restFilteredInfo,
    columns,
  }
}
