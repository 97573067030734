import { States } from 'src/store/APIs/survivors/types'

enum PreferredPronoun {
  HeHim = 'He/Him',
  SheHer = 'She/Her',
  TheyThem = 'They/Them',
}

enum LegalSex {
  Male = 'Male',
  Female = 'Female',
}

enum Ethnicity {
  HispanicOrLatino = 'Hispanic Or Latino',
  NotHispanicOrLatino = 'Not Hispanic Or Latino',
  Declined = 'Declined',
  Unknown = 'Unknown',
}

enum Race {
  AmericanIndianOrAlaskaNative = 'American Indian Or Alaska Native',
  Asian = 'Asian',
  BlackOrAfricanAmerican = 'Black Or African American',
  NativeHawaiianOrOtherPacificIslander = 'Native Hawaiian Or Other Pacific Islander',
  White = 'White',
  Declined = 'Declined',
  Unknown = 'Unknown',
  Other = 'Other',
}

export enum CarePartnerType {
  NEW = 'Create new care partner',
  EXISTING = 'Existing care partner',
}

export const selectOptions = () => {
  const preferredPronounOptions = [
    { label: PreferredPronoun.HeHim, value: PreferredPronoun.HeHim },
    { label: PreferredPronoun.SheHer, value: PreferredPronoun.SheHer },
    { label: PreferredPronoun.TheyThem, value: PreferredPronoun.TheyThem },
  ]
  const legalSexOptions = [
    { label: LegalSex.Female, value: LegalSex.Female },
    { label: LegalSex.Male, value: LegalSex.Male },
  ]
  const ethnicityOptions = [
    { label: Ethnicity.HispanicOrLatino, value: Ethnicity.HispanicOrLatino },
    {
      label: Ethnicity.NotHispanicOrLatino,
      value: Ethnicity.NotHispanicOrLatino,
    },
    { label: Ethnicity.Declined, value: Ethnicity.Declined },
    { label: Ethnicity.Unknown, value: Ethnicity.Unknown },
  ]
  const raceOptions = [
    {
      label: Race.AmericanIndianOrAlaskaNative,
      value: Race.AmericanIndianOrAlaskaNative,
    },
    { label: Race.Asian, value: Race.Asian },
    {
      label: Race.BlackOrAfricanAmerican,
      value: Race.BlackOrAfricanAmerican,
    },
    { label: Race.Declined, value: Race.Declined },
    {
      label: Race.NativeHawaiianOrOtherPacificIslander,
      value: Race.NativeHawaiianOrOtherPacificIslander,
    },
    { label: Race.White, value: Race.White },
    { label: Race.Other, value: Race.Other },
    { label: Race.Unknown, value: Race.Unknown },
  ]
  const stateOptions = Object.entries(States).map(([label, value]) => ({
    label,
    value,
  }))

  return {
    ethnicityOptions,
    legalSexOptions,
    preferredPronounOptions,
    raceOptions,
    stateOptions,
  }
}
