import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  GetStrokeCausesResponse,
  StrokeCauseData,
  strokeCauseDeserializer,
  strokeCausesDeserializer,
  SurvivorStrokeCauseData,
  survivorStrokeCausesDeserializer,
} from './types'

export const getStrokeCauses = (builder: Builder) =>
  builder.query<PaginatedItems<StrokeCauseData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.StrokeCauses),
    query: endpointWithFilters(Endpoints.GetStrokeCauses),
    transformResponse: (response: GetStrokeCausesResponse) =>
      strokeCausesDeserializer(response),
  })

export const updateSurvivorsStrokeCauses = (builder: Builder) =>
  builder.mutation<void, { stroke_causes: string[]; id: string }>({
    invalidatesTags: () => [{ type: Tags.StrokeCauses }],
    query: ({ id, stroke_causes }) => ({
      body: { stroke_causes },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorsStrokeCauses, id),
    }),
  })

export const getSurvivorsStrokeCauses = (builder: Builder) =>
  builder.query<SurvivorStrokeCauseData[], string>({
    providesTags: () => [{ type: Tags.StrokeCauses }],
    query: id => format(Endpoints.GetSurvivorsStrokeCauses, id),
    transformResponse: ({ stroke_causes }) =>
      survivorStrokeCausesDeserializer(stroke_causes),
  })

export const getStrokeCause = (builder: Builder) =>
  builder.query<StrokeCauseData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.StrokeCauses }],
    query: ({ id }) => format(Endpoints.GetStrokeCause, id),
    transformResponse: ({ cause }) => strokeCauseDeserializer(cause),
  })

export const addStrokeCause = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.StrokeCauses),
    query: ({ body }) => ({
      body: { cause: body },
      method: HttpMethod.Post,
      url: Endpoints.AddStrokeCause,
    }),
    transformResponse: performActionTransformResponse,
  })

export const editStrokeCause = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.StrokeCauses }],
    query: ({ body, id }) => ({
      body: { cause: body },
      method: HttpMethod.Put,
      url: format(Endpoints.EditStrokeCause, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteStrokeCause = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.StrokeCauses),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteStrokeCause, id),
    }),
    transformResponse: performActionTransformResponse,
  })
