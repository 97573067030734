import {
  Radio as AntdRadio,
  RadioGroupProps,
  RadioProps as AntdRadioProps,
} from 'antd'
import React from 'react'

export interface RadioProps extends AntdRadioProps {
  children?: React.ReactNode
}

interface RadioSubComponents {
  Group: React.FC<RadioGroupProps>
}

export const Radio: React.FC<RadioProps> & RadioSubComponents = (
  props: RadioProps,
) => <AntdRadio {...props}>{props.children}</AntdRadio>

export const Group: React.FC<RadioGroupProps> = props => (
  <AntdRadio.Group {...props}>{props.children}</AntdRadio.Group>
)

Radio.Group = Group
