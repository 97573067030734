import { baseApi } from 'src/store/APIs'

import {
  addDocument,
  deleteDocument,
  getDocument,
  getSurvivorDocuments,
  updateDocument,
} from './actions'

export const documentsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addDocument: addDocument(builder),
    deleteDocument: deleteDocument(builder),
    getDocument: getDocument(builder),
    getSurvivorDocuments: getSurvivorDocuments(builder),
    updateDocument: updateDocument(builder),
  }),
})

export const {
  useGetSurvivorDocumentsQuery,
  useLazyGetSurvivorDocumentsQuery,
  useUpdateDocumentMutation,
  useGetDocumentQuery,
  useAddDocumentMutation,
  useLazyGetDocumentQuery,
  useDeleteDocumentMutation,
} = documentsApi
