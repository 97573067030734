import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    healthcareRoleSection: 'Healthcare Team Role',
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    healthConditionsSection: 'Health Conditions',
    healthEventsSection: 'Health Events',
    hospitalsSection: 'Hospitals',
    impairmentsSection: 'Impairments',
    strokeDetailsSection: 'Stroke Details',
    title: 'Survivor Profile',
  },
})
