import React from 'react'
import { AppLink, RouteName } from 'src/routes'

// TODO: implement this page
export const NotFound = () => (
  <div>
    This page does not exist!
    <AppLink routeName={RouteName.Home}>Go Home</AppLink>
  </div>
)
