import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    daysFrom: 'Days from %s Date: %s',
    editLabel: 'Edit',
    onboardingBased: 'Onboarding Date Based',
    registrationBased: 'Registration Date Based',
  },
})
