import { baseApi } from 'src/store/APIs'

import {
  addPackage,
  assignPackage,
  deletePackage,
  editPackageDeploymentDate,
  getPackage,
  getPackages,
  updatePackage,
  updatePackageRow,
} from './actions'

export const packagesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addPackage: addPackage(builder),
    assignPackage: assignPackage(builder),
    deletePackage: deletePackage(builder),
    editPackageDeploymentDate: editPackageDeploymentDate(builder),
    getPackage: getPackage(builder),
    getPackages: getPackages(builder),
    updatePackage: updatePackage(builder),
    updatePackageRow: updatePackageRow(builder),
  }),
})

export const {
  useAssignPackageMutation,
  useDeletePackageMutation,
  useUpdatePackageMutation,
  useUpdatePackageRowMutation,
  useLazyGetPackagesQuery,
  useLazyGetPackageQuery,
  useAddPackageMutation,
  useGetPackagesQuery,
  useEditPackageDeploymentDateMutation,
} = packagesApi
