import { deserializeFormattedDate } from 'src/common/types'

import { Entity, PaginatedItems, PaginatedModel } from '../types'

export enum Endpoints {
  UpdateSurvivorsOtherEvents = '/cms/v1/mobile_survivors/%s/profile/complications/batch_put.json',
  GetSurvivorsOtherEvents = '/cms/v1/mobile_survivors/%s/profile/complications.json',
  GetOtherEvents = '/cms/v1/global_settings/complications.json',
  AddOtherEvent = '/cms/v1/global_settings/complications.json',
  GetOtherEvent = '/cms/v1/global_settings/complications/%s.json',
  EditOtherEvent = '/cms/v1/global_settings/complications/%s.json',
  DeleteOtherEvent = '/cms/v1/global_settings/complications/%s.json',
}

export interface GetOtherEventsResponse extends PaginatedModel {
  complications: OtherEventsResponse[]
}

export interface OtherEventsResponse extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export interface OtherEventsData extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export const otherEventDeserializer = (
  data: OtherEventsResponse,
): OtherEventsData => ({
  ...data,
})

export const otherEventsDeserializer = (
  data: GetOtherEventsResponse,
): PaginatedItems<OtherEventsData> => ({
  items: data.complications.map(otherEventDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

export interface SurvivorOtherEventsResponse {
  complication: {
    name: string
    id: string
  }
  created_at: string
  id: string
  updated_at: string
}

export interface SurvivorOtherEventData {
  created_at: string
  event: string
  id: string
}

export const survivorOtherEventDeserializer = (
  data: SurvivorOtherEventsResponse,
): SurvivorOtherEventData => {
  return {
    created_at: deserializeFormattedDate(data.created_at),
    event: data.complication.name,
    id: data.complication.id,
  }
}

export const survivorOtherEventsDeserializer = (
  data: SurvivorOtherEventsResponse[],
) => data.map(survivorOtherEventDeserializer)
