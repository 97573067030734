import { LayoutType, withLayout } from 'src/hocs/withLayout'

import { SurvivorResetPassword } from './SurvivorResetPassword'

const WrappedSurvivorResetPassword = withLayout(
  LayoutType.Default,
  SurvivorResetPassword,
)

export { WrappedSurvivorResetPassword as SurvivorResetPassword }
