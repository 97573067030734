/* eslint-disable no-nested-ternary */
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import colors from 'src/assets/stylesheets/colors.scss'
import { generalStrings } from 'src/common/generalStrings'
import { getUserFullName } from 'src/common/helpers'
import { SectionNames, Status, statusProps, TabTypes } from 'src/common/types'
import { Button } from 'src/components/Button'
import { DeleteButton } from 'src/components/DeleteButton'
import { Spinner } from 'src/components/Spinner'
import { SubHeader } from 'src/components/SubHeader'
import { Tabs } from 'src/components/Tabs'
import { Tag } from 'src/components/Tag'
import { useTabParams } from 'src/hooks/useTabParams'
import { useToggleEditMode } from 'src/hooks/useToggleEditMode'
import { RouteName } from 'src/routes'
import {
  useDeleteSurvivorMutation,
  useGetSurvivorsAlertsByTabQuery,
  useLazyGetSurvivorQuery,
} from 'src/store/APIs/survivors'
import { AlertPriority } from 'src/store/APIs/survivors/types'
import { ConversationType } from 'src/store/APIs/types'

import { AccountManagement } from './AccountManagement'
import { Alerts } from './Alerts'
import { Assessments } from './Assessments'
import { BasicDemographics } from './BasicDemographics'
import { CommunicationLogs } from './CommunicationLogs'
import { Documents } from './Documents'
import { Groups } from './Groups'
import { HealthcareTeam } from './HealthcareTeam'
import { HealthConditions } from './HealthConditions'
import { HealthEvents } from './HealthEvents'
import { HospitalInfo } from './HospitalInfo'
import { Impairments } from './Impairments'
import { Learn } from './Learn'
import { NavigatorTasks } from './NavigatorTasks'
import { PlanOfCare } from './PlanOfCare'
import { PurchaseTodos } from './PurchaseTodos'
import { QuestionsToAsk } from './QuestionsToAsk'
import { QuickJots } from './QuickJots'
import { strings } from './strings'
import { StrokeDetails } from './StrokeDetails'
import { SurvivorConversation } from './SurvivorConversation'
import styles from './survivorView.module.scss'
import { Tacas } from './Tacas'
import { Todos } from './Todos'
import { Tab, TabNames } from './types'

interface SurvivorViewProps {}

// TODO: Replace mockedBreadcrumbRoutes with real routes
const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: 'Survivors', path: RouteName.Survivors },
]

export const SurvivorView: React.FC<SurvivorViewProps> = () => {
  const { id } = useParams<{ id: string }>()
  const [triggerGetSurvivorQuery, { data, isLoading }] =
    useLazyGetSurvivorQuery()
  const history = useHistory()
  const [triggerDeleteSurvivor, { isSuccess: deleteSuccess }] =
    useDeleteSurvivorMutation()
  const { data: tabsWithAlert } = useGetSurvivorsAlertsByTabQuery(id)

  const { editMode, toggleEditMode } = useToggleEditMode()

  useEffect(() => {
    if (deleteSuccess) {
      history.goBack()
    }
  }, [deleteSuccess])

  useEffect(() => {
    triggerGetSurvivorQuery(id)
  }, [])

  const subHeaderTitle = getUserFullName(data?.first_name, data?.last_name)

  const tabPermissions = data?.tabs_permissions

  const TabMap: { [key: string]: Tab } = {
    [TabTypes.Alerts]: { content: <Alerts />, tabName: TabNames.alerts },
    [TabTypes.BasicDemographics]: {
      content: (
        <BasicDemographics
          survivor={data}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
        />
      ),
      editable: true,
      tabName: TabNames.basic_demographics,
    },
    [TabTypes.AccountManagement]: {
      content: (
        <AccountManagement
          userStatus={data?.status as Status}
          mobileUserId={data?.mobile_user_id}
          welcomeEmailSentDate={data?.welcome_mail_date}
          graduationEmailSentDate={data?.graduation_mail_date}
        />
      ),
      tabName: TabNames.account_management,
    },
    [TabTypes.Documents]: {
      content: <Documents />,
      tabName: TabNames.documents,
    },
    [TabTypes.PlanOfCare]: {
      content: (
        <PlanOfCare editMode={editMode} toggleEditMode={toggleEditMode} />
      ),
      editable: true,
      tabName: TabNames.plan_of_care,
    },
    [TabTypes.HospitalInfo]: {
      content: (
        <HospitalInfo
          survivor={data}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
        />
      ),
      editable: true,
      tabName: TabNames.hospital_info,
    },
    [TabTypes.StrokeDetails]: {
      content: (
        <StrokeDetails
          survivorInfo={data}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
        />
      ),
      editable: true,
      tabName: TabNames.stroke_details,
    },
    [TabTypes.Impairments]: {
      content: (
        <Impairments editMode={editMode} toggleEditMode={toggleEditMode} />
      ),
      tabName: TabNames.impairments,
    },
    [TabTypes.HealthCareTeam]: {
      content: (
        <HealthcareTeam editMode={editMode} toggleEditMode={toggleEditMode} />
      ),
      editable: true,
      tabName: TabNames.health_care_team,
    },
    [TabTypes.HealthEvents]: {
      content: (
        <HealthEvents editMode={editMode} toggleEditMode={toggleEditMode} />
      ),
      tabName: TabNames.health_events,
    },
    [TabTypes.HealthConditions]: {
      content: (
        <HealthConditions editMode={editMode} toggleEditMode={toggleEditMode} />
      ),
      tabName: TabNames.health_conditions,
    },
    [TabTypes.Learn]: {
      content: <Learn />,
      tabName: TabNames.learning,
    },
    [TabTypes.QuestionsToAsk]: {
      content: <QuestionsToAsk />,
      tabName: TabNames.questions_to_ask,
    },
    [TabTypes.ToDos]: { content: <Todos />, tabName: TabNames.to_do_s },
    [TabTypes.PurchaseToDos]: {
      content: <PurchaseTodos />,
      tabName: TabNames.purchase_todos,
    },
    [TabTypes.QuickJots]: {
      content: <QuickJots />,
      tabName: TabNames.quick_jots,
    },
    [TabTypes.Tacas]: { content: <Tacas />, tabName: TabNames.tacas },
    [TabTypes.Assessments]: {
      content: <Assessments />,
      tabName: TabNames.assessments,
    },
    [TabTypes.PrivateConversation]: {
      content: <SurvivorConversation type={ConversationType.Private} />,
      tabName: TabNames.private_conversation,
    },
    [TabTypes.GroupConversation]: {
      content: <SurvivorConversation type={ConversationType.Group} />,
      tabName: TabNames.group_conversation,
    },
    [TabTypes.Groups]: { content: <Groups />, tabName: TabNames.groups },
    [TabTypes.CommunicationLogs]: {
      content: <CommunicationLogs />,
      tabName: TabNames.communication_logs,
    },
    [TabTypes.NavigatorTasks]: {
      content: <NavigatorTasks />,
      tabName: TabNames.navigator_tasks,
    },
  }

  const items = tabPermissions?.map(tab => TabMap[tab])

  const { currentTab, onChangeTab: navigateToTab } = useTabParams(
    RouteName.SurvivorView,
  )

  const onChangeTab = (activeKey: string) => {
    if (editMode) toggleEditMode()
    navigateToTab(activeKey)
  }

  if (isLoading) return <Spinner />
  return (
    <>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        title={subHeaderTitle}
        section={SectionNames.Survivors}>
        <div className={styles.buttonContainer}>
          {data?.permissions?.can_destroy && (
            <DeleteButton
              className={styles.button}
              onClickYes={triggerDeleteSurvivor}
              id={id}
              modalTitle={strings.deleteConfirmationMessage}>
              {strings.deleteButtonLabel}
            </DeleteButton>
          )}
          <Tag color={statusProps(data?.status as Status).color}>
            {statusProps(data?.status as Status).title}
          </Tag>
        </div>
      </SubHeader>
      <div className={styles.separatorContainer}>
        <div className={styles.separator} />
      </div>
      <Tabs
        defaultActiveKey={TabNames.basic_demographics}
        activeKey={currentTab}
        onChange={onChangeTab}
        type="card"
        items={items?.map(item => {
          const key = item.tabName
          const tabAlert = tabsWithAlert?.find(
            tabAlertInfo => tabAlertInfo.tab === key,
          )
          return {
            children: isLoading ? (
              <Spinner />
            ) : (
              <div className={styles.tabContainer}>
                {item.editable && data?.permissions?.can_update ? (
                  !editMode ? (
                    <div className={styles.editButtonContainer}>
                      <Button onClick={toggleEditMode}>
                        {strings.editButtonLabel}
                      </Button>
                    </div>
                  ) : (
                    <div className={styles.editButtonContainer}>
                      <Button onClick={toggleEditMode}>
                        {strings.cancelButtonLabel}
                      </Button>
                    </div>
                  )
                ) : undefined}
                {item.content}
              </div>
            ),
            key,
            label: (
              <div className={styles.tab}>
                <div className={styles.tabLabel}>{item.tabName}</div>
                {tabAlert && (
                  <div
                    className={styles.badge}
                    style={{
                      backgroundColor:
                        tabAlert.priority === AlertPriority.High
                          ? colors.red_7
                          : colors.green_7,
                    }}
                  />
                )}
              </div>
            ),
          }
        })}
      />
    </>
  )
}
