import { TagProps } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { generalStrings } from './generalStrings'

// INFO: Is the way to configure "plugins" in dayjs, for extending and adding the .local() feature
// https://day.js.org/docs/en/plugin/utc
dayjs.extend(utc)
// https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(advancedFormat)
// https://day.js.org/docs/en/plugin/timezone
dayjs.extend(timezone)

export enum LocalStorageKey {
  isLoggedIn = 'isLoggedIn',
}

export interface StatusProps {
  title: StatusOptions
  color: TagProps['color']
}

export enum Status {
  activated = 'activated',
  activation_link_sent = 'activation_link_sent',
  password_reset_in_cms = 'password_reset_in_cms',
  deactivated = 'deactivated',
  new_user = 'new_user',
  in_progress = 'in_progress',
}

export enum StatusOptions {
  Activated = 'Activated',
  ActivationLinkSent = 'Activation Link Sent',
  PasswordResetInCms = 'Password Reset In CMS',
  Deactivated = 'Deactivated',
  NewUser = 'New User',
  InProgress = 'In Progress',
  NotDefined = 'Not Defined',
}

export const statusFilters = [
  { text: StatusOptions.Activated, value: Status.activated },
  {
    text: StatusOptions.ActivationLinkSent,
    value: Status.activation_link_sent,
  },
  {
    text: StatusOptions.PasswordResetInCms,
    value: Status.password_reset_in_cms,
  },
  { text: StatusOptions.Deactivated, value: Status.deactivated },
  { text: StatusOptions.NewUser, value: Status.new_user },
  { text: StatusOptions.InProgress, value: Status.in_progress },
]

export enum AlertsOptions {
  Yes = 'yes',
  No = 'no',
}

export const alertsFilters = [
  { text: generalStrings.yes, value: AlertsOptions.Yes },
  { text: generalStrings.no, value: AlertsOptions.No },
]

export const enum BiologicalSex {
  Male = 'Male',
  Female = 'Female',
}

export const statusProps = (status: Status): StatusProps => {
  switch (status) {
    case Status.activated:
      return {
        color: 'green',
        title: StatusOptions.Activated,
      }
    case Status.activation_link_sent:
      return {
        color: 'blue',
        title: StatusOptions.ActivationLinkSent,
      }
    case Status.password_reset_in_cms:
      return {
        color: 'red',
        title: StatusOptions.PasswordResetInCms,
      }
    case Status.deactivated:
      return {
        color: 'default',
        title: StatusOptions.Deactivated,
      }
    case Status.new_user:
      return {
        color: 'purple',
        title: StatusOptions.NewUser,
      }
    case Status.in_progress:
      return {
        color: 'volcano',
        title: StatusOptions.InProgress,
      }
    default:
      return {
        color: 'default',
        title: StatusOptions.NotDefined,
      }
  }
}

export type CustomDate = Dayjs

export const dateDeserializer = (date: string, tz?: string): CustomDate => {
  if (tz) return dayjs(date).tz(tz).utc(true)

  return dayjs(date).local()
}

export const optionalDateDeserializer = (
  date: string | null,
): CustomDate | undefined => (date ? dateDeserializer(date) : undefined)

export const deserializeExtendedFormattedDate = (date: string) =>
  dateDeserializer(date).format('MMMM D, YYYY - hh:mm A')

export const deserializeFormattedDate = (date: string) =>
  dateDeserializer(date).format('MMMM D, YYYY hh:mm A')

export const deserializeBasicFormattedDate = (date: string) =>
  dateDeserializer(date).format('MMMM D, YYYY')

export const deserializeShortFormattedDate = (date: string) =>
  dateDeserializer(date).format('MMM. D, YYYY')

export const formatDateToRender = (date: CustomDate | undefined) =>
  date?.format('MMMM D, YYYY')

export const formatDateWithTimeToRender = (date: CustomDate | undefined) =>
  date?.format('MMMM D, YYYY hh:mm A')

export const formatShortDateToRender = (date: CustomDate | undefined) =>
  date?.format('MMM. D, YYYY')

export const formatShortDateWithTimeToRender = (date: CustomDate | undefined) =>
  date?.format('MMM. D, YYYY hh:mm A')

export const timezoneFromIANAToOffset = (timezoneIANA?: string) => {
  if (!timezoneIANA) return ''
  return `${dayjs().tz(timezoneIANA).format('z')} (${dayjs()
    .tz(timezoneIANA)
    .format('zzz')})`
}

export const alphabeticSorter = (a?: string, b?: string) => {
  if (!a || !b) return 0
  if (a > b) return 1
  if (a < b) return -1

  return 0
}

export const dateSorter = (a?: string, b?: string) => {
  if (!a || !b) return 0
  const dateA = new Date(a)
  const dateB = new Date(b)
  if (dateA > dateB) return -1
  if (dateB > dateA) return 1
  return 0
}

export const numericSorter = (a?: number, b?: number) => {
  if (a === undefined || b === undefined) return 0
  if (a > b) return 1
  if (a < b) return -1

  return 0
}

export interface Option {
  label: string
  value: string
}

export enum PhoneNumberTypes {
  Home = 'home',
  Mobile = 'mobile',
}

export enum PreferredPhoneFields {
  HomePhonePreferred = 'home_phone_preferred',
  MobilePhonePreferred = 'phone_number_preferred',
}

export enum SectionNames {
  CommunityGroups = 'COMMUNITY GROUPS',
  Configuration = 'CONFIGURATION',
  Users = 'USERS',
  Survivors = 'SURVIVORS',
  CarePartners = 'CARE PARTNERS',
}

export const DEFAULT_TIMEZONE = 'UTC'

export interface AntValidationError {
  errorFields: number[]
}

export enum TabTypes {
  AccountManagement = 'account_management',
  BasicDemographics = 'basic_demographics',
  StrokeDetails = 'stroke_details',
  Impairments = 'impairments',
  HealthCareTeam = 'health_care_team',
  HealthEvents = 'health_events',
  HealthConditions = 'health_conditions',
  HospitalInfo = 'hospital_info',
  PlanOfCare = 'plan_of_care',
  QuickJots = 'quick_jots',
  CommunicationLogs = 'communication_logs',
  Groups = 'groups',
  NavigatorTasks = 'navigator_tasks',
  Alerts = 'alerts',
  Assessments = 'assessments',
  GroupConversation = 'group_conversation',
  PrivateConversation = 'private_conversation',
  ToDos = 'to_do_s',
  PurchaseToDos = 'purchase_todos',
  QuestionsToAsk = 'questions_to_ask',
  Documents = 'documents',
  Learn = 'learn',
  Tacas = 'tacas',
  Survivors = 'survivors',
}
