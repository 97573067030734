import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'

import { strings } from './strings'
import styles from './uploading.module.scss'

interface UploadingProps {
  visible?: boolean
}

export const Uploading: React.FC<UploadingProps> = ({ visible }) =>
  visible ? (
    <div className={styles.uploadingLabelContainer}>
      <LoadingOutlined />
      <div className={styles.uploadingLabel}>{strings.uploadinglabel}</div>
    </div>
  ) : null
