import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    descriptionLabel: 'Description',
    titleLabel: 'Title',
    titleValidation: '"Title" is required',
    uploadTitle: 'Click or drag file to this area to upload documents',
  },
})
