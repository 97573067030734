import { CustomDate } from 'src/common/types'
import { AssignPackage, PackageForm } from 'src/pages/Learn/Packages/types'
import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { GeneralTagIds, HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  getPackagesDeserializer,
  Package,
  packageDeserializer,
  PackageInfo,
} from './types'

export const generatePackageFormData = (body: PackageForm) => {
  const formData = new FormData()
  formData.append('unit[title]', body.title)
  formData.append('unit[internal_id]', body.internal_id)
  formData.append('unit[internal_name]', body.internal_name)
  formData.append('unit[audience]', body.audience)
  formData.append('unit[language]', body.language)
  if (!body.image) {
    formData.append('unit[image]', '')
  } else if (body.image && typeof body.image !== 'string') {
    formData.append('unit[image]', body?.image?.file)
  }
  body.articles.forEach(article => {
    formData.append('unit[article_ids][]', article.id)
  })
  if (body.assign_to_user) {
    formData.append(
      'unit[mobile_user_lessons_attributes][][mobile_user_id]',
      body.assign_to_user,
    )
  } else if (body.unit_id) {
    formData.append('unit[mobile_user_lessons_attributes][][id]', body.unit_id)
  }
  if (body.deployment_date) {
    formData.append(
      'unit[mobile_user_lessons_attributes][][deployment_date]',
      body.deployment_date.format(),
    )
  }
  if (body.new_status) {
    formData.append('unit[status]', body.new_status)
  }
  return formData
}

export const getPackages = (builder: Builder) =>
  builder.query<PaginatedItems<PackageInfo>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Packages),
    query: endpointWithFilters(Endpoints.GetPackages),
    transformResponse: getPackagesDeserializer,
  })

export const getPackage = (builder: Builder) =>
  builder.query<Package, { id: string }>({
    providesTags: (_, __, { id }) => [
      { id, type: Tags.Packages },
      { id: GeneralTagIds.Individual, type: Tags.Packages },
    ],
    query: ({ id }) => format(Endpoints.GetPackage, id),
    transformResponse: ({ unit }) => packageDeserializer(unit),
  })

export const addPackage = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: PackageForm }>({
    invalidatesTags: () => [
      { type: Tags.Packages },
      { id: GeneralTagIds.Units, type: Tags.Survivors },
      { id: GeneralTagIds.Units, type: Tags.CarePartners },
    ],
    query: ({ body }) => {
      const formData = generatePackageFormData(body)
      return {
        body: formData,
        method: HttpMethod.Post,
        url: Endpoints.AddPackage,
      }
    },
    transformResponse: performActionTransformResponse,
  })

export const updatePackage = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string; body: PackageForm }>({
    invalidatesTags: [
      { type: Tags.Packages },
      { id: GeneralTagIds.Units, type: Tags.Survivors },
      { id: GeneralTagIds.Units, type: Tags.CarePartners },
    ],
    query: ({ id, body }) => {
      const formData = generatePackageFormData(body)
      return {
        body: formData,
        method: HttpMethod.Put,
        url: format(Endpoints.UpdatePackage, id),
      }
    },
    transformResponse: performActionTransformResponse,
  })

export const deletePackage = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.Packages),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.UpdatePackage, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const editPackageDeploymentDate = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    {
      id: string
      body: {
        deployment_date: CustomDate
        lessonId: string
        mobileUserId: string
        articleIds: string[]
      }
    }
  >({
    invalidatesTags: [
      { type: Tags.Packages },
      { id: GeneralTagIds.Units, type: Tags.Survivors },
      { id: GeneralTagIds.Units, type: Tags.CarePartners },
    ],
    query: ({ id, body }) => {
      return {
        body: {
          unit: {
            article_ids: body.articleIds,
            mobile_user_lessons_attributes: [
              {
                deployment_date: body.deployment_date.format(),
                id: body.lessonId,
                mobile_user_id: body.mobileUserId,
              },
            ],
          },
        },
        method: HttpMethod.Put,
        url: format(Endpoints.UpdatePackage, id),
      }
    },
    transformResponse: performActionTransformResponse,
  })

export const updatePackageRow = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { id: string; body: Partial<Package> & { article_ids: string[] } }
  >({
    invalidatesTags: [
      { type: Tags.Packages },
      { id: GeneralTagIds.Units, type: Tags.Survivors },
      { id: GeneralTagIds.Units, type: Tags.CarePartners },
    ],
    query: ({ id, body: unit }) => {
      return {
        body: {
          unit,
        },
        method: HttpMethod.Put,
        url: format(Endpoints.UpdatePackage, id),
      }
    },
    transformResponse: performActionTransformResponse,
  })

export const assignPackage = (builder: Builder) =>
  builder.mutation<PerformActionResponse, AssignPackage>({
    invalidatesTags: [
      { id: GeneralTagIds.Units, type: Tags.Survivors },
      { id: GeneralTagIds.Units, type: Tags.CarePartners },
    ],
    query: ({ assign_to_user, ...body }) => {
      return {
        body: {
          lesson: {
            deployment_date: body.deployment_date.format(),
            mobile_user_id: assign_to_user,
            unit_id: body.package,
          },
        },
        method: HttpMethod.Post,
        url: format(Endpoints.AssignPackage, assign_to_user),
      }
    },
    transformResponse: performActionTransformResponse,
  })
