import React from 'react'
import { ResetPassword } from 'src/components/ResetPassword'
import {
  useLazyGetSurvivorQuery,
  useResetSurvivorsPasswordMutation,
} from 'src/store/APIs/survivors'

interface SurvivorResetPasswordProps {}

export const SurvivorResetPassword: React.FC<
  SurvivorResetPasswordProps
> = () => (
  <ResetPassword
    useResetPassword={useResetSurvivorsPasswordMutation}
    useLazyGetUserQuery={useLazyGetSurvivorQuery}
  />
)
