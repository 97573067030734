/* eslint-disable import/no-extraneous-dependencies */
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { cloneElement, ReactElement } from 'react'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import {
  CompletionStatusOptions,
  completionStatusProps,
} from 'src/pages/SurvivorView/Learn/types'

import { ArticlesDataIndex } from '../../Articles/types'
import styles from './addPackage.module.scss'
import { strings } from './strings'
import { RowProps } from './types'

export const columns: (
  onClickDeleteColumn: (id: string) => () => void,
  isChild: boolean,
  packageStatus: CompletionStatusOptions,
) => ColumnsType<any> = (onClickDeleteColumn, isChild, packageStatus) => {
  const conditionalColumn = isChild
    ? {
        dataIndex: ArticlesDataIndex.completion_status,
        render: (status: CompletionStatusOptions) => {
          const statusProp = completionStatusProps(status)
          return status ? (
            <Tag color={statusProp.color}>{statusProp.title}</Tag>
          ) : (
            <>-</>
          )
        },
        title: strings.statusLabel,
      }
    : {
        dataIndex: ArticlesDataIndex.has_audio,
        render: (has_audio: boolean) => (
          <Tag color={has_audio ? 'green' : 'default'}>
            {has_audio ? strings.audioYesLabel : strings.audioNoLabel}
          </Tag>
        ),
        title: strings.audioLabel,
      }

  const sortColumn =
    isChild && packageStatus === CompletionStatusOptions.Completed
      ? []
      : [
          {
            key: 'sort',
            width: 45,
          },
        ]

  return [
    ...sortColumn,
    Table.SELECTION_COLUMN,
    {
      dataIndex: ArticlesDataIndex.internal_name,
      title: strings.internalNameLabel,
    },
    {
      dataIndex: ArticlesDataIndex.internal_id,
      title: strings.internalIdLabel,
    },
    { ...conditionalColumn },
    {
      render: record => {
        const disabled =
          record.completion_status &&
          !record?.permissions?.can_unassign &&
          !record?.permissions?.can_destroy &&
          !record?.permissions?.can_remove
        return (
          <Button
            disabled={disabled}
            onClick={onClickDeleteColumn(record.internal_id)}
            style={{ opacity: disabled ? 0.5 : 0.8 }}
            icon={<DeleteOutlined className={styles.deleteIcon} />}
            type="text"
          />
        )
      },
      width: 30,
    },
  ]
}

export const TableRow = ({ children, ...props }: RowProps) => {
  const disabledIds = (children as ReactElement[])
    ?.filter(
      child =>
        child?.props?.record?.completion_status ===
        CompletionStatusOptions.Completed,
    )
    .map(child => child?.props?.record?.internal_id)
  const disabled = disabledIds?.includes(props['data-row-key'])

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    disabled: {
      draggable: disabled,
      droppable: disabled,
    },
    id: props['data-row-key'],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {(children as ReactElement[]).map(child => {
        const status = child?.props?.record.completion_status
        const canSort = status !== CompletionStatusOptions.Completed
        if (child.key === 'sort') {
          return cloneElement(child, {
            children: canSort ? (
              <MenuOutlined
                className={styles.menuIcon}
                ref={setActivatorNodeRef}
                {...listeners}
              />
            ) : null,
          })
        }
        return child
      })}
    </tr>
  )
}
