import { baseApi } from 'src/store/APIs'

import { getUsers } from './actions'

export const usersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUsers: getUsers(builder),
  }),
})

export const {
  useLazyGetUsersQuery,
  reducer: usersApiReducer,
  reducerPath: usersApiReducerPath,
} = usersApi
