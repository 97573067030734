import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, FormProps, Image, Modal, Upload } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { deleteModalIcon } from 'src/components/DeleteButton'
import { Drawer } from 'src/components/Drawer'
import { useImagePreview } from 'src/hooks/useImagePreview'
import { useUploadImage } from 'src/hooks/useUploadImage'
import { useNewGroupPostMutation } from 'src/store/APIs/communityGroups'
import { PostPayload } from 'src/store/APIs/communityGroups/types'

import styles from './communityGroup.module.scss'
import { strings } from './strings'
import { FormNames } from './types'

interface AddPostValues {
  content: string
  image?: UploadChangeParam
}
interface AddCommunityGroupPostProps {
  onCloseDrawer: () => void
  drawerOpen?: boolean
  groupId: string
}

export const AddCommunityGroupPost: React.FC<AddCommunityGroupPostProps> = ({
  onCloseDrawer,
  drawerOpen,
  groupId,
}) => {
  const [triggerNewPost] = useNewGroupPostMutation()
  const [form] = Form.useForm<AddPostValues>()
  const { beforeUpload, imageURL, setPreviewImage } = useUploadImage(form)
  const {
    previewOpen,
    handlePreview,
    setPreviewOpen,
    previewImageURL,
    setPreviewImageURL,
  } = useImagePreview()

  const closeDrawer = () => {
    form.setFieldsValue({ content: '', image: undefined })
    setPreviewImage(undefined)
    onCloseDrawer()
  }

  const handleSubmit: FormProps<AddPostValues>['onFinish'] = async ({
    content,
    image,
  }) => {
    if (groupId) {
      const post: PostPayload = {
        body: { content, image: image?.fileList['0'].originFileObj },
        groupId,
      }
      triggerNewPost(post)
    }
    closeDrawer()
  }

  const initialValues = { content: '', image: undefined }

  const handlePreviewClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    handlePreview({ url: imageURL } as UploadFile)
  }

  const contentValue = Form.useWatch('content', form)
  const imageValue = Form.useWatch('image', form)

  const resetImage = (event: React.MouseEvent) => {
    event.stopPropagation()
    Modal.confirm({
      cancelText: generalStrings.cancel,
      icon: deleteModalIcon,
      okText: generalStrings.delete,
      okType: 'danger',
      onOk: () => {
        setPreviewImage(undefined)
        form.setFieldValue('image', undefined)
      },
      title: strings.resetImageModalTitle,
    })
  }

  return (
    <Drawer
      open={drawerOpen}
      onClose={closeDrawer}
      title={strings.drawerTitle}
      onClickRightButton={form.submit}
      rightButtonDisabled={!contentValue && !imageValue}>
      <Form
        name={FormNames.create_post}
        initialValues={initialValues}
        form={form}
        onFinish={handleSubmit}>
        <div>{strings.contentLabel}</div>
        <Form.Item name="content">
          <TextArea
            size="large"
            title="Content"
            placeholder={strings.postPlaceholder}
            className={styles.textArea}
            autoSize={{ maxRows: 8, minRows: 2 }}
          />
        </Form.Item>
        <div>{strings.imageLabel}</div>
        <Form.Item name="image">
          <Upload
            showUploadList={false}
            listType="picture-card"
            beforeUpload={beforeUpload}
            accept="image/*"
            maxCount={1}
            onPreview={handlePreview}>
            {imageURL ? (
              <div className={styles.imageContainer}>
                <img
                  src={imageURL}
                  alt="post"
                  className={styles.newPostImage}
                />
                <div className={styles.overlay}>
                  <div className={styles.imageButtons}>
                    <Button
                      shape="circle"
                      icon={<EyeOutlined />}
                      onClick={handlePreviewClick}
                    />
                    <Button
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={resetImage}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <PlusOutlined />
                <div className={styles.uploadButton}>
                  {strings.uploadButtonText}
                </div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      {previewImageURL && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            afterOpenChange: visible => !visible && setPreviewImageURL(''),
            onVisibleChange: visible => setPreviewOpen(visible),
            visible: previewOpen,
          }}
          src={previewImageURL}
        />
      )}
    </Drawer>
  )
}
