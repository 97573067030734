import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    batchActionsButtonLabel: 'Batch Actions',
    closeLabel: 'Close',
    cpColumnLabel: 'Care Partner',
    deleteSelectedLabel: 'Delete selected',
    emailColumnLabel: 'Email',
    itemsSelectedLabel: '{0} items selected',
    navigatorColumnLabel: 'Navigator',
    phoneNumberColumnLabel: 'Preferred Phone',
    statusColumnLabel: 'Status',
    tagNo: 'No',
    tagYes: 'Yes',
    title: 'Care Partners',
  },
})
