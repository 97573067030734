import { deserializeShortFormattedDate } from 'src/common/types'
import {
  AudienceOptions,
  RedirectLinkOptions,
  ThemeOptions,
} from 'src/pages/Learn/Articles/types'
import { CompletionStatusOptions } from 'src/pages/SurvivorView/Learn/types'

import { completionStatusDeserializer } from '../packages/types'
import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  Languages,
  LearnStatus,
  PaginatedItems,
  PaginationResponse,
  Permissions,
} from '../types'

export enum Endpoints {
  GetArticles = '/cms/v1/articles.json',
  GetArticle = '/cms/v1/articles/%s.json',
  AddArticle = '/cms/v1/articles.json',
  PaginateContent = '/cms/v1/articles/paginate_html.json',
  GenerateSsml = '/cms/v1/articles/transform_to_ssml.json',
  GenerateAudio = '/cms/v1/article_audios.json',
  GetAudio = '/cms/v1/article_audios/%s.json',
  DeleteAudio = '/cms/v1/article_audios/%s.json',
  EditArticle = '/cms/v1/articles/%s.json',
  UploadPicture = '/cms/v1/photos.json',
  DeleteArticle = '/cms/v1/articles/%s.json',
  RemoveArticle = '/cms/v1/articles/%s/remove',
}

export interface ArticleInfoResponse extends Entity {
  id: string
  title: string
  internal_name: string
  theme: string
  internal_id: string
  audience: string
  unit: {
    title: string
    id: string
  }
  created_at: string
  status: LearnStatus
}

export interface GetArticleInfoResponse {
  articles: ArticleInfoResponse[]
  model_permissions: Permissions
  pagination: PaginationResponse
}

export interface ArticleInfo extends Entity {
  id: string
  title: string
  internal_name: string
  theme: string
  internal_id: string
  audience: string
  unit?: {
    title: string
    id: string
  }
  created_at: string
  status: LearnStatus
  ['units.title']?: string
}

export const articleInfoDeserializer = (
  data: ArticleInfoResponse,
): ArticleInfo => {
  return {
    audience: data.audience,
    created_at: data.created_at,
    id: data.id,
    internal_id: data.internal_id,
    internal_name: data.internal_name,
    status: data.status,
    theme: data.theme,
    title: data.title,
    unit: data.unit,
    'units.title': data.unit?.title,
  }
}

export const getArticlesDeserializer = (
  data: GetArticleInfoResponse,
): PaginatedItems<ArticleInfo> => {
  return {
    items: data.articles.map(articleInfoDeserializer),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}

export interface PageInfo {
  page_number: number
  id?: string
  content: string | null
  ssml_content: string | null
}

export interface PageResponse {
  id?: string
  page_number: number
  content: string | null
  ssml_content: string | null
}

export interface RetailProductSuggestedTodo {
  id: string
  created_at: string
  item_description: string | null
  name: string
  updated_at: string
}

export interface SuggestedTodo {
  article_id: string
  created_at: string
  retail_products: RetailProductSuggestedTodo[]
  selected: boolean
  todo: string
  updated_at: string
  id: string
}

export interface SuggestedQuestion {
  article_id: string
  created_at: string
  question: string
  selected: boolean
  updated_at: string
  id: string
}

export enum ArticleMode {
  Content = 'Content',
  Paginated = 'Paginated',
  Ssml = 'Ssml',
}

export const deserializeArticleMode = (pages: PageInfo[]): ArticleMode => {
  if (!pages || pages.length === 0) {
    return ArticleMode.Content
  }
  const hasSsml = pages.some(pageInfo => !!pageInfo.ssml_content)
  if (hasSsml) {
    return ArticleMode.Ssml
  }
  return ArticleMode.Paginated
}

export interface ArticleAudio {
  id: string
  file: {
    url: string
  }
}

export interface ExtendedArticleInfoResponse extends Entity {
  id: string
  title: string
  internal_name: string
  theme: string | null
  internal_id: string
  audience: string
  content: string
  unit: {
    title: string
    id: string
  } | null
  created_at: string
  status: LearnStatus
  updated_at: string | null
  updated_by: BaseUserResponse | null
  feedback_disabled: boolean
  audio: ArticleAudio | null
  link: string | null
  number_of_pages: number
  pages: PageInfo[]
  revision_history: string | null
  suggested_questions: SuggestedQuestion[]
  suggested_todos: SuggestedTodo[]
  bookmark: boolean
  parent_id: string | null
  discuss_with_navigator: boolean | null
  has_assigned_children: boolean
  assigned_to: BaseUserResponse | null
  previous_unit?: {
    id: string
    title: string
  }
  completion_status?: string
  language: Languages
}

export interface ExtendedArticleInfo extends Entity {
  id: string
  title: string
  internal_name: string
  theme?: string
  internal_id: string
  audience: string
  content: string
  unit?: {
    title: string
    id: string
  }
  created_at: string
  status: LearnStatus
  updated_at?: string
  last_update_by?: BaseUser
  feedback_disabled: boolean
  audio?: ArticleAudio
  link?: string
  number_of_pages: number
  pages_attributes: PageInfo[]
  revision_history?: string
  suggested_questions: SuggestedQuestion[]
  suggested_todos: SuggestedTodo[]
  mode: ArticleMode
  bookmark: boolean
  parent_id?: string
  discuss_with_navigator?: boolean
  has_assigned_children: boolean
  assigned_to?: BaseUser
  previous_unit?: {
    id: string
    title: string
  }
  completion_status?: CompletionStatusOptions | string
  has_audio: boolean
  language: Languages
}

export const articlePermissionsDeserializer = (
  data: Permissions,
): Permissions => ({
  ...data,
  can_destroy: !!data.can_destroy,
  can_disable: !!data.can_disable,
  can_enable: !!data.can_enable,
  can_read: !!data.can_read,
  can_remove: !!data.can_remove,
  can_unassign: !!data.can_unassign,
  can_update: !!data.can_update,
})

export const extendedArticleInfoDeserializer = (
  data: ExtendedArticleInfoResponse,
): ExtendedArticleInfo => {
  return {
    assigned_to: data.assigned_to
      ? baseUserDeserializer(data.assigned_to)
      : undefined,
    audience: data.audience,
    audio: data.audio ?? undefined,
    bookmark: data.bookmark,
    completion_status: completionStatusDeserializer(data.completion_status),
    content: data.content,
    created_at: deserializeShortFormattedDate(data.created_at),
    discuss_with_navigator: data.discuss_with_navigator ?? undefined,
    feedback_disabled: data.feedback_disabled,
    has_assigned_children: data.has_assigned_children,
    has_audio: !!data.audio,
    id: data.id,
    internal_id: data.internal_id,
    internal_name: data.internal_name,
    language: data.language,
    last_update_by: data.updated_by
      ? baseUserDeserializer(data.updated_by)
      : undefined,
    link: data.link ?? undefined,
    mode: deserializeArticleMode(data.pages),
    number_of_pages: data.number_of_pages,
    pages_attributes: data.pages,
    parent_id: data.parent_id ?? undefined,
    permissions: data.permissions
      ? articlePermissionsDeserializer(data.permissions)
      : undefined,
    previous_unit: data.previous_unit ?? undefined,
    revision_history: data.revision_history ?? undefined,
    status: data.status,
    suggested_questions: data.suggested_questions,
    suggested_todos: data.suggested_todos,
    theme: data.theme ?? undefined,
    title: data.title,
    unit: data.unit ?? undefined,
    updated_at: data.updated_at
      ? deserializeShortFormattedDate(data.updated_at)
      : undefined,
  }
}

export interface NewSuggestedTodo {
  todo: string
  retail_product_ids?: string[]
}

export interface FormNewArticle {
  title: string
  internal_name: string
  theme?: ThemeOptions
  audience: AudienceOptions
  internal_id: string
  link?: RedirectLinkOptions
  revision_history?: string
  enable_feedback: boolean
  content: string
  is_draft: boolean
  pages_attributes?: PageResponse[]
  pages_attributes_edited?: PageResponse[]
  suggested_questions: SuggestedQuestion[]
  suggested_todos: NewSuggestedTodo[]
  status: LearnStatus
}

export interface NewArticle {
  article: {
    title: string
    internal_name: string
    theme?: ThemeOptions
    audience: AudienceOptions
    internal_id: string
    link: RedirectLinkOptions | null
    revision_history?: string
    feedback_disabled: boolean
    content: string
    status: LearnStatus
    pages_attributes?: PageInfo[]
    suggested_questions_attributes: SuggestedQuestion[]
    suggested_todos_attributes: NewSuggestedTodo[]
  }
}

export const serializeArticle = (article: FormNewArticle): NewArticle => {
  const pageFormName =
    article.pages_attributes_edited ?? article.pages_attributes
  return {
    article: {
      ...article,
      feedback_disabled: !article.enable_feedback,
      link: article.link ?? null,
      pages_attributes: pageFormName?.map((page, index) => {
        return {
          ...page,
          content: page.content,
          page_number: index,
        }
      }),
      status: article.is_draft ? LearnStatus.draft : article.status,
      suggested_questions_attributes: article.suggested_questions,
      suggested_todos_attributes: article.suggested_todos,
    },
  }
}

export interface ContentPagesResponse {
  pages: PageResponse[]
}

export interface SsmlPage {
  ssml_content: {
    ssml: string
    page_number: number
  }
  content: {
    id: string
    content: string | null
    page_number: number
    ssml_content: string | null
  }
}
export interface SsmlPagesResponse {
  pages: SsmlPage[]
}

export const deserializeSsmlPages = (
  data: SsmlPagesResponse,
): ContentPagesResponse => {
  return {
    pages: data.pages.map(page => {
      return {
        content: page.content.content,
        id: page.content.id,
        page_number: page.content.page_number,
        ssml_content: page?.ssml_content?.ssml,
      }
    }),
  }
}

export interface GenerateAudioResponse {
  id: string
  message: string
}

export interface AudioResponse {
  error?: boolean
  id?: string
  status?: number
  file?: {
    url: string
  }
}

export interface Media {
  location: string
  type: MediaType
}

export enum MediaType {
  Image = 'image',
  Video = 'video',
}

export interface UploadMedia {
  file: FileList
}
