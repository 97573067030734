import { TabTypes } from './types'

export const ORDERED_TABS = [
  TabTypes.Alerts,
  TabTypes.BasicDemographics,
  TabTypes.AccountManagement,
  TabTypes.Survivors,
  TabTypes.Documents,
  TabTypes.PlanOfCare,
  TabTypes.HospitalInfo,
  TabTypes.StrokeDetails,
  TabTypes.Impairments,
  TabTypes.HealthCareTeam,
  TabTypes.HealthEvents,
  TabTypes.HealthConditions,
  TabTypes.Learn,
  TabTypes.QuestionsToAsk,
  TabTypes.ToDos,
  TabTypes.QuickJots,
  TabTypes.PurchaseToDos,
  TabTypes.Tacas,
  TabTypes.Assessments,
  TabTypes.PrivateConversation,
  TabTypes.GroupConversation,
  TabTypes.Groups,
  TabTypes.CommunicationLogs,
  TabTypes.NavigatorTasks,
]
