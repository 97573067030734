import { Form, FormInstance, FormProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { Button } from 'src/components/Button'
import { CarePartnerForm } from 'src/components/CarePartnerForm'
import {
  CarePartnerType,
  selectOptions,
} from 'src/components/CarePartnerForm/selectOptions'
import { SearchSelect } from 'src/components/SearchSelect'
import { Select } from 'src/components/Select'
import { Spinner } from 'src/components/Spinner'
import { constants } from 'src/config/constants'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import {
  useLazyGetCarePartnerQuery,
  useLazyGetCarePartnersQuery,
} from 'src/store/APIs/carePartners'
import { CarePartner } from 'src/store/APIs/carePartners/types'

import styles from './addCarePartnerForm.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

interface AddCarePartnerFormProps {
  selectCarePartnerForm: FormInstance<{
    select_option: CarePartnerType
    select_existing_care_partner: string
  }>
  formEditName: string
  formCreateName: string
  carePartner?: CarePartner
  carePartnerOptionSelected?: string
  setCarePartnerOptionSelected: (value: string) => void
  children?: React.ReactNode
  onFormFinish?: FormProps['onFinish']
}

const CarePartnerFormComponent: React.FC<{
  formEditName: string
  formCreateName: string
  optionSelected: CarePartnerType
  initialValues?: CarePartner
  carePartnerForm: FormInstance<CarePartner | undefined>
  children?: React.ReactNode
  onFormFinish?: FormProps['onFinish']
}> = ({
  formEditName,
  formCreateName,
  optionSelected,
  initialValues,
  carePartnerForm,
  children,
  onFormFinish,
}) => {
  if (optionSelected === CarePartnerType.NEW) {
    return (
      <CarePartnerForm
        name={formCreateName}
        form={carePartnerForm}
        onFormFinish={onFormFinish}>
        {children}
      </CarePartnerForm>
    )
  }

  return (
    <CarePartnerForm
      name={formEditName}
      form={carePartnerForm}
      initialValues={initialValues}
      onFormFinish={onFormFinish}>
      {children}
    </CarePartnerForm>
  )
}

export const AddCarePartnerForm: React.FC<AddCarePartnerFormProps> = ({
  selectCarePartnerForm,
  carePartner,
  formEditName,
  formCreateName,
  carePartnerOptionSelected,
  setCarePartnerOptionSelected,
  onFormFinish,
  children,
}) => {
  const [triggerGetCarePartnersQuery] = useLazyGetCarePartnersQuery()
  const [
    triggerGetCarePartnerQuery,
    { data: selectedCarePartner, isFetching: isFetchingCarePartner },
  ] = useLazyGetCarePartnerQuery()
  const [carePartnerForm] = Form.useForm<CarePartner | undefined>()

  const { carePartnerType } = selectOptions()

  const optionSelected = Form.useWatch('select_option', selectCarePartnerForm)

  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const selectedItems = carePartner ? [carePartner] : undefined

  const enableAddUser = constants.addUserEnabled

  const {
    optionsSelect: carePartnerSelectOptions,
    fetchedFilteredElems: fetchedFilteredCarePartners,
  } = useSearchOptionsSelect(
    useLazyGetCarePartnersQuery,
    selectedItems,
    'full_name',
  )

  const initialValues = selectedCarePartner ?? carePartner

  const onShowDropdown = () => setShowDropdown(true)

  useEffect(() => {
    if (!carePartner) {
      triggerGetCarePartnersQuery({})
    }
  }, [])

  useEffect(() => {
    const getCarePartner = async () => {
      if (carePartnerOptionSelected) {
        await triggerGetCarePartnerQuery(carePartnerOptionSelected)
        carePartnerForm.resetFields()
      }
    }
    getCarePartner()
  }, [carePartnerOptionSelected])

  useEffect(() => {
    carePartnerForm.resetFields()
  }, [optionSelected])

  // INFO: Adding a CP will be only available on STA and RC

  if (!carePartner && !showDropdown) {
    return (
      <Button
        type="primary"
        size="small"
        onClick={onShowDropdown}
        disabled={!enableAddUser}
        tooltipTitle={!enableAddUser ? strings.addCarePartnerDisabled : ''}
        className={styles.button}>
        {strings.addCarePartner}
      </Button>
    )
  }

  return (
    <>
      <Form form={selectCarePartnerForm}>
        <Form.Item
          name="select_option"
          initialValue={
            carePartner ? CarePartnerType.EXISTING : CarePartnerType.NEW
          }>
          <Select
            label={strings.carePartnerLabel}
            options={carePartnerType}
            disabled={!enableAddUser}
            tooltipTitle={!enableAddUser ? strings.disabledInputTooltip : ''}
          />
        </Form.Item>
        {carePartnerSelectOptions &&
          ((carePartner && optionSelected === CarePartnerType.EXISTING) ||
            optionSelected === CarePartnerType.EXISTING) && (
            <Form.Item
              name="select_existing_care_partner"
              rules={[yupSync]}
              initialValue={carePartner?.id}>
              <SearchSelect
                required
                label={strings.selectExistingCarePartner}
                fetchOptions={fetchedFilteredCarePartners}
                options={carePartnerSelectOptions}
                onChange={setCarePartnerOptionSelected}
                disabled={!enableAddUser}
                tooltipTitle={
                  !enableAddUser ? strings.disabledInputTooltip : ''
                }
              />
            </Form.Item>
          )}
      </Form>
      {isFetchingCarePartner ? (
        <Spinner />
      ) : (
        <CarePartnerFormComponent
          formCreateName={formCreateName}
          formEditName={formEditName}
          optionSelected={optionSelected}
          initialValues={initialValues}
          carePartnerForm={carePartnerForm}
          onFormFinish={onFormFinish}>
          {children}
        </CarePartnerFormComponent>
      )}
    </>
  )
}
