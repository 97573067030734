import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addHealthEventButton: 'Add Health Event',
    createdAtColumn: 'Created At',
    deleteModalTitle: 'Are you sure you want to delete this health event?',
    healthEventsTitle: 'Health Events',
    nameColumn: 'Name',
    resourceName: 'Health Event',
    updatedAtColumn: 'Updated At',
  },
})
