export enum HealthcareTeamDataIndex {
  'health_care_role_id' = 'health_care_role_id',
  'other_role' = 'other_role',
  'first_name' = 'first_name',
  'last_name' = 'last_name',
  'affiliated_organization' = 'affiliated_organization',
  'phone_number' = 'phone_number',
  'last_visit_date' = 'last_visit_date',
  'last_visit_summary' = 'last_visit_summary',
  'additional_comments' = 'additional_comments',
  'role_name' = 'role_name',
}
