import { CustomDate, optionalDateDeserializer } from 'src/common/types'

import {
  Entity,
  Identifiable,
  PaginatedItems,
  PaginatedModel,
  PaginationResponse,
} from '../types'

export enum Endpoints {
  GetHealthEvents = '/cms/v1/global_settings/health_events.json',
  GetSurvivorsHealthEvents = '/cms/v1/mobile_survivors/%s/profile/health_events.json?page=%s',
  UpdateSurvivorHealthEvents = '/cms/v1/mobile_survivors/%s/profile/health_events/batch_put',
  AddHealthEvent = '/cms/v1/global_settings/health_events.json',
  GetHealthEvent = '/cms/v1/global_settings/health_events/%s.json',
  DeleteHealthEvent = '/cms/v1/global_settings/health_events/%s.json',
  EditHealthEvent = '/cms/v1/global_settings/health_events/%s.json',
}

export interface GetHealthEventsResponse extends PaginatedModel {
  health_events: HealthEventResponse[]
}

export interface HealthEventResponse extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export interface HealthEventsData extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export const healthEventDeserializer = (
  data: HealthEventResponse,
): HealthEventsData => ({
  ...data,
})

export const healthEventsDeserializer = (
  data: GetHealthEventsResponse,
): PaginatedItems<HealthEventsData> => ({
  ...data,
  items: data.health_events,
})

export interface PaginatedSurvivorHealthEventsResponse {
  health_events: SurvivorHealthEventsResponse[]
  pagination: PaginationResponse
}

export interface SurvivorHealthEventsResponse {
  id: string
  major_health_event: {
    name: string
    id: string
  }
  created_at: string
  description: string
  major_health_event_id: string
  occurrence_date: string | null
  other_major_health_event: string
  updated_at: string
}

export interface SurvivorHealthEventsPayload extends Identifiable {
  major_health_event_id: string
  occurrence_date?: string
  description: string
}

export interface SurvivorHealthEventData {
  id: string
  major_health_event_id: string
  major_health_event: string
  other_major_health_event?: string
  occurrence_date: CustomDate | undefined
  description: string
}

export const survivorHealthEventDeserializer = (
  data: SurvivorHealthEventsResponse,
): SurvivorHealthEventData => {
  return {
    description: data.description,
    id: data.id,
    major_health_event: data.major_health_event.name,
    major_health_event_id: data.major_health_event_id,
    occurrence_date: optionalDateDeserializer(data.occurrence_date),
    other_major_health_event: data.other_major_health_event,
  }
}

export const survivorHealthEventsDeserializer = (
  data: PaginatedSurvivorHealthEventsResponse,
): PaginatedItems<SurvivorHealthEventData> => ({
  items: data.health_events.map(survivorHealthEventDeserializer),
  pagination: data.pagination,
})
