import { Occurrence } from 'src/store/APIs/todos/types'

import { strings } from '../strings'

export const selectOptions = [
  {
    label: strings.repeatDaily,
    value: Occurrence.RepeatDaily,
  },
  {
    label: strings.repeatWeekly,
    value: Occurrence.RepeatWeekly,
  },
  {
    label: strings.repeatMonthly,
    value: Occurrence.RepeatMonthly,
  },
  {
    label: strings.repeatAnnually,
    value: Occurrence.RepeatAnnually,
  },
]
