import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    articlesLabel: 'Articles',
    assignToUserLabel: 'Assign to User',
    audienceLabel: 'Audience',
    audioLabel: 'Audio',
    audioNoLabel: 'No',
    audioYesLabel: 'Yes',
    deleteArticleModalTitle: 'Are you sure you want to delete this article?',
    deleteSelectedLabel: 'Delete selected',
    deleteSelectedModalTitle: 'Are you sure you want to delete these articles?',
    deploymentDateLabel: 'Deployment Date',
    deploymentDateRequired: 'You must add deployment date',
    deploymentTzLabel: 'In survivor or CP timezone',
    imageLabel: 'Image',
    imageRequirementsLabel: 'Max file size 10 MB',
    imageSizeError: 'Image size must be less than 10 MB',
    internalIdLabel: 'Internal ID',
    internalNameLabel: 'Internal Name',
    languageLabel: 'Language',
    optionalLabel: '(optional)',
    requiredValidation: 'This field is required',
    resetImageModalTitle: 'Are you sure you want to delete this image?',
    resourceName: 'Package',
    selectDatePlaceholder: 'Select date',
    statusLabel: 'Status',
    themeLabel: 'Theme',
    titleLabel: 'Title',
    unassignArticleModalTitle:
      'Are you sure you want to unassign this article?',
    unassignSelectedLabel: 'Unassign selected',
    unassignSelectedModalTitle:
      'Are you sure you want to unassign these articles?',
    uploadLabel: 'Upload',
  },
})
