import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addNewMember: '+ Add New Member',
    // eslint-disable-next-line sort-keys
    additionalComments: 'Additional Comments',
    affiliatedOrganization: 'Affiliated Organization',
    firstName: 'First Name',
    healthcareRole: 'Healthcare Role',
    healthcareTeamLabel: 'Healthcare',
    lastName: 'Last Name',
    lastNameValidation: 'Last Name is required',
    lastVisitDate: 'Last Visit Date',
    lastVisitSummary: 'Last Visit Summary',
    otherRole: 'Other Role',
    phoneNumber: 'Phone Number',
    phoneNumberFormatValidation: 'Phone number must be valid',
    removeLabel: 'Remove',
    requiredMessage: 'Healthcare Role or Other Role is required',
    role: 'Role',
    title: 'Survivor Healthcare Team',
  },
})
