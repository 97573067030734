import { Toolbar } from 'src/components/RichTextEditor/types'

export const quillArticleToolbar: Toolbar = {
  container: [
    [{ header: [1, false] }],
    ['bold', 'italic', 'strike', 'link'],
    [
      'blockquote',
      { list: 'bullet' },
      { list: 'ordered' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['image'],
  ],
}

export type Timer = ReturnType<typeof setInterval>

export interface SsmlError {
  data?: {
    generation_error_page_number: number
    errors: string
    id?: string
  }
}
