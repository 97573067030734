import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    articlesHeader: 'Articles',
    articlesTitleLabel: 'Article Title',
    assignedToLabel: 'Assigned to',
    audienceLabel: 'Audience',
    audioLabel: 'Audio',
    completionStatusLabel: 'Completion Status',
    createdAtLabel: 'Created At',
    imageLabel: 'Image',
    internalIdLabel: 'Internal ID',
    internalNameLabel: 'Internal Name',
    languageLabel: 'Language',
    lastUpdateByLabel: 'Last Updated By',
    packageHeader: 'Package Details',
    packageStatusLabel: 'Package Status',
    tagNo: 'No',
    tagYes: 'Yes',
    titleLabel: 'Title',
    updatedAtLabel: 'Updated At',
  },
})
