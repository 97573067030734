import { Form } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Checkbox } from 'src/components/Checkbox'
import { DatePicker } from 'src/components/DatePicker'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { Select } from 'src/components/Select'
import { TextInput } from 'src/components/TextInput'
import { UploadDocuments } from 'src/pages/SurvivorView/CommunicationLogs/UploadDocuments'
import { useGetCommunicationLogOptionsQuery } from 'src/store/APIs/communicationLogs'
import { CommunicationLog } from 'src/store/APIs/communicationLogs/types'

import styles from '../communicationLogs.module.scss'
import { strings } from '../strings'
import { yupSync } from './validations'

export interface AddCommunicationLogProps {
  data?: CommunicationLog
}

export const AddCommunicationLog: React.FC<AddCommunicationLogProps> = ({
  data,
}) => {
  const { id: survivorId } = useParams<{ id: string }>()
  const { data: options } = useGetCommunicationLogOptionsQuery({ survivorId })

  return (
    <div className={styles.container}>
      <Form.Item name="mobile_survivor_id" hidden initialValue={survivorId} />
      <div className={styles.row}>
        <Form.Item name="title" rules={[yupSync]}>
          <TextInput label={strings.titleColumnLabel} required />
        </Form.Item>
        <Form.Item name="encounter_date" rules={[yupSync]}>
          <DatePicker
            label={strings.encounterDateColumnLabel}
            required
            allowClear={false}
          />
        </Form.Item>
      </div>
      <div className={styles.row}>
        <Form.Item
          name="participants_ids"
          rules={[yupSync]}
          initialValue={data?.participants.map(item => item.mobile_user_id)}>
          <Select
            label={strings.participantsColumnLabel}
            mode="multiple"
            options={options?.participants}
            placeholder={strings.selectPlaceholder}
            className={styles.fitContent}
            required
          />
        </Form.Item>
        <Form.Item name="status" rules={[yupSync]}>
          <Select
            label={strings.statusColumnLabel}
            options={options?.statuses}
            placeholder={strings.selectPlaceholder}
            className={styles.fitContent}
            required
          />
        </Form.Item>
      </div>
      <div className={styles.row}>
        <Form.Item name="type_of" rules={[yupSync]}>
          <Select
            label={strings.typeColumnLabel}
            mode="multiple"
            options={options?.types}
            placeholder={strings.selectPlaceholder}
            className={styles.fitContent}
            required
          />
        </Form.Item>
        <Form.Item name="topic" rules={[yupSync]}>
          <Select
            label={strings.topicColumnLabel}
            options={options?.topics}
            placeholder={strings.selectPlaceholder}
            className={styles.fitContent}
            required
          />
        </Form.Item>
      </div>
      <Form.Item name="escalation" valuePropName="checked">
        <Checkbox>{strings.escalationColumnLabel}</Checkbox>
      </Form.Item>
      {/* INFO: In case you want to make file uploading required you need to send the other props */}
      <UploadDocuments maxCount={20} data={data?.documentsFileList} />
      <Form.Item name="summary" rules={[yupSync]}>
        <RichTextEditor
          className={styles.notesInput}
          label={strings.summaryColumnLabel}
          required
          placeholder={strings.notesPlaceholder}
        />
      </Form.Item>
    </div>
  )
}
