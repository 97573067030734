/* eslint-disable no-nested-ternary */
import {
  EditOutlined,
  InfoCircleFilled,
  RightOutlined,
} from '@ant-design/icons'
import { FormInstance, message, Progress, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useState } from 'react'
import colors from 'src/assets/stylesheets/colors.scss'
import {
  formatShortDateToRender,
  formatShortDateWithTimeToRender,
} from 'src/common/types'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import {
  CompletionStatusOptions,
  completionStatusProps,
} from 'src/pages/SurvivorView/Learn/types'
import { useEditPackageDeploymentDateMutation } from 'src/store/APIs/packages'
import { Article, MainUnit } from 'src/store/APIs/survivors/types'

import { Button } from '../Button'
import { DeleteButton } from '../DeleteButton'
import styles from './learn.module.scss'
import { strings } from './strings'
import { LearnDataIndex, learnStatusFilters } from './types'

export const useLearnViewColumns = (
  onClickPackageRow: (id: string) => () => void,
  isCarePartner: boolean,
  form: FormInstance<any>,
  onClickArticleRow: (id: string) => () => void,
  onUnassignPackage: (unit: MainUnit) => () => void,
  onClickEditPackage: (id: string) => () => void,
  timezone?: string,
) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const [editingRowId, setEditingRowId] = useState<string>('')

  const isEditing = (record: MainUnit) => editingRowId === record.unit_id

  const [triggerEditDeploymentDate] = useEditPackageDeploymentDateMutation()

  const onClickEdit = (record: MainUnit) => {
    setEditingRowId(record.unit_id)
    form.setFieldsValue({ ...record })
  }

  const onClickSave = (record: MainUnit) => {
    form
      .validateFields()
      .then(() => {
        setEditingRowId('')
        triggerEditDeploymentDate({
          body: {
            articleIds: record.articles.map(article => article.id),
            deployment_date: form.getFieldValue(LearnDataIndex.deployment_date),
            lessonId: record.id,
            mobileUserId: record.mobile_user_id,
          },
          id: record.unit_id,
        })
      }) // INFO: This error is shown when the user tries to save with validation errors
      .catch(() => message.error(strings.errorMessage))
  }

  const columns: ColumnsType<MainUnit> = [
    {
      ...generateColumnTitle(
        LearnDataIndex['units.title'],
        strings.packageTitle,
      ),
      ...generateColumnSearchProps(LearnDataIndex['units.title']),
      filteredValue: filteredInfo.filters?.['units.title'] || null,
      fixed: 'left',
      render: (title, record) => {
        return onClickPackageRow ? (
          <Button type="link" onClick={onClickPackageRow(record.unit_id)}>
            {title}
          </Button>
        ) : (
          title
        )
      },
      sorter: true,
      width: 180,
    },
    {
      ...generateColumnTitle(LearnDataIndex.status, strings.packageStatus),
      filteredValue: filteredInfo.filters?.status || null,
      filters: learnStatusFilters,
      render(_, record) {
        if (!record.status) return <div className={styles.empty}>-</div>
        return (
          <Tag color={completionStatusProps(record.status).color as string}>
            {completionStatusProps(record.status).title}
          </Tag>
        )
      },
      sorter: true,
      width: 120,
    },
    {
      ...generateColumnTitle(
        LearnDataIndex['articles.title'],
        strings.articles,
      ),
      ...generateColumnSearchProps(LearnDataIndex['articles.title']),
      filteredValue: filteredInfo.filters?.['articles.title'] || null,
      render(_, record) {
        if (!record.articles || record.articles.length === 0)
          return <div className={styles.empty}>-</div>
        return (
          <ul className={styles.listContainer}>
            {record.articles.map((article: Article) => {
              return (
                <li key={article.id}>
                  <Button
                    type="link"
                    className={styles.listButton}
                    onClick={onClickArticleRow(article.id)}>
                    {article.title}
                  </Button>
                </li>
              )
            })}
          </ul>
        )
      },
    },
    {
      ...generateColumnTitle(
        LearnDataIndex.articles_completed,
        strings.articlesCompleted,
      ),
      render(_, record) {
        const completed =
          record.number_of_articles_completed / record.number_of_articles === 1
        if (!record.articles || record.articles.length === 0)
          return <div className={styles.empty}>-</div>
        return (
          <Progress
            strokeColor={completed ? colors.green_7 : undefined}
            trailColor={colors.gray_2}
            width={100}
            steps={record.number_of_articles}
            format={() =>
              `${record.number_of_articles_completed} / ${record.number_of_articles}`
            }
            percent={
              (record.number_of_articles_completed * 100) /
              record.number_of_articles
            }
          />
        )
      },
    },
    {
      ...generateColumnTitle(
        LearnDataIndex.deployment_date,
        strings.deploymentDate,
      ),
      onCell: (record: MainUnit) => {
        return {
          dataIndex: LearnDataIndex.deployment_date,
          editable: isEditing(record),
          record,
          timezone,
          title: strings.deploymentDate,
        }
      },
      render(_, record) {
        const editing = isEditing(record)
        return (
          <div>
            {!editing && (
              <span>
                {formatShortDateWithTimeToRender(record.deployment_date)}
              </span>
            )}
            {record.status === CompletionStatusOptions.Scheduled ? (
              editing ? (
                <Button
                  className={styles.leftButton}
                  type="primary"
                  onClick={() => onClickSave(record)}>
                  <RightOutlined />
                </Button>
              ) : (
                <Button
                  className={styles.leftButton}
                  onClick={() => onClickEdit(record)}
                  disabled={!!editingRowId && editingRowId !== record.unit_id}>
                  <EditOutlined />
                </Button>
              )
            ) : undefined}
          </div>
        )
      },
      sorter: true,
      title: (
        <div className={styles.deploymentDateTitle}>
          <span>{strings.deploymentDate}</span>
          <Tooltip
            title={
              isCarePartner
                ? strings.cpTooltipMessage
                : strings.survivorTooltipMessage
            }>
            <InfoCircleFilled className={styles.icon} />
          </Tooltip>
        </div>
      ),
      width: 250,
    },
    {
      ...generateColumnTitle(
        LearnDataIndex['mobile_user_lessons.created_at'],
        strings.createdAt,
      ),
      render: formatShortDateWithTimeToRender,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        LearnDataIndex['units.completed_date'],
        strings.userCompletedDate,
      ),
      render: formatShortDateToRender,
      sorter: true,
    },
    {
      fixed: 'right',
      render(_, record) {
        return (
          <div className={styles.actionButtonContainer}>
            <Button
              disabled={!record.unit?.permissions?.can_update}
              onClick={onClickEditPackage(record.unit_id)}>
              {strings.editButton}
            </Button>
            <DeleteButton
              disabled={!record.unit.permissions?.can_unassign}
              modalTitle={strings.unassignPackageTitle}
              okText={strings.unassignButton}
              onOk={onUnassignPackage(record)}>
              {strings.unassignButton}
            </DeleteButton>
          </div>
        )
      },
      title: strings.actionsLabel,
      width: 200,
    },
  ]

  return {
    columns,
    filteredInfo,
    ...restFilteredInfo,
  }
}
