import { Form } from 'antd'
import { FormProviderProps } from 'antd/es/form/context'
import React, { useEffect } from 'react'
import { PhoneNumberTypes } from 'src/common/types'
import { Button } from 'src/components/Button'
import { CarePartnerForm } from 'src/components/CarePartnerForm'
import { CarePartnerTabNames } from 'src/pages/CarePartner/types'
import { useUpdateCarePartnerMutation } from 'src/store/APIs/carePartners'
import {
  CarePartner,
  CarePartnerPayload,
} from 'src/store/APIs/carePartners/types'

import styles from '../carePartner.module.scss'
import { strings } from './strings'

interface BasicDemographicsEditProps {
  closeEditMode: () => void
  carePartner?: CarePartner
}

export const BasicDemographicsEdit: React.FC<BasicDemographicsEditProps> = ({
  carePartner,
  closeEditMode,
}) => {
  const [
    triggerUpdateCarePartner,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateCarePartnerMutation()
  const [carePartnerForm] = Form.useForm<CarePartner | undefined>()

  const isSuccess = updateSuccess
  const isLoading = updateLoading

  const handleCarePartnerSubmit = async (values: any) => {
    const body: CarePartnerPayload = {
      mobile_caregiver: {
        ...values,
        mobile_survivor_ids: carePartner?.survivors.map(
          survivor => survivor.id,
        ),
        preferred_phone: values.home_phone_preferred
          ? PhoneNumberTypes.Home
          : PhoneNumberTypes.Mobile,
      },
    }

    if (carePartner?.id) {
      await triggerUpdateCarePartner({
        body,
        id: carePartner?.id,
      })
    }
  }

  const onFormFinish: FormProviderProps['onFormFinish'] = async (
    _,
    { forms },
  ) => {
    if (forms[CarePartnerTabNames.basic_demographics]) {
      forms[CarePartnerTabNames.basic_demographics]
        .validateFields()
        .then(() =>
          handleCarePartnerSubmit(
            forms[CarePartnerTabNames.basic_demographics].getFieldsValue(),
          ),
        )
    }
  }

  useEffect(() => {
    if (isSuccess) {
      closeEditMode()
    }
  }, [isSuccess])

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <CarePartnerForm
        name={CarePartnerTabNames.basic_demographics}
        initialValues={carePartner}
        form={carePartnerForm}
      />
      <Form>
        <Form.Item>
          <div className={styles.submitButtonContainer}>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              {strings.updateButtonLabel}
            </Button>
            <div className={styles.buttonSeparator} />
            <Button htmlType="button" onClick={closeEditMode}>
              {strings.cancelButtonLabel}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Form.Provider>
  )
}
