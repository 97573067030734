import { baseApi } from 'src/store/APIs'

import { me } from './actions'

export const miscApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    me: me(builder),
  }),
})

export const {
  useMeQuery,
  reducer: miscApiReducer,
  reducerPath: miscApiReducerPath,
} = miscApi
