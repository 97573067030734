import { ColumnsType } from 'antd/es/table'
import { generateColumnTitle } from 'src/helpers/utils'
import { SurvivorHospitalTreatmentData } from 'src/store/APIs/hospitalTreatments/types'
import { SurvivorOtherEventData } from 'src/store/APIs/otherEvents/types'
import { SurvivorStrokeCauseData } from 'src/store/APIs/strokeCauses/types'

import { strings } from './strings'
import { StrokeDetailsDataIndex } from './types'

export const hospitalTreatmentsColumns: ColumnsType<SurvivorHospitalTreatmentData> =
  [
    {
      ...generateColumnTitle(
        StrokeDetailsDataIndex.name,
        strings.treatmentColumnLabel,
      ),
    },
    {
      ...generateColumnTitle(
        StrokeDetailsDataIndex.created_at,
        strings.createdAtColumnLabel,
      ),
      width: '30%',
    },
  ]

export const strokeCausesColumns: ColumnsType<SurvivorStrokeCauseData> = [
  {
    ...generateColumnTitle(
      StrokeDetailsDataIndex.cause,
      strings.causeColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      StrokeDetailsDataIndex.created_at,
      strings.createdAtColumnLabel,
    ),
    width: '30%',
  },
]

export const otherHealthEventsColumns: ColumnsType<SurvivorOtherEventData> = [
  {
    ...generateColumnTitle(
      StrokeDetailsDataIndex.event,
      strings.eventColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      StrokeDetailsDataIndex.created_at,
      strings.createdAtColumnLabel,
    ),
    width: '30%',
  },
]
