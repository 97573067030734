import { Form, Table } from 'antd'
import React, { useEffect } from 'react'
import { Button } from 'src/components/Button'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { TableTitle } from 'src/components/TableTitle'
import { TabNames } from 'src/pages/SurvivorView/types'
import {
  useGetSurvivorsHospitalTreatmentsQuery,
  useUpdateSurvivorsHospitalTreatmentsMutation,
} from 'src/store/APIs/hospitalTreatments'
import {
  useGetSurvivorsOtherEventsQuery,
  useUpdateSurvivorsOtherEventsMutation,
} from 'src/store/APIs/otherEvents'
import {
  useGetSurvivorsStrokeCausesQuery,
  useUpdateSurvivorsStrokeCausesMutation,
} from 'src/store/APIs/strokeCauses'
import { useUpdateSurvivorMutation } from 'src/store/APIs/survivors'
import { Survivor } from 'src/store/APIs/survivors/types'

import {
  hospitalTreatmentsColumns,
  otherHealthEventsColumns,
  strokeCausesColumns,
} from './columns'
import { TypeOfStroke } from './selectOptions'
import { strings } from './strings'
import styles from './strokeDetails.module.scss'

interface StrokeDetailsEditProps {
  survivor: Survivor
  toggleEditMode: () => void
}

export const StrokeDetailsEdit: React.FC<StrokeDetailsEditProps> = ({
  survivor,
  toggleEditMode,
}) => {
  const { data: strokeCauses, isFetching: isFetchingStrokeCauses } =
    useGetSurvivorsStrokeCausesQuery(survivor.id)
  const { data: hospitalTreatments, isFetching: isFetchingHospitalTreatments } =
    useGetSurvivorsHospitalTreatmentsQuery(survivor.id)
  const { data: otherEvents, isFetching: isFetchingOtherHealthEvents } =
    useGetSurvivorsOtherEventsQuery(survivor.id)

  const [
    triggerUpdateSurvivor,
    {
      isSuccess: updateSurvivorIsSuccess,
      isLoading: updateSurvivorIsLoading,
      isError: updateSurvivorIsError,
      error: updateSurvivorError,
    },
  ] = useUpdateSurvivorMutation()

  const [
    triggerUpdateSurvivorsStrokeCauses,
    {
      isSuccess: updateStrokeCausesIsSuccess,
      isLoading: updateStrokeCausesIsLoading,
      isError: updateStrokeCausesIsError,
      error: updateStrokeCausesError,
    },
  ] = useUpdateSurvivorsStrokeCausesMutation()

  const [
    triggerUpdateSurvivorsHospitalTreatments,
    {
      isSuccess: updateHospitalTreatmentsIsSuccess,
      isLoading: updateHospitalTreatmentsIsLoading,
      isError: updateHospitalTreatmentsIsError,
      error: updateHospitalTreatmentsError,
    },
  ] = useUpdateSurvivorsHospitalTreatmentsMutation()

  const [
    triggerUpdateSurvivorsOtherEvents,
    {
      isSuccess: updateOtherEventsIsSuccess,
      isLoading: updateOtherEventsIsLoading,
      isError: updateOtherEventsIsError,
      error: updateOtherEventsError,
    },
  ] = useUpdateSurvivorsOtherEventsMutation()

  const isSuccess =
    updateSurvivorIsSuccess ||
    updateStrokeCausesIsSuccess ||
    updateHospitalTreatmentsIsSuccess ||
    updateOtherEventsIsSuccess
  const isLoading =
    updateSurvivorIsLoading ||
    updateStrokeCausesIsLoading ||
    updateHospitalTreatmentsIsLoading ||
    updateOtherEventsIsLoading
  const isError =
    updateSurvivorIsError ||
    updateStrokeCausesIsError ||
    updateHospitalTreatmentsIsError ||
    updateOtherEventsIsError
  const error =
    updateSurvivorError ||
    updateStrokeCausesError ||
    updateHospitalTreatmentsError ||
    updateOtherEventsError

  const handleSubmit = async (values: any) => {
    const updatedSurvivorInfo = {
      id: survivor?.id,
      stroke_story: values.stroke_story,
      stroke_summary: values.stroke_summary,
    }
    await triggerUpdateSurvivor(updatedSurvivorInfo)

    if (values.stroke_causes)
      await triggerUpdateSurvivorsStrokeCauses({
        id: survivor?.id,
        stroke_causes: values.stroke_causes,
      })

    if (values.hospital_treatments)
      await triggerUpdateSurvivorsHospitalTreatments({
        hospital_treatments: values.hospital_treatments,
        id: survivor?.id,
      })

    if (values.other_events)
      await triggerUpdateSurvivorsOtherEvents({
        complications: values.other_events,
        id: survivor?.id,
      })
  }

  useEffect(() => {
    if (isSuccess && !isError) {
      toggleEditMode()
    }
  }, [isSuccess])

  const [form] = Form.useForm()

  const isHemorrhagicStroke =
    survivor?.type_of_stroke === TypeOfStroke.Hemorrhagic

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      name={TabNames.stroke_details}
      initialValues={survivor}>
      <div className={styles.title}>{strings.internalStrokeSummary}</div>
      <Form.Item name="stroke_summary">
        <RichTextEditor />
      </Form.Item>
      <div className={styles.title}>{strings.strokeStory}</div>
      <Form.Item name="stroke_story">
        <MultilineTextInput />
      </Form.Item>
      <div className={styles.title}>{strings.typeOfStroke}</div>
      {survivor?.type_of_stroke ? (
        <span>{survivor?.type_of_stroke}</span>
      ) : (
        <span className={styles.emptyText}>{strings.emptyText}</span>
      )}
      {isHemorrhagicStroke && (
        <>
          <div className={styles.title}>{strings.strokeSubtype}</div>
          <span>{survivor?.stroke_subtype}</span>
        </>
      )}
      <div className={styles.title}>{strings.anatomicalLocation}</div>
      {survivor?.anatomical_location ? (
        <span>{survivor?.anatomical_location}</span>
      ) : (
        <span className={styles.emptyText}>{strings.emptyText}</span>
      )}
      <div className={styles.title}>{strings.occurrenceDate}</div>
      {survivor?.occurrence_date ? (
        <span>{survivor?.occurrence_date?.format('MMMM D, YYYY')}</span>
      ) : (
        <span className={styles.emptyText}>{strings.emptyText}</span>
      )}
      <div className={styles.title}>{strings.dischargeDate}</div>
      {survivor?.discharge_date ? (
        <span>{survivor?.discharge_date?.format('MMMM D, YYYY')}</span>
      ) : (
        <span className={styles.emptyText}>{strings.emptyText}</span>
      )}
      <div className={styles.tableContainer}>
        <Table
          className={styles.spaceBwTables}
          columns={strokeCausesColumns}
          dataSource={strokeCauses}
          caption={<TableTitle title={strings.strokeCausesTitle} />}
          pagination={false}
          loading={isFetchingStrokeCauses}
        />
        <Table
          className={styles.spaceBwTables}
          columns={hospitalTreatmentsColumns}
          dataSource={hospitalTreatments}
          caption={<TableTitle title={strings.hospitalTreatmentsTitle} />}
          pagination={false}
          loading={isFetchingHospitalTreatments}
        />
        <Table
          className={styles.spaceBwTables}
          columns={otherHealthEventsColumns}
          dataSource={otherEvents}
          caption={<TableTitle title={strings.otherEventsTitle} />}
          pagination={false}
          loading={isFetchingOtherHealthEvents}
        />
      </div>
      <Form.Item>
        <div className={styles.submitButtonContainer}>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            {strings.updateButtonLabel}
          </Button>
          <div className={styles.buttonSeparator} />
          <Button htmlType="button" onClick={toggleEditMode}>
            {strings.cancelButtonLabel}
          </Button>
          {isError && (
            // TODO: create a better error message
            <div>{JSON.stringify((error as any)?.data?.errors)}</div>
          )}
        </div>
      </Form.Item>
    </Form>
  )
}
