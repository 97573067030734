import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddHealthConditionMutation,
  useDeleteHealthConditionMutation,
  useLazyGetHealthConditionQuery,
  useLazyGetHealthConditionsQuery,
  useUpdateHealthConditionMutation,
} from 'src/store/APIs/healthConditions'

import styles from '../survivorProfile.module.scss'
import { columns } from './columns'
import { strings } from './strings'

export const HealthConditions: React.FC = () => {
  const { open, onCloseDrawer, selectedId, showDrawer, openView } = useDrawer()

  const [triggerAddHealthConditionMutation, { isLoading: loadingAdd }] =
    useAddHealthConditionMutation()

  const [triggerEditHealthConditionMutation, { isLoading: loadingEdit }] =
    useUpdateHealthConditionMutation()

  const [triggerDeleteHealthConditionMutation, { isLoading: loadingDelete }] =
    useDeleteHealthConditionMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.healthConditionsTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addHealthConditionButton}
        </Button>
      </div>
      <DrawerWithState
        selectedId={selectedId}
        open={open}
        resourceName={strings.resourceName}
        RenderView={ViewBasicItem}
        RenderAdd={AddBasicItem}
        useLazyGetResource={useLazyGetHealthConditionQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddHealthConditionMutation}
        isLoadingRightButton={isLoadingRightButton}
        triggerEditResourceMutation={triggerEditHealthConditionMutation}
        triggerDeleteResourceMutation={triggerDeleteHealthConditionMutation}
        isLoadingLeftButton={loadingDelete}
        deleteModalTitle={strings.deleteModalTitle}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetHealthConditionsQuery}
      />
    </div>
  )
}
