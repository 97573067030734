import React from 'react'
import { useParams } from 'react-router-dom'
import { AssessmentsView } from 'src/components/AssessmentsView'
import {
  useDeleteSurvivorAssessmentMutation,
  useLazyGetSurvivorsAssessmentsQuery,
} from 'src/store/APIs/assessments'
import { useGetSurvivorQuery } from 'src/store/APIs/survivors'

interface AssessmentsProps {}

export const Assessments: React.FC<AssessmentsProps> = () => {
  const { id } = useParams<{ id: string }>()
  const { data: survivorInfo } = useGetSurvivorQuery(id)

  return (
    <AssessmentsView
      useLazyQuery={useLazyGetSurvivorsAssessmentsQuery}
      useDeleteMutation={useDeleteSurvivorAssessmentMutation}
      user={survivorInfo}
    />
  )
}
