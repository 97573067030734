import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addButtonLabel: 'Add',
    addCarePartner: 'Add Care Partner',
    cancelButtonLabel: 'Cancel',
    carePartnerLabel: 'Care Partner',
    emailLabel: 'Email',
    emailValidation: 'Email must be valid',
    firstNameLabel: 'First Name',
    homePhoneLabel: 'Home Phone',
    languageLabel: 'Language',
    lastNameLabel: 'Last Name',
    legalSexLabel: 'Legal Sex',
    limitationsLabel: 'Limitations',
    mobilePhoneLabel: 'Mobile Phone',
    phoneNumberFormatValidation: 'Phone number must be valid',
    phoneNumberLength: 'Phone number length must be 10',
    preferredLabel: 'Preferred',
    preferredNameLabel: 'Preferred Name',
    preferredPronounLabel: 'Preferred Pronoun',
    requiredValidation: 'This field is required',
    selectExistingCarePartner: 'Select existing care partner',
    tagNo: 'NO',
    tagYes: 'YES',
    testUserLabel: 'Test User',
    timezoneLabel: 'Time zone',
  },
})
