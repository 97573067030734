import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addDocument: 'Add Document',
    addDocumentButtonLabel: 'Add Document',
    descriptionLabel: 'Description',
    documentsTitle: 'Documents',
    editDocument: 'Edit Document',
    fileLabel: 'File',
    fileValidation: 'Uploading a file is required',
    nameLabel: 'Name',
    resourceName: 'Document',
    titleLabel: 'Title',
    titleValidation: '"Title" is required',
    uploadDateLabel: 'Upload Date',
    uploadTitle: 'Click or drag file to this area to upload',
    viewDocument: 'Document',
  },
})
