import React from 'react'
import { ResetPassword } from 'src/components/ResetPassword'
import {
  useLazyGetCarePartnerQuery,
  useResetCarePartnerPasswordMutation,
} from 'src/store/APIs/carePartners'

interface CarePartnerResetPasswordProps {}

export const CarePartnerResetPassword: React.FC<
  CarePartnerResetPasswordProps
> = () => (
  <ResetPassword
    useLazyGetUserQuery={useLazyGetCarePartnerQuery}
    isCarePartner
    useResetPassword={useResetCarePartnerPasswordMutation}
  />
)
