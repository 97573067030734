import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export interface ErrorState {
  message?: string
  audioError?: FetchBaseQueryError
  addPackageError?: FetchBaseQueryError
  addArticleError?: FetchBaseQueryError
}

export const initialState: ErrorState = {
  addArticleError: undefined,
  addPackageError: undefined,
  audioError: undefined,
  message: undefined,
}
