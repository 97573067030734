import { RcFile, UploadChangeParam } from 'antd/es/upload'
import { deserializeFormattedDate } from 'src/common/types'
import {
  BaseUser,
  Entity,
  Model,
  PaginatedItems,
  PaginationResponse,
  permissionsDeserializer,
  UserType,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetCommunityGroups = '/cms/v1/groups.json',
  GetCommunityGroup = '/cms/v1/groups/%s',
  GetCommunityGroupMembers = '/cms/v1/groups/%s/members.json?page=%s',
  NewCommunityGroup = '/cms/v1/groups',
  EditCommunityGroup = '/cms/v1/groups/%s',
  DeleteCommunityGroup = '/cms/v1/groups/%s',
  GetGroupPosts = '/cms/v1/groups/%s/posts.json',
  NewGroupPost = '/cms/v1/groups/%s/posts.json',
  DeleteGroupPost = '/cms/v1/groups/%s/posts/%s.json',
  LikeGroupPost = '/cms/v1/groups/%s/like_post.json',
  UnlikeGroupPost = '/cms/v1/groups/%s/unlike_post.json',
  LikePostComment = '/cms/v1/groups/%s/like_comment.json',
  UnlikePostComment = '/cms/v1/groups/%s/unlike_comment.json',
  GetPostComments = '/cms/v1/groups/%s/posts/%s/comments.json',
  NewPostComment = '/cms/v1/groups/%s/posts/%s/comments.json',
  DeletePostComment = '/cms/v1/groups/%s/posts/%s/comments/%s.json',
}

export interface CommunityGroupResponse extends Entity {
  id: string
  created_at: string
  description: string
  image: string
  name: string
  updated_at: string
  cms_navigators: BaseUser[]
  mobile_users: BaseUser[]
}

export interface GetCommunityGroupsResponse extends Model {
  groups: CommunityGroupResponse[]
  pagination: PaginationResponse
}

export interface CommunityGroup extends Entity {
  id: string
  created_at: string
  description?: string
  image?: string
  name: string
  updated_at: string
  cms_navigators: BaseUser[]
  mobile_users: BaseUser[]
}

export const getCommunityGroupDeserializer = (
  data: CommunityGroupResponse,
): CommunityGroup => {
  return {
    cms_navigators: data.cms_navigators,
    created_at: deserializeFormattedDate(data.created_at),
    description: data.description ?? undefined,
    id: data.id,
    image: data.image ?? undefined,
    mobile_users: data.mobile_users,
    name: data.name,
    permissions: permissionsDeserializer(data.permissions),
    updated_at: deserializeFormattedDate(data.updated_at),
  }
}

export const getCommunityGroupsDeserializer = (
  data: GetCommunityGroupsResponse,
): PaginatedItems<CommunityGroup> => ({
  items: data.groups.map(getCommunityGroupDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

export interface MemberResponse {
  id: string
  biography: string | null
  image: string | null
  name: string
  user_type: UserType
  label: string
}

export interface Member {
  id: string
  biography: string | undefined
  image: string | undefined
  name: string
  user_type: UserType
  label: string
}

export interface CommunityGroupMembersResponse {
  members: MemberResponse[]
  pagination: PaginationResponse
}

export interface CommunityGroupPostsResponse {
  posts: Post[]
}

export interface CommunityGroupPostCommentsResponse {
  post_comments: Comment[]
}

export const getCommunityGroupMemberDeserializer = (
  data: MemberResponse,
): Member => {
  return {
    biography: data.biography ? data.biography : undefined,
    id: data.id,
    image: data.image ? data.image : undefined,
    label: data.label,
    name: data.name,
    user_type: data.user_type,
  }
}

export const getCommunityGroupMembersDeserializer = (
  data: CommunityGroupMembersResponse,
): PaginatedItems<Member> => ({
  items: data.members.map(getCommunityGroupMemberDeserializer),
  pagination: data.pagination,
})

export interface CommunityGroupDetail {
  name: string
  description?: string
  mobile_user_ids: string[]
  cms_navigator_ids: string[]
  image?: File | string
}

export interface CommunityGroupDetailValues {
  name: string
  description?: string
  mobile_user_ids: string[]
  cms_navigator_ids: string[]
  image?: UploadChangeParam | string
}

export interface PostPayload {
  groupId: string
  body: {
    content: string
    image?: RcFile
  }
}

export interface CommentPayload {
  groupId: string
  postId: string
  body: {
    message: string
    image?: RcFile
    activityForeignId: string
  }
}

export interface Post extends Entity {
  id: string
  actor: Actor
  content: string
  attachments: {
    images: string[]
  }
  time: string
  reactions_counts: {
    comment: number
    like: number
  }
  foreign_id: string
  comments: Comment[]
  likes: Like[]
  liked_by_user: boolean
}

export interface Actor {
  id: string
  first_name?: string
  image?: string
  label: string
  last_name: string
  user_type: UserType
  name?: string
}

export interface Comment extends Entity {
  id: string
  actor: Actor
  object: string
  // TODO: Try to filter this data since we only support a single image/attachment
  attachments?: { images: string[] }
  time: string
  reactions_counts: {
    comment: number
    like: number
  }
  liked_by_user: boolean
  like_id?: string
}

export interface Like {
  id: string
  actor: Actor
  time: string
}

export const getCommunityGroupPostsDeserializer = (
  data: CommunityGroupPostsResponse,
) => {
  return data.posts.map(post => {
    return {
      ...post,
      time: `${post.time}Z`,
    }
  })
}

export const getCommunityGroupPostCommentsDeserializer = (
  data: CommunityGroupPostCommentsResponse,
) => data.post_comments
