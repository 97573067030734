import { UploadChangeParam } from 'antd/es/upload'

export interface ConversationValues {
  message: string
  image?: UploadChangeParam
}

export enum TypeOfMessage {
  ONE_ITEM_AT_TIME = 'ONE_ITEM_AT_TIME',
  MULTIPLE_ITEMS = 'MULTIPLE_ITEMS',
}
