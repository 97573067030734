import { Tag as AntdTag } from 'antd'
import React from 'react'

import { customColor, TagProps } from './types'

export const Tag: React.FC<TagProps> = ({ color, ...props }) => {
  const customStyles = customColor(color)
  return (
    <AntdTag
      {...props}
      color={color}
      className={`${props.className} ${customStyles}`}
    />
  )
}
