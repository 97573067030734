import React from 'react'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useLazyGetSurvivorImpairmentsQuery } from 'src/store/APIs/impairments'

import { EditableTab } from '../types'
import { columns } from './columns'
import { ImpairmentsEdit } from './ImpairmentsEdit'

interface ImpairmentsProps extends EditableTab {}

export const Impairments: React.FC<ImpairmentsProps> = ({
  toggleEditMode,
  editMode,
}) => {
  if (editMode) return <ImpairmentsEdit toggleEditMode={toggleEditMode} />
  return (
    <PaginatedTable
      columns={columns}
      useLazyQuery={useLazyGetSurvivorImpairmentsQuery}
      hasBadge
    />
  )
}
