import { DownOutlined, UpOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import {
  StatusOptions,
  statusProps,
} from 'src/pages/SurvivorView/NavigatorTasks/types'
import { useUpdateNavigatorTaskMutation } from 'src/store/APIs/navigatorTasks'

import styles from './tagSelect.module.scss'

interface TagSelectProps {
  options: string[]
  selectedStatus: string
  taskId: string
}

export const TagSelect: React.FC<TagSelectProps> = ({
  options,
  selectedStatus,
  taskId,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>(selectedStatus)
  const optionsRef = useRef<HTMLDivElement>(null)

  const [triggerUpdateNavigatorTask] = useUpdateNavigatorTaskMutation()

  const handleTagClick = () => {
    setVisible(!visible)
  }

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option)
    triggerUpdateNavigatorTask({
      body: { status: option as StatusOptions },
      id: taskId,
    })
    setVisible(false)
  }

  useEffect(() => {
    setSelectedOption(selectedStatus)
  }, [selectedStatus])

  useEffect(() => {
    if (optionsRef.current) {
      optionsRef.current.scrollTop = 0
    }
  }, [visible])

  return (
    <div className={styles.mainContainer}>
      <Tag
        className={styles.tag}
        onClick={handleTagClick}
        color={statusProps(selectedOption as StatusOptions).color}>
        {`${selectedOption} `}
        {visible ? <UpOutlined /> : <DownOutlined />}
      </Tag>
      {visible && (
        <div className={styles.container} ref={optionsRef}>
          {options.map(option => (
            <Button
              className={styles.option}
              size="small"
              key={option}
              onClick={() => handleOptionSelect(option)}
              style={{
                color: selectedOption === option ? '#1890ff' : undefined,
              }}>
              {option}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}
