import { Form } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { DatePicker } from 'src/components/DatePicker'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { Radio } from 'src/components/Radio'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { Select } from 'src/components/Select'
import { Reminder } from 'src/store/APIs/todos/types'

import { strings } from '../strings'
import styles from '../todos.module.scss'
import { selectOptions } from './selectOptions'
import { yupSync } from './validations'

interface AddTodoProps {}

export const AddTodo: React.FC<AddTodoProps> = () => {
  const { id } = useParams<{ id: string }>()
  const form = Form.useFormInstance()
  const reminder = Form.useWatch('type_of', form)
  return (
    <>
      <div className={styles.titleInputContainer}>
        <Form.Item name="description" rules={[yupSync]}>
          <RichTextEditor
            toolbar={[['link']]}
            label={strings.titleLabel}
            required
            subtitle={strings.titleNote}
          />
        </Form.Item>
      </div>
      <Form.Item name="additional_details">
        <MultilineTextInput label={strings.descriptionLabel} />
      </Form.Item>
      <div>{strings.reminderQuestion}</div>
      <Form.Item name="type_of" initialValue={Reminder.No}>
        <Radio.Group>
          <Radio value={Reminder.No}>{strings.noRadioLabel}</Radio>
          <Radio value={Reminder.Yes}>{strings.yesRadioLabel}</Radio>
          <Radio value={Reminder.YesRecurringReminder}>
            {strings.yesRecurringRadioLabel}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <div className={styles.row}>
        <Form.Item
          name="occurrence"
          rules={[
            {
              message: strings.reminderDateValidation,
              required: reminder === Reminder.YesRecurringReminder,
            },
          ]}
          className={styles.firstReminderInput}
          hidden={reminder !== Reminder.YesRecurringReminder}>
          <Select
            className={styles.repeatScheduleSelect}
            options={selectOptions}
            required
            label={strings.repeatScheduleLabel}
          />
        </Form.Item>
        <Form.Item
          name="due_datetime"
          hidden={reminder === Reminder.No || !reminder}
          rules={[
            {
              message: strings.reminderDateValidation,
              required: reminder === Reminder.Yes,
            },
          ]}>
          <DatePicker
            allowClear={false}
            required
            label={
              reminder === Reminder.YesRecurringReminder
                ? strings.firstReminderDateLabel
                : strings.reminderDateLabel
            }
            format="MM-DD-YYYY hh:mm A"
            showTime={{ use12Hours: true }}
            subtitle={strings.reminderDateSubtitle}
          />
        </Form.Item>
        <Form.Item name="mobile_survivor_id" hidden initialValue={id} />
      </div>
    </>
  )
}
