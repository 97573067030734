import { LayoutType, withLayout } from 'src/hocs/withLayout'

import { CarePartnerAssessmentDetails } from './CarePartnerAssessmentDetails'

const WrappedCarePartnerAssessmentDetails = withLayout(
  LayoutType.Default,
  CarePartnerAssessmentDetails,
)

export { WrappedCarePartnerAssessmentDetails as CarePartnerAssessmentDetails }
