import { InboxOutlined } from '@ant-design/icons'
import { Form, Upload } from 'antd'
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { TextInput } from 'src/components/TextInput'

import styles from '../documents.module.scss'
import { strings } from '../strings'
import { AddDocumentProps } from '../types'

export const AddDocument: React.FC<AddDocumentProps> = ({
  selectedId,
  data,
  setCanSubmitForm,
}) => {
  const { id } = useParams<{ id: string }>()
  const form = Form.useFormInstance()
  const file = Form.useWatch('file', form)

  const onRemove = () => {
    setCanSubmitForm?.(false)
  }

  useEffect(() => {
    if (selectedId) {
      setCanSubmitForm?.(true)
    }
    return () => setCanSubmitForm?.(false)
  }, [selectedId])

  useEffect(() => {
    if (file && file.fileList) {
      setCanSubmitForm?.(true)
    }
  }, [file])

  const itemRender = (originNode: ReactElement) => {
    return (
      <div className={styles.fileCard}>
        {originNode}
        <Form.Item
          name="name"
          className={styles.inputContainer}
          rules={[{ message: strings.titleValidation, required: true }]}>
          <TextInput label={strings.titleLabel} required />
        </Form.Item>
        <Form.Item name="description" className={styles.inputContainer}>
          <MultilineTextInput label={strings.descriptionLabel} />
        </Form.Item>
        <Form.Item name="mobile_survivor_id" hidden initialValue={id} />
      </div>
    )
  }
  const beforeUpload = () => {
    return false
  }

  return (
    <Form.Item name="file" className={styles.inputContainer}>
      <Upload.Dragger
        onRemove={onRemove}
        defaultFileList={
          data && [
            {
              name: data?.file.name,
              uid: selectedId ?? '',
              url: data?.file.url,
            },
          ]
        }
        maxCount={1}
        itemRender={itemRender}
        beforeUpload={beforeUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{strings.uploadTitle}</p>
      </Upload.Dragger>
    </Form.Item>
  )
}
