import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { DrawerWithStateProps } from 'src/components/DrawerWithState/types'
import { DrawerWithStateLearn } from 'src/components/DrawerWithStateLearn'
import {
  useAddPackageMutation,
  useDeletePackageMutation,
  useLazyGetPackageQuery,
  useUpdatePackageMutation,
  useUpdatePackageRowMutation,
} from 'src/store/APIs/packages'
import { LearnStatus } from 'src/store/APIs/types'

import { modalIconStyle } from '../../styles'
import { AddPackage } from '../AddPackage'
import { strings } from '../strings'
import { ViewPackage } from '../ViewPackage'

interface PackagesDrawerProps extends Partial<DrawerWithStateProps> {
  open: boolean
  onCloseDrawer: () => void
  selectedId?: string
}

export const PackagesDrawer: React.FC<PackagesDrawerProps> = ({
  open,
  onCloseDrawer,
  selectedId,
  initialDrawerState,
  ...props
}) => {
  const [triggerAddPackageMutation, { isLoading: isLoadingAdd }] =
    useAddPackageMutation()
  const [triggerEditPackageMutation, { isLoading: isLoadingEdit }] =
    useUpdatePackageMutation()
  const [triggerDeletePackageMutation, { isLoading: isLoadingDelete }] =
    useDeletePackageMutation()

  const [triggerEditPackageRowMutation] = useUpdatePackageRowMutation()

  const removePackage = (
    canUnassign: boolean,
    closeDrawer: () => void,
    articleIds?: string[],
  ) => {
    if (selectedId && canUnassign) {
      Modal.confirm({
        cancelText: generalStrings.cancel,
        icon: <ExclamationCircleOutlined style={modalIconStyle} />,
        okText: strings.unassignLabel,
        okType: 'danger',
        onCancel() {},
        async onOk() {
          await triggerEditPackageRowMutation({
            body: {
              article_ids: articleIds ?? [],
              status: LearnStatus.unassigned,
            },
            id: selectedId,
          })
          closeDrawer()
        },
        title: strings.unassignModalTitle,
      })
    }
  }

  const isLoadingRightButton = isLoadingAdd || isLoadingEdit

  return (
    <DrawerWithStateLearn
      isLoadingRightButton={isLoadingRightButton}
      isLoadingLeftButton={isLoadingDelete}
      removeResource={removePackage}
      initialDrawerState={initialDrawerState}
      push={false}
      deleteModalTitle={strings.deletePackageModalTitle}
      disableModalTitle={strings.disablePackageModalTitle}
      triggerAddResourceMutation={triggerAddPackageMutation}
      triggerEditResourceMutation={triggerEditPackageMutation}
      triggerDeleteResourceMutation={triggerDeletePackageMutation}
      selectedId={selectedId}
      open={open}
      onClose={onCloseDrawer}
      resourceName={strings.resourceName}
      RenderAdd={AddPackage}
      RenderView={ViewPackage}
      RenderEdit={AddPackage}
      useLazyGetResource={useLazyGetPackageQuery}
      onCloseDrawer={onCloseDrawer}
      {...props}
    />
  )
}
