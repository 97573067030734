import React from 'react'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useLazyGetSurvivorsHealthEventsQuery } from 'src/store/APIs/healthEvents'

import { EditableTab } from '../types'
import { columns } from './columns'
import { HealthEventsEdit } from './HealthEventsEdit'

interface HealthEventsProps extends EditableTab {}

export const HealthEvents: React.FC<HealthEventsProps> = ({
  editMode,
  toggleEditMode,
}) => {
  if (editMode) return <HealthEventsEdit toggleEditMode={toggleEditMode} />
  return (
    <PaginatedTable
      columns={columns}
      useLazyQuery={useLazyGetSurvivorsHealthEventsQuery}
      hasBadge
    />
  )
}
