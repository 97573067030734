import { Divider, Form } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { disableDateAfterToday } from 'src/common/helpers'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import {
  useAnswerAssessmentMutation,
  useCreateAndAnswerAssessmentMutation,
} from 'src/store/APIs/assessments'
import {
  AssessmentDraft,
  AssessmentStepType,
  SliderInterface,
} from 'src/store/APIs/assessments/types'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { useMeQuery } from 'src/store/APIs/misc'
import { Survivor } from 'src/store/APIs/survivors/types'
import { UserType } from 'src/store/APIs/types'
import { useLazyGetUsersQuery } from 'src/store/APIs/users'
import { UserRole } from 'src/store/APIs/users/types'

import { AssessmentStep } from '../AssessmentStep'
import { Button } from '../Button'
import { DatePicker } from '../DatePicker'
import { SearchSelect } from '../SearchSelect'
import { Select } from '../Select'
import { Spinner } from '../Spinner'
import styles from './assessmentDetails.module.scss'
import { strings } from './strings'
import { BOTH_VALUE, submitAssessmentHelper } from './submitAssessmentHelper'
import { useCheckScore } from './useCheckScore'

interface CompleteAssessmentContentProps {
  user?: Survivor | CarePartner
  accomplishAssessmentId?: string
  onClose: () => void
  form: FormInstance
  setRightButtonDisabled: (value: boolean) => void
  data?: AssessmentDraft
  isLoading?: boolean
  triggerGetAssessmentsQuestions: (id: string) => void
  errorFields: number[]
  clearRadioGroupError: (key: number) => () => void
  setErrorFields: Dispatch<SetStateAction<number[]>>
}

export const CompleteAssessmentContent: React.FC<
  CompleteAssessmentContentProps
> = ({
  user,
  accomplishAssessmentId,
  onClose,
  setRightButtonDisabled,
  form,
  data,
  isLoading,
  triggerGetAssessmentsQuestions,
  errorFields,
  clearRadioGroupError,
  setErrorFields,
}) => {
  const [triggerAnswerAssessment, { isSuccess }] = useAnswerAssessmentMutation()
  const { idAssessment } = useParams<{
    idAssessment: string
  }>()
  const [
    triggerCreateAndAnswerAssessment,
    { isSuccess: isSuccessCreateAndAnswer },
  ] = useCreateAndAnswerAssessmentMutation()

  const { data: loggedInUser } = useMeQuery()

  const {
    optionsSelect: usersOptions,
    fetchedFilteredElems: fetchedFilteredUsers,
  } = useSearchOptionsSelect(useLazyGetUsersQuery, undefined, 'display_name', {
    roles: [UserRole.Admin, UserRole.Navigator],
  })

  const isAdmin = loggedInUser?.user.user_type === UserType.admin

  const initialResponses =
    data?.steps.map(step => ({
      answer:
        step.type === AssessmentStepType.SLIDER
          ? (data?.options[step.content][0] as SliderInterface)?.min.toString()
          : undefined,
      key: step.key,
    })) ?? []

  let survivor
  let carePartner

  if (user) {
    if ('survivors' in user) {
      carePartner = user
      ;[survivor] = user.survivors
    } else {
      survivor = user
      ;[carePartner] = user.mobile_caregivers
    }
  }

  let spokeWithOptions = [{ label: survivor?.full_name, value: survivor?.id }]

  const spokeWithValues = {
    [UserType.mobile_survivor]: survivor?.id,
    [UserType.mobile_caregiver]: carePartner?.id,
  }

  const onFormFinish = submitAssessmentHelper(
    spokeWithValues,
    accomplishAssessmentId,
    idAssessment,
    user,
    loggedInUser?.user.user_type === UserType.admin,
  )

  const {
    disableUntilIndex,
    showUntilIndex,
    onClickNext,
    isLoadingScore,
    onClickEditPrevious,
  } = useCheckScore(
    form,
    setRightButtonDisabled,
    onClose,
    onFormFinish,
    data,
    setErrorFields,
  )

  if (carePartner) {
    spokeWithOptions = [
      ...spokeWithOptions,
      {
        label: carePartner.full_name,
        value: carePartner.id,
      },
      { label: strings.bothLabel, value: BOTH_VALUE },
    ]
  }

  useEffect(() => {
    if (accomplishAssessmentId) {
      triggerGetAssessmentsQuestions(accomplishAssessmentId)
    }
  }, [accomplishAssessmentId])

  useEffect(() => {
    if (isSuccess || isSuccessCreateAndAnswer) onClose()
  }, [isSuccess, isSuccessCreateAndAnswer])

  useEffect(() => {
    if (data?.steps) {
      form.setFieldsValue({ responses: initialResponses })
    }
  }, [data])

  useEffect(() => {
    return () => {
      setErrorFields([])
    }
  }, [])

  if (isLoading || !data) return <Spinner />

  return (
    <Form
      preserve={false}
      className={styles.content}
      form={form}
      onFinish={onFormFinish(
        triggerAnswerAssessment,
        triggerCreateAndAnswerAssessment,
      )}>
      <div className={isAdmin ? undefined : styles.navigatorTopSection}>
        <div className={styles.adminTopSection}>
          <Form.Item
            name="answered_date"
            initialValue={dayjs()}
            className={styles.formItem}
            rules={[
              {
                message: generalStrings.requiredFieldValidation,
                required: true,
              },
            ]}>
            <DatePicker
              allowClear={false}
              disabledDate={disableDateAfterToday}
              className={styles.dateInput}
              label={strings.dateCompletedLabel}
              placeholder={strings.datePlaceholder}
              required
            />
          </Form.Item>
          {isAdmin && (
            <Form.Item
              name="completed_by"
              className={styles.completedBySelect}
              rules={[
                {
                  message: generalStrings.requiredFieldValidation,
                  required: true,
                },
              ]}>
              {usersOptions && (
                <SearchSelect
                  label={strings.completedBy}
                  placeholder={strings.selectPlaceholder}
                  required
                  options={usersOptions}
                  fetchOptions={fetchedFilteredUsers}
                />
              )}
            </Form.Item>
          )}
        </div>
        <Form.Item name="spoke_with_survivor_id" hidden />
        <Form.Item name="spoke_with_caregiver_id" hidden />
        <Form.Item
          name="spoke_with"
          className={
            isAdmin ? styles.selectSpokeToAdmin : styles.selectSpokeToNav
          }
          initialValue={!carePartner ? survivor?.id : undefined}
          rules={[
            {
              message: generalStrings.requiredFieldValidation,
              required: true,
              type: 'string',
            },
          ]}>
          <Select
            label={strings.spokeToLabel}
            placeholder={strings.selectPlaceholder}
            required
            disabled={!carePartner}
            options={spokeWithOptions}
          />
        </Form.Item>
      </div>
      <Divider className={styles.divider} />
      <span>{strings.questionsTitle}</span>
      <Form.List name="responses" initialValue={initialResponses}>
        {fields => {
          return (
            <div className={styles.steps}>
              {fields.map((field, index) => {
                const disabledStep =
                  disableUntilIndex && index <= disableUntilIndex
                const hiddenStep = showUntilIndex && index > showUntilIndex
                return (
                  <div key={field.key}>
                    <AssessmentStep
                      disabled={!!disabledStep}
                      hidden={!!hiddenStep}
                      field={field}
                      actualStep={data?.steps[index]}
                      assessment={data}
                      validationError={errorFields.includes(field.key)}
                      onSelectRadioButton={clearRadioGroupError(field.key)}
                    />
                    {index === showUntilIndex &&
                      index !== data?.steps?.length && (
                        <div>
                          <Button
                            type="primary"
                            onClick={onClickNext}
                            loading={isLoadingScore}>
                            {strings.nextButton}
                          </Button>
                        </div>
                      )}
                    {index === disableUntilIndex && (
                      <div>
                        <Button type="primary" onClick={onClickEditPrevious}>
                          {strings.editPreviousQuestionsButton}
                        </Button>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        }}
      </Form.List>
    </Form>
  )
}
