/* eslint-disable react/no-danger */
import React from 'react'
import { useParams } from 'react-router-dom'
import { InfoTable } from 'src/components/InfoTable'
import { Spinner } from 'src/components/Spinner'
import { TableTitle } from 'src/components/TableTitle'
import { useGetSurvivorPlanOfCareQuery } from 'src/store/APIs/survivors'

import { EditableTab } from '../types'
import styles from './planOfCare.module.scss'
import { PlanOfCareEdit } from './PlanOfCareEdit'
import { strings } from './strings'

interface PlanOfCareProps extends EditableTab {}

export const PlanOfCare: React.FC<PlanOfCareProps> = ({
  editMode,
  toggleEditMode,
}) => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useGetSurvivorPlanOfCareQuery(id)
  const rowsFirstTable = [
    {
      content: data?.onboarding ? (
        <div dangerouslySetInnerHTML={{ __html: data?.onboarding }} />
      ) : null,
      label: strings.onboarding,
    },
    {
      content: data?.plan_of_care ? (
        <div dangerouslySetInnerHTML={{ __html: data.plan_of_care }} />
      ) : null,
      label: strings.planOfCare,
    },
  ]
  const rowsMDPTable = [
    { content: data?.mdp1, label: strings.mdp1 },
    { content: data?.mdp2, label: strings.mdp2 },
    { content: data?.mdp3, label: strings.mdp3 },
    { content: data?.mdp4, label: strings.mdp4 },
    { content: data?.mdp5, label: strings.mdp5 },
    { content: data?.mdp6, label: strings.mdp6 },
  ]
  const rowsMRSTable = [
    { content: data?.mrs_baseline, label: strings.mrsBaseline },
    { content: data?.mrs_7_day, label: strings.mrs7Day },
    { content: data?.mrs_30_day, label: strings.mrs30Day },
    { content: data?.mrs_90_day, label: strings.mrs90Day },
    { content: data?.mrs_graduation, label: strings.mrsGraduation },
  ]
  const rowsSocialNeedsTable = [
    data?.disabilities_enabled && {
      content: data?.disabilities,
      label: strings.disabilities,
    },
    data?.education_enabled && {
      content: data?.education,
      label: strings.education,
    },
    data?.employment_enabled && {
      content: data?.employment,
      label: strings.employment,
    },
    data?.family_and_community_support_enabled && {
      content: data?.family_and_community_support,
      label: strings.familyAndCommunitySupport,
    },
    data?.financial_strain_enabled && {
      content: data?.financial_strain,
      label: strings.financialStrain,
    },
    data?.food_enabled && {
      content: data?.food,
      label: strings.food,
    },
    data?.legal_status_enabled && {
      content: data?.legal_status,
      label: strings.legalStatus,
    },
    data?.living_situation_enabled && {
      content: data?.living_situation,
      label: strings.livingSituation,
    },
    data?.mental_health_enabled && {
      content: data?.mental_health,
      label: strings.mentalHealth,
    },
    data?.physical_activity_enabled && {
      content: data?.physical_activity,
      label: strings.physicalActivity,
    },
    data?.safety_enabled && {
      content: data?.safety,
      label: strings.safety,
    },
    data?.substance_abuse_enabled && {
      content: data?.substance_abuse,
      label: strings.substanceAbuse,
    },
    data?.transportation_enabled && {
      content: data?.transportation,
      label: strings.transportation,
    },
    data?.utilities_enabled && {
      content: data?.utilities,
      label: strings.utilities,
    },
  ].filter(row => row)

  const rowsNihss = [{ content: data?.nihss, label: strings.nihss }]

  if (isLoading) return <Spinner />

  if (editMode)
    return <PlanOfCareEdit planOfCare={data} toggleEditMode={toggleEditMode} />

  return (
    <div className={styles.container}>
      <InfoTable
        title={
          <TableTitle title={strings.mrsTitle} className={styles.tableTitle} />
        }
        rows={rowsMRSTable}
      />
      <InfoTable rows={rowsNihss} className={styles.spaceBwTables} />
      <InfoTable rows={rowsFirstTable} className={styles.spaceBwTables} />
      <InfoTable
        title={
          <TableTitle
            title={strings.socialNeedsTitle}
            className={styles.tableTitle}
          />
        }
        rows={rowsSocialNeedsTable as { label: string; content: any }[]}
        className={styles.spaceBwTables}
      />
      <InfoTable
        title={
          <TableTitle
            title={strings.mdpViewTitle}
            className={styles.tableTitle}
          />
        }
        rows={rowsMDPTable}
        className={styles.spaceBwTables}
      />
    </div>
  )
}
