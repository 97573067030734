import { DndContext, DragEndEvent } from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { FloatButton } from 'antd'
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React, { useEffect, useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { SubHeader } from 'src/components/SubHeader'
import { RouteName } from 'src/routes'
import {
  useGetDefaultNavigatorTasksQuery,
  useReorderDefaultNavigatorTasksMutation,
} from 'src/store/APIs/navigatorTasks'

import { AddDefaultTask } from './AddDefaultTask'
import { DefaultNavigatorTask } from './DefaultNavigatorTask'
import { DefaultNavigatorTask as DefaultNavigatorTaskType } from './DefaultNavigatorTask/types'
import styles from './defaultNavigatorTasks.module.scss'
import { DefaultTaskSkeleton } from './DefaultTaskSkeleton'
import { strings } from './strings'

interface DefaultNavigatorTasksProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: strings.title, path: RouteName.DefaultNavigatorTasks },
]

export const DefaultNavigatorTasks: React.FC<
  DefaultNavigatorTasksProps
> = () => {
  const [isReordering, setIsReordering] = useState(false)
  const { data, isLoading, isFetching } = useGetDefaultNavigatorTasksQuery()
  const [defaultTasks, setDefaultTasks] = useState<
    DefaultNavigatorTaskType[] | undefined
  >()
  const [isAdding, setIsAdding] = useState(false)
  const [triggerReorderDefaultNavigatorTasks, { isLoading: isLoadingReorder }] =
    useReorderDefaultNavigatorTasksMutation()
  const loadingTasks = !defaultTasks || isLoading || isFetching
  const skeletonArray = Array.from(Array(10).keys())

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDefaultTasks(
        arrayMove(
          defaultTasks ?? [],
          (defaultTasks ?? []).findIndex(i => i.id === active.id),
          (defaultTasks ?? []).findIndex(i => i.id === over?.id),
        ),
      )
    }
  }
  const onClickReorder = () => {
    setIsReordering(!isReordering)
  }

  const onClickCancel = () => {
    setDefaultTasks(data ?? [])
    setIsReordering(false)
  }

  const onClickResetOrder = () => {
    setDefaultTasks(data ?? [])
  }

  const onClickSubmitOrder = async () => {
    const orderedTasks = defaultTasks?.map(item => item.id)
    await triggerReorderDefaultNavigatorTasks(orderedTasks ?? [])
    setIsReordering(false)
  }
  const [elementsInEdition, setElementsInEdition] = useState<number[]>([])

  const toggleEdit = (position: number) => () => {
    if (elementsInEdition.includes(position)) {
      setElementsInEdition(prevState =>
        prevState.filter(item => item !== position),
      )
    } else {
      setElementsInEdition(prevState => [...prevState, position])
    }
  }

  const onDelete = (position: number) => () => {
    if (elementsInEdition.includes(position)) {
      setElementsInEdition(prevState =>
        prevState.reduce((acc, item) => {
          if (item !== position && item < position) {
            acc.push(item)
          } else if (item !== position) {
            acc.push(item - 1)
          }
          return acc
        }, [] as number[]),
      )
    } else {
      setElementsInEdition(prevState => [...prevState, position])
    }
  }

  const toggleAdd = () => {
    setIsAdding(!isAdding)
  }

  const subHeaderRef = React.useRef<HTMLDivElement>(null)
  const listRef = React.useRef<HTMLDivElement>(null)

  const isSticky = () => {
    const subHeader = subHeaderRef.current
    const list = listRef.current
    // INFO: 64 is the height of the header
    if (window.scrollY > 64) {
      subHeader?.classList.add(styles.sticky)
      list?.classList.add(styles.listPadding)
    } else {
      subHeader?.classList.remove(styles.sticky)
      list?.classList.remove(styles.listPadding)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      return window.removeEventListener('scroll', isSticky)
    }
  }, [])

  useEffect(() => {
    if (data) {
      setDefaultTasks(data)
    }
  }, [data])

  return (
    <div className={styles.container}>
      <SubHeader
        subHeaderRef={subHeaderRef}
        className={styles.subHeader}
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.Configuration}
        title={strings.title}>
        <div className={styles.buttonsContainer}>
          {isReordering ? (
            <>
              <Button onClick={onClickResetOrder}>
                {strings.resetOrderLabel}
              </Button>
              <Button onClick={onClickCancel}>{generalStrings.cancel}</Button>
              <Button
                loading={isLoadingReorder}
                type="primary"
                onClick={onClickSubmitOrder}>
                {strings.submitOrderLabel}
              </Button>
            </>
          ) : (
            <Button
              disabled={isAdding || !!elementsInEdition.length}
              onClick={onClickReorder}>
              {strings.reorderListLabel}
            </Button>
          )}
          <Button
            onClick={toggleAdd}
            type="primary"
            disabled={isAdding || isReordering || !!elementsInEdition.length}>
            {strings.addTaskLabel}
          </Button>
        </div>
      </SubHeader>
      <div ref={listRef} className={styles.listContainer}>
        {loadingTasks ? (
          skeletonArray.map(i => <DefaultTaskSkeleton key={i} />)
        ) : (
          <DndContext
            onDragEnd={onDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
            <SortableContext
              strategy={verticalListSortingStrategy}
              items={defaultTasks ? defaultTasks?.map(item => item.id) : []}>
              {(defaultTasks ?? [])?.map((item, index) => {
                if (elementsInEdition.includes(item.position)) {
                  return (
                    <AddDefaultTask
                      key={item.id}
                      onClickCancel={toggleEdit(item.position)}
                      initialValues={item}
                      onDeleteCallback={onDelete(item.position)}
                    />
                  )
                }
                return (
                  <DefaultNavigatorTask
                    sortable={isReordering}
                    defaultTask={item}
                    key={item.id}
                    isAdding={isAdding}
                    index={index}
                    onClickEdit={toggleEdit}
                  />
                )
              })}

              {isAdding && (
                <AddDefaultTask
                  onClickCancel={toggleAdd}
                  initialValues={{
                    position: data?.length ? data.length + 1 : 1,
                  }}
                />
              )}
            </SortableContext>
          </DndContext>
        )}
      </div>
      <FloatButton.BackTop className={styles.fab} />
    </div>
  )
}
