import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { generateColumnTitle } from 'src/helpers/utils'
import { AppLink } from 'src/routes'
import { Member } from 'src/store/APIs/communityGroups/types'
import { getRouteForUserType, UserType } from 'src/store/APIs/types'

import { strings } from './strings'
import { MembersDataIndex } from './types'

export const membersColumns: ColumnsType<Member> = [
  {
    ...generateColumnTitle(MembersDataIndex.image, strings.pictureLabel),
    render: image =>
      image ? (
        <Avatar src={<img src={image} alt="avatar" />} />
      ) : (
        <Avatar icon={<UserOutlined />} />
      ),
  },
  {
    ...generateColumnTitle(MembersDataIndex.name, strings.nameLabel),
    render(_, record) {
      return record.user_type !== UserType.cms_navigator ? (
        <AppLink
          routeName={getRouteForUserType(record.user_type)}
          pathParams={{ id: record.id }}>
          {record.name}
        </AppLink>
      ) : (
        record.name
      )
    },
  },
  {
    ...generateColumnTitle(MembersDataIndex.label, strings.typeLabel),
  },
  {
    ...generateColumnTitle(MembersDataIndex.biography, strings.biographyLabel),
  },
]
