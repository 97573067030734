import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    anatomicalLocation: 'Anatomical Location',
    cancelButtonLabel: 'Cancel',
    causeColumnLabel: 'Cause',
    createdAtColumnLabel: 'Created At',
    dischargeDate: 'Discharge Date',
    emptyText: 'Empty',
    eventColumnLabel: 'Event',
    eventsPlaceholder: 'Events',
    hospitalTreatmentsTitle: 'Hospital Treatments',
    ich: 'ICH',
    internalStrokeSummary: 'Internal Stroke Summary',
    occurrenceDate: 'Occurrence Date',
    otherEventsTitle: 'Other Events',
    sah: 'SAH',
    strokeCausePlaceholder: 'Causes',
    strokeCausesTitle: 'Stroke Causes',
    strokeStory: 'Stroke Story',
    strokeSubtype: 'Stroke Subtype',
    treatmentColumnLabel: 'Treatment',
    treatmentsPlaceholder: 'Treatments',
    typeOfStroke: 'Type Of Stroke',
    updateButtonLabel: 'Update',
  },
})
