import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    filterNameDefaultValue: 'full_name',
    gleapTokenMissing: 'Unable to initialize Gleap, token not found',
    imageCancelModalText: 'No',
    imageConfirmModalText: 'Yes',
    imageConfirmModalTitle:
      'Are you sure you want to replace the previous image?',
  },
})
