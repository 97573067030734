import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  delete: yup.boolean().nullable(),
  description: yup.string().nullable(),
  major_health_event_id: yup.string().required(strings.requiredValidation),
  occurrence_date: yup.date().nullable(),
})

export const yupSync: Rule = yupSyncGenerator(schema)
