import { Form } from 'antd'
import React, { useEffect } from 'react'
import { Button } from 'src/components/Button'

import styles from './multipleBlockFooter.module.scss'
import { strings } from './strings'

interface MultipleBlockFooterProps {
  isLast: boolean
  isLoading: boolean
  isSuccess: boolean
  onCancel: () => void
  onSuccess: () => void
}

export const MultipleBlockFooter: React.FC<MultipleBlockFooterProps> = ({
  isLast,
  isLoading,
  isSuccess,
  onCancel,
  onSuccess,
}) => {
  useEffect(() => {
    if (isSuccess) onSuccess()
  }, [isSuccess])

  return isLast ? (
    <Form.Item>
      <div className={styles.submitButtonContainer}>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          {strings.updateButtonLabel}
        </Button>
        <div className={styles.buttonSeparator} />
        <Button htmlType="button" onClick={onCancel}>
          {strings.cancelButtonLabel}
        </Button>
      </div>
    </Form.Item>
  ) : (
    <div className={styles.separatorBWEntities} />
  )
}
