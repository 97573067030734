import {
  AssessmentAnswerPayload,
  AssessmentCreateAndAnswerPayload,
  AssessmentResponseItem,
} from 'src/store/APIs/assessments/types'
import { UserType } from 'src/store/APIs/types'

import { OnAssessmentFormFinish, SubmitAssessmentHelper } from './types'

export const BOTH_VALUE = 'both'

export const submitAssessmentHelper: SubmitAssessmentHelper = (
  spokeWithValues,
  accomplishAssessmentId,
  idAssessment,
  user,
  isAdmin,
) => {
  const getSpokeWithIds = (spokeWith: string) => {
    const spokeWithIds: {
      spoke_with_caregiver_id?: string
      spoke_with_survivor_id?: string
    } = {
      spoke_with_caregiver_id: undefined,
      spoke_with_survivor_id: undefined,
    }
    if (
      spokeWith === BOTH_VALUE ||
      spokeWith === spokeWithValues[UserType.mobile_caregiver]
    ) {
      spokeWithIds.spoke_with_caregiver_id =
        spokeWithValues[UserType.mobile_caregiver]
    }
    if (
      spokeWith === BOTH_VALUE ||
      spokeWith === spokeWithValues[UserType.mobile_survivor]
    ) {
      spokeWithIds.spoke_with_survivor_id =
        spokeWithValues[UserType.mobile_survivor]
    }
    return spokeWithIds
  }

  const getNavigatorBody = (values: any): AssessmentAnswerPayload => {
    return {
      answered_date: values.answered_date,
      responses: values.responses.filter(
        (item: AssessmentResponseItem) => item.answer,
      ),
    }
  }

  const getAdminBody = (values: any): AssessmentCreateAndAnswerPayload => {
    return {
      answered_date: values.answered_date,
      assessment_id: values.assessment_id,
      completed_by_id: values.completed_by,
      mobile_user_id: user?.mobile_user_id,
      responses: values.responses.filter(
        (item: AssessmentResponseItem) => item.answer,
      ),
    }
  }

  const onFormFinish: OnAssessmentFormFinish =
    (triggerAnswerAssessment, triggerCreateAndAnswerAssessment) =>
    async values => {
      let result
      if (accomplishAssessmentId) {
        const spokeWithIds = getSpokeWithIds(values.spoke_with)
        const navigatorBody: AssessmentAnswerPayload = {
          ...getNavigatorBody(values),
          ...spokeWithIds,
        }
        const adminBody: AssessmentCreateAndAnswerPayload = {
          ...getAdminBody({
            ...values,
            assessment_id: accomplishAssessmentId,
          }),
          ...spokeWithIds,
        }
        if (idAssessment) {
          result = await triggerAnswerAssessment({
            body: isAdmin ? adminBody : navigatorBody,
            id: idAssessment,
          })
        } else {
          result = triggerCreateAndAnswerAssessment({
            body: adminBody,
          })
        }
      }
      return result
    }
  return onFormFinish
}
