import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  description: yup.string().notRequired(),
  health_condition_id: yup.string().required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
