import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  assign_to_user: yup.string().notRequired(),
  audience: yup.string().required(strings.requiredValidation),
  internal_id: yup.string().required(strings.requiredValidation),
  internal_name: yup.string().required(strings.requiredValidation),
  language: yup.string().required(strings.requiredValidation),
  title: yup.string().required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
