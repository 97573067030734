import { LayoutType, withLayout } from 'src/hocs/withLayout'

import { CarePartnerResetPassword } from './CarePartnerResetPassword'

const WrappedCarePartnerResetPassword = withLayout(
  LayoutType.Default,
  CarePartnerResetPassword,
)

export { WrappedCarePartnerResetPassword as CarePartnerResetPassword }
