import React from 'react'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useLazyGetDocumentQuery,
  useLazyGetSurvivorDocumentsQuery,
  useUpdateDocumentMutation,
} from 'src/store/APIs/documents'

import { AddDocument } from './AddDocument'
import { columns } from './columns'
import styles from './documents.module.scss'
import { strings } from './strings'
import { ViewDocument } from './ViewDocument'

interface DocumentsProps {}

export const Documents: React.FC<DocumentsProps> = () => {
  const { open, onCloseDrawer, showDrawer, openView, selectedId } = useDrawer()
  const [triggerAddDocumentMutation, { isLoading: loadingAdd }] =
    useAddDocumentMutation()
  const [triggerUpdateDocumentMutation, { isLoading: loadingUpdate }] =
    useUpdateDocumentMutation()
  const [triggerDeleteDocumentMutation] = useDeleteDocumentMutation()
  const loadingRightButton = loadingAdd || loadingUpdate

  return (
    <>
      <DrawerWithState
        isLoadingRightButton={loadingRightButton}
        selectedId={selectedId}
        useLazyGetResource={useLazyGetDocumentQuery}
        RenderEdit={AddDocument}
        resourceName={strings.resourceName}
        RenderAdd={AddDocument}
        RenderView={ViewDocument}
        open={open}
        triggerAddResourceMutation={triggerAddDocumentMutation}
        triggerDeleteResourceMutation={triggerDeleteDocumentMutation}
        triggerEditResourceMutation={triggerUpdateDocumentMutation}
        onCloseDrawer={onCloseDrawer}
      />
      <div className={styles.documentsTabContainer}>
        <Button onClick={showDrawer} type="primary">
          {strings.addDocumentButtonLabel}
        </Button>
        <PaginatedTable
          columns={columns(openView)}
          useLazyQuery={useLazyGetSurvivorDocumentsQuery}
        />
      </div>
    </>
  )
}
