import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from '../strings'

const schema = yup.object({
  completed_date: yup.date().notRequired().nullable(),
  description: yup.string().required(strings.requiredValidation),
  goal_date: yup.date().nullable().notRequired(),
  status: yup.string().required(strings.requiredValidation),
  time_spent: yup.number().required(strings.requiredValidation),
  type_of: yup.string().required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
