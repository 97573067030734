import { ColumnsType } from 'antd/es/table'
import { generateColumnTitle } from 'src/helpers/utils'
import { GetSurvivorImpairmentsData } from 'src/store/APIs/impairments/types'

import { strings } from './strings'

export enum ImpairmentsDataIndex {
  'impairment' = 'impairment',
  'clinical_name' = 'clinical_name',
  'description' = 'description',
}

export const columns: ColumnsType<GetSurvivorImpairmentsData> = [
  {
    ...generateColumnTitle(
      ImpairmentsDataIndex.impairment,
      strings.impairmentColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      ImpairmentsDataIndex.clinical_name,
      strings.clinicalNameColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      ImpairmentsDataIndex.description,
      strings.descriptionColumnLabel,
    ),
  },
]
