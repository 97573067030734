import React from 'react'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { goToPage, RouteName } from 'src/routes'
import { useLazyGetSurvivorsAlertsQuery } from 'src/store/APIs/survivors'
import { BaseUser, UserType } from 'src/store/APIs/types'

import { TabNames } from '../types'
import { columns } from './columns'

interface AlertsProps {}

const getTabName = (module: string) => {
  switch (module) {
    case 'private-conversation':
      return TabNames.private_conversation
    case 'group-conversation':
      return TabNames.group_conversation
    case 'impairments':
      return TabNames.impairments
    case 'health-event':
      return TabNames.health_events
    case 'assessments':
      return TabNames.assessments
    case 'learning':
      return TabNames.learning
    case 'quick-jots':
      return TabNames.quick_jots
    case 'to-do-s':
      return TabNames.to_do_s
    default:
      return null
  }
}

export const Alerts: React.FC<AlertsProps> = () => {
  const onClickAlert = (alertTabName: string, owner: BaseUser) => () =>
    goToPage(
      owner.user_type === UserType.mobile_survivor
        ? RouteName.SurvivorView
        : RouteName.CarePartner,
      { id: owner.id! },
      { tab: getTabName(alertTabName) },
    )

  return (
    <PaginatedTable
      columns={columns(onClickAlert)}
      useLazyQuery={useLazyGetSurvivorsAlertsQuery}
      hasBadge
    />
  )
}
