/* eslint-disable no-nested-ternary */
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query'
import {
  UseLazyQuery,
  UseMutation,
} from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Form, Modal } from 'antd'
import { Dayjs } from 'dayjs'
import { RuleObject } from 'rc-field-form/lib/interface'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { validateTimeWithTimezone } from 'src/common/generalValidations'
import {
  disableDateBeforeCustomDate,
  disableDateBeforeToday,
} from 'src/common/helpers'
import { AddAssessment } from 'src/components/AddAssessment'
import { Button } from 'src/components/Button'
import { useDrawer } from 'src/hooks/useDrawer'
import { useGetAssessmentsQuery } from 'src/store/APIs/assessments'
import { AssessmentUserType } from 'src/store/APIs/assessments/types'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { useMeQuery } from 'src/store/APIs/misc'
import { Survivor } from 'src/store/APIs/survivors/types'
import {
  AssessmentsData,
  PaginatedItems,
  PerformActionResponse,
  UserType,
} from 'src/store/APIs/types'

import { CompleteAssessmentDrawer } from '../AssessmentDetails/CompleteAssessmentDrawer'
import { DateAndTimePicker } from '../DateAndTimePicker'
import { DatePicker } from '../DatePicker'
import { PaginatedTable } from '../PaginatedTable'
import { Select } from '../Select'
import styles from './assessments.module.scss'
import { useAssessmentsColumns } from './columns'
import { strings } from './strings'
import { AssessmentsDataIndex } from './types'

interface AssessmentsViewProps {
  useDeleteMutation: UseMutation<
    MutationDefinition<
      {
        idUser: string
        idElem: string
      },
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      any,
      PerformActionResponse,
      any
    >
  >
  useLazyQuery: UseLazyQuery<
    QueryDefinition<any, any, any, PaginatedItems<any>, any>
  >
  isCarePartner?: boolean
  user?: Survivor | CarePartner
}

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: AssessmentsData
  timezone: string
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  timezone,
  ...restProps
}) => {
  const form = Form.useFormInstance()

  const deploymentDateValue = Form.useWatch(
    AssessmentsDataIndex.deployment_date,
    form,
  )

  const validateDueDate = (_: RuleObject, value: Dayjs) => {
    if (disableDateBeforeCustomDate(deploymentDateValue, value)) {
      return Promise.reject(new Error(strings.dueDateValidation))
    }
    return Promise.resolve()
  }

  let childNode = children

  childNode =
    editable && dataIndex === AssessmentsDataIndex.deployment_date ? (
      <Form.Item
        className={styles.formItem}
        initialValue={record.deployment_date}
        name={dataIndex}
        rules={[
          {
            message: strings.requiredValidation,
            required: true,
          },
          { validator: validateTimeWithTimezone(timezone) },
        ]}>
        <DateAndTimePicker disabledDate={disableDateBeforeToday} />
      </Form.Item>
    ) : editable && dataIndex === AssessmentsDataIndex.due_time ? (
      <Form.Item
        className={styles.formItem}
        initialValue={record.due_time}
        name={dataIndex}
        rules={[
          {
            message: strings.requiredValidation,
            required: true,
          },
          { validator: validateDueDate },
        ]}
        dependencies={[AssessmentsDataIndex.deployment_date]}>
        <DatePicker
          disabledDate={currentDate =>
            disableDateBeforeCustomDate(deploymentDateValue, currentDate)
          }
        />
      </Form.Item>
    ) : (
      <div>{children}</div>
    )

  return <td {...restProps}>{childNode}</td>
}

export const AssessmentsView: React.FC<AssessmentsViewProps> = ({
  useLazyQuery,
  isCarePartner,
  useDeleteMutation,
  user,
}) => {
  const { id } = useParams<{ id: string }>()
  const [form] = Form.useForm()

  const [triggerDeleteAssessment] = useDeleteMutation()

  const columnsAndFilters = useAssessmentsColumns(
    !!isCarePartner,
    id,
    triggerDeleteAssessment,
    form,
    user?.time_zone,
  )

  const { open, onCloseDrawer, showDrawer } = useDrawer()

  const {
    open: openAssessmentDrawer,
    onCloseDrawer: onCloseAssessmentDrawer,
    showDrawer: showDrawerAssessmentDrawer,
  } = useDrawer()

  const components = {
    body: {
      cell: EditableCell,
    },
  }

  const [openModal, setOpenModal] = useState(false)

  const onClickEnterAssessment = () => setOpenModal(true)

  const handleCancel = () => setOpenModal(false)

  const { data: assessments } = useGetAssessmentsQuery({
    type: isCarePartner
      ? AssessmentUserType.CarePartner
      : AssessmentUserType.Survivor,
  })

  const assessmentsOptions = assessments?.map(assessment => ({
    label: assessment.name,
    value: assessment.id,
  }))

  const { data: loggedInUser } = useMeQuery()

  const [selectedAssessmentId, setSelectedAssessmentId] = useState<
    string | undefined
  >(undefined)

  const onClickNext = () => {
    setOpenModal(false)
    showDrawerAssessmentDrawer()
  }

  return (
    <>
      <div className={styles.firstButton}>
        <Button
          type="primary"
          onClick={showDrawer}
          className={styles.leftButton}>
          {strings.assignAssessmentLabel}
        </Button>
        {loggedInUser?.user.user_type === UserType.admin && (
          <Button onClick={onClickEnterAssessment}>
            {strings.enterAssessmentLabel}
          </Button>
        )}
      </div>
      <Modal
        open={openModal}
        title={strings.modalTitle}
        closable={false}
        okText={strings.nextButton}
        onOk={onClickNext}
        onCancel={handleCancel}
        destroyOnClose>
        <Select
          label={strings.assessmentLabel}
          options={assessmentsOptions}
          placeholder={strings.assessmentPlaceholder}
          className={styles.select}
          onChange={setSelectedAssessmentId}
        />
        <div className={styles.modalParagraph}>{strings.modalParagraph}</div>
      </Modal>
      <AddAssessment
        isCarePartner={isCarePartner}
        onCloseDrawer={onCloseDrawer}
        open={open}
        user={user}
      />
      <Form form={form} component={false}>
        <PaginatedTable
          components={components}
          className={styles.table}
          useLazyQuery={useLazyQuery}
          {...columnsAndFilters}
        />
      </Form>
      <CompleteAssessmentDrawer
        open={openAssessmentDrawer}
        user={user}
        onCloseDrawer={onCloseAssessmentDrawer}
        accomplishAssessmentId={selectedAssessmentId}
      />
    </>
  )
}
