import { Form, FormListFieldData, Slider } from 'antd'
import React from 'react'
import colors from 'src/assets/stylesheets/colors.scss'
import { generalStrings } from 'src/common/generalStrings'
import { classnames } from 'src/helpers/utils'
import {
  AssessmentDraft,
  AssessmentStep as AssessmentStepInterface,
  AssessmentStepType,
  PickOptionInterface,
  SliderInterface,
} from 'src/store/APIs/assessments/types'

import { MultilineTextInput } from '../MultilineTextInput'
import { Radio } from '../Radio'
import styles from './assessmentStep.module.scss'

interface AssessmentStepProps {
  actualStep: AssessmentStepInterface
  assessment: AssessmentDraft
  field: FormListFieldData
  hidden?: boolean
  disabled?: boolean
  validationError?: boolean
  onSelectRadioButton: () => void
}

export const AssessmentStep: React.FC<AssessmentStepProps> = ({
  actualStep,
  assessment,
  field,
  hidden,
  disabled,
  validationError = false,
  onSelectRadioButton,
}) => {
  const showAssessmentQuestion = () => {
    switch (actualStep?.type) {
      case AssessmentStepType.CHECKBOX:
      case AssessmentStepType.CHOOSE_OPTION:
        return (
          <Form.Item
            name={[field.name, 'answer']}
            rules={[
              {
                message: generalStrings.requiredFieldValidation,
                required: true,
              },
            ]}
            className={styles.formItem}>
            <Radio.Group
              className={styles.content}
              onChange={onSelectRadioButton}>
              {assessment?.options[actualStep.content].map(option => (
                <Radio
                  disabled={disabled}
                  key={(option as PickOptionInterface).key}
                  value={(option as PickOptionInterface).title}
                  className={classnames({
                    [styles.radioButtonError]: validationError,
                  })}>
                  {(option as PickOptionInterface).title}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        )
      case AssessmentStepType.FREE_TEXT_MULTILINE:
        return (
          <Form.Item
            name={[field.name, 'answer']}
            rules={[
              {
                message: generalStrings.requiredFieldValidation,
                required: true,
              },
            ]}
            className={styles.formItem}>
            <MultilineTextInput placeholder={actualStep.title} />
          </Form.Item>
        )
      case AssessmentStepType.SLIDER: {
        const options = assessment.options[
          actualStep.content
        ][0] as SliderInterface
        return (
          <div className={styles.slider}>
            <Form.Item
              name={[field.name, 'answer']}
              normalize={value => `${value}`}
              rules={[
                {
                  message: generalStrings.requiredFieldValidation,
                  required: true,
                },
              ]}
              className={classnames(styles.validationText, styles.formItem)}
              initialValue={`${options.min}`}>
              <Slider
                min={options.min}
                max={options.max}
                marks={{
                  [options.min]: {
                    label: options.minMessage,
                    style: {
                      color: colors.surface_70,
                      marginLeft: options.minMessage.length > 15 ? 20 : 0,
                    },
                  },
                  [options.max]: {
                    label: options.maxMessage,
                    style: {
                      color: colors.surface_70,
                      width:
                        options.maxMessage.length > 20
                          ? 'min-content'
                          : 'max-content',
                    },
                  },
                }}
              />
            </Form.Item>
          </div>
        )
      }
      default:
        return null
    }
  }

  if (hidden) return null

  return (
    <div
      className={classnames(styles.step, {
        [styles.titleError]: validationError,
      })}>
      <Form.Item
        name={[field.name, 'key']}
        hidden
        initialValue={actualStep?.key}
      />
      <div
        className={classnames({
          [styles.disabledQuestion]: !!disabled,
        })}>
        {actualStep?.title}
        <br />
        {actualStep?.description}
      </div>
      {showAssessmentQuestion()}
    </div>
  )
}
