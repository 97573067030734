/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './appLink.module.scss'
import { getRouteFor, Params } from './route-helpers'
import { RouteName } from './routes'

/*
  This component is a wrapper for linking across and inside apps.

  AppLink automatically detects if the route passed is inside or outside the
  current app, and creates a Link or <a> tag according to the case.

  NOTE: this component is only useful for routing inside the apps
  defined in this project. To link outside, use <a></a> tags as usual.
*/

type AppLinkProps = {
  children: React.ReactNode
  className?: string
  pathParams?: Params
  queryParams?: Params
  routeName: RouteName
  targetBlank?: boolean
  disabled?: boolean
}

const defaultProps = {
  className: '',
  pathParams: {},
  queryParams: {},
  targetBlank: false,
}

const AppLink = (props: AppLinkProps) => {
  const routePath = getRouteFor(
    props.routeName,
    props.pathParams,
    props.queryParams,
  )
  let targetBlankProps = {}
  if (props.targetBlank) {
    targetBlankProps = {
      rel: 'noopener noreferrer',
      target: '_blank',
    }
  }
  return (
    <>
      {!props.disabled ? (
        <Link
          className={props.className}
          to={routePath}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...targetBlankProps}>
          {props.children}
        </Link>
      ) : (
        <div className={styles.disabled}>{props.children}</div>
      )}
    </>
  )
}

AppLink.defaultProps = defaultProps

export { AppLink }
