import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addSuggestedQuestionButtonLabel: 'Add Suggested Question',
    addSuggestedTodoButtonLabel: 'Add Suggested To-do',
    audienceLabel: 'Audience',
    audioButtonDisabled:
      'You have to make changes to the SSML before generating the audio',
    audioDeleteModalTitle: 'Are you sure you want to delete the audio file?',
    audioLabel: 'Audio File',
    cancelLabel: 'Cancel',
    contentLabel: 'Content',
    deleteAudioLabel: 'Delete Audio',
    deleteContentConfirmation:
      'Are you sure you want to reset content? This will delete all content.',
    deleteLabel: 'Delete',
    deletePageLabel: 'Delete Page',
    emptyPageValidation: 'This page cannot be empty',
    enableFeedbackLabel: 'Enable feedback',
    generateAudioLabel: 'Generate Audio',
    generateSsmlLabel: 'Generate SSML',
    internalIdLabel: 'Internal ID',
    internalNameLabel: 'Internal Name',
    languageLabel: 'Language',
    note: 'Note: The Paginate feature is not integrated with the backend yet, so the paginated data will be mocked.',
    optionalLabel: ' (optional)',
    pageDeleteConfirmation:
      'Are you sure you want to delete this page? This will delete the contents of the page.',
    pageLabel: 'Page',
    pageValidation: 'You cannot submit an empty page.',
    paginateButtonLabel: 'Paginate',
    questionLabel: 'Question',
    questionPlaceholder: 'Enter question',
    redirectLinkLabel: 'Redirect Link',
    regenerateAudioLabel: 'Regenerate Audio',
    removeButtonLabel: 'Remove',
    requiredValidation: 'This field is required',
    resetContentLabel: 'Reset Content',
    retailProductLabel: 'Retail Product',
    retailProductsLabel: 'Retail Products',
    revisionHistoryLabel: 'Revision History',
    selectPlaceholder: 'Select',
    ssmlLabel: 'SSML',
    ssmlValidation: 'Please correct SSML errors',
    suggestedQuestions: 'Suggested Questions',
    suggestedTodos: `Suggested To-do's`,
    themeLabel: 'Theme',
    titleLabel: 'Title',
    todoLabel: 'To-do',
    todoPlaceholder: 'Enter to-do',
  },
})
