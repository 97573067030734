import { Spin } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { debounce } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'

import { Select, SelectProps } from '../Select'
import styles from './searchSelect.module.scss'

export interface SearchSelectProps extends SelectProps {
  fetchOptions: (search: string) => Promise<DefaultOptionType[]>
  debounceTimeout?: number
  withDescription?: boolean
}

interface Option extends DefaultOptionType {
  description?: string
}

export const SearchSelect: React.FC<SearchSelectProps> = ({
  debounceTimeout = 500,
  fetchOptions,
  withDescription,
  ...props
}) => {
  const [options, setOptions] = useState<Option[]>(props.options ?? [])
  const [fetching, setFetching] = useState(false)
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) return

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching && <Spin size="small" />}
      {...props}
      options={!withDescription ? options : undefined}>
      {props.children}
      {withDescription &&
        options.map(option => (
          <Select.Option key={option.value} value={option.value}>
            <div className={styles.title}>{option.label}</div>
            <div className={styles.description}>{option.description}</div>
          </Select.Option>
        ))}
    </Select>
  )
}
