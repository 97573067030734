import React from 'react'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddTodoMutation,
  useDeleteTodoMutation,
  useLazyGetSurvivorsTodosQuery,
  useLazyGetTodoQuery,
  useUpdateTodoMutation,
} from 'src/store/APIs/todos'

import { AddTodo } from './AddTodo'
import { columns } from './columns'
import { strings } from './strings'
import { ViewTodo } from './ViewTodo'

interface TodosProps {}

export const Todos: React.FC<TodosProps> = () => {
  const { open, onCloseDrawer, showDrawer, selectedId, openView } = useDrawer()
  const [triggerAddTodoMutation, { isLoading: loadingAdd }] =
    useAddTodoMutation()
  const [triggerUpdateTodoMutation, { isLoading: loadingUpdate }] =
    useUpdateTodoMutation()
  const [triggerDeleteDocumentMutation] = useDeleteTodoMutation()
  const loadingRightButton = loadingAdd || loadingUpdate
  return (
    <>
      <DrawerWithState
        isLoadingRightButton={loadingRightButton}
        selectedId={selectedId}
        open={open}
        onClose={onCloseDrawer}
        resourceName={strings.resourceName}
        RenderAdd={AddTodo}
        RenderView={ViewTodo}
        useLazyGetResource={useLazyGetTodoQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddTodoMutation}
        triggerEditResourceMutation={triggerUpdateTodoMutation}
        triggerDeleteResourceMutation={triggerDeleteDocumentMutation}
        deleteModalTitle={strings.deleteModalTitle}
      />
      <Button type="primary" onClick={showDrawer}>
        {strings.addTodoButtonLabel}
      </Button>
      <PaginatedTable
        columns={columns(openView)}
        useLazyQuery={useLazyGetSurvivorsTodosQuery}
        hasBadge
      />
    </>
  )
}
