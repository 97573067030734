import { Form } from 'antd'
import { FormProviderProps } from 'antd/es/form/context'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { MultipleBlockFooter } from 'src/components/MultipleBlockFooter'
import { Spinner } from 'src/components/Spinner'
import { useEditedItems } from 'src/hooks/useEditedItems'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import {
  useGetSurvivorHealthcareTeamQuery,
  useLazyGetHealthcareTeamRolesQuery,
  useUpdateSurvivorsHealthcareTeamMutation,
} from 'src/store/APIs/healthcareTeam'
import { GetSurvivorHealthcareTeamData } from 'src/store/APIs/healthcareTeam/types'

import styles from '../survivorView.module.scss'
import { HealthcareMemberForm } from './HealthcareMemberForm'
import { strings } from './strings'

interface HealthcareTeamEditProps {
  toggleEditMode: () => void
}

const initialHealthcareMember: Partial<GetSurvivorHealthcareTeamData> = {
  additional_comments: '',
  affiliated_organization: '',
  first_name: '',
  last_name: '',
  last_visit_date: undefined,
  last_visit_summary: '',
  other_role: '',
  phone_number: '',
}

export const HealthcareTeamEdit: React.FC<HealthcareTeamEditProps> = ({
  toggleEditMode,
}) => {
  const { id } = useParams<{ id: string }>()
  const { data: survivorHealthcareTeam } = useGetSurvivorHealthcareTeamQuery({
    id,
  })

  const {
    optionsSelect: healthcareRoles,
    fetchedFilteredElems: fetchFilteredHealthcareRoles,
  } = useSearchOptionsSelect(
    useLazyGetHealthcareTeamRolesQuery,
    undefined,
    'name',
  )

  const {
    editedItems: editedHealthcareTeam,
    addNewEmptyItem: addNewMember,
    onPressRemoveItem: onPressRemoveMember,
    prepareFormValues,
    lastItemIndex,
  } = useEditedItems(survivorHealthcareTeam?.items, initialHealthcareMember)

  const [triggerUpdateSurvivorHealthcareTeam, { isSuccess, isLoading }] =
    useUpdateSurvivorsHealthcareTeamMutation()

  const onFormFinish: FormProviderProps['onFormFinish'] = (_, { forms }) => {
    const { updatedFormItems: updatedHealthcareTeam } = prepareFormValues(forms)
    triggerUpdateSurvivorHealthcareTeam({
      health_care_team: updatedHealthcareTeam,
      id,
    })
  }

  if (!editedHealthcareTeam && !healthcareRoles) return <Spinner />

  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.title}>{strings.title}</div>
        <Button type="default" onClick={addNewMember}>
          {strings.addNewMember}
        </Button>
      </div>
      <Form.Provider onFormFinish={onFormFinish}>
        {editedHealthcareTeam?.map(
          (doctor: Partial<GetSurvivorHealthcareTeamData>, index) => (
            <HealthcareMemberForm
              key={doctor.id}
              isLoading={isLoading}
              isSuccess={isSuccess}
              index={index}
              roles={healthcareRoles}
              fetchFilteredHealthcareRoles={fetchFilteredHealthcareRoles}
              doctor={doctor}
              lastItemIndex={lastItemIndex}
              onPressRemoveMember={onPressRemoveMember}
              toggleEditMode={toggleEditMode}
            />
          ),
        )}
        {editedHealthcareTeam?.length === 0 &&
          survivorHealthcareTeam?.items?.length !== 0 && (
            <Form>
              <MultipleBlockFooter
                isLoading={isLoading}
                isLast
                isSuccess={isSuccess}
                onCancel={toggleEditMode}
                onSuccess={toggleEditMode}
              />
            </Form>
          )}
      </Form.Provider>
    </>
  )
}
