import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addHospitalButton: 'Add Hospital',
    createdAtColumn: 'Created At',
    deleteHospitalModalTitle: 'Are you sure you want to delete this hospital?',
    hospitalTitle: 'Hospital',
    // eslint-disable-next-line sort-keys
    hospitalsTitle: 'Hospitals',
    nameColumn: 'Name',
    updatedAtColumn: 'Updated At',
  },
})
