import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    enterEmailValidation: 'Please enter your email',
    enterPasswordValidation: 'Please enter a password',
    forgotPasswordLabel: 'Forgot password',
    loginLabel: 'Log in',
    passwordPlaceholder: 'Password',
    rememberMeLabel: 'Remember me',
    usernamePlaceholder: 'Username',
    validEmailValidation: 'Please enter a valid email address',
  },
})
