import React, { useEffect, useState } from 'react'

import { DrawerWithState } from '../DrawerWithState'
import { DrawerWithStateProps } from '../DrawerWithState/types'

interface DrawerWithStateLearnProps extends DrawerWithStateProps {
  addData?: any
}

export const DrawerWithStateLearn: React.FC<
  DrawerWithStateLearnProps
> = props => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>()

  useEffect(() => {
    if (props.open) {
      setDrawerVisible(true)
    } else {
      setTimeout(() => setDrawerVisible(false), 300)
    }
  }, [props.open])
  return drawerVisible ? (
    <DrawerWithState {...props} isLearn addData={props.addData} />
  ) : null
}
