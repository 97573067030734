import { RuleObject } from 'antd/es/form'
import { Dayjs } from 'dayjs'

import { generalStrings } from './generalStrings'
import { disableDateBeforeTodayUTC } from './helpers'

export const validateTimeWithTimezone =
  (timezone?: string) => (_: RuleObject, value: Dayjs) => {
    if (
      timezone &&
      value &&
      disableDateBeforeTodayUTC(value.utc(true), timezone)
    ) {
      return Promise.reject(new Error(generalStrings.deploymentDateInvalid))
    }
    return Promise.resolve()
  }
