import {
  CustomDate,
  dateDeserializer,
  optionalDateDeserializer,
} from 'src/common/types'

import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  Model,
  PaginatedItems,
  PaginationResponse,
  Permissions,
} from '../types'

export enum Endpoints {
  GetQuickJot = '/cms/v1/quick_jots/%s.json',
  GetSurvivorsQuickJots = '/cms/v1/mobile_survivors/%s/quick_jots.json?page=%s',
}

export interface GetSurvivorQuickJotsResponse extends Model {
  quick_jots: QuickJotResponse[]
  pagination: PaginationResponse
}

export interface QuickJotResponse extends Entity {
  id: string
  jot: string | null
  created_at: string
  deleted_at: string
}

export interface QuickJot {
  id: string
  jot?: string
  created_at: CustomDate
  deleted_at?: CustomDate
  permissions?: Permissions
}

export const getSurvivorQuickJotsDeserializer = (
  data: GetSurvivorQuickJotsResponse,
): PaginatedItems<QuickJot> => {
  return {
    items: data.quick_jots.map(
      (jot: QuickJotResponse): QuickJot => ({
        created_at: dateDeserializer(jot.created_at),
        deleted_at: optionalDateDeserializer(jot.deleted_at),
        id: jot.id,
        jot: jot.jot ? jot.jot : undefined,
        permissions: jot.permissions,
      }),
    ),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}

export interface Symptom {
  id: string
  rate: number | null
  symptom: {
    id: string
    name: string
    type_of: string // INFO: could be "fixed" if it's a default symptom or "custom"
  }
}

export interface QuickJotDetailsResponse {
  id: string
  deleted_by: BaseUserResponse | null
  jot: string
  quick_jot_symptoms: Symptom[]
  created_by: BaseUserResponse
}

export interface QuickJotDetails {
  id: string
  deleted_by: BaseUser | undefined
  jot: string
  quick_jot_symptoms: Symptom[]
  created_by: BaseUser
}

export const quickJotDeserializer = (
  data: QuickJotDetailsResponse,
): QuickJotDetails => ({
  created_by: baseUserDeserializer(data.created_by),
  deleted_by: data.deleted_by
    ? baseUserDeserializer(data.deleted_by)
    : undefined,
  id: data.id,
  jot: data.jot,
  quick_jot_symptoms: data.quick_jot_symptoms,
})
