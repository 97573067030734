import { ColumnsType } from 'antd/es/table'
import { formatDateToRender } from 'src/common/types'
import { generateColumnTitle } from 'src/helpers/utils'
import { GetSurvivorHealthcareTeamData } from 'src/store/APIs/healthcareTeam/types'

import { strings } from './strings'
import { HealthcareTeamDataIndex } from './types'

export const columns: ColumnsType<GetSurvivorHealthcareTeamData> = [
  {
    ...generateColumnTitle(HealthcareTeamDataIndex.role_name, strings.role),
  },
  {
    ...generateColumnTitle(
      HealthcareTeamDataIndex.other_role,
      strings.otherRole,
    ),
  },
  {
    ...generateColumnTitle(
      HealthcareTeamDataIndex.first_name,
      strings.firstName,
    ),
  },
  {
    ...generateColumnTitle(HealthcareTeamDataIndex.last_name, strings.lastName),
  },
  {
    ...generateColumnTitle(
      HealthcareTeamDataIndex.affiliated_organization,
      strings.affiliatedOrganization,
    ),
  },
  {
    ...generateColumnTitle(
      HealthcareTeamDataIndex.phone_number,
      strings.phoneNumber,
    ),
  },
  {
    ...generateColumnTitle(
      HealthcareTeamDataIndex.last_visit_date,
      strings.lastVisitDate,
    ),
    render: formatDateToRender,
  },
  {
    ...generateColumnTitle(
      HealthcareTeamDataIndex.last_visit_summary,
      strings.lastVisitSummary,
    ),
  },
  {
    ...generateColumnTitle(
      HealthcareTeamDataIndex.additional_comments,
      strings.additionalComments,
    ),
  },
]
