import React from 'react'

import styles from './tableTitle.module.scss'

interface TableTitleProps {
  title: string
  className?: string
}

export const TableTitle: React.FC<TableTitleProps> = ({ title, className }) => {
  const style = `${styles.title} ${className ?? ''}`
  return <div className={style}>{title}</div>
}
