import { ButtonProps, Tooltip } from 'antd'
import React from 'react'
import { Button } from 'src/components/Button'

import styles from './accountManagement.module.scss'

interface ActionsButtonsProps extends ButtonProps {
  actions: {
    text: string
    action: () => void
    loading?: boolean
    tooltip?: string
  }[]
  disabled?: boolean
  className?: string
}

export const ActionsButtons: React.FC<ActionsButtonsProps> = ({
  actions,
  disabled = false,
  className,
}) => {
  const buttonStyle = `${styles.sendButton} ${className}`
  return (
    <>
      {actions.map(({ text, action, loading, tooltip }) =>
        tooltip ? (
          <Tooltip title={tooltip} key={text}>
            <Button
              disabled={disabled}
              key={text}
              htmlType="button"
              type="primary"
              className={buttonStyle}
              onClick={action}
              loading={loading}>
              {text}
            </Button>
          </Tooltip>
        ) : (
          <Button
            disabled={disabled}
            key={text}
            htmlType="button"
            type="primary"
            className={buttonStyle}
            onClick={action}
            loading={loading}>
            {text}
          </Button>
        ),
      )}
    </>
  )
}
