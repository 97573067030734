import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { formatDateToRender } from 'src/common/types'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { QuickJot } from 'src/store/APIs/quickJots/types'

import { strings } from './strings'
import { QuickJotsDataIndex } from './types'

export const columns: (
  onClickRow?: (id: string) => () => void,
) => ColumnsType<QuickJot> = onClickRow => [
  {
    ...generateColumnTitle(QuickJotsDataIndex.jot, strings.jotLabel),
    render: (jot, record) => {
      const label = jot ?? strings.resource
      return record.permissions?.can_read ? (
        <Button type="link" onClick={onClickRow && onClickRow(record.id)}>
          {label}
        </Button>
      ) : (
        label
      )
    },
  },
  {
    ...generateColumnTitle(
      QuickJotsDataIndex.created_at,
      strings.createdAtLabel,
    ),
    render: formatDateToRender,
  },
  {
    ...generateColumnTitle(
      QuickJotsDataIndex.deleted_at,
      strings.deletedAtLabel,
    ),
    render: formatDateToRender,
  },
]
