import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    cancelDelete: 'Cancel',
    daysFromReferenceLabel: 'Days from Reference Date',
    daysPlaceholder: 'Days',
    deleteButton: 'Delete',
    deleteLabel: 'Delete',
    deleteModalTitle:
      'Are you sure you want to delete this default navigator task?',
    orderLabel: 'Order',
    referenceDateTypeLabel: 'Reference Date Type',
    titleLabel: 'Title',
  },
})
