import {
  Entity,
  Model,
  PaginatedItems,
  PaginatedModel,
  PaginationResponse,
} from '../types'

export enum Endpoints {
  GetHealthConditions = '/cms/v1/global_settings/health_conditions.json',
  GetSurvivorsHealthConditions = '/cms/v1/mobile_survivors/%s/profile/health_conditions.json',
  UpdateSurvivorsHealthConditions = '/cms/v1/mobile_survivors/%s/profile/health_conditions/batch_put.json',
  AddHealthCondition = '/cms/v1/global_settings/health_conditions.json',
  GetHealthCondition = '/cms/v1/global_settings/health_conditions/%s.json',
  DeleteHealthCondition = '/cms/v1/global_settings/health_conditions/%s.json',
  UpdateHealthCondition = '/cms/v1/global_settings/health_conditions/%s.json',
}
export interface GetHealthConditionsResponse extends PaginatedModel {
  health_conditions: HealthConditionResponse[]
}

export interface HealthConditionResponse extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export const healthConditionDeserializer = (
  data: HealthConditionResponse,
): HealthConditionData => {
  return {
    ...data,
  }
}

export const healthConditionsDeserializer = (
  data: GetHealthConditionsResponse,
): PaginatedItems<HealthConditionData> => ({
  items: data.health_conditions.map(healthConditionDeserializer),
  pagination: data.pagination,
})

export interface GetSurvivorHealthConditionsResponse extends Model {
  health_conditions: SurvivorHealthConditionsResponse[]
  pagination: PaginationResponse
}

export interface SurvivorHealthConditionsResponse {
  id: string
  health_condition: {
    name: string
    id: string
  }
  created_at: string
  description: string
  health_condition_id: string
  mobile_survivor_id: string
  updated_at: string
}

export interface SurvivorHealthConditionData {
  id: string
  health_condition: string
  description: string
  health_condition_id: string
}

export const survivorHealthConditionDeserializer = (
  data: SurvivorHealthConditionsResponse,
): SurvivorHealthConditionData => {
  return {
    description: data.description,
    health_condition: data.health_condition.name,
    health_condition_id: data.health_condition.id,
    id: data.id,
  }
}

export const survivorHealthConditionsDeserializer = (
  data: GetSurvivorHealthConditionsResponse,
): PaginatedItems<SurvivorHealthConditionData> => ({
  items: data.health_conditions.map(survivorHealthConditionDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

export interface HealthConditionData extends Entity {
  id: string
  name: string
  updated_at: string
  created_at: string
}
