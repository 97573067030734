/* eslint-disable no-nested-ternary */
import { InfoCircleFilled } from '@ant-design/icons'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { FormInstance, message, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useState } from 'react'
import {
  formatDateToRender,
  formatDateWithTimeToRender,
} from 'src/common/types'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { AppLink, RouteName } from 'src/routes'
import { useEditAssessmentMutation } from 'src/store/APIs/assessments'
import { AssessmentsData, PerformActionResponse } from 'src/store/APIs/types'

import { Button } from '../Button'
import { DeleteButton } from '../DeleteButton'
import styles from './assessments.module.scss'
import { strings } from './strings'
import {
  AssessmentsDataIndex,
  assessmentsFilters,
  AssessmentStatusOptions,
  assessmentStatusProps,
} from './types'

export const useAssessmentsColumns = (
  isCarePartner: boolean,
  id: string,
  triggerDeleteAssessment: MutationTrigger<
    MutationDefinition<
      {
        idUser: string
        idElem: string
      },
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      any,
      PerformActionResponse,
      any
    >
  >,
  form: FormInstance<any>,
  timezone?: string,
) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const [editingRowId, setEditingRowId] = useState<string>('')
  const isEditing = (record: AssessmentsData) => editingRowId === record.id

  const [triggerEditAssessment] = useEditAssessmentMutation()

  const onClickEdit = (record: AssessmentsData) => {
    form.setFieldsValue({ ...record })
    setEditingRowId(record.id)
  }

  const onClickSave = (record: AssessmentsData) => {
    form
      .validateFields()
      .then(() => {
        setEditingRowId('')
        triggerEditAssessment({
          body: {
            deployment_date: form
              .getFieldValue(AssessmentsDataIndex.deployment_date)
              .format(),
            due_time: form.getFieldValue(AssessmentsDataIndex.due_time),
          },
          id: record.id,
        }).unwrap()
      })
      .catch(() => message.error(strings.errorMessage))
  }

  const onClickCancel = () => {
    setEditingRowId('')
  }

  const columns: ColumnsType<AssessmentsData> = [
    {
      ...generateColumnTitle(
        AssessmentsDataIndex.assessment,
        strings.assignedAssessmentsColumnLabel,
      ),
      ...generateColumnSearchProps(AssessmentsDataIndex.assessment),
      filteredValue: filteredInfo.filters?.assessment || null,
      render(_, record) {
        return (
          <AppLink
            routeName={
              isCarePartner
                ? RouteName.CarePartnerAssessmentDetails
                : RouteName.SurvivorAssessmentDetails
            }
            pathParams={{
              id,
              idAssessment: record.id,
            }}>
            {record.assessment}
          </AppLink>
        )
      },
    },
    {
      ...generateColumnTitle(
        AssessmentsDataIndex.status,
        strings.statusColumnLabel,
      ),
      filteredValue: filteredInfo.filters?.status || null,
      filters: assessmentsFilters,
      render(status) {
        return (
          <Tag color={assessmentStatusProps(status).color as string}>
            {assessmentStatusProps(status).title}
          </Tag>
        )
      },
    },
    {
      ...generateColumnTitle(
        AssessmentsDataIndex.deployment_date,
        strings.deploymentDateColumnLabel,
      ),
      onCell: (record: AssessmentsData) => {
        return {
          dataIndex: AssessmentsDataIndex.deployment_date,
          editable: isEditing(record),
          record,
          timezone,
          title: strings.deploymentDateColumnLabel,
        }
      },
      render: formatDateWithTimeToRender,
      sorter: true,
      title: (
        <div className={styles.deploymentDateTitle}>
          <span>{strings.deploymentDateColumnLabel}</span>
          <Tooltip
            title={
              isCarePartner
                ? strings.cpTooltipMessage
                : strings.survivorTooltipMessage
            }>
            <InfoCircleFilled className={styles.icon} />
          </Tooltip>
        </div>
      ),
    },
    {
      ...generateColumnTitle(
        AssessmentsDataIndex.due_time,
        strings.dueDateColumnLabel,
      ),
      onCell: (record: AssessmentsData) => {
        return {
          dataIndex: AssessmentsDataIndex.due_time,
          editable: isEditing(record),
          record,
          title: strings.dueDateColumnLabel,
        }
      },
      render: formatDateToRender,
      sorter: true,
    },
    {
      render(_, record) {
        const editable = isEditing(record)
        return (
          <>
            <DeleteButton
              id={record.id}
              ownerId={id}
              modalTitle={strings.deleteModalTitle}
              onClickYes={triggerDeleteAssessment}
              className={styles.leftButton}>
              {strings.deleteButton}
            </DeleteButton>
            {record.status === AssessmentStatusOptions.Scheduled ? (
              editable ? (
                <>
                  <Button
                    type="primary"
                    className={styles.leftButton}
                    onClick={() => onClickSave(record)}>
                    {strings.saveLabel}
                  </Button>
                  <Button onClick={onClickCancel}>{strings.cancelLabel}</Button>
                </>
              ) : (
                <Button
                  onClick={() => onClickEdit(record)}
                  disabled={!!editingRowId && editingRowId !== record.id}>
                  {strings.editLabel}
                </Button>
              )
            ) : undefined}
          </>
        )
      },
      title: strings.actionsColumnLabel,
    },
  ]

  return {
    filteredInfo,
    ...restFilteredInfo,
    columns,
  }
}
