import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddHealthEventMutation,
  useDeleteHealthEventMutation,
  useEditHealthEventMutation,
  useLazyGetHealthEventQuery,
  useLazyGetHealthEventsQuery,
} from 'src/store/APIs/healthEvents'

import styles from '../survivorProfile.module.scss'
import { columns } from './columns'
import { strings } from './strings'

export const HealthEvents: React.FC = () => {
  const { open, onCloseDrawer, selectedId, showDrawer, openView } = useDrawer()

  const [triggerAddHealthEventMutation, { isLoading: loadingAdd }] =
    useAddHealthEventMutation()

  const [triggerDeleteHealthEventMutation, { isLoading: loadingDelete }] =
    useDeleteHealthEventMutation()

  const [triggerEditHealthEventMutation, { isLoading: loadingEdit }] =
    useEditHealthEventMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.healthEventsTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addHealthEventButton}
        </Button>
      </div>
      <DrawerWithState
        selectedId={selectedId}
        open={open}
        resourceName={strings.resourceName}
        RenderView={ViewBasicItem}
        RenderAdd={AddBasicItem}
        RenderEdit={AddBasicItem}
        useLazyGetResource={useLazyGetHealthEventQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddHealthEventMutation}
        triggerDeleteResourceMutation={triggerDeleteHealthEventMutation}
        triggerEditResourceMutation={triggerEditHealthEventMutation}
        isLoadingRightButton={isLoadingRightButton}
        isLoadingLeftButton={loadingDelete}
        deleteModalTitle={strings.deleteModalTitle}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetHealthEventsQuery}
      />
    </div>
  )
}
