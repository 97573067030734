import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addHealthcareTeamRoleButton: 'Add Role',
    createdAtColumn: 'Created At',
    deleteModalTitle:
      'Are you sure you want to delete this healthcare team role?',
    healthcareTeamRoleTitle: 'Healthcare Team Role',
    nameColumn: 'Name',
    resourceName: 'Healthcare Team Role',
    updatedAtColumn: 'Updated At',
  },
})
