import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { Table, TableProps } from 'src/components/Table'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { useLazyGetSurvivorsAlertsByTabQuery } from 'src/store/APIs/survivors'
import { Filters, PaginatedItems, sortData } from 'src/store/APIs/types'

import { Button } from '../Button'
import styles from './paginatedTable.module.scss'

interface PaginatedTableProps extends TableProps {
  useLazyQuery: UseLazyQuery<
    QueryDefinition<any, any, any, PaginatedItems<any>, any>
  >
  hasBadge?: boolean
  filteredInfo?: ReturnType<typeof useFilteredInfo>['filteredInfo']
  onChangeFiltered?: ReturnType<typeof useFilteredInfo>['onChangeFiltered']
  resetFilters?: ReturnType<typeof useFilteredInfo>['resetFilters']
  otherParams?: Object
  otherFilters?: Filters
  preferCacheValue?: boolean
}

export const PaginatedTable: React.FC<PaginatedTableProps> = ({
  useLazyQuery,
  columns,
  hasBadge = false,
  filteredInfo: filteredInfoProp,
  onChangeFiltered: onChangeFilteredProp,
  resetFilters: resetFiltersProp,
  otherParams = {},
  otherFilters,
  preferCacheValue = true,
  ...props
}) => {
  const { id } = useParams<{ id?: string }>()
  const [triggerQuery, { data, isFetching }] = useLazyQuery()
  const [triggerGetAlertsByTabQuery] = useLazyGetSurvivorsAlertsByTabQuery()

  const filtersHook = useFilteredInfo()
  const filteredInfo = filteredInfoProp ?? filtersHook.filteredInfo

  useEffect(() => {
    triggerQuery(
      {
        filters: { ...filteredInfo.filters, ...otherFilters },
        id,
        sortItem: sortData(filteredInfo.sorter),
        ...otherParams,
        page: filteredInfo?.pagination?.current ?? 1,
      },
      preferCacheValue,
    )
  }, [filteredInfo, otherFilters])

  useEffect(() => {
    if (data && hasBadge && id) {
      triggerGetAlertsByTabQuery(id)
    }
  }, [data])

  return (
    <>
      {resetFiltersProp && (
        <Button onClick={resetFiltersProp}>
          {generalStrings.clearFiltersButtonLabel}
        </Button>
      )}
      <Table
        className={styles.table}
        dataSource={data?.items}
        columns={columns}
        bordered
        loading={isFetching}
        rowKey={record => record.id}
        onChange={onChangeFilteredProp ?? filtersHook.onChangeFiltered}
        pagination={{
          defaultPageSize: 20,
          pageSize: data?.pagination.items,
          showSizeChanger: false,
          total: data?.pagination.count,
        }}
        {...props}
      />
    </>
  )
}
