import { Checkbox, Form, Typography } from 'antd'
import { capitalize } from 'lodash'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { NumberInput } from 'src/components/NumberInput'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { Select } from 'src/components/Select'
import { TextInput } from 'src/components/TextInput'
import { TabNames } from 'src/pages/SurvivorView/types'
import { useUpdateSurvivorPlanOfCareMutation } from 'src/store/APIs/survivors'
import { SurvivorPlanOfCare } from 'src/store/APIs/survivors/types'

import styles from './planOfCare.module.scss'
import { selectOptions } from './selectOptions'
import { strings } from './strings'

interface PlanOfCareEditProps {
  toggleEditMode: () => void
  planOfCare?: SurvivorPlanOfCare
}

export const PlanOfCareEdit: React.FC<PlanOfCareEditProps> = ({
  toggleEditMode,
  planOfCare,
}) => {
  const { id } = useParams<{ id: string }>()
  const [
    triggerUpdateSurvivorPlanOfCare,
    { isSuccess, isLoading: isLoadingUpdatePlanOfCare },
  ] = useUpdateSurvivorPlanOfCareMutation()
  const { mrsOptions, mdpOptions } = selectOptions()
  const [form] = Form.useForm()

  const mdp1Value = Form.useWatch('mdp1', form)
  const mdp2Value = Form.useWatch('mdp2', form)
  const mdp3Value = Form.useWatch('mdp3', form)
  const mdp4Value = Form.useWatch('mdp4', form)
  const mdp5Value = Form.useWatch('mdp5', form)
  const totalScore = mdp1Value + mdp2Value + mdp3Value + mdp4Value + mdp5Value
  const parsedTotalScore: number = !Number.isNaN(totalScore)
    ? totalScore
    : undefined

  const handleSubmit = async (values: Partial<SurvivorPlanOfCare>) => {
    const body = {
      plan_of_care: {
        ...values,
        id: planOfCare?.id,
        mdp6: parsedTotalScore,
        mobile_survivor_id: id,
      },
    }

    triggerUpdateSurvivorPlanOfCare({ body, id })
  }

  useEffect(() => {
    if (isSuccess) {
      toggleEditMode()
    }
  }, [isSuccess])

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      name={TabNames.impairments}
      initialValues={planOfCare}>
      <div className={styles.sectionTitle}>{strings.mrsTitle}</div>
      <Form.Item name="mrs_baseline">
        <Select
          label={strings.mrsBaseline}
          options={mrsOptions}
          disabled
          tooltipTitle={strings.disabledInputTooltip}
        />
      </Form.Item>
      <Form.Item name="mrs_7_day">
        <Select label={strings.mrs7Day} options={mrsOptions} />
      </Form.Item>
      <Form.Item name="mrs_30_day">
        <Select label={strings.mrs30Day} options={mrsOptions} />
      </Form.Item>
      <Form.Item name="mrs_90_day">
        <Select label={strings.mrs90Day} options={mrsOptions} />
      </Form.Item>
      <Form.Item name="mrs_graduation">
        <Select label={strings.mrsGraduation} options={mrsOptions} />
      </Form.Item>
      <Form.Item name="nihss">
        <NumberInput min={0} max={25} label={strings.nihss} />
      </Form.Item>
      <div>{strings.onboarding}</div>
      <Form.Item name="onboarding" valuePropName="value">
        <RichTextEditor />
      </Form.Item>
      <div>{strings.planOfCare}</div>
      <Form.Item name="plan_of_care" valuePropName="value">
        <RichTextEditor />
      </Form.Item>
      <div className={styles.sectionTitle}>{strings.socialNeedsTitle}</div>
      <div className={styles.socialNeedsContainer}>
        {planOfCare &&
          Object.entries(planOfCare)
            .filter(entry => {
              return entry[0].includes('_enabled')
            })
            .map(item => {
              const socialNeed = item[0].replace('_enabled', '')
              const socialNeedLabel = capitalize(
                socialNeed.replaceAll('_', ' '),
              )
              return (
                <div className={styles.socialNeed} key={item[0]}>
                  <Form.Item
                    key={item[0]}
                    name={
                      item[0] === 'mental_health' ? 'mental_health' : item[0]
                    }
                    valuePropName="checked"
                    className={styles.socialNeedCheckbox}>
                    <Checkbox>{socialNeedLabel}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues[item[0]] !== currentValues[item[0]]
                    }>
                    {({ getFieldValue }) => (
                      <Form.Item
                        name={socialNeed}
                        className={styles.socialNeedInput}>
                        <TextInput disabled={!getFieldValue(item[0])} />
                      </Form.Item>
                    )}
                  </Form.Item>
                </div>
              )
            })}
      </div>
      <div className={styles.sectionTitle}>{strings.mdpEditTitle}</div>
      <div className={styles.sectionSubtitle}>{strings.mdpEditSubtitle}</div>
      <Form.Item name="mdp1">
        <Select label={strings.mdp1} options={mdpOptions} />
      </Form.Item>
      <Form.Item name="mdp2">
        <Select label={strings.mdp2} options={mdpOptions} />
      </Form.Item>
      <Form.Item name="mdp3">
        <Select label={strings.mdp3} options={mdpOptions} />
      </Form.Item>
      <Form.Item name="mdp4">
        <Select label={strings.mdp4} options={mdpOptions} />
      </Form.Item>
      <Form.Item name="mdp5">
        <Select label={strings.mdp5} options={mdpOptions} />
      </Form.Item>
      <div>{strings.mdp6}</div>
      <Form.Item name="mdp6">
        <Typography>{parsedTotalScore}</Typography>
      </Form.Item>
      <Form.Item>
        <div className={styles.submitButtonContainer}>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoadingUpdatePlanOfCare}>
            {strings.updateButtonLabel}
          </Button>
          <div className={styles.buttonSeparator} />
          <Button htmlType="button" onClick={toggleEditMode}>
            {strings.cancelButtonLabel}
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}
