import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { ImpairmentData } from 'src/store/APIs/impairments/types'

import { ImpairmentsDataIndex } from '../types'
import { strings } from './strings'

export const columns: (
  onClickRow?: (id: string) => () => void,
) => ColumnsType<ImpairmentData> = onClickRow => [
  {
    ...generateColumnTitle(ImpairmentsDataIndex.name, strings.nameColumn),
    render: (title, record) => {
      return record.permissions?.can_read ? (
        <Button type="link" onClick={onClickRow && onClickRow(record.id)}>
          {title}
        </Button>
      ) : (
        <span>{title}</span>
      )
    },
  },
  {
    ...generateColumnTitle(
      ImpairmentsDataIndex.clinical_name,
      strings.clinicalName,
    ),
  },
  {
    ...generateColumnTitle(
      ImpairmentsDataIndex.updated_at,
      strings.updatedAtColumn,
    ),
    render: deserializeShortFormattedDate,
  },
  {
    ...generateColumnTitle(
      ImpairmentsDataIndex.created_at,
      strings.createdAtColumn,
    ),
    render: deserializeShortFormattedDate,
  },
]
