import { InboxOutlined } from '@ant-design/icons'
import { Form, Upload, UploadProps } from 'antd'
import React, { ReactElement, useEffect } from 'react'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { TextInput } from 'src/components/TextInput'
import styles from 'src/pages/SurvivorView/Documents/documents.module.scss'
import { FileList } from 'src/store/APIs/communicationLogs/types'

import { strings } from './strings'

export const FILE_FORMNAME_NAME = 'file-name-'
export const FILE_FORMNAME_DESCRIPTION = 'file-description-'
export const FILE_FORMNAME_ID = 'file-id-'

interface UploadDocumentsProps {
  maxCount?: number
  data?: FileList[]
  selectedId?: string
  setCanSubmitForm?: (value: boolean) => void
}

export const UploadDocuments: React.FC<UploadDocumentsProps> = ({
  selectedId,
  data = [],
  setCanSubmitForm,
  maxCount = 1,
}) => {
  const form = Form.useFormInstance()
  const file = Form.useWatch('file', form)

  const onRemove = () => {
    setCanSubmitForm?.(false)
  }

  useEffect(() => {
    if (selectedId) {
      setCanSubmitForm?.(true)
    }
    return () => setCanSubmitForm?.(false)
  }, [selectedId])

  useEffect(() => {
    if (file && file.fileList) {
      setCanSubmitForm?.(true)
    }
  }, [file])

  const itemRender: UploadProps['itemRender'] = (
    originNode: ReactElement,
    currentFile,
  ) => {
    return (
      <div className={styles.fileCard}>
        {originNode}
        <Form.Item
          hidden
          name={`${FILE_FORMNAME_ID}${currentFile.uid}`}
          initialValue={(currentFile as FileList).document_id}
        />
        <Form.Item
          name={`${FILE_FORMNAME_NAME}${currentFile.uid}`}
          className={styles.inputContainer}
          initialValue={(currentFile as FileList).document_name}
          rules={[{ message: strings.titleValidation, required: true }]}>
          <TextInput
            // INFO: If in the future the backend allows editing this field remove this
            disabled={Object.hasOwn(currentFile, 'document_name')}
            label={strings.titleLabel}
            required
          />
        </Form.Item>
        <Form.Item
          name={`${FILE_FORMNAME_DESCRIPTION}${currentFile.uid}`}
          initialValue={(currentFile as FileList).document_description}
          className={styles.inputContainer}>
          <MultilineTextInput
            // INFO: If in the future the backend allows editing this field remove this
            disabled={Object.hasOwn(currentFile, 'document_description')}
            label={strings.descriptionLabel}
          />
        </Form.Item>
      </div>
    )
  }

  const beforeUpload = () => {
    return false
  }

  return (
    <Form.Item
      name="file"
      className={styles.inputContainer}
      initialValue={{ fileList: data }}>
      <Upload.Dragger
        onRemove={onRemove}
        defaultFileList={data}
        maxCount={maxCount}
        itemRender={itemRender}
        beforeUpload={beforeUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{strings.uploadTitle}</p>
      </Upload.Dragger>
    </Form.Item>
  )
}
