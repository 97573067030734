import {
  DefaultNavigatorTask,
  DefaultNavigatorTaskModel,
} from 'src/pages/DefaultNavigatorTasks/DefaultNavigatorTask/types'
import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  defaultNavigatorTaskDeserializer,
  Endpoints,
  getNavigatorTasksDeserializer,
  NavigatorTask,
  navigatorTaskDeserializer,
  NavigatorTaskPayload,
} from './types'

export const getNavigatorTask = (builder: Builder) =>
  builder.query<NavigatorTask, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.NavigatorTasks }],
    query: ({ id }) => format(Endpoints.GetNavigatorTask, id),
    transformResponse: ({ navigator_task }) =>
      navigatorTaskDeserializer(navigator_task),
  })

export const addNavigatorTask = (builder: Builder) =>
  builder.mutation<NavigatorTask, { body: NavigatorTaskPayload }>({
    invalidatesTags: provideListToTag(Tags.NavigatorTasks),
    query: ({ body }) => ({
      body: { navigator_task: body },
      method: HttpMethod.Post,
      url: Endpoints.AddNavigatorTask,
    }),
    transformResponse: ({ navigator_task }) =>
      navigatorTaskDeserializer(navigator_task),
  })

export const getNavigatorTasks = (builder: Builder) =>
  builder.query<PaginatedItems<NavigatorTask>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.NavigatorTasks),
    query: endpointWithFilters(Endpoints.GetNavigatorTasks),
    transformResponse: getNavigatorTasksDeserializer,
  })

export const updateNavigatorTask = (builder: Builder) =>
  builder.mutation<
    NavigatorTask,
    { id: string; body: Partial<NavigatorTaskPayload> }
  >({
    invalidatesTags: () => [{ type: Tags.NavigatorTasks }],
    query: ({ body, id }) => ({
      body: { navigator_task: body },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateNavigatorTask, id),
    }),
    transformResponse: ({ navigator_task }) =>
      navigatorTaskDeserializer(navigator_task),
  })

export const deleteNavigatorTask = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.NavigatorTasks),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteNavigatorTask, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const updateGoalDate = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.NavigatorTasks),
    query: ({ id }) => ({
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateGoalDate, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const getDefaultNavigatorTasks = (builder: Builder) =>
  builder.query<DefaultNavigatorTask[], void>({
    providesTags: provideListToTag(Tags.DefaultNavigatorTasks),
    query: () => Endpoints.GetDefaultNavigatorTasks,
    transformResponse: ({ default_navigator_tasks }) =>
      defaultNavigatorTaskDeserializer(default_navigator_tasks),
  })

export const addDefaultNavigatorTask = (builder: Builder) =>
  builder.mutation<PerformActionResponse, DefaultNavigatorTaskModel>({
    invalidatesTags: provideListToTag(Tags.DefaultNavigatorTasks),
    query: body => ({
      body: {
        default_navigator_task: {
          ...body,
          goal_date: parseInt(body.goal_date, 10),
        },
      },
      method: HttpMethod.Post,
      url: Endpoints.AddDefaultNavigatorTask,
    }),
    transformResponse: performActionTransformResponse,
  })

export const reorderDefaultNavigatorTasks = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string[]>({
    invalidatesTags: provideListToTag(Tags.DefaultNavigatorTasks),
    query: reorderedTasks => ({
      body: { default_navigator_tasks: reorderedTasks },
      method: HttpMethod.Put,
      url: Endpoints.ReorderDefaultNavigatorTasks,
    }),
    transformResponse: performActionTransformResponse,
  })

export const editDefaultNavigatorTask = (builder: Builder) =>
  builder.mutation<PerformActionResponse, DefaultNavigatorTaskModel>({
    invalidatesTags: provideListToTag(Tags.DefaultNavigatorTasks),
    query: body => ({
      body: {
        default_navigator_task: {
          ...body,
          goal_date: parseInt(body.goal_date, 10),
        },
      },
      method: HttpMethod.Put,
      url: format(Endpoints.EditDefaultNavigatorTask, body.id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteDefaultNavigatorTask = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.DefaultNavigatorTasks),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteDefaultNavigatorTask, id),
    }),
    transformResponse: performActionTransformResponse,
  })
