import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addEventButton: 'Add Event',
    addHospitalTreatmentButton: 'Add Hospital Treatment',
    addStrokeCauseButton: 'Add Stroke Cause',
    createdAtColumn: 'Created At',
    deleteHospitalTreatmentModalTitle:
      'Are you sure you want to delete the hospital treatment?',
    deleteOtherEventModalTitle: 'Are you sure you want to delete this event?',
    deleteStrokeCauseModalTitle:
      'Are you sure you want to delete the stroke cause?',
    hospitalTreatmentResource: 'Hospital Treatment',
    hospitalTreatmentsTitle: 'Hospital Treatments',
    nameColumn: 'Name',
    otherEventResource: 'Event',
    otherEventsTitle: 'Other Events',
    strokeCauseTitle: 'Stroke Cause',
    updatedAtColumn: 'Updated At',
  },
})
