import { baseApi } from 'src/store/APIs'

import {
  changeUserStatus,
  deleteCarePartner,
  deleteCarePartnersConversationMessage,
  editCarePartnersConversationMessage,
  getCarePartner,
  getCarePartnerGroups,
  getCarePartners,
  getConversation,
  getUnits,
  newCarePartner,
  notifyUncompletedLesson,
  resetPassword,
  sendAppInstallEmail,
  sendAppInstallSMS,
  sendConversationMessage,
  sendGraduationEmail,
  sendSignUpEmail,
  sendSignUpSMS,
  sendWelcomeEmail,
  updateCarePartner,
  updateCarePartnerLearnTrack,
} from './actions'

export const carePartnersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    changeCarePartnerStatus: changeUserStatus(builder),
    deleteCarePartner: deleteCarePartner(builder),
    deleteCarePartnersConversationMessage:
      deleteCarePartnersConversationMessage(builder),
    editCarePartnersConversationMessage:
      editCarePartnersConversationMessage(builder),
    getCarePartner: getCarePartner(builder),
    getCarePartnerGroups: getCarePartnerGroups(builder),
    getCarePartners: getCarePartners(builder),
    getCarePartnersConversation: getConversation(builder),
    getCarePartnersUnits: getUnits(builder),
    newCarePartner: newCarePartner(builder),
    notifyCarePartnerUncompletedLesson: notifyUncompletedLesson(builder),
    resetCarePartnerPassword: resetPassword(builder),
    sendCarePartnerAppInstallEmail: sendAppInstallEmail(builder),
    sendCarePartnerAppInstallSMS: sendAppInstallSMS(builder),
    sendCarePartnerConversationMessage: sendConversationMessage(builder),
    sendCarePartnerGraduationEmail: sendGraduationEmail(builder),
    sendCarePartnerSignUpEmail: sendSignUpEmail(builder),
    sendCarePartnerSignUpSMS: sendSignUpSMS(builder),
    sendCarePartnerWelcomeEmail: sendWelcomeEmail(builder),
    updateCarePartner: updateCarePartner(builder),
    updateCarePartnerLearnTrack: updateCarePartnerLearnTrack(builder),
  }),
})

export const {
  useUpdateCarePartnerLearnTrackMutation,
  useLazyGetCarePartnersConversationQuery,
  useSendCarePartnerConversationMessageMutation,
  useNotifyCarePartnerUncompletedLessonMutation,
  useGetCarePartnersUnitsQuery,
  useLazyGetCarePartnersUnitsQuery,
  useLazyGetCarePartnerQuery,
  useLazyGetCarePartnersQuery,
  useLazyGetCarePartnerGroupsQuery,
  useChangeCarePartnerStatusMutation,
  useResetCarePartnerPasswordMutation,
  useSendCarePartnerAppInstallEmailMutation,
  useSendCarePartnerAppInstallSMSMutation,
  useSendCarePartnerSignUpEmailMutation,
  useSendCarePartnerSignUpSMSMutation,
  useNewCarePartnerMutation,
  useUpdateCarePartnerMutation,
  useGetCarePartnerQuery,
  useDeleteCarePartnerMutation,
  useSendCarePartnerGraduationEmailMutation,
  useSendCarePartnerWelcomeEmailMutation,
  useEditCarePartnersConversationMessageMutation,
  useDeleteCarePartnersConversationMessageMutation,
  reducer: carePartnersApiReducer,
  reducerPath: carePartnersApiReducerPath,
} = carePartnersApi
