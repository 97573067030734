import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
  Tooltip,
} from 'antd'
import React from 'react'

export interface ButtonProps extends AntdButtonProps {
  tooltipTitle?: string
}

export const Button: React.FC<ButtonProps> = ({
  tooltipTitle = '',
  ...props
}) => (
  <Tooltip title={tooltipTitle}>
    <AntdButton {...props} className={`avoid-overflow ${props.className}`}>
      {props.children}
    </AntdButton>
  </Tooltip>
)
