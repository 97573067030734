/* eslint-disable no-nested-ternary */
import { Form, Row } from 'antd'
import { Rule } from 'antd/es/form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect } from 'react'
import { validateTimeWithTimezone } from 'src/common/generalValidations'
import { disableDateBeforeToday } from 'src/common/helpers'
import { DEFAULT_TIMEZONE } from 'src/common/types'
import { DateAndTimePicker } from 'src/components/DateAndTimePicker'
import { SearchSelect } from 'src/components/SearchSelect'
import { Select } from 'src/components/Select'
import { SelectOption } from 'src/store/APIs/types'

import styles from '../addPackage.module.scss'
import { strings } from '../strings'

export interface AssignToUserRowProps {
  disabledSelectUser?: boolean
  selectedUser?: string
  optionsSelectUsers?: SelectOption[]
  fetchedFilteredElemsUsers?: (search: string) => Promise<any>
  fixedUser?: SelectOption
  deploymentDate?: Dayjs
  timezone?: string
  isDeployed?: boolean
  key?: string
}

export const AssignToUserRow: React.FC<AssignToUserRowProps> = ({
  disabledSelectUser,
  selectedUser,
  optionsSelectUsers,
  fetchedFilteredElemsUsers,
  fixedUser,
  deploymentDate,
  timezone,
  isDeployed,
  key,
}) => {
  // INFO: if deploymentDate exists, it's in UTC format because it's deserialized in that way
  // If deploymentDate is undefined, the date is selected from the picker and should be based on the user's timezone
  const form = useFormInstance()

  let initialDate: Dayjs | undefined

  if (fixedUser || selectedUser) {
    if (deploymentDate) {
      initialDate = deploymentDate.utc(true)
    } else if (timezone === DEFAULT_TIMEZONE) {
      initialDate = dayjs().tz(timezone).utc(true)
    } else {
      initialDate = dayjs().tz(timezone)
    }
  }

  const disabledDate =
    (!selectedUser && !fixedUser) || (deploymentDate && isDeployed)

  let rules: Rule[] = [
    {
      message: strings.deploymentDateRequired,
      required: !!selectedUser || !!fixedUser,
    },
  ]

  if (!isDeployed) {
    rules = [...rules, { validator: validateTimeWithTimezone(timezone) }]
  }

  useEffect(() => {
    form.setFieldValue('deployment_date', initialDate)
    if (!disabledDate) {
      form.validateFields(['deployment_date'])
    }
  }, [selectedUser])

  return (
    <Row className={styles.row}>
      <Form.Item name="assign_to_user" initialValue={fixedUser?.value}>
        {fixedUser ? (
          <Select
            options={[fixedUser]}
            disabled
            className={styles.assignToUserSelect}
            label={strings.assignToUserLabel}
          />
        ) : (
          <SearchSelect
            allowClear
            key={key}
            disabled={disabledSelectUser}
            options={optionsSelectUsers}
            fetchOptions={fetchedFilteredElemsUsers!}
            className={styles.assignToUserSelect}
            placeholder={strings.assignToUserLabel}
            label={strings.assignToUserLabel}
            optionalLabel
          />
        )}
      </Form.Item>
      <Form.Item
        initialValue={initialDate}
        name="deployment_date"
        className={styles.fixedWidthInput}
        rules={rules}>
        <DateAndTimePicker
          disabled={disabledDate}
          label={strings.deploymentDateLabel}
          required={!!selectedUser || !!fixedUser}
          subtitle={strings.deploymentTzLabel}
          placeholder={strings.selectDatePlaceholder}
          disabledDate={date => disableDateBeforeToday(date)}
        />
      </Form.Item>
    </Row>
  )
}
