import { Form } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useDeleteAudioMutation,
  useGenerateAudioMutation,
  useLazyGetAudioQuery,
} from 'src/store/APIs/articles'
import {
  ExtendedArticleInfo,
  PageResponse,
} from 'src/store/APIs/articles/types'
import { HttpStatus } from 'src/store/helpers'
import { clearAudioErrorAction } from 'src/store/storage/error'
import { selectAudioError } from 'src/store/storage/error/selectors'

import { Timer } from '../types'

export const useAudioGeneration = (
  pagesFormName: string,
  article?: ExtendedArticleInfo,
) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [existChanges, setExistChanges] = useState(!article?.audio?.file)
  const form = Form.useFormInstance()
  const audioError = useSelector(selectAudioError)
  const ssmlPages = Form.useWatch(pagesFormName, form)
  const validateFirstTime = useRef<boolean>(true)
  const onChangeSsml = () => {
    setExistChanges(true)
    validateFirstTime.current = false
  }

  const [
    triggerGenerateAudioMutation,
    { data: generateAudio, reset: resetGenerateAudio },
  ] = useGenerateAudioMutation()

  const [triggerLazyGetAudioQuery, { data: audio, error }] =
    useLazyGetAudioQuery()

  const audioFile = article?.audio?.id ? article.audio : audio

  const [triggerDeleteAudioMutation, { reset: resetDeleteAudio }] =
    useDeleteAudioMutation()

  const intervalIdRef = useRef<Timer>()

  const startPolling = () => {
    const id = setInterval(() => {
      if (generateAudio?.id) {
        triggerLazyGetAudioQuery(generateAudio.id)
      }
    }, 1000)
    return id
  }

  const onDelete = () => {
    if (audioFile?.id)
      triggerDeleteAudioMutation({
        id: audioFile.id,
        idArticle: article?.id,
      })
    resetGenerateAudio()
    form.setFieldValue('article_audio_id', undefined)
    setExistChanges(true)
  }

  const onClickGenerateAudio = () => {
    dispatch(clearAudioErrorAction())
    if (audioFile?.file?.url) onDelete()
    setLoading(true)
    setExistChanges(false)
    const pages = ssmlPages.map((page: PageResponse) => page.ssml_content)
    triggerGenerateAudioMutation(pages)
  }

  const audioButtonDisabled =
    !ssmlPages?.length ||
    ssmlPages?.some((p: PageResponse) => !p.ssml_content) ||
    !existChanges

  useEffect(() => {
    if (error) {
      form.validateFields([pagesFormName], { recursive: true })
    }
  }, [error, audioError])

  useEffect(() => {
    if (generateAudio?.id && !intervalIdRef.current) {
      intervalIdRef.current = startPolling()
    }
    if (
      (audio?.file ||
        (audio?.status && audio?.status !== HttpStatus.Accepted) ||
        !!audioError) &&
      intervalIdRef.current
    ) {
      if (audio?.file) {
        form.setFieldValue('article_audio_id', audio?.id)
      }
      clearInterval(intervalIdRef.current)
      resetDeleteAudio()
      intervalIdRef.current = undefined
      validateFirstTime.current = true
      setLoading(false)
    }
  }, [generateAudio, audio, audioError])

  return {
    audio,
    audioButtonDisabled,
    error,
    loading,
    onChangeSsml,
    onClickGenerateAudio,
    onDelete,
    validateFirstTime,
  }
}
