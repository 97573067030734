import { Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { isNaN } from 'lodash'
import React from 'react'
import {
  alphabeticSorter,
  dateSorter,
  formatShortDateToRender,
  numericSorter,
} from 'src/common/types'
import { generateColumnTitle } from 'src/helpers/utils'
import { TagSelect } from 'src/pages/SurvivorView/NavigatorTasks/TagSelect'
import { NavigatorTask } from 'src/store/APIs/navigatorTasks/types'
import { format } from 'util'

import styles from './navigatorTasks.module.scss'
import { strings } from './strings'
import {
  NavigatorTasksDataIndex,
  StatusOptions,
  statusProps,
  TypeLabels,
  TypeOptions,
  typeProps,
} from './types'

export const columns = (
  onClickRow: (id: string) => () => void,
): ColumnsType<NavigatorTask> => [
  {
    ...generateColumnTitle(NavigatorTasksDataIndex.task, strings.taskLabel),
    render(_, record) {
      return (
        <div className={styles.taskDescriptionCell}>
          <span
            className={styles.pseudoLink}
            onClick={onClickRow(record.id)}
            role="presentation">
            {record.description}
          </span>
        </div>
      )
    },
    sorter: (a, b) => alphabeticSorter(a.description, b.description),
  },
  {
    ...generateColumnTitle(NavigatorTasksDataIndex.status, strings.statusLabel),
    filters: [
      {
        text: StatusOptions.Completed,
        value: StatusOptions.Completed,
      },
      {
        text: StatusOptions.InProgress,
        value: StatusOptions.InProgress,
      },
      {
        text: StatusOptions.NotStarted,
        value: StatusOptions.NotStarted,
      },
      {
        text: StatusOptions.OnHold,
        value: StatusOptions.OnHold,
      },
      {
        text: StatusOptions.DoesNotApply,
        value: StatusOptions.DoesNotApply,
      },
    ],
    render(_, record) {
      const options = Object.values(StatusOptions)
      return (
        <TagSelect
          taskId={record.id}
          options={options}
          selectedStatus={statusProps(record.status as StatusOptions).title}
        />
      )
    },
    sorter: (a, b) => alphabeticSorter(a.status, b.status),
  },
  {
    ...generateColumnTitle(NavigatorTasksDataIndex.type, strings.typeLabel),
    filters: [
      {
        text: TypeLabels.Enrollment,
        value: TypeOptions.Enrollment,
      },
      {
        text: TypeLabels.Onboarding,
        value: TypeOptions.Onboarding,
      },
    ],
    render(_, record) {
      return (
        <Tag color={typeProps(record.type_of as TypeOptions).color as string}>
          {typeProps(record.type_of as TypeOptions).title}
        </Tag>
      )
    },
    sorter: (a, b) => alphabeticSorter(a.type_of, b.type_of),
  },
  {
    ...generateColumnTitle(
      NavigatorTasksDataIndex.goal_date,
      strings.goalDateLabel,
    ),
    render: formatShortDateToRender,
    sorter: (a, b) =>
      dateSorter(
        formatShortDateToRender(a.goal_date),
        formatShortDateToRender(b.goal_date),
      ),
  },
  {
    ...generateColumnTitle(
      NavigatorTasksDataIndex.completed_date,
      strings.completedDateLabel,
    ),
    render: formatShortDateToRender,
    sorter: (a, b) =>
      dateSorter(
        formatShortDateToRender(a.completed_date),
        formatShortDateToRender(b.completed_date),
      ),
  },
  {
    ...generateColumnTitle(
      NavigatorTasksDataIndex.time_spent,
      strings.timeSpentLabel,
    ),
    render(_, record) {
      if (record.time_spent) {
        return (
          <div className={styles.timeSpent}>
            {format(strings.timeSpentValue, parseInt(record.time_spent, 10))}
          </div>
        )
      }
      return null
    },
    sorter: (a, b) => {
      const aNum = parseInt(a.time_spent ?? '', 10)
      const bNum = parseInt(b.time_spent ?? '', 10)
      if (!isNaN(aNum) && !isNaN(bNum)) {
        return numericSorter(
          parseInt(a.time_spent ?? '', 10),
          parseInt(b.time_spent ?? '', 10),
        )
      }

      return 0
    },
  },
  {
    ...generateColumnTitle(
      NavigatorTasksDataIndex.created_at,
      strings.createdAtLabel,
    ),
    render: formatShortDateToRender,
    sorter: (a, b) =>
      dateSorter(
        formatShortDateToRender(a.created_at),
        formatShortDateToRender(b.created_at),
      ),
  },
  // TODO: Sync with BE
  // {
  //   ...generateColumnTitle(
  //     NavigatorTasksDataIndex.updated_at,
  //     strings.updatedAtLabel,
  //   ),
  //   render: formatShortDateToRender,
  //   sorter: (a, b) =>
  //     dateSorter(
  //       formatShortDateToRender(a.updated_at),
  //       formatShortDateToRender(b.updated_at),
  //     ),
  // },
  {
    ...generateColumnTitle(NavigatorTasksDataIndex.notes, strings.notesLabel),
    render(_, record) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: record.notes ?? '' }} />
    },
    sorter: (a, b) => alphabeticSorter(a.notes, b.notes),
  },
]
