import { ReactNode } from 'react'

export enum CarePartnerTabNames {
  basic_demographics = 'Basic Demographics',
  survivors = 'Survivors',
  learn = 'Learn',
  assessments = 'Assessments',
  groups = 'Community Groups',
  account_management = 'Account Management',
  private_conversation = '2-Way Chat',
  group_conversation = '3-Way Chat',
}

export interface CarePartnerTab {
  content: ReactNode
  tabName: CarePartnerTabNames
  editable?: boolean
}

export interface EditableTab {
  editMode: boolean
  toggleEditMode: () => void
}
