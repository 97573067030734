import { Modal } from 'antd'
import { FormInstance } from 'rc-field-form'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import colors from 'src/assets/stylesheets/colors.scss'
import { generalStrings } from 'src/common/generalStrings'
import { AntValidationError } from 'src/common/types'
import {
  useCheckScoreMutation,
  useCreateAndCheckScoreMutation,
} from 'src/store/APIs/assessments'
import {
  AssessmentDraft,
  AssessmentResponseItem,
} from 'src/store/APIs/assessments/types'

import { deleteModalIcon } from '../DeleteButton'
import { strings } from './strings'
import { OnAssessmentFormFinish } from './types'
import { getErrorFields } from './validations'

export const useCheckScore = (
  form: FormInstance,
  setRightButtonDisabled: (value: boolean) => void,
  onCloseDrawer: () => void,
  onFormFinish: OnAssessmentFormFinish,
  data: AssessmentDraft | undefined,
  setErrorFields: Dispatch<SetStateAction<number[]>>,
) => {
  const [showUntilIndex, setShowUntilIndex] = React.useState<
    number | undefined
  >(undefined)

  const [disableUntilIndex, setDisableUntilIndex] = React.useState<number>(-1)

  const [triggerCheckScoreMutation, { isLoading: isLoadingCheck }] =
    useCheckScoreMutation()
  const [triggerCreateAndCheckScoreMutation, { isLoading: isLoadingCreate }] =
    useCreateAndCheckScoreMutation()

  const isLoadingScore = isLoadingCheck || isLoadingCreate

  const modifyShowUntilIndex = () => {
    if (!showUntilIndex) return
    const nextShowUntilIndex = data?.steps.findIndex(
      (step, index) => index > showUntilIndex && step?.checkScoreWithBackend,
    )
    setShowUntilIndex(
      (nextShowUntilIndex === -1 ? data?.steps?.length : nextShowUntilIndex) ??
        1000,
    )
  }

  const rightButtonDisabled = showUntilIndex !== data?.steps?.length

  const onClickNext = async () => {
    try {
      await form.validateFields()
    } catch (errors) {
      const newErrorFields = getErrorFields(errors as AntValidationError)
      setErrorFields(newErrorFields)
      return
    }

    await form.validateFields()
    const response = await onFormFinish(
      triggerCheckScoreMutation,
      triggerCreateAndCheckScoreMutation,
    )(form.getFieldsValue())
    if ('data' in response) {
      if (!response.data.finish) {
        setDisableUntilIndex(showUntilIndex ?? -1)
        modifyShowUntilIndex()
      } else {
        Modal.success({
          content: strings.assessmentCompleteModalBody,
          okButtonProps: { style: { backgroundColor: colors.primary_50 } },
          onOk: onCloseDrawer,
          title: strings.assessmentCompleteModalTitle,
        })
      }
    }
  }

  const goBackAStep = () => {
    const newDisable =
      data?.steps.findIndex(
        (step, index) =>
          index < disableUntilIndex && step?.checkScoreWithBackend,
      ) ?? -1
    setDisableUntilIndex(newDisable)
    if (!showUntilIndex) return
    const newShowUntilIndex = data?.steps.findIndex(
      (step, index) => index < showUntilIndex && step?.checkScoreWithBackend,
    )
    setShowUntilIndex(
      newShowUntilIndex === -1 ? data?.steps?.length : newShowUntilIndex,
    )
    form.setFieldsValue({
      responses: form
        .getFieldValue('responses')
        .map((item: AssessmentResponseItem) => ({
          ...item,
          answer: undefined,
        })),
    })
  }

  const onClickEditPrevious = () => {
    Modal.confirm({
      cancelText: generalStrings.cancel,
      icon: deleteModalIcon,
      okText: strings.confirmEditPreviousQuestions,
      okType: 'danger',
      onOk: goBackAStep,
      title: strings.editPreviousModalTitle,
    })
  }

  useEffect(() => {
    if (data?.steps) {
      const newShowUntilIndex = data.steps.findIndex(
        step => step?.checkScoreWithBackend,
      )
      setShowUntilIndex(
        newShowUntilIndex === -1 ? data.steps.length : newShowUntilIndex,
      )
    }
  }, [data])

  useEffect(() => {
    setRightButtonDisabled(rightButtonDisabled)
  }, [rightButtonDisabled])

  return {
    disableUntilIndex,
    isLoadingScore,
    onClickEditPrevious,
    onClickNext,
    showUntilIndex,
  }
}
