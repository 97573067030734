import {
  CustomDate,
  dateDeserializer,
  deserializeFormattedDate,
} from 'src/common/types'

import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  PaginatedItems,
  PaginationResponse,
} from '../types'

export enum Endpoints {
  GetTacas = '/cms/v1/tacas/%s.json',
  GetSurvivorTacas = '/cms/v1/mobile_survivors/%s/tacas.json?page=%s',
  DeleteTacas = '/cms/v1/tacas/%s.json',
}

export interface GetSurvivorTacasResponse {
  tacas: TacasResponse[]
  pagination: PaginationResponse
}

export interface TacasResponse extends Entity {
  id: string
  created_at: string
  version: string
}

export interface Tacas extends Entity {
  id: string
  created_at: CustomDate
  version: string
}

export const getSurvivorTacasDeserializer = (
  data: GetSurvivorTacasResponse,
): PaginatedItems<Tacas> => {
  return {
    items: data.tacas.map(
      (tacas: TacasResponse): Tacas => ({
        created_at: dateDeserializer(tacas.created_at),
        id: tacas.id,
        permissions: tacas.permissions,
        version: tacas.version,
      }),
    ),
    pagination: data.pagination,
  }
}

export interface ExtraQuestionsResponse {
  title: string
  content: string
  image: null | string
  audio: null | string
}

export interface TacasDetailsResponse extends Entity {
  id: string
  basic_questions: TacasBasicQuestion[]
  completed_by: BaseUserResponse | null
  created_at: string
  extra_questions: ExtraQuestionsResponse[]
  mobile_survivor: BaseUserResponse
  version: string
}

export interface TacasBasicQuestion {
  title: string
  content: string
}

export interface TacasExtraQuestion extends TacasBasicQuestion {
  image?: string
  audio?: string
}

export const deserializeExtraQuestion = (
  data: ExtraQuestionsResponse,
): TacasExtraQuestion => ({
  audio: data.audio ?? undefined,
  content: data.content,
  image: data.image ?? undefined,
  title: data.title,
})

export interface TacasDetails extends Entity {
  id: string
  basic_questions: TacasBasicQuestion[]
  completed_by?: BaseUser
  created_at: string
  extra_questions: TacasExtraQuestion[]
  mobile_survivor: BaseUser
  version: string
}

export const tacasDeserializer = (
  data: TacasDetailsResponse,
): TacasDetails => ({
  basic_questions: data.basic_questions,
  completed_by: data.completed_by
    ? baseUserDeserializer(data.completed_by)
    : undefined,
  created_at: deserializeFormattedDate(data.created_at),
  extra_questions: data.extra_questions.map(item =>
    deserializeExtraQuestion(item),
  ),
  id: data.id,
  mobile_survivor: baseUserDeserializer(data.mobile_survivor),
  version: data.version,
})
