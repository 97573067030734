import {
  CustomDate,
  dateDeserializer,
  deserializeFormattedDate,
  optionalDateDeserializer,
} from 'src/common/types'
import {
  AudioFile,
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  PaginatedItems,
  PaginationResponse,
  Permissions,
  permissionsDeserializer,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetAllSurvivorsQTAs = '/cms/v1/mobile_survivors/%s/questions.json',
  GetActiveSurvivorsQTAs = '/cms/v1/mobile_survivors/%s/questions.json?type=active&page=%s',
  GetDeletedSurvivorsQTAs = '/cms/v1/mobile_survivors/%s/questions.json?type=deleted&page=%s',
  AddSurvivorQTA = '/cms/v1/questions',
  GetSurvivorQTA = '/cms/v1/questions/%s.json',
  UpdateSurvivorQTA = '/cms/v1/questions/%s.json',
  DeleteSurvivorQTA = '/cms/v1/questions/%s.json',
  NotifyUncompletedQTA = '/cms/v1/mobile_survivors/%s/questions/notify_uncompleted.json',
}

export interface SurvivorQTAResponse extends Entity {
  id: string
  answer: string
  answered_by: BaseUserResponse | null
  created_by: BaseUserResponse | null
  answered_date: string | null
  created_at: string
  question: string
  type_answered_by: string | null
}

export interface GetSurvivorQTAsResponse {
  questions: SurvivorQTAResponse[]
  model_permissions: Permissions
  pagination: PaginationResponse
}

export interface SurvivorQTA extends Entity {
  id: string
  question: string
  answer: string
  created_by: BaseUser | undefined
  answered_date: CustomDate | undefined
  created_at: CustomDate | undefined
}

export const getSurvivorQTADeserializer = (
  data: SurvivorQTAResponse,
): SurvivorQTA => {
  return {
    answer: data.answer,
    answered_date: optionalDateDeserializer(data.answered_date),
    created_at: dateDeserializer(data.created_at),
    created_by: data.created_by
      ? baseUserDeserializer(data.created_by)
      : undefined,
    id: data.id,
    permissions: permissionsDeserializer(data.permissions),
    question: data.question,
  }
}

export const getSurvivorQTAsDeserializer = (
  data: GetSurvivorQTAsResponse,
): PaginatedItems<SurvivorQTA> => ({
  items: data.questions.map(getSurvivorQTADeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

export interface AddQTAData {
  question: string
  answer?: string
  mobile_survivor_id: string
}

export interface QTAResponse {
  id: string
  answer: string | null
  answered_by: BaseUserResponse | null
  answered_date: string | null
  created_at: string
  created_by?: BaseUserResponse
  discarded_at: string | null
  mobile_survivor: BaseUserResponse
  permissions: Permissions
  question: string
  question_file: AudioFile | null
  updated_at: string | null
}

export interface QTA extends Entity {
  id: string
  answer?: string
  answered_by?: BaseUser
  answered_date?: string
  created_at: string
  created_by?: BaseUser
  discarded_at?: string
  question: string
  question_file?: AudioFile
  updated_at?: string
}

export const qtaDeserializer = (data: QTAResponse): QTA => {
  return {
    answer: data.answer ?? undefined,
    answered_by: data.answered_by
      ? baseUserDeserializer(data.answered_by)
      : undefined,
    answered_date: data.answered_date
      ? deserializeFormattedDate(data.answered_date)
      : undefined,
    created_at: deserializeFormattedDate(data.created_at),
    created_by: data.created_by
      ? baseUserDeserializer(data.created_by)
      : undefined,
    discarded_at: data.discarded_at
      ? deserializeFormattedDate(data.discarded_at)
      : undefined,
    id: data.id,
    permissions: permissionsDeserializer(data.permissions),
    question: data.question,
    question_file: data.question_file ?? undefined,
    updated_at: data.updated_at
      ? deserializeFormattedDate(data.updated_at)
      : undefined,
  }
}
