import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    cancelButtonLabel: 'Cancel',
    disabilities: 'Disabilities',
    disabledInputTooltip: 'Please edit this field in Tellescope',
    education: 'Education',
    employment: 'Employment',
    familyAndCommunitySupport: 'Family And Community Support',
    financialStrain: 'Financial Strain',
    food: 'Food',
    legalStatus: 'Legal Status',
    livingSituation: 'Living Situation',
    mdp1: 'Navigate Onscreen Menus Using The Touchscreen',
    mdp2: 'Use The Onscreen Keyboard To Type',
    mdp3: 'Send Emails',
    mdp4: 'Send Pictures',
    mdp5: 'Use Apps',
    mdp6: 'Total Score',
    mdpEditSubtitle: 'Using a mobile device I can:',
    mdpEditTitle:
      'Mobile Device Proficiency (Scale 1 (never tried) | 2 (not at all) | 3 (not very easily) | 4 (somewhat easily) | 5 (very easily))',
    mdpViewTitle: 'Mobile Device Proficiency',
    mentalHealth: 'Mental Health',
    mrs30Day: '30 Day mRS',
    // eslint-disable-next-line sort-keys
    mrs7Day: '7 Day mRS',
    mrs90Day: '90 Day mRS',
    mrsBaseline: 'Baseline mRS',
    mrsGraduation: 'Graduation mRS',
    mrsTitle: 'mRS (Modified Rankin Score)',
    nihss: 'NIHSS',
    onboarding: 'Onboarding',
    physicalActivity: 'Physical Activity',
    planOfCare: 'Plan Of Care',
    safety: 'Safety',
    socialNeedsTitle: 'Social Needs',
    substanceAbuse: 'Substance Abuse',
    transportation: 'Transportation',
    updateButtonLabel: 'Update',
    utilities: 'Utilities',
  },
})
