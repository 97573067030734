import { useParams } from 'react-router-dom'
import { TabNames } from 'src/pages/SurvivorView/types'
import { goToPage, RouteName } from 'src/routes'

export enum QueryParams {
  Tab = 'tab',
}

export const useTabParams = (
  currentRoute: RouteName,
  initialTab = TabNames.basic_demographics,
) => {
  const { id } = useParams<{ id: string }>()
  const searchParams = new URLSearchParams(window.location.search)
  const currentTab = searchParams.get(QueryParams.Tab) ?? initialTab

  // TODO: Probably we could type the keys?
  const onChangeTab = (activeKey: string) => {
    goToPage(currentRoute, { id }, { tab: activeKey })
  }

  const navigateBack = () => {
    goToPage(currentRoute, { id }, { tab: currentTab })
  }

  return { currentTab, navigateBack, onChangeTab }
}
