import { UploadChangeParam } from 'antd/es/upload'
import { Dayjs } from 'dayjs'
import {
  BaseUser,
  BaseUserResponse,
  Entity,
  PaginatedItems,
  PaginationResponse,
  Permissions,
  permissionsDeserializer,
  User,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetDocument = '/cms/v1/documents/%s.json',
  AddDocument = '/cms/v1/documents.json',
  UpdateDocument = '/cms/v1/documents/%s.json',
  GetSurvivorDocuments = '/cms/v1/mobile_survivors/%s/utilities/documents?page=%s',
}

export interface DocumentResponse {
  id: string
  name: string
  description: string
  file: FileResponse
  created_at: string
  created_by: BaseUserResponse
  mobile_survivor: User
  permissions: Permissions
}

export interface FileResponse {
  url: string
  name: string
}

export interface DocumentData {
  id: string
  name: string
  description: string
  file: FileResponse
  created_at: Dayjs
  created_by: BaseUser
  permissions: Permissions
}

export interface DocumentModel {
  mobile_survivor_id: string
  name: string
  description: string
  file: UploadChangeParam
}

export interface DocumentsResponse extends Entity {
  id: string
  name: string
  description: string
  file: {
    url: string
    name: string
  }
  created_at: string
  created_by: string
  updated_at: string
}

export interface GetDocumentsResponse {
  documents: DocumentsResponse[]
  pagination: PaginationResponse
}

export interface DocumentsData extends Entity {
  id: string
  name: string
  description: string
  url: string
  fileName: string
}

export const documentDeserializer = (document: DocumentsResponse) => ({
  description: document.description,
  fileName: document.file.name,
  id: document.id,
  name: document.name,
  permissions: permissionsDeserializer(document.permissions),
  url: document.file.url,
})

export const getDocumentsDeserializer = (
  data: GetDocumentsResponse,
): PaginatedItems<DocumentsData> => {
  return {
    items: data?.documents.map(documentDeserializer),
    pagination: data.pagination,
  }
}
