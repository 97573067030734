import { PayloadAction } from '@reduxjs/toolkit'

import { ErrorState } from './types'

export const clearError = (state: ErrorState) => {
  state.message = undefined
}

export const setError = (state: ErrorState, action: PayloadAction<string>) => {
  state.message = action.payload
}

export const clearAudioError = (state: ErrorState) => {
  state.audioError = undefined
}

export const clearAddPackageError = (state: ErrorState) => {
  state.addPackageError = undefined
}

export const clearAddArticleError = (state: ErrorState) => {
  state.addArticleError = undefined
}
