import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addTaskLabel: 'Add Navigator Task',
    onboardingBased: 'Onboarding Date Based',
    registrationBased: 'Registration Date Based',
    reorderListLabel: 'Reorder List',
    resetOrderLabel: 'Reset Order',
    submitOrderLabel: 'Submit Order',
    title: 'Default Navigator Tasks',
  },
})
