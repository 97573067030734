export enum CarePartnerType {
  NEW = 'Create new care partner',
  EXISTING = 'Existing care partner',
}

export const selectOptions = () => {
  const carePartnerType = [
    { label: CarePartnerType.NEW, value: CarePartnerType.NEW },
    { label: CarePartnerType.EXISTING, value: CarePartnerType.EXISTING },
  ]
  return {
    carePartnerType,
  }
}
