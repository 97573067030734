import { baseApi } from 'src/store/APIs'

import {
  addCommunicationLog,
  deleteCommunicationLog,
  editCommunicationLog,
  getCommunicationLog,
  getCommunicationLogOptions,
  getSurvivorsCommunicationLogs,
} from './actions'

export const communicationLogsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addCommunicationLog: addCommunicationLog(builder),
    deleteCommunicationLog: deleteCommunicationLog(builder),
    editCommunicationLog: editCommunicationLog(builder),
    getCommunicationLog: getCommunicationLog(builder),
    getCommunicationLogOptions: getCommunicationLogOptions(builder),
    getSurvivorsCommunicationLogs: getSurvivorsCommunicationLogs(builder),
  }),
})

export const {
  useLazyGetCommunicationLogQuery,
  useGetCommunicationLogQuery,
  useGetCommunicationLogOptionsQuery,
  useAddCommunicationLogMutation,
  useLazyGetSurvivorsCommunicationLogsQuery,
  useEditCommunicationLogMutation,
  useDeleteCommunicationLogMutation,
} = communicationLogsApi
