import React from 'react'
import { CustomDate, Status } from 'src/common/types'
import { AccountManagementView } from 'src/components/AccountManagementView'
import {
  useChangeCarePartnerStatusMutation,
  useSendCarePartnerAppInstallEmailMutation,
  useSendCarePartnerAppInstallSMSMutation,
  useSendCarePartnerGraduationEmailMutation,
  useSendCarePartnerSignUpEmailMutation,
  useSendCarePartnerSignUpSMSMutation,
  useSendCarePartnerWelcomeEmailMutation,
} from 'src/store/APIs/carePartners'

interface AccountManagementProps {
  userStatus?: Status
  mobileUserId?: string
  welcomeEmailSentDate?: CustomDate
  graduationEmailSentDate?: CustomDate
}

export const AccountManagement: React.FC<AccountManagementProps> = ({
  userStatus,
  mobileUserId,
  welcomeEmailSentDate,
  graduationEmailSentDate,
}) => (
  <AccountManagementView
    isCarePartner
    userStatus={userStatus}
    mobileUserId={mobileUserId}
    welcomeEmailSentDate={welcomeEmailSentDate}
    graduationEmailSentDate={graduationEmailSentDate}
    useSendGraduationEmail={useSendCarePartnerGraduationEmailMutation}
    useSendWelcomeEmail={useSendCarePartnerWelcomeEmailMutation}
    useSendAppInstallEmail={useSendCarePartnerAppInstallEmailMutation}
    useSendAppInstallSMS={useSendCarePartnerAppInstallSMSMutation}
    useSendSignUpEmail={useSendCarePartnerSignUpEmailMutation}
    useSendSignUpSMS={useSendCarePartnerSignUpSMSMutation}
    useChangeUserStatus={useChangeCarePartnerStatusMutation}
  />
)
