import { CustomDate, optionalDateDeserializer } from 'src/common/types'

import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  PaginatedItems,
  PaginationResponse,
  Permissions,
} from '../types'

export enum Endpoints {
  AddTodo = 'cms/v1/tasks.json',
  GetSurvivorsTodos = '/cms/v1/mobile_survivors/%s/tasks.json?page=%s',
  GetTodo = '/cms/v1/tasks/%s.json',
  UpdateTodo = '/cms/v1/tasks/%s.json',
  DeleteTodo = '/cms/v1/tasks/%s.json',
}

export enum Occurrence {
  RepeatDaily = 'Repeat_daily',
  RepeatWeekly = 'Repeat_weekly',
  RepeatMonthly = 'Repeat_monthly',
  RepeatAnnually = 'Repeat_annually',
}

export enum Reminder {
  No = 'No',
  Yes = 'Yes_-_One_time_only',
  YesRecurringReminder = 'Yes_-_Recurring_reminder',
}

export interface TodoModel {
  description: string
  additional_details?: string
  due_datetime?: string
  type_of?: Reminder
  occurrence?: Occurrence
  mobile_survivor_id: string
}

export interface SurvivorTodosResponse {
  id: string
  additional_details: string
  completed: boolean
  completed_by: string | null
  completed_date: string | null
  created_at: string
  created_by: string | null
  deleted_by: string | null
  description: string
  discarded_at: string | null
  due_datetime: string | null
  mobile_survivor_id: string
  occurrence: string | null
  type_completed_by: string | null
  type_created_by: string
  type_deleted_by: string | null
  type_of: string
  updated_at: string
  permissions: Permissions
}

export interface SurvivorTodosData {
  todo: string
  completed: boolean
  completed_date: CustomDate | undefined
  reminder: string
  repeat_schedule: string | undefined
  first_reminder_date: CustomDate | undefined
  id: string
  permissions: Permissions
}

export const getSurvivorTodoDeserializer = (
  data: SurvivorTodosResponse,
): SurvivorTodosData => {
  return {
    completed: data.completed,
    completed_date: optionalDateDeserializer(data.completed_date),
    first_reminder_date: optionalDateDeserializer(data.due_datetime),
    id: data.id,
    permissions: data.permissions,
    reminder: data.type_of?.replace(/_/g, ' '),
    repeat_schedule: data.occurrence
      ? data.occurrence?.replace(/_/g, ' ')
      : undefined,
    todo: data.description,
  }
}

export interface PaginatedSurvivorTodosResponse {
  tasks: SurvivorTodosResponse[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

export const getSurvivorTodosDeserializer = (
  data: PaginatedSurvivorTodosResponse,
): PaginatedItems<SurvivorTodosData> => ({
  items: data.tasks.map(getSurvivorTodoDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})
export interface TodoDetailResponse {
  task: {
    id: string
    description: string
    additional_details: string
    due_datetime: string
    type_of: Reminder
    occurrence: Occurrence
    completed: boolean
    deleted_by: BaseUserResponse | null
    permissions: Permissions
    created_by: BaseUserResponse | null
    completed_date: string | null
    completed_by: BaseUserResponse | null
  }
}

export interface TodoDetail {
  id: string
  description: string
  additional_details: string
  due_datetime: CustomDate | undefined
  type_of: Reminder
  occurrence: Occurrence
  completed: boolean
  deleted_by: BaseUser | null
  permissions: Permissions
  created_by: BaseUser | null
  completed_date: CustomDate | undefined
  completed_by: BaseUser | null
}

export const todoDetailDeserializer = (
  data: TodoDetailResponse,
): TodoDetail => ({
  additional_details: data.task.additional_details,
  completed: data.task.completed,
  completed_by: data.task.completed_by
    ? baseUserDeserializer(data.task.completed_by)
    : null,
  completed_date: optionalDateDeserializer(data.task.completed_date),
  created_by: data.task.created_by
    ? baseUserDeserializer(data.task.created_by)
    : null,
  deleted_by: data.task.deleted_by
    ? baseUserDeserializer(data.task.deleted_by)
    : null,
  description: data.task.description,
  due_datetime: optionalDateDeserializer(data.task.due_datetime),
  id: data.task.id,
  occurrence: data.task.occurrence,
  permissions: data.task.permissions,
  type_of: data.task.type_of,
})
