import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Dayjs } from 'dayjs'
import { RuleObject } from 'rc-field-form/lib/interface'
import React from 'react'
import { useParams } from 'react-router-dom'
import { validateTimeWithTimezone } from 'src/common/generalValidations'
import {
  disableDateBeforeCustomDate,
  disableDateBeforeToday,
} from 'src/common/helpers'
import { DatePicker } from 'src/components/DatePicker'
import { Select } from 'src/components/Select'
import {
  useAssignCarePartnerAssessmentMutation,
  useAssignSurvivorAssessmentMutation,
  useGetAssessmentsQuery,
} from 'src/store/APIs/assessments'
import { AssessmentUserType } from 'src/store/APIs/assessments/types'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { Survivor } from 'src/store/APIs/survivors/types'
import { AddAssessmentValues } from 'src/store/APIs/types'

import { DateAndTimePicker } from '../DateAndTimePicker'
import { Drawer } from '../Drawer'
import { strings } from './strings'

interface AddAssessmentProps {
  isCarePartner?: boolean
  onCloseDrawer: () => void
  open: boolean
  user?: Survivor | CarePartner
}

export const AddAssessment: React.FC<AddAssessmentProps> = ({
  isCarePartner,
  onCloseDrawer,
  open,
  user,
}) => {
  const { id } = useParams<{ id: string }>()
  const [
    triggerAssignSurvivorAssessment,
    { isLoading: isLoadingSurvivorAssessment },
  ] = useAssignSurvivorAssessmentMutation()

  const [
    triggerAssignCarePartnerAssessment,
    { isLoading: isLoadingCarePartnerAssessment },
  ] = useAssignCarePartnerAssessmentMutation()

  const isLoading =
    isLoadingCarePartnerAssessment || isLoadingSurvivorAssessment

  const { data: assessments } = useGetAssessmentsQuery({
    type: isCarePartner
      ? AssessmentUserType.CarePartner
      : AssessmentUserType.Survivor,
  })

  const assessmentsOptions = assessments?.map(assessment => ({
    label: assessment.name,
    value: assessment.id,
  }))

  const [form] = useForm()
  const deploymentDateValue = Form.useWatch('deployment_date', form)

  const onClose = () => {
    form.resetFields()
    onCloseDrawer()
  }

  const handleSubmit = (values: AddAssessmentValues) => {
    const data = {
      ...values,
      deployment_date: values.deployment_date.format(),
      due_time: values.due_time.format(),
    }
    if (isCarePartner) {
      triggerAssignCarePartnerAssessment({
        assign_assessment: data,
        id,
      }).then(onClose)
    } else {
      triggerAssignSurvivorAssessment({
        assign_assessment: data,
        id,
      }).then(onClose)
    }
  }

  const onClickSubmit = () => {
    form.submit()
  }

  const validateDueDate = (_: RuleObject, value: Dayjs) => {
    if (disableDateBeforeCustomDate(deploymentDateValue, value)) {
      return Promise.reject(new Error(strings.dueDateValidation))
    }
    return Promise.resolve()
  }

  return (
    <Drawer
      open={open}
      onClose={onCloseDrawer}
      title={strings.title}
      onClickRightButton={onClickSubmit}
      onClickLeftButton={onClose}
      leftButtonLabel={strings.cancel}
      isLoadingRightButton={isLoading}>
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          name="assessment_id"
          rules={[
            {
              message: strings.requiredValidation,
              required: true,
              type: 'string',
            },
          ]}>
          <Select
            options={assessmentsOptions}
            placeholder={strings.assessmentPlaceholder}
            label={strings.assessmentLabel}
            required
          />
        </Form.Item>
        <Form.Item
          name="deployment_date"
          rules={[
            {
              message: strings.requiredValidation,
              required: true,
            },
            { validator: validateTimeWithTimezone(user?.time_zone) },
          ]}>
          <DateAndTimePicker
            allowClear={false}
            label={strings.deploymentDateLabel}
            required
            subtitle={
              isCarePartner
                ? strings.deploymentDateSubLabelCarePartner
                : strings.deploymentDateSubLabelSurvivor
            }
            placeholder={strings.selectDatePlaceholder}
            disabledDate={disableDateBeforeToday}
          />
        </Form.Item>
        <Form.Item
          name="due_time"
          rules={[
            {
              message: strings.requiredValidation,
              required: true,
            },
            { validator: validateDueDate },
          ]}
          dependencies={['deployment_date']}>
          <DatePicker
            allowClear={false}
            label={strings.dueDateLabel}
            required
            placeholder={strings.selectDatePlaceholder}
            disabled={!deploymentDateValue}
            disabledDate={currentDate =>
              disableDateBeforeCustomDate(deploymentDateValue, currentDate)
            }
          />
        </Form.Item>
      </Form>
    </Drawer>
  )
}
