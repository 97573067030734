import { ReactNode } from 'react'

export enum StrokeDetailsTabNames {
  strokeCause = 'Stroke Cause',
  hospitalTreatments = 'Hospital Treatments',
  otherEvents = 'Other Events',
}

export interface StrokeDetailsTab {
  content: ReactNode
  tabName: StrokeDetailsTabNames
}
