import { PaginatedItems, PaginationResponse } from 'src/store/APIs/types'

export enum Endpoints {
  GetUsers = '/cms/v1/users.json',
}

export enum UserRole {
  Admin = 'admin',
  Navigator = 'navigator',
  Clinician = 'clinician',
}

export interface UserResponse {
  display_name: string
  id: string
  role: UserRole
}

export interface User {
  display_name: string
  id: string
  role: UserRole
}

export interface GetUsersResponse {
  users: UserResponse[]
  pagination: PaginationResponse
}

export const userDeserializer = (data: UserResponse): User => {
  return {
    ...data,
  }
}

export const usersDeserializer = (data: UserResponse[]): User[] =>
  data.map(userDeserializer)

export const getUsersResponseDeserializer = (
  data: GetUsersResponse,
): PaginatedItems<User> => {
  return {
    items: usersDeserializer(data.users),
    pagination: data.pagination,
  }
}
