import React from 'react'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import { InfoTable } from 'src/components/InfoTable'
import { UserHyperlink } from 'src/components/UserHyperlink'

import { strings } from '../strings'

interface ViewQTAProps extends DrawerRenderProps {}

export const ViewQTA: React.FC<ViewQTAProps> = ({ data }) => {
  const rows = [
    {
      content: data?.question,
      label: strings.questionLabel,
    },
    {
      content: data?.answer,
      label: strings.answerColumnLabel,
    },
    {
      content: data?.created_by ? (
        <UserHyperlink user={data.created_by} />
      ) : undefined,
      label: strings.createdByColumnLabel,
    },
    {
      content: data?.answered_by ? (
        <UserHyperlink user={data.answered_by} />
      ) : undefined,

      label: strings.answeredByLabel,
    },
    {
      content: data?.answered_date,
      label: strings.answeredDateColumnLabel,
    },
    {
      content: data?.created_at,
      label: strings.createdAtColumnLabel,
    },
    {
      content: data?.updated_at,
      label: strings.updatedAtLabel,
    },
    {
      content: data?.discarded_at,
      label: strings.discardedAtLabel,
    },
    {
      content: data?.question_file ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls src={data.question_file.file.url}>
          {strings.voiceMessageLabel}
        </audio>
      ) : undefined,
      label: strings.voiceMessageLabel,
    },
  ]

  return <InfoTable rows={rows} />
}
