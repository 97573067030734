import { Divider, Form, RadioChangeEvent } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
import { getUserFullName } from 'src/common/helpers'
import { Drawer } from 'src/components/Drawer'
import { Radio } from 'src/components/Radio'
import { AudienceOptions } from 'src/pages/Learn/Articles/types'
import { AddPackage } from 'src/pages/Learn/Packages/AddPackage'
import { AssignToUserRow } from 'src/pages/Learn/Packages/AddPackage/AssignToUserRow'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import {
  useAddPackageMutation,
  useAssignPackageMutation,
  useGetPackagesQuery,
} from 'src/store/APIs/packages'
import { Survivor } from 'src/store/APIs/survivors/types'
import { SelectOption } from 'src/store/APIs/types'

import { strings } from '../strings'
import { AssignPackageOptions } from '../types'
import { SelectPackage } from './SelectPackage'

interface AssignPackageProps {
  user?: Survivor | CarePartner
  open?: boolean
  setOpen: (open: boolean) => void
  audienceOptions?: SelectOption[]
  isCarePartner?: boolean
}

export const AssignPackage: React.FC<AssignPackageProps> = ({
  audienceOptions,
  user,
  open,
  setOpen,
  isCarePartner,
}) => {
  // TODO: we need this data for the permissions. I spoke with backend and we decided
  // to call this endpoint now and leave it as a future improvement
  const { data: packages } = useGetPackagesQuery({ page: 1 })

  const [triggerAddPackageMutation, { isLoading: isLoadingAdd }] =
    useAddPackageMutation()
  const [triggerAssignPackageMutation, { isLoading: isLoadingAssign }] =
    useAssignPackageMutation()
  const [assignOption, setAssignOption] = useState<AssignPackageOptions>(
    AssignPackageOptions.Existing,
  )
  const [form] = useForm()
  const loading = isLoadingAdd || isLoadingAssign
  const fixedUser = {
    label: getUserFullName(user?.first_name, user?.last_name) ?? '',
    value: user?.mobile_user_id ?? '',
  }

  const onChangeAssignOption = (e: RadioChangeEvent) => {
    setAssignOption(e.target.value)
  }

  const onCloseDrawer = () => {
    setOpen(false)
    setAssignOption(AssignPackageOptions.Existing)
  }

  const handleSubmit = async (values: any) => {
    let result
    if (assignOption === AssignPackageOptions.New) {
      result = await triggerAddPackageMutation({ body: values })
      if ('data' in result) {
        onCloseDrawer()
      }
    } else {
      result = await triggerAssignPackageMutation(values)
    }
    if ('data' in result) {
      onCloseDrawer()
    }
  }

  const initialValues = {
    audience: audienceOptions?.find(
      value => value.value !== AudienceOptions.Shared,
    )?.value,
  }

  return (
    <Drawer
      destroyOnClose
      size="large"
      open={open}
      onClose={onCloseDrawer}
      title={strings.assignPackageLabel}
      onClickRightButton={() => form.submit()}
      onClickLeftButton={onCloseDrawer}
      isLoadingRightButton={loading}>
      <Form
        preserve={false}
        onFinish={handleSubmit}
        form={form}
        initialValues={initialValues}>
        {packages?.model_permissions?.can_create && (
          <>
            <p>{strings.radioGroupTitle}</p>
            <Radio.Group
              defaultValue={AssignPackageOptions.Existing}
              onChange={onChangeAssignOption}>
              <Radio value={AssignPackageOptions.Existing}>
                {strings.selectExistingLabel}
              </Radio>
              <Radio value={AssignPackageOptions.New}>
                {strings.createNewLabel}
              </Radio>
            </Radio.Group>
            <Divider />
          </>
        )}
        {assignOption === AssignPackageOptions.Existing ? (
          <>
            <SelectPackage
              isCarePartner={isCarePartner}
              fixedLanguage={user?.content_language}
            />
            <AssignToUserRow fixedUser={fixedUser} timezone={user?.time_zone} />
          </>
        ) : (
          <AddPackage
            fixedUser={fixedUser}
            audienceOptions={audienceOptions}
            timezone={user?.time_zone}
            fixedLanguage={user?.content_language}
          />
        )}
      </Form>
    </Drawer>
  )
}
