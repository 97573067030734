import { DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { Form, Modal } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import React, { useEffect, useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { deleteModalIcon } from 'src/components/DeleteButton'
import { useLazyGetArticleQuery } from 'src/store/APIs/articles'
import { ExtendedArticleInfo } from 'src/store/APIs/articles/types'
import { ArticleAssigned } from 'src/store/APIs/packages/types'

import { PackageForm } from '../../types'
import { strings } from '../strings'

export const useArticleSelectTable = (isChild: boolean) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const form = useFormInstance<PackageForm>()
  const selectedArticle = Form.useWatch('article_select', form)
  const dataSource = Form.useWatch('articles', form)
  const disabledDeleteButton = selectedRowKeys.length === 0
  const [triggerGetArticleQuery, { isLoading, isFetching }] =
    useLazyGetArticleQuery()
  const loading = isLoading || isFetching

  const onOkDeleteSelected = () => {
    form.setFieldValue(
      'articles',
      dataSource?.filter(i => !selectedRowKeys.includes(i.internal_id)),
    )
    setSelectedRowKeys([])
  }

  const onClickDeleteColumn = (id: string) => () => {
    Modal.confirm({
      cancelText: generalStrings.cancel,
      icon: deleteModalIcon,
      okText: generalStrings.delete,
      okType: 'danger',
      onOk: () => {
        form.setFieldValue(
          'articles',
          dataSource?.filter(i => i.internal_id !== id),
        )
        setSelectedRowKeys(previous => {
          return previous.filter(i => i !== id)
        })
      },
      title: isChild
        ? strings.unassignArticleModalTitle
        : strings.deleteArticleModalTitle,
    })
  }

  const rowSelection = {
    getCheckboxProps: (record: ArticleAssigned) => ({
      disabled:
        !!record.completion_status &&
        !record?.permissions?.can_unassign &&
        !record?.permissions?.can_destroy &&
        !record?.permissions?.can_remove,
    }),
    onChange: (keys: React.Key[]) => {
      setSelectedRowKeys(keys)
    },
    selectedRowKeys,
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      form.setFieldValue(
        'articles',
        arrayMove(
          dataSource ?? [],
          (dataSource ?? []).findIndex(i => i.internal_id === active.id),
          (dataSource ?? []).findIndex(i => i.internal_id === over?.id),
        ),
      )
    }
  }

  useEffect(() => {
    if (selectedArticle) {
      triggerGetArticleQuery({ id: selectedArticle })
        .unwrap()
        .then((res: ExtendedArticleInfo) => {
          form.setFieldValue(
            'articles',
            dataSource?.find(i => i.internal_id === res.internal_id)
              ? dataSource
              : [...(dataSource ?? []), res],
          )
          form.setFieldValue('article_select', undefined)
        })
    }
  }, [selectedArticle])

  useEffect(() => {
    if (dataSource?.length && dataSource.length > 0) {
      form.validateFields(['article_select'])
    }
  }, [dataSource])

  return {
    dataSource,
    disabledDeleteButton,
    loading,
    onClickDeleteColumn,
    onDragEnd,
    onOkDeleteSelected,
    rowSelection,
    selectedRowKeys,
  }
}
