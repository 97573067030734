import React from 'react'

import { Button } from '../Button'
import styles from './communityGroupPost.module.scss'
import { ReactionButton } from './types'

interface PostReactionsBarProps {
  reactions: ReactionButton[]
}

export const PostReactionsBar: React.FC<PostReactionsBarProps> = ({
  reactions,
}) => (
  <>
    {reactions.map(
      ({ isLoading, onClick, icon, label, key, disabledReaction }) => (
        <div key={key} className={styles.reaction}>
          <Button
            disabled={isLoading || disabledReaction}
            icon={icon}
            onClick={onClick}
            type="text"
            className={styles.reactionButton}
          />
          <div>{label}</div>
        </div>
      ),
    )}
  </>
)
