import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddOtherEventMutation,
  useDeleteOtherEventMutation,
  useEditOtherEventMutation,
  useLazyGetOtherEventQuery,
  useLazyGetOtherEventsQuery,
} from 'src/store/APIs/otherEvents'

import styles from '../../survivorProfile.module.scss'
import { strings } from '../strings'
import { columns } from './columns'

export const OtherEvents: React.FC = () => {
  const { open, onCloseDrawer, openView, selectedId, showDrawer } = useDrawer()

  const [triggerAddOtherEventMutation, { isLoading: loadingAdd }] =
    useAddOtherEventMutation()

  const [triggerEditOtherEventMutation, { isLoading: loadingEdit }] =
    useEditOtherEventMutation()

  const [triggerDeleteOtherEventMutation, { isLoading: loadingDelete }] =
    useDeleteOtherEventMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.otherEventsTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addEventButton}
        </Button>
      </div>
      <DrawerWithState
        isLoadingRightButton={isLoadingRightButton}
        selectedId={selectedId}
        open={open}
        resourceName={strings.otherEventResource}
        RenderAdd={AddBasicItem}
        RenderView={ViewBasicItem}
        RenderEdit={AddBasicItem}
        useLazyGetResource={useLazyGetOtherEventQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddOtherEventMutation}
        triggerEditResourceMutation={triggerEditOtherEventMutation}
        triggerDeleteResourceMutation={triggerDeleteOtherEventMutation}
        isLoadingLeftButton={loadingDelete}
        deleteModalTitle={strings.deleteOtherEventModalTitle}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetOtherEventsQuery}
      />
    </>
  )
}
