import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    actionsColumnLabel: 'Actions',
    applyLabel: 'Apply',
    assessmentLabel: 'Assessment',
    assessmentPlaceholder: 'Select an assessment',
    assignAssessmentLabel: 'Assign Assessment',
    assignedAssessmentsColumnLabel: 'Assigned Assessments',
    cancelLabel: 'Cancel',
    cpTooltipMessage: `In care partner's timezone`,
    deleteButton: 'Delete',
    deleteModalTitle: 'Are you sure you want delete this assessment?',
    deploymentDateColumnLabel: 'Deployment Date',
    dueDateColumnLabel: 'Due Date',
    dueDateValidation: 'Due date must be after deployment date',
    editLabel: 'Edit',
    enterAssessmentLabel: 'Enter Assessment',
    errorMessage: `You can't save with errors`,
    modalParagraph:
      'Please note, this is specifically for entry of retroactive assessment data collected. If you would like to complete an assessment with a patient, first assign the assessment then select the option to complete.',
    modalTitle: 'Which assessment would you like to enter?',
    nextButton: 'Next',
    notDefined: 'Not defined',
    requiredValidation: 'This field is required',
    saveLabel: 'Save',
    statusColumnLabel: 'Status',
    survivorTooltipMessage: `In survivor's timezone`,
    todayLabel: 'Today',
  },
})
