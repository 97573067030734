import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    deleteButtonLabel: 'Delete',
    deleteCommentModalTitle: 'Are you sure you want delete this comment?',
    deletePostModalTitle: 'Are you sure you want delete this post?',
    like: 'Like',
    likes: `Likes`,
    sendCommentPlaceholder: 'Add a comment',
    userDeleted: '[Deleted User]',
  },
})
