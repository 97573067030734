import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import React from 'react'

import { PaginatedTable } from '../PaginatedTable'
import { columns } from './columns'

interface GroupsViewProps {
  useLazyQuery: UseLazyQuery<QueryDefinition<any, any, any, any, any>>
}

export const GroupsView: React.FC<GroupsViewProps> = ({ useLazyQuery }) => (
  <PaginatedTable columns={columns} useLazyQuery={useLazyQuery} />
)
