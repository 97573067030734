import { MutationDefinition } from '@reduxjs/toolkit/dist/query'
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  CustomDate,
  formatShortDateToRender,
  Status,
  statusProps,
} from 'src/common/types'
import { InfoTable } from 'src/components/InfoTable'
import { Tag } from 'src/components/Tag'
import { goToPage, RouteName } from 'src/routes'

import styles from './accountManagement.module.scss'
import { ActionsButtons } from './ActionsButtons'
import { strings } from './strings'

interface AccountManagementViewProps {
  userStatus?: Status
  mobileUserId?: string
  isCarePartner?: boolean
  welcomeEmailSentDate?: CustomDate
  graduationEmailSentDate?: CustomDate
  useSendAppInstallEmail: UseMutation<MutationDefinition<any, any, any, any>>
  useSendAppInstallSMS: UseMutation<MutationDefinition<any, any, any, any>>
  useSendSignUpEmail: UseMutation<MutationDefinition<any, any, any, any>>
  useSendSignUpSMS: UseMutation<MutationDefinition<any, any, any, any>>
  useChangeUserStatus: UseMutation<MutationDefinition<any, any, any, any>>
  useSendGraduationEmail: UseMutation<MutationDefinition<any, any, any, any>>
  useSendWelcomeEmail: UseMutation<MutationDefinition<any, any, any, any>>
}

export const AccountManagementView: React.FC<AccountManagementViewProps> = ({
  userStatus,
  mobileUserId,
  isCarePartner = false,
  welcomeEmailSentDate,
  graduationEmailSentDate,
  useSendAppInstallEmail,
  useSendAppInstallSMS,
  useSendSignUpEmail,
  useSendSignUpSMS,
  useChangeUserStatus,
  useSendGraduationEmail,
  useSendWelcomeEmail,
}) => {
  const { id } = useParams<{ id: string }>()
  const [
    triggerSendAppInstallEmail,
    { isLoading: isSendAppInstallEmailLoading },
  ] = useSendAppInstallEmail()
  const [triggerSendAppInstallSMS, { isLoading: isSendAppInstallSMSLoading }] =
    useSendAppInstallSMS()
  const [triggerSendSignUpEmail, { isLoading: isSendSignUpEmailLoading }] =
    useSendSignUpEmail()
  const [triggerSendSignUpSMS, { isLoading: isSendSignUpSMSLoading }] =
    useSendSignUpSMS()
  const [triggerChangeUserStatus, { isLoading: isChangeUserStatusLoading }] =
    useChangeUserStatus()
  const [
    triggerSendGraduationEmail,
    { isLoading: isSendGraduationEmailLoading },
  ] = useSendGraduationEmail()
  const [triggerSendWelcomeEmail, { isLoading: isSendWelcomeEmailLoading }] =
    useSendWelcomeEmail()

  const sendSignUpEmail = () => triggerSendSignUpEmail(id)
  const sendSignUpSMS = () => triggerSendSignUpSMS(id)
  const sendAppInstallEmail = () => triggerSendAppInstallEmail(id)
  const sendAppInstallSMS = () => triggerSendAppInstallSMS(id)
  const sendGraduationEmail = () => triggerSendGraduationEmail(id)
  const sendWelcomeEmail = () => triggerSendWelcomeEmail(id)
  const goToResetPassword = () =>
    goToPage(
      isCarePartner
        ? RouteName.CarePartnerResetPassword
        : RouteName.SurvivorResetPassword,
      { id },
    )
  const changeUserStatus = (status: Partial<Status>) => () =>
    triggerChangeUserStatus({ id, status })

  const sharedActions = [
    {
      content: (
        <ActionsButtons
          actions={[
            {
              action: sendAppInstallEmail,
              loading: isSendAppInstallEmailLoading,
              text: strings.sendAppInvitationEmailButton,
            },
            {
              action: sendAppInstallSMS,
              loading: isSendAppInstallSMSLoading,
              text: strings.sendAppInvitationEmailSMS,
            },
          ]}
        />
      ),
      label: strings.sendAppInvitation,
    },
    {
      content: (
        <>
          <ActionsButtons
            className={styles.buttonWithDate}
            disabled
            actions={[
              {
                action: sendWelcomeEmail,
                loading: isSendWelcomeEmailLoading,
                text: strings.welcomeEmailButton,
                tooltip: strings.disabledTooltip,
              },
            ]}
          />
          {welcomeEmailSentDate && (
            <span>{`${strings.sent} ${formatShortDateToRender(
              welcomeEmailSentDate,
            )}`}</span>
          )}
        </>
      ),
      label: strings.welcomeEmailLabel,
    },
    {
      content: (
        <>
          <ActionsButtons
            className={styles.buttonWithDate}
            disabled
            actions={[
              {
                action: sendGraduationEmail,
                loading: isSendGraduationEmailLoading,
                text: strings.graduationEmailButton,
                tooltip: strings.disabledTooltip,
              },
            ]}
          />
          {graduationEmailSentDate && (
            <span>{`${strings.sent} ${formatShortDateToRender(
              graduationEmailSentDate,
            )}`}</span>
          )}
        </>
      ),
      label: strings.graduationEmailLabel,
    },
    {
      content: (
        <ActionsButtons
          actions={[
            {
              action: goToResetPassword,
              text: isCarePartner
                ? strings.resetCarePartnerPasswordButton
                : strings.resetSurvivorPasswordButton,
            },
          ]}
        />
      ),
      label: strings.resetPassword,
    },
    {
      content: (
        <ActionsButtons
          actions={[
            {
              action: changeUserStatus(Status.deactivated),
              loading: isChangeUserStatusLoading,
              text: strings.deactivateUser,
            },
          ]}
          disabled={userStatus === Status.deactivated}
        />
      ),
      label: strings.deactivateUser,
    },
    {
      content: (
        <>
          <Tag color={statusProps(userStatus as Status).color}>
            {statusProps(userStatus as Status).title}
          </Tag>
          {userStatus === Status.new_user && (
            <ActionsButtons
              actions={[
                {
                  action: changeUserStatus(Status.in_progress),
                  loading: isChangeUserStatusLoading,
                  text: strings.changeStatus,
                },
              ]}
            />
          )}
        </>
      ),
      label: strings.status,
    },
    { content: mobileUserId, label: strings.mobileUserId },
  ]

  const restrictedActions = [
    Status.deactivated,
    Status.new_user,
    Status.in_progress,
    Status.activation_link_sent,
  ].includes(userStatus as Status)
    ? [
        {
          content: (
            <ActionsButtons
              actions={[
                {
                  action: sendSignUpEmail,
                  loading: isSendSignUpEmailLoading,
                  text: strings.signUpMessageEmailButton,
                },
                {
                  action: sendSignUpSMS,
                  loading: isSendSignUpSMSLoading,
                  text: strings.signUpMessageSMSButton,
                },
              ]}
            />
          ),
          label: strings.signUpMessage,
        },
      ]
    : []

  const rows = [...restrictedActions, ...sharedActions]

  return <InfoTable rows={rows} />
}
