import { Form } from 'antd'
import { FormProviderProps } from 'antd/es/form/context'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { DatePicker } from 'src/components/DatePicker'
import { MultipleBlockFooter } from 'src/components/MultipleBlockFooter'
import { SearchSelect } from 'src/components/SearchSelect'
import { Spinner } from 'src/components/Spinner'
import { TextInput } from 'src/components/TextInput'
import { useEditedItems } from 'src/hooks/useEditedItems'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import {
  useGetSurvivorsHealthEventsQuery,
  useLazyGetHealthEventsQuery,
  useUpdateSurvivorHealthEventsMutation,
} from 'src/store/APIs/healthEvents'
import {
  SurvivorHealthEventData,
  SurvivorHealthEventsPayload,
} from 'src/store/APIs/healthEvents/types'

import styles from '../survivorView.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

interface HealthEventsEditProps {
  toggleEditMode: () => void
}

const initialHealthEvent: Partial<SurvivorHealthEventsPayload> = {
  description: '',
  major_health_event_id: '',
  occurrence_date: undefined,
}

export const HealthEventsEdit: React.FC<HealthEventsEditProps> = ({
  toggleEditMode,
}) => {
  const { id } = useParams<{ id: string }>()
  const { data: survivorHealthEvents, isFetching: isFetchingHealthEvents } =
    useGetSurvivorsHealthEventsQuery({ id })

  const {
    optionsSelect: healthEventsOptions,
    fetchedFilteredElems: fetchedFilteredHealthEvents,
  } = useSearchOptionsSelect(useLazyGetHealthEventsQuery, undefined, 'name')

  const [triggerUpdateSurvivorHealthEvents, { isSuccess, isLoading }] =
    useUpdateSurvivorHealthEventsMutation()

  const {
    editedItems: editedHealthEvents,
    addNewEmptyItem: addNewHealthEvent,
    onPressRemoveItem: onPressRemoveHealthEvent,
    prepareFormValues,
    lastItemIndex,
  } = useEditedItems(survivorHealthEvents?.items, initialHealthEvent)

  const onFormFinish: FormProviderProps['onFormFinish'] = (_, { forms }) => {
    const { updatedFormItems: healthEvents } = prepareFormValues(forms)
    triggerUpdateSurvivorHealthEvents({
      health_events: healthEvents,
      id,
    })
  }

  if (isFetchingHealthEvents) return <Spinner />

  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.title}>{strings.title}</div>
        <Button type="default" onClick={addNewHealthEvent}>
          {strings.addNewHealthEvent}
        </Button>
      </div>
      <Form.Provider onFormFinish={onFormFinish}>
        {editedHealthEvents?.map(
          (item: Partial<SurvivorHealthEventData>, index) => {
            return (
              <Form
                key={item.id}
                name={strings.healthEventLabel + index}
                initialValues={item}>
                <Form.Item name="id" hidden />
                {healthEventsOptions && (
                  <Form.Item name="major_health_event_id" rules={[yupSync]}>
                    <SearchSelect
                      required
                      label={strings.healthEventLabel}
                      options={healthEventsOptions}
                      fetchOptions={fetchedFilteredHealthEvents}
                      placeholder={strings.healthEventLabel}
                    />
                  </Form.Item>
                )}
                <Form.Item name="occurrence_date">
                  <DatePicker label={strings.occurrenceDateLabel} />
                </Form.Item>
                <Form.Item name="description" rules={[yupSync]}>
                  <TextInput label={strings.descriptionLabel} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={onPressRemoveHealthEvent(item)}>
                    {strings.removeLabel}
                  </Button>
                </Form.Item>
                <MultipleBlockFooter
                  isLoading={isLoading}
                  isLast={index === lastItemIndex}
                  isSuccess={isSuccess}
                  onCancel={toggleEditMode}
                  onSuccess={toggleEditMode}
                />
              </Form>
            )
          },
        )}
        {editedHealthEvents?.length === 0 &&
          survivorHealthEvents?.items.length !== 0 && (
            <Form>
              <MultipleBlockFooter
                isLoading={isLoading}
                isLast
                isSuccess={isSuccess}
                onCancel={toggleEditMode}
                onSuccess={toggleEditMode}
              />
            </Form>
          )}
      </Form.Provider>
    </>
  )
}
