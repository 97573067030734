export enum Endpoints {
  GetAssessments = '/cms/v1/assessments%s',
  GetCarePartnerAssessments = '/cms/v1/mobile_caregivers/%s/assessments.json',
  EditAssessment = '/cms/v1/assign_assessments/%s.json',
  AssignCarePartnerAssessment = '/cms/v1/mobile_caregivers/%s/assessments.json',
  GetCarePartnerAssessmentDetails = '/cms/v1/mobile_caregivers/%s/assessments/%s.json',
  DeleteCarePartnerAssessment = '/cms/v1/mobile_caregivers/%s/assessments/%s.json',
  GetSurvivorsAssessments = '/cms/v1/mobile_survivors/%s/assessments.json',
  GetSurvivorAssessmentDetails = '/cms/v1/mobile_survivors/%s/assessments/%s.json',
  DeleteSurvivorAssessment = '/cms/v1/mobile_survivors/%s/assessments/%s.json',
  AssignSurvivorAssessment = '/cms/v1/mobile_survivors/%s/assessments.json',
  GetAssessmentQuestions = '/cms/v1/assessments/%s.json',
  AnswerAssessment = '/cms/v1/assign_assessments/%s/answer.json',
  CheckScore = '/cms/v1/assign_assessments/%s/check_score.json',
  CreateAndCheckScore = '/cms/v1/assign_assessments/check_score.json',
  CreateAndAnswerAssessment = '/cms/v1/assign_assessments/create_and_answer.json',
}

export interface Assessment {
  id: string
  name: string
}

export enum AssessmentUserType {
  'Survivor' = 'survivor',
  'CarePartner' = 'caregiver',
  'Both' = '',
}

export interface PickOptionInterface {
  key: string
  title: string
}

export interface SliderInterface {
  min: number
  max: number
  minMessage: string
  maxMessage: string
}

export interface AssessmentStep {
  checkScoreWithBackend: boolean
  content: string
  key: string
  title: string
  type: AssessmentStepType
  description?: string
}

export enum AssessmentStepType {
  CHECKBOX = 'StepType.CHECKBOX',
  CHOOSE_OPTION = 'StepType.CHOOSE_OPTION',
  FREE_TEXT_MULTILINE = 'StepType.FREE_TEXT_MULTILINE',
  SLIDER = 'StepType.SLIDER',
}

export interface AssessmentDraftResponse {
  assessment: {
    id: string
    assessment: {
      steps: AssessmentStep[]
      title: string
      type: string
      options: { [key: string]: PickOptionInterface[] | SliderInterface[] }
    }
    created_at: string
    name: string
    updated_at: string
  }
}

export interface AssessmentDraft {
  id: string
  steps: AssessmentStep[]
  title: string
  type: string
  options: { [key: string]: PickOptionInterface[] | SliderInterface[] }
  name: string
}

export interface AssessmentAnswerPayload {
  spoke_with_survivor_id?: string
  spoke_with_caregiver_id?: string
  answered_date: string
  responses: AssessmentResponseItem[]
}

export interface AssessmentResponseItem {
  key: string
  answer: string
}

export const assessmentDraftDeserializer = (
  data: AssessmentDraftResponse,
): AssessmentDraft => ({
  id: data.assessment.id,
  name: data.assessment.name,
  options: data.assessment.assessment.options,
  steps: data.assessment.assessment.steps,
  title: data.assessment.assessment.title,
  type: data.assessment.assessment.type,
})

export interface AssessmentCreateAndAnswerPayload {
  spoke_with_survivor_id?: string
  spoke_with_caregiver_id?: string
  answered_date: string
  assessment_id: string
  completed_by_id: string
  mobile_user_id?: string
  responses: [
    {
      key: string
      answer: string
    },
  ]
}
