import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    alertsColumnLabel: 'Alerts',
    batchActionsButtonLabel: 'Batch Actions',
    carePartnerColumnLabel: 'Care Partner',
    deleteSelectedLabel: 'Delete selected',
    disabledAddUserButton: 'Please create new users in Tellescope',
    emailColumnLabel: 'Email',
    itemsSelectedLabel: '{0} items selected',
    navigatorColumnLabel: 'Navigator',
    newButtonLabel: 'Add User',
    originatingHospitalColumnLabel: 'Originating Hospital',
    phoneNumberColumnLabel: 'Preferred Phone',
    statusColumnLabel: 'Status',
    survivorColumnLabel: 'Survivor',
    tagNo: 'No',
    tagYes: 'Yes',
    title: 'Survivors',
  },
})
