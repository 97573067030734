import { Drawer as AntdDrawer, DrawerProps } from 'antd'
import React from 'react'
import { Button } from 'src/components/Button'

import styles from './drawer.module.scss'
import { strings } from './strings'

export interface StyledDrawerProps extends DrawerProps {
  leftButtonLabel?: string
  rightButtonLabel?: string
  onClickLeftButton?: () => void
  onClickRightButton?: () => void
  children?: React.ReactNode
  leftButtonDanger?: boolean
  isLoadingRightButton?: boolean
  isLoadingLeftButton?: boolean
  rightButtonDisabled?: boolean
  leftButtonDisabled?: boolean
  leftButtonStyle?: React.CSSProperties
}

export const Drawer: React.FC<StyledDrawerProps> = ({
  leftButtonLabel = strings.cancelLabel,
  rightButtonLabel = strings.submitLabel,
  size = 'large',
  leftButtonDanger,
  onClickLeftButton,
  onClickRightButton,
  isLoadingRightButton = false,
  isLoadingLeftButton = false,
  children,
  rightButtonDisabled = false,
  leftButtonDisabled = false,
  leftButtonStyle = {},
  ...props
}) => {
  const extra = (
    <>
      {onClickLeftButton && (
        <Button
          disabled={leftButtonDisabled}
          onClick={onClickLeftButton}
          loading={isLoadingLeftButton}
          danger={leftButtonDanger}
          style={!leftButtonDisabled ? leftButtonStyle : {}}>
          {leftButtonLabel}
        </Button>
      )}
      {onClickRightButton && (
        <Button
          disabled={rightButtonDisabled}
          type="primary"
          onClick={onClickRightButton}
          className={styles.rightButton}
          loading={isLoadingRightButton}>
          {rightButtonLabel}
        </Button>
      )}
    </>
  )
  return (
    <AntdDrawer size={size} {...props} extra={extra} destroyOnClose>
      {children}
    </AntdDrawer>
  )
}
