import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addNewHealthCondition: '+ New Health Condition',
    cancelButtonLabel: 'Cancel',
    descriptionLabel: 'Description',
    healthCondition: 'Health Condition',
    removeLabel: 'Remove',
    requiredValidation: 'This field is required',
    title: 'Survivor Medical History',
    updateButtonLabel: 'Update',
  },
})
