import { StarFilled } from '@ant-design/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import colors from 'src/assets/stylesheets/colors.scss'
import {
  formatDateToRender,
  PhoneNumberTypes,
  timezoneFromIANAToOffset,
} from 'src/common/types'
import { InfoTable } from 'src/components/InfoTable'
import { Tabs } from 'src/components/Tabs'
import { Tag } from 'src/components/Tag'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { Survivor } from 'src/store/APIs/survivors/types'

import { strings } from '../strings'
import { BasicDemographicsTab, BasicDemographicsTabNames } from '../types'
import styles from './basicDemographicsView.module.scss'
import { CarePartnerTab } from './CarePartnerTab'

interface BasicDemographicsViewProps {
  survivor?: Survivor
  carePartner?: CarePartner
  activeTab?: BasicDemographicsTabNames
  onChangeTab: (tab: string) => void
}

export const BasicDemographicsView: React.FC<BasicDemographicsViewProps> = ({
  survivor,
  carePartner,
  activeTab = BasicDemographicsTabNames.survivor,
  onChangeTab,
}) => {
  const survivorInfoRows = [
    { content: survivor?.first_name, label: strings.firstNameLabel },
    {
      content: survivor?.last_name,
      label: strings.lastNameLabel,
    },
    {
      content: survivor?.preferred_name ?? undefined,
      label: strings.preferredNameLabel,
    },
    {
      content: formatDateToRender(survivor?.date_of_birth),
      label: strings.dateOfBirthLabel,
    },
    {
      content: formatDateToRender(survivor?.post_acute_registration_date),
      label: strings.registrationDateLabel,
    },
    {
      content: formatDateToRender(survivor?.post_acute_onboarding_date),
      label: strings.onboardingDateLabel,
    },
    {
      content: survivor?.preferred_pronoun ?? undefined,
      label: strings.preferredPronounLabel,
    },
    {
      content: survivor?.biological_sex ?? undefined,
      label: strings.legalSexLabel,
    },
    {
      content: survivor?.gender_identity ?? undefined,
      label: strings.genderIdentityLabel,
    },
    {
      content: survivor?.race.length ? survivor?.race.join(', ') : undefined,
      label: strings.raceLabel,
    },
    {
      content: survivor?.ethnicity ?? undefined,
      label: strings.ethnicityLabel,
    },
    {
      content: survivor?.content_language ?? undefined,
      label: strings.languageLabel,
    },
    {
      content: (
        <div className={styles.row}>
          <div className={styles.phone}>{survivor?.phone_number}</div>
          {survivor?.preferred_phone === PhoneNumberTypes.Mobile && (
            <Tag color="gold" icon={<StarFilled />}>
              {strings.preferredLabel}
            </Tag>
          )}
        </div>
      ),
      label: strings.mobilePhoneLabel,
    },
    {
      content: survivor?.home_phone ? (
        <div className={styles.row}>
          <div className={styles.phone}>{survivor?.home_phone}</div>
          {survivor?.preferred_phone === PhoneNumberTypes.Home && (
            <Tag color="gold" icon={<StarFilled />}>
              {strings.preferredLabel}
            </Tag>
          )}
        </div>
      ) : undefined,
      label: strings.homePhoneLabel,
    },
    { content: survivor?.email, label: strings.emailLabel },
    {
      content: survivor?.address ?? undefined,
      label: strings.addressLabel,
    },
    {
      content: survivor?.city ?? undefined,
      label: strings.cityLabel,
    },
    {
      content: survivor?.state,
      label: strings.stateLabel,
    },
    {
      content: survivor?.zip_code ?? undefined,
      label: strings.zipCodeLabel,
    },
    {
      content: timezoneFromIANAToOffset(survivor?.time_zone),
      label: strings.timezoneLabel,
    },
    {
      content: survivor?.emergency ?? undefined,
      label: strings.emergencyContactLabel,
    },
    {
      content: survivor?.primary_insurance_info ?? undefined,
      label: strings.insuranceInfoLabel,
    },
    {
      content: survivor?.living_situation ?? undefined,
      label: strings.livingSituationLabel,
    },
    {
      content: survivor?.family_situation ?? undefined,
      label: strings.familySituationLabel,
    },
    {
      content: (
        <Tag color={survivor?.ambassador ? colors.info_50 : colors.surface_50}>
          {survivor?.ambassador ? strings.tagYes : strings.tagNo}
        </Tag>
      ),
      label: strings.ambassadorLabel,
    },
    {
      content: (
        <Tag color={survivor?.test_user ? colors.info_50 : colors.surface_50}>
          {survivor?.test_user ? strings.tagYes : strings.tagNo}
        </Tag>
      ),
      label: strings.testUserLabel,
    },
    {
      content:
        survivor?.mobile_caregivers && survivor.mobile_caregivers.length > 0 ? (
          <Link to={`/care_partners/${survivor?.mobile_caregivers[0].id}`}>
            {`${survivor?.mobile_caregivers[0].first_name} ${survivor?.mobile_caregivers[0].last_name}`}
          </Link>
        ) : undefined,
      label: strings.carePartnerLabel,
    },
    {
      content: survivor?.cms_navigator ? (
        <div>
          {`${survivor?.cms_navigator.name} ${survivor.cms_navigator.last_name}`}
        </div>
      ) : undefined,
      label: strings.navigatorLabel,
    },
  ]

  const tabs: BasicDemographicsTab[] = [
    {
      content: <InfoTable rows={survivorInfoRows} />,
      tabName: BasicDemographicsTabNames.survivor,
    },
    {
      content: (
        <CarePartnerTab carePartner={carePartner} survivorId={survivor?.id} />
      ),
      tabName: BasicDemographicsTabNames.care_partner,
    },
  ]

  return (
    <Tabs
      defaultActiveKey={activeTab}
      onChange={onChangeTab}
      type="card"
      items={tabs.map(item => {
        return {
          children: (
            <div className={styles.tabInfoContainer}>{item.content}</div>
          ),
          key: item.tabName,
          label: <div className={styles.tabLabel}>{item.tabName}</div>,
        }
      })}
    />
  )
}
