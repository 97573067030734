import { TagProps } from 'antd'
import { TypeLabels } from 'src/pages/SurvivorView/NavigatorTasks/types'

import { strings } from './strings'

export interface DefaultNavigatorTask {
  id: string
  description: string
  position: number
  goal_date_type: DefaultNavigatorTaskTypeOptions
  goal_date: number
}

export interface DefaultNavigatorTaskModel {
  id?: string
  description: string
  goal_date_type: DefaultNavigatorTaskTypeOptions
  goal_date: string
  position?: number
}

export enum DefaultNavigatorTaskTypeOptions {
  Enrollment = 'Enrollment',
  Onboarding = 'Onboarding',
}

export const getNavigatorTypeLabel = (
  type: DefaultNavigatorTaskTypeOptions,
) => {
  switch (type) {
    case DefaultNavigatorTaskTypeOptions.Enrollment:
      return TypeLabels.Enrollment
    case DefaultNavigatorTaskTypeOptions.Onboarding:
      return TypeLabels.Onboarding
    default:
      return undefined
  }
}

export interface DefaultNavigatorTaskTypeProps {
  title: DefaultNavigatorTaskTypeOptions | string
  color: TagProps['color']
}

export const defaultNavigatorTaskTypeProps = (
  status: DefaultNavigatorTaskTypeOptions,
): DefaultNavigatorTaskTypeProps | undefined => {
  switch (status) {
    case DefaultNavigatorTaskTypeOptions.Enrollment:
      return {
        color: 'purple',
        title: strings.registrationBased,
      }
    case DefaultNavigatorTaskTypeOptions.Onboarding:
      return {
        color: 'blue',
        title: strings.onboardingBased,
      }
    default:
      return undefined
  }
}
