import { baseApi } from 'src/store/APIs'

import {
  addArticle,
  deleteArticle,
  deleteAudio,
  editArticle,
  generateAudio,
  generateSsml,
  getArticle,
  getArticles,
  getAudio,
  paginateContent,
  removeArticle,
  uploadMedia,
} from './actions'

export const articlesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addArticle: addArticle(builder),
    deleteArticle: deleteArticle(builder),
    deleteAudio: deleteAudio(builder),
    editArticle: editArticle(builder),
    generateAudio: generateAudio(builder),
    generateSsml: generateSsml(builder),
    getArticle: getArticle(builder),
    getArticles: getArticles(builder),
    getAudio: getAudio(builder),
    paginateContent: paginateContent(builder),
    removeArticle: removeArticle(builder),
    uploadMedia: uploadMedia(builder),
  }),
})

export const {
  useRemoveArticleMutation,
  useUploadMediaMutation,
  useDeleteAudioMutation,
  useLazyGetAudioQuery,
  useGenerateAudioMutation,
  useLazyGetArticlesQuery,
  useLazyGetArticleQuery,
  useAddArticleMutation,
  usePaginateContentMutation,
  useGenerateSsmlMutation,
  useEditArticleMutation,
  useDeleteArticleMutation,
} = articlesApi
