import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    cancelButtonLabel: 'Cancel',
    emptyPasswordValidation: 'You must set a password',
    passwordConfirmationLabel: 'Password confirmation',
    passwordConfirmationRequired: 'You must write the password confirmation',
    passwordLabel: 'Password',
    passwordLowercase: 'Password must contain at least one letter in lowercase',
    passwordMatchValidation: 'Passwords must match',
    passwordMinCharacters: 'Password must have at least 8 characters',
    passwordNumberValidation: 'Password must contain a number',
    passwordUppercase: 'Password must contain at least one letter in uppercase',
    submitButtonLabel: 'Submit',
    title: 'Reset Password',
  },
})
