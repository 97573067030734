import React from 'react'
import { useGetCsrfTokenQuery } from 'src/store/APIs/auth'

import { Spinner } from '../Spinner'

export const OnMountLogic: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const { isLoading } = useGetCsrfTokenQuery()

  return isLoading ? <Spinner /> : children
}
