import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { SubHeader } from 'src/components/SubHeader'
import { RouteName } from 'src/routes'
import { useLazyGetCarePartnersQuery } from 'src/store/APIs/carePartners'

import styles from './carePartners.module.scss'
import { useCarePartnersColumns } from './columns'
import { strings } from './strings'

interface CarePartnersProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  {
    breadcrumbName: generalStrings.carePartnersBreadcrumb,
    path: RouteName.CarePartners,
  },
]

export const CarePartners: React.FC<CarePartnersProps> = () => {
  const { resetFilters, ...columnsAndFilters } = useCarePartnersColumns()

  return (
    <div className={styles.container}>
      <SubHeader
        className={styles.subHeader}
        routes={mockedBreadcrumbRoutes}
        title={strings.title}
        section={SectionNames.Users}>
        <Button onClick={resetFilters}>
          {generalStrings.clearFiltersButtonLabel}
        </Button>
      </SubHeader>
      <PaginatedTable
        className={styles.table}
        useLazyQuery={useLazyGetCarePartnersQuery}
        {...columnsAndFilters}
      />
    </div>
  )
}
