import { ReactNode } from 'react'

export enum CommunityGroupTabNames {
  members = 'Members',
  posts = 'Posts',
}

export enum FormNames {
  create_post = 'Create Post',
}

export interface CommunityGroupTab {
  content: ReactNode
  tabName: CommunityGroupTabNames
  editable?: boolean
}

export enum MembersDataIndex {
  image = 'image',
  name = 'name',
  user_type = 'user_type',
  biography = 'biography',
  label = 'label',
}
