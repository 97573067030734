import { deserializeFormattedDate } from 'src/common/types'

import {
  Entity,
  PaginatedItems,
  PaginationResponse,
  Permissions,
  User,
} from '../types'

export enum Endpoints {
  GetStrokeCauses = '/cms/v1/global_settings/causes.json',
  GetSurvivorsStrokeCauses = '/cms/v1/mobile_survivors/%s/profile/causes.json',
  UpdateSurvivorsStrokeCauses = '/cms/v1/mobile_survivors/%s/profile/causes/batch_put.json',
  GetStrokeCause = '/cms/v1/global_settings/causes/%s.json',
  AddStrokeCause = '/cms/v1/global_settings/causes.json',
  EditStrokeCause = '/cms/v1/global_settings/causes/%s.json',
  DeleteStrokeCause = '/cms/v1/global_settings/causes/%s.json',
}

export interface GetStrokeCausesResponse {
  causes: StrokeCauseResponse[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

export interface StrokeCauseResponse extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export interface StrokeCauseData extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export const strokeCauseDeserializer = (
  data: StrokeCauseResponse,
): StrokeCauseData => ({
  ...data,
})

export const strokeCausesDeserializer = (
  data: GetStrokeCausesResponse,
): PaginatedItems<StrokeCauseData> => ({
  ...data,
  items: data.causes,
})

export interface SurvivorStrokeCausesResponse {
  id: string
  cause: {
    name: string
    id: string
  }
  mobile_survivor: User
  created_at: string
}

export interface SurvivorStrokeCauseData {
  created_at: string
  cause: string
  id: string
}

export const survivorStrokeCauseDeserializer = (
  data: SurvivorStrokeCausesResponse,
): SurvivorStrokeCauseData => {
  return {
    cause: data.cause.name,
    created_at: deserializeFormattedDate(data.created_at),
    id: data.cause.id,
  }
}

export const survivorStrokeCausesDeserializer = (
  data: SurvivorStrokeCausesResponse[],
) => data.map(survivorStrokeCauseDeserializer)
