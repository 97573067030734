import { Form } from 'antd'
import React from 'react'
import { TextInput } from 'src/components/TextInput'

import { strings } from './strings'
import { yupSync } from './validations'

interface AddImpairmentProps {}

export const AddImpairment: React.FC<AddImpairmentProps> = () => {
  return (
    <>
      <Form.Item name="name" rules={[yupSync]}>
        <TextInput label={strings.nameLabel} required />
      </Form.Item>
      <Form.Item name="clinical_name" rules={[yupSync]}>
        <TextInput label={strings.clinicalNameLabel} />
      </Form.Item>
    </>
  )
}
