import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
} from 'src/store/APIs/types'

export enum Endpoints {
  Me = '/cms/v1/users/me.json',
}

export interface MeResponse {
  user: BaseUserResponse & {
    email: string
    address?: string
    biography?: string
    full_name: string
    image: string | null
    phone_number?: string
  }
}

export interface Me {
  user: BaseUser & {
    email: string
    address?: string
    biography?: string
    full_name: string
    image?: string
    phone_number?: string
  }
}

export const meDeserializer = (data: MeResponse): Me => ({
  user: {
    ...baseUserDeserializer(data.user),
    address: data.user.address,
    biography: data.user.biography,
    email: data.user.email,
    full_name: data.user.full_name,
    image: data.user.image ?? undefined,
    phone_number: data.user.phone_number,
  },
})
