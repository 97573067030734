import { DatePickerType } from 'antd/es/date-picker'
import locale from 'antd/es/date-picker/locale/en_US'
import React from 'react'

import { DatePicker, DatePickerProps } from '../DatePicker'
import { strings } from './strings'

export const DateAndTimePicker: React.FC<DatePickerProps> = ({
  format = ['MM-DD-YYYY hh:mm A', 'MMDDYYYY hhmm a', 'MMDDYYYY hhmm A'],
  ...props
}) => {
  return (
    <DatePicker
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          now: strings.todayLabel,
          ok: strings.applyLabel,
        },
      }}
      format={format}
      showTime={{ use12Hours: true }}
      {...(props as DatePickerType)}
    />
  )
}
