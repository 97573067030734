import React from 'react'
import { AppLink, RouteName } from 'src/routes'
import { BaseUser, getRouteForUserType } from 'src/store/APIs/types'

export interface UserProps {
  user: BaseUser
}

export const UserHyperlink: React.FC<UserProps> = ({ user }) => {
  const routeName = getRouteForUserType(user.user_type)

  if (!user.permissions?.can_read) return <span>{user.full_name}</span>

  return routeName === RouteName.NotFound || !user.id ? (
    <span>{user.full_name}</span>
  ) : (
    <AppLink routeName={routeName} pathParams={{ id: user.id }}>
      {user.full_name}
    </AppLink>
  )
}
