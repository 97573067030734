import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addQuickJotButtonLabel: 'Add Quick Jot',
    createdAtLabel: 'Created at',
    createdByLabel: 'Created By',
    deletedAtLabel: 'Deleted at',
    discardedByLabel: 'Discarded By',
    jotLabel: 'Jot',
    resource: 'Quick Jot',
  },
})
