export enum SuggestedTodoDataIndex {
  'todo' = 'todo',
  'retail_products' = 'retail_products',
  'selected' = 'selected',
}

export enum SuggestedQuestionDataIndex {
  'question' = 'question',
  'selected' = 'selected',
}

export enum ContentDataIndex {
  'content' = 'content',
  'ssml_content' = 'ssml_content',
}
