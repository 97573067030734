import React from 'react'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddPurchaseTodoMutation,
  useDeletePurchaseTodoMutation,
  useLazyGetPurchaseTodoQuery,
  useLazyGetSurvivorsPurchaseTodosQuery,
  useUpdatePurchaseTodoMutation,
} from 'src/store/APIs/purchaseTodos'

import { AddPurchaseTodo } from './AddPurchaseTodo'
import { columns } from './columns'
import { strings } from './strings'
import { ViewPurchaseTodo } from './ViewPurchaseTodo'

interface PurchaseTodosProps {}

export const PurchaseTodos: React.FC<PurchaseTodosProps> = () => {
  const { open, openView, selectedId, onCloseDrawer, showDrawer } = useDrawer()
  const [triggerAddTodoMutation, { isLoading: loadingAdd }] =
    useAddPurchaseTodoMutation()
  const [triggerUpdateTodoMutation, { isLoading: loadingUpdate }] =
    useUpdatePurchaseTodoMutation()
  const [triggerDeleteTodoMutation] = useDeletePurchaseTodoMutation()
  const loadingRightButton = loadingAdd || loadingUpdate

  return (
    <>
      <DrawerWithState
        isLoadingRightButton={loadingRightButton}
        selectedId={selectedId}
        open={open}
        onClose={onCloseDrawer}
        resourceName={strings.resourceName}
        RenderAdd={AddPurchaseTodo}
        RenderView={ViewPurchaseTodo}
        useLazyGetResource={useLazyGetPurchaseTodoQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddTodoMutation}
        triggerEditResourceMutation={triggerUpdateTodoMutation}
        triggerDeleteResourceMutation={triggerDeleteTodoMutation}
        deleteModalTitle={strings.deleteModalTitle}
      />
      <Button type="primary" onClick={showDrawer}>
        {strings.addPurchaseTodoButtonLabel}
      </Button>
      <PaginatedTable
        columns={columns(openView)}
        useLazyQuery={useLazyGetSurvivorsPurchaseTodosQuery}
      />
    </>
  )
}
