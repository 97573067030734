import { BaseQueryApi } from '@reduxjs/toolkit/dist/query'

import { getCsrfTokenEndpointName } from './APIs/auth'
import { ReduxState } from './rootReducer'
import { selectHeaders } from './storage/headers'

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum ErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  PreconditionFailed = 412,
  ServerError = 500,
  NotFound = 404,
  Forbidden = 403,
}

export enum HttpStatus {
  Success = 200,
  Created = 201,
  NoContent = 204,
  Accepted = 202,
}

export enum GeneralTagIds {
  List = 'LIST',
  Individual = 'INDIVIDUAL',
  Units = 'UNITS',
}

export const prepareHeaders = (
  headers: Headers,
  {
    endpoint,
    getState,
  }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) => {
  if (endpoint !== getCsrfTokenEndpointName) {
    const selectedHeaders = selectHeaders(getState() as ReduxState)
    if (selectedHeaders && selectedHeaders.csrfToken) {
      headers.set('X-CSRF-Token', selectedHeaders.csrfToken)
    }
  }

  return headers
}
