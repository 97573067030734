import { LayoutType, withLayout } from 'src/hocs/withLayout'

import { DefaultNavigatorTasks } from './defaultNavigatorTasks'

const WrappedDefaultNavigatorTasks = withLayout(
  LayoutType.NoPadding,
  DefaultNavigatorTasks,
)

export { WrappedDefaultNavigatorTasks as DefaultNavigatorTasks }
