import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { getUserFullName } from 'src/common/helpers'
import { InfoTable } from 'src/components/InfoTable'
import { Table } from 'src/components/Table'
import { useDrawer } from 'src/hooks/useDrawer'
import { CarePartnerTabNames } from 'src/pages/CarePartner/types'
import { TabNames } from 'src/pages/SurvivorView/types'
import { AppLink, RouteName } from 'src/routes'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { Survivor } from 'src/store/APIs/survivors/types'
import { AssessmentDetail, AssessmentType } from 'src/store/APIs/types'

import {
  AssessmentStatusOptions,
  assessmentStatusProps,
} from '../AssessmentsView/types'
import { BreadcrumbParams } from '../Breadcrumb'
import { Button } from '../Button'
import { Spinner } from '../Spinner'
import { SubHeader } from '../SubHeader'
import { TableTitle } from '../TableTitle'
import { Tag } from '../Tag'
import { UserHyperlink } from '../UserHyperlink'
import styles from './assessmentDetails.module.scss'
import { columns } from './columns'
import { CompleteAssessmentDrawer } from './CompleteAssessmentDrawer'
import { promisGlobalScoreColumns } from './promisGlobalScoreColumns'
import { strings } from './strings'

interface AssessmentDetailsProps {
  useLazyQuery: UseLazyQuery<
    QueryDefinition<any, any, any, AssessmentDetail, any>
  >
  isCarePartner?: boolean
  user?: Survivor | CarePartner
}

export const AssessmentDetails: React.FC<AssessmentDetailsProps> = ({
  isCarePartner,
  user,
  useLazyQuery,
}) => {
  const { idAssessment, id } = useParams<{
    idAssessment: string
    id: string
  }>()

  const [triggerGetAssessmentDetails, { data, isFetching, isLoading }] =
    useLazyQuery()

  const { open, showDrawer, onCloseDrawer } = useDrawer()

  const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
    { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
    {
      breadcrumbName: isCarePartner
        ? generalStrings.carePartnersBreadcrumb
        : generalStrings.survivorsBreadcrumb,
      path: isCarePartner ? RouteName.CarePartners : RouteName.Survivors,
    },
    {
      breadcrumbName: getUserFullName(user?.first_name, user?.last_name),
      path: isCarePartner ? RouteName.CarePartner : RouteName.SurvivorView,
    },
  ]

  let communicatedWith
  if (data?.spoke_with_caregiver?.id && data?.spoke_with_survivor?.id) {
    communicatedWith = strings.communicatedWithBoth
  } else if (data?.spoke_with_survivor?.id) {
    communicatedWith = (
      <AppLink
        routeName={RouteName.SurvivorView}
        pathParams={{ id: data?.spoke_with_survivor?.id }}>
        {getUserFullName(
          data?.spoke_with_survivor?.first_name,
          data?.spoke_with_survivor?.last_name,
        )}
      </AppLink>
    )
  } else if (data?.spoke_with_caregiver) {
    communicatedWith = (
      <AppLink
        routeName={RouteName.CarePartner}
        pathParams={{ id: data?.spoke_with_caregiver.id }}>
        {getUserFullName(
          data?.spoke_with_caregiver?.first_name,
          data?.spoke_with_caregiver?.last_name,
        )}
      </AppLink>
    )
  }

  const rows = [
    {
      content: data?.title,
      label: strings.patientFacingName,
    },
    {
      content: (
        <AppLink
          routeName={
            isCarePartner ? RouteName.CarePartner : RouteName.SurvivorView
          }
          pathParams={{ id }}>
          {getUserFullName(user?.first_name, user?.last_name)}
        </AppLink>
      ),
      label: strings.assignedTo,
    },
    {
      content: data?.answered_by ? (
        <UserHyperlink user={data.answered_by} />
      ) : undefined,
      label: strings.completedBy,
    },
    {
      content: communicatedWith,
      label: strings.communicatedWith,
    },
    { content: data?.due_time, label: strings.dueDate },
    {
      content: data?.answered_date,
      label: strings.completedDate,
    },
    {
      content: data ? (
        <Tag color={assessmentStatusProps(data.status).color as string}>
          {assessmentStatusProps(data.status).title}
        </Tag>
      ) : undefined,
      label: strings.status,
    },
  ]

  const scoreRows = [
    {
      content: data?.score?.final_score,
      label: strings.finalScore,
    },
    {
      content: data?.score?.explanation,
      label: strings.scoreExplanation,
    },
    {
      content: data?.score?.recommendation ? (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data.score?.recommendation }}
          className={styles.title}
        />
      ) : undefined,
      label: strings.recommendation,
    },
  ]

  const extraPromisGlobalRows = [
    {
      content: data?.score?.promis_global_score ? (
        <>
          <div>
            {data.score.promis_global_score[1].category} {strings.t_score}
          </div>
          <div className={styles.textSeparator}>
            {data.score.promis_global_score[1].explanation}
          </div>
          <div>
            {data.score.promis_global_score[0].category} {strings.t_score}
          </div>
          <div>{data.score.promis_global_score[0].explanation}</div>
        </>
      ) : undefined,
      label: strings.scoreExplanation,
    },
    {
      content: data?.score?.promis_global_score ? (
        <>
          <div className={styles.textSeparator}>
            {data.score.promis_global_score[1].recommendation}
          </div>
          <div>{data.score.promis_global_score[0].recommendation}</div>
        </>
      ) : undefined,
      label: strings.recommendation,
    },
  ]

  const assessmentsTab = isCarePartner
    ? CarePartnerTabNames.assessments
    : TabNames.assessments

  const breadcrumbParams: BreadcrumbParams = {
    pathParams: [{}, {}, { id }],
    queryParams: [{}, {}, { tab: assessmentsTab }],
  }

  useEffect(() => {
    triggerGetAssessmentDetails({ idAssessment, idUser: id })
  }, [])

  if (isLoading) return <Spinner />

  return (
    <>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        params={breadcrumbParams}
        title={data?.accomplish_assessment.name}>
        <Button
          onClick={showDrawer}
          type="primary"
          disabled={data?.status === AssessmentStatusOptions.Completed}>
          {strings.completeAssessmentLabel}
        </Button>
      </SubHeader>
      <CompleteAssessmentDrawer
        open={open}
        user={user}
        onCloseDrawer={onCloseDrawer}
        accomplishAssessmentId={data?.accomplish_assessment.id}
      />
      <div className={styles.container}>
        <InfoTable rows={rows} />
        {data?.accomplish_assessment.name === AssessmentType.PromisGlobal ? (
          <>
            <Table
              dataSource={data?.score?.promis_global_score}
              columns={promisGlobalScoreColumns}
              bordered
              loading={isFetching}
              pagination={false}
              rowKey={record => record.id}
              caption={<TableTitle title={strings.score} />}
              className={styles.table}
            />
            <InfoTable
              rows={extraPromisGlobalRows}
              title={
                <div className={styles.tableTitle}>
                  {strings.extraPromisGlobalTableTitle}
                </div>
              }
              className={styles.table}
            />
          </>
        ) : (
          <InfoTable
            rows={scoreRows}
            title={<div className={styles.tableTitle}>{strings.score}</div>}
            className={styles.table}
          />
        )}
        <Table
          dataSource={data?.responses}
          columns={columns}
          bordered
          loading={isFetching}
          pagination={false}
          rowKey={record => record.id}
          caption={<TableTitle title={strings.answersTitle} />}
          className={styles.table}
        />
      </div>
    </>
  )
}
