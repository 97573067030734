import { useEffect, useMemo, useRef } from 'react'
import { useUploadMediaMutation } from 'src/store/APIs/articles'
import { MediaType } from 'src/store/APIs/articles/types'

export const useUploadMedia = () => {
  const [
    triggerUploadMedia,
    { isSuccess, data: media, isLoading: isLoadingMedia },
  ] = useUploadMediaMutation()

  const handlers = useMemo(
    () => ({
      image: () => {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*|video/*')
        input.click()
        input.onchange = async () => {
          const file = input.files
          if (file !== null) {
            triggerUploadMedia({ file })
          }
        }
      },
    }),
    [],
  )

  const quillRef = useRef<any>()
  const editor = quillRef.current?.getEditor()

  useEffect(() => {
    if (isSuccess && editor) {
      editor.insertEmbed(
        editor.getSelection()?.index ?? 0,
        media?.type ?? MediaType.Image,
        media?.location,
      )
    }
  }, [isSuccess])

  return {
    handlers,
    isLoadingMedia,
    quillRef,
  }
}
