import { SearchOutlined } from '@ant-design/icons'
import { Input, InputRef, Space } from 'antd'
import { ColumnType } from 'antd/es/table/interface'
import React, { useRef } from 'react'
import { Button } from 'src/components/Button'

import styles from './columnSearch.module.scss'
import { strings } from './strings'

type FunctionVoid = () => void

export const useColumnSearch = () => {
  const searchInput = useRef<InputRef>(null)

  const handleReset =
    (clearFilters: FunctionVoid, confirm: FunctionVoid) => () => {
      clearFilters()
      confirm()
    }

  const handleFilterDropdownVisibleChange = (visible: boolean) => {
    if (visible && searchInput) {
      setTimeout(() => {
        searchInput.current?.select()
      }, 100)
    }
  }

  function generateColumnSearchProps<T extends Record<string, any>>(
    dataIndex: string,
  ): ColumnType<T> {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div className={styles.searchContainer}>
          <Input
            ref={searchInput}
            placeholder={strings
              .formatString(strings.searchPlaceholder, dataIndex)
              .toString()
              .replaceAll(/[._]/g, ' ')}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            className={styles.searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              className={styles.filterButton}>
              {strings.searchLabel}
            </Button>
            <Button
              onClick={handleReset(clearFilters, confirm)}
              size="small"
              className={styles.filterButton}>
              {strings.resetLabel}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined className={filtered ? styles.iconFiltered : ''} />
      ),
      onFilter: (value, record) =>
        record?.[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()) || false,
      onFilterDropdownOpenChange: handleFilterDropdownVisibleChange,
    }
  }

  return {
    generateColumnSearchProps,
  }
}
