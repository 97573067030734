import { Dayjs } from 'dayjs'
import { ExtendedArticleInfo } from 'src/store/APIs/articles/types'
import { Entity, Languages, LearnStatus } from 'src/store/APIs/types'

import { AudienceOptions } from '../Articles/types'

export enum PackagesDataIndex {
  title = 'title',
  created_at = 'created_at',
  status = 'status',
  audience = 'audience',
  internal_id = 'internal_id',
  internal_name = 'internal_name',
}

export interface PackageForm extends Entity {
  new_status?: LearnStatus
  unit_id?: string
  assign_to_user?: string
  deployment_date?: Dayjs
  articles: ExtendedArticleInfo[]
  title: string
  internal_name: string
  internal_id: string
  audience: AudienceOptions
  created_at: string
  image: {
    file: File
  }
  article_select: string
  language: Languages
}

export interface LearnAddError {
  data: {
    errors: {
      internal_id?: string[]
    }
  }
}

export interface AssignPackage {
  assign_to_user: string
  package: string
  deployment_date: Dayjs
}
