import React from 'react'
import { Conversation } from 'src/components/Conversation'
import {
  useDeleteSurvivorsConversationMessageMutation,
  useEditSurvivorsConversationMessageMutation,
  useLazyGetSurvivorsConversationQuery,
  useSendSurvivorsConversationMessageMutation,
} from 'src/store/APIs/survivors'
import { ConversationType } from 'src/store/APIs/types'

import styles from './survivorConversations.module.scss'

interface SurvivorConversationProps {
  type: ConversationType
}

export const SurvivorConversation: React.FC<SurvivorConversationProps> = ({
  type,
}) => (
  <div className={styles.container}>
    <Conversation
      type={type}
      useLazyGetConversationQuery={useLazyGetSurvivorsConversationQuery}
      useSendConversationMessageMutation={
        useSendSurvivorsConversationMessageMutation
      }
      useEditConversationMessageMutation={
        useEditSurvivorsConversationMessageMutation
      }
      useDeleteConversationMessageMutation={
        useDeleteSurvivorsConversationMessageMutation
      }
    />
  </div>
)
