import {
  Breadcrumb as AntBreadcrumb,
  BreadcrumbProps as AntBreadcrumProps,
} from 'antd'
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { RouteName } from 'src/routes'
import { AppLink } from 'src/routes/app-link'
import { Params } from 'src/routes/route-helpers'

export type BreadcrumbParams = { pathParams?: Params[]; queryParams?: Params[] }

interface BreadcrumbProps extends AntBreadcrumProps {}

export const Breadcrumb: React.FC<BreadcrumbProps> = props => {
  const itemRender: (
    route: BreadcrumbItemType,
    params: BreadcrumbParams,
    routes: BreadcrumbItemType[],
    paths: string[],
  ) => React.ReactNode = (route, params, routes) => (
    <AppLink
      routeName={route.path as RouteName}
      pathParams={
        params?.pathParams
          ? params.pathParams[routes.indexOf(route)]
          : undefined
      }
      queryParams={
        params?.queryParams
          ? params.queryParams[routes.indexOf(route)]
          : undefined
      }>
      {route.breadcrumbName}
    </AppLink>
  )
  return <AntBreadcrumb {...props} itemRender={itemRender} />
}
