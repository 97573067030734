import { ThemeConfig } from 'antd/es/config-provider/context'

import colors from './colors.scss'

// NOTE: unused. But we will probably need it in the future
export const Theme: ThemeConfig = {
  token: {
    colorPrimary: colors.primary_50,
  },
}
