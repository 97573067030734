import React from 'react'
import { formatShortDateToRender } from 'src/common/types'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import { InfoTable } from 'src/components/InfoTable'
import { Tag } from 'src/components/Tag'
import { format } from 'util'

import { strings } from '../strings'
import { statusProps, TypeOptions, typeProps } from '../types'

interface ViewNavigatorTaskProps extends DrawerRenderProps {}

export const ViewNavigatorTask: React.FC<ViewNavigatorTaskProps> = ({
  data,
}) => {
  const rows = [
    {
      content: data?.description,
      label: strings.taskLabel,
    },
    {
      content: data?.status && (
        <Tag color={statusProps(data.status).color as string}>
          {statusProps(data.status).title}
        </Tag>
      ),
      label: strings.statusLabel,
    },
    {
      content: data?.type_of && (
        <Tag color={typeProps(data.type_of as TypeOptions).color as string}>
          {typeProps(data.type_of as TypeOptions).title}
        </Tag>
      ),
      label: strings.typeLabel,
    },
    {
      content: formatShortDateToRender(data?.goal_date),
      label: strings.goalDateLabel,
    },
    {
      content: formatShortDateToRender(data?.completed_date),
      label: strings.completedDateLabel,
    },
    {
      content: data?.time_spent && (
        <div>
          {format(strings.timeSpentValue, parseInt(data.time_spent, 10))}
        </div>
      ),
      label: strings.timeSpentLabel,
    },
    {
      // eslint-disable-next-line react/no-danger
      content: <div dangerouslySetInnerHTML={{ __html: data?.notes ?? '' }} />,
      label: strings.notesLabel,
    },
  ]

  return <InfoTable rows={rows} />
}
