import { FormInstance } from 'antd'
import { useEffect } from 'react'
import { transformPhoneNumber } from 'src/common/helpers'
import { textNotEmptyValidator } from 'src/common/validators'

export const usePhone = (
  initialValue?: string,
  form?: FormInstance,
  itemName?: string,
) => {
  const updatePhone = (value?: string) => {
    const phoneNumber = transformPhoneNumber(value)
    form?.setFieldValue(itemName ?? '', phoneNumber)
  }

  useEffect(() => {
    if (!textNotEmptyValidator(initialValue) || initialValue?.includes('(')) {
      return
    }
    const newPhoneNumber = `(${initialValue?.slice(
      2,
      5,
    )}) ${initialValue?.slice(5, 8)}-${initialValue?.slice(8, 12)}`
    form?.setFieldValue(itemName ?? '', newPhoneNumber)
  }, [initialValue])

  return { updatePhone }
}
