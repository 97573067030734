import { baseApi } from 'src/store/APIs'

import {
  addHealthCondition,
  deleteHealthCondition,
  getHealthCondition,
  getHealthConditions,
  getSurvivorsHealthConditions,
  updateHealthCondition,
  updateSurvivorsHealthConditions,
} from './actions'

export const healthConditionsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addHealthCondition: addHealthCondition(builder),
    deleteHealthCondition: deleteHealthCondition(builder),
    getHealthCondition: getHealthCondition(builder),
    getHealthConditions: getHealthConditions(builder),
    getSurvivorsHealthConditions: getSurvivorsHealthConditions(builder),
    updateHealthCondition: updateHealthCondition(builder),
    updateSurvivorsHealthConditions: updateSurvivorsHealthConditions(builder),
  }),
})

export const {
  useUpdateHealthConditionMutation,
  useDeleteHealthConditionMutation,
  useLazyGetHealthConditionQuery,
  useAddHealthConditionMutation,
  useUpdateSurvivorsHealthConditionsMutation,
  useLazyGetSurvivorsHealthConditionsQuery,
  useGetSurvivorsHealthConditionsQuery,
  useGetHealthConditionsQuery,
  useLazyGetHealthConditionsQuery,
  reducer: healthConditionsApiReducer,
  reducerPath: healthConditionsApiReducerPath,
} = healthConditionsApi
