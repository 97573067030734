import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addImpairmentButton: 'Add Impairment',
    clinicalName: 'Clinical Name',
    createdAtColumn: 'Created At',
    deleteModalTitle: 'Are you sure you want to delete this impairment?',
    impairmentDrawerTitle: 'Impairment',
    impairmentsTitle: 'Impairments',
    nameColumn: 'Name',
    updatedAtColumn: 'Updated At',
  },
})
