import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  Tags,
} from 'src/store/APIs/types'

import { Endpoints, getUsersResponseDeserializer, User } from './types'

export const getUsers = (builder: Builder) =>
  builder.query<PaginatedItems<User>, ParamsWithFilters>({
    providesTags: () => [{ type: Tags.Users }],
    query: endpointWithFilters(Endpoints.GetUsers),
    transformResponse: getUsersResponseDeserializer,
  })
