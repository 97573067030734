import React from 'react'
import { Conversation } from 'src/components/Conversation'
import {
  useDeleteCarePartnersConversationMessageMutation,
  useEditCarePartnersConversationMessageMutation,
  useLazyGetCarePartnersConversationQuery,
  useSendCarePartnerConversationMessageMutation,
} from 'src/store/APIs/carePartners'
import { ConversationType } from 'src/store/APIs/types'

interface CarePartnerConversationProps {
  type: ConversationType
}

export const CarePartnerConversation: React.FC<
  CarePartnerConversationProps
> = ({ type }) => (
  <Conversation
    type={type}
    useLazyGetConversationQuery={useLazyGetCarePartnersConversationQuery}
    useSendConversationMessageMutation={
      useSendCarePartnerConversationMessageMutation
    }
    useEditConversationMessageMutation={
      useEditCarePartnersConversationMessageMutation
    }
    useDeleteConversationMessageMutation={
      useDeleteCarePartnersConversationMessageMutation
    }
    isCarePartner
  />
)
