import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { InfoTable, InfoTableRow } from 'src/components/InfoTable'
import { Permissions } from 'src/store/APIs/types'

import { strings } from './strings'

interface BasicItem {
  id: string
  name: string
  created_at: string
  updated_at: string
  permissions: Permissions
}

interface ViewBasicItemProps {
  data?: BasicItem
}

export const ViewBasicItem: React.FC<ViewBasicItemProps> = ({ data }) => {
  const contentStyle = { width: '80%' }

  const rows: InfoTableRow[] = [
    {
      content: data?.name,
      label: strings.nameColumn,
    },
    {
      content: data?.created_at
        ? deserializeShortFormattedDate(data?.created_at)
        : undefined,
      label: strings.createdAtColumn,
    },
    {
      content: data?.updated_at
        ? deserializeShortFormattedDate(data?.updated_at)
        : undefined,
      label: strings.updatedAtColumn,
    },
  ]
  return <InfoTable rows={rows} contentStyle={contentStyle} />
}
