import { Input, Tooltip } from 'antd'
import { TextAreaProps } from 'antd/es/input'
import React from 'react'
import colors from 'src/assets/stylesheets/colors.scss'

import styles from './multilineTextInput.module.scss'

interface MultilineTextInputProps extends TextAreaProps {
  label?: string
  quillHeader?: boolean
  tooltipTitle?: string
}

export const MultilineTextInput: React.FC<MultilineTextInputProps> = ({
  label,
  placeholder,
  required,
  quillHeader,
  rows = 3,
  tooltipTitle = '',
  ...props
}) => {
  const { TextArea } = Input
  const headerStyle = {
    borderColor: props['aria-invalid'] ? colors.red_5 : colors.surface_30,
  }
  return (
    <>
      <div>
        {label}
        {required && '*'}
      </div>
      {quillHeader && (
        <div className={styles.quillHeader} style={headerStyle} />
      )}
      <Tooltip title={tooltipTitle}>
        <TextArea
          {...props}
          required={required}
          placeholder={placeholder === undefined ? label : placeholder}
          rows={rows}
        />
      </Tooltip>
    </>
  )
}
