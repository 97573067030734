import colors from 'src/assets/stylesheets/colors.scss'

export const modalIconStyle = {
  color: colors.red_7,
}

export const textAreaStyle = {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  height: 400,
  resize: 'none',
}
