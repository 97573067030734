import { baseApi } from 'src/store/APIs'

import {
  addHospital,
  deleteHospital,
  getHospital,
  getHospitals,
  updateHospital,
} from './actions'

export const hospitalsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addHospital: addHospital(builder),
    deleteHospital: deleteHospital(builder),
    getHospital: getHospital(builder),
    getHospitals: getHospitals(builder),
    updateHospital: updateHospital(builder),
  }),
})

export const {
  useAddHospitalMutation,
  useUpdateHospitalMutation,
  useDeleteHospitalMutation,
  useLazyGetHospitalsQuery,
  useLazyGetHospitalQuery,
  reducer: hospitalsApiReducer,
  reducerPath: hospitalsApiReducerPath,
} = hospitalsApi
