import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import { richTextDefaultText } from 'src/components/RichTextEditor'
import * as yup from 'yup'

import { strings } from '../strings'

const schema = yup.object({
  encounter_date: yup.date().required(strings.requiredValidation).nullable(),
  participants_ids: yup.array().required(strings.requiredValidation),
  status: yup.string().required(strings.requiredValidation),
  summary: yup
    .string()
    .notOneOf([richTextDefaultText], strings.requiredValidation)
    .required(strings.requiredValidation),
  title: yup.string().required(strings.requiredValidation),
  topic: yup.string().required(strings.requiredValidation),
  type_of: yup.array().required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
