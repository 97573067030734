import { transformPhoneNumber } from 'src/common/helpers'
import { CustomDate, optionalDateDeserializer } from 'src/common/types'

import {
  Entity,
  Identifiable,
  PaginatedItems,
  PaginatedModel,
  PaginationResponse,
} from '../types'

export enum Endpoints {
  HealthcareTeamRoles = '/cms/v1/global_settings/health_care_roles.json',
  GetSurvivorHealthcareTeam = '/cms/v1/mobile_survivors/%s/profile/health_care_teams.json?page=%s',
  UpdateSurvivorHealthcareTeam = '/cms/v1/mobile_survivors/%s/profile/health_care_teams/batch_put.json',
  AddHealthcareTeamRole = '/cms/v1/global_settings/health_care_roles.json',
  GetHealthcareTeamRole = '/cms/v1/global_settings/health_care_roles/%s.json',
  DeleteHealthcareTeamRole = '/cms/v1/global_settings/health_care_roles/%s.json',
  EditHealthcareTeamRole = '/cms/v1/global_settings/health_care_roles/%s.json',
}

export interface PaginatedHealthcareTeamRoleResponse extends PaginatedModel {
  health_care_roles: HealthcareTeamRoleResponse[]
}

export interface HealthcareTeamRoleResponse extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export interface HealthcareTeamRoleData extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export const healthcareRoleDeserializer = (
  data: HealthcareTeamRoleResponse,
): HealthcareTeamRoleData => {
  return {
    ...data,
  }
}

export const healthcareRolesDeserializer = (
  data: PaginatedHealthcareTeamRoleResponse,
): PaginatedItems<HealthcareTeamRoleData> => ({
  items: data.health_care_roles.map(healthcareRoleDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

export interface PaginatedSurvivorHealthcareTeamResponse {
  health_care_team: GetSurvivorHealthcareTeamResponse[]
  pagination: PaginationResponse
}

export interface GetSurvivorHealthcareTeamData extends Identifiable {
  id: string
  role_name: string
  other_role?: string
  first_name: string
  last_name: string
  affiliated_organization?: string
  phone_number?: string
  last_visit_date?: CustomDate
  last_visit_summary?: string
  additional_comments?: string
  health_care_role_id: string
}

export const getSurvivorHealthcareTeamDeserializer = (
  data: GetSurvivorHealthcareTeamResponse,
): GetSurvivorHealthcareTeamData => {
  return {
    additional_comments: data.additional_comments ?? undefined,
    affiliated_organization: data.affiliated_organization ?? undefined,
    first_name: data.first_name ?? undefined,
    health_care_role_id: data.health_care_role?.id ?? data.health_care_role_id,
    id: data.id,
    last_name: data.last_name,
    last_visit_date: optionalDateDeserializer(data.last_visit_date),
    last_visit_summary: data.last_visit_summary ?? undefined,
    other_role: data.other_role ?? undefined,
    phone_number:
      transformPhoneNumber(data.phone_number ?? undefined) ?? undefined,
    role_name: data.health_care_role?.name,
  }
}

export const getSurvivorHealthcareTeamsDeserializer = (
  data: PaginatedSurvivorHealthcareTeamResponse,
): PaginatedItems<GetSurvivorHealthcareTeamData> => ({
  items: data.health_care_team.map(getSurvivorHealthcareTeamDeserializer),
  pagination: data.pagination,
})

export interface GetSurvivorHealthcareTeamResponse {
  id: string
  created_at: string
  additional_comments: string | null
  affiliated_organization: string | null
  first_name: string
  last_name: string
  last_visit_date: string | null
  health_care_role: {
    id: string
    name: string
  }
  updated_at: string
  health_care_role_id: string | null
  last_visit_summary: string | null
  mobile_survivor_id: string | null
  other_role: string | null
  phone_number: string | null
}
