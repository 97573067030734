import { LoadingOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Form, Row } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import React, { useEffect } from 'react'
import { DeleteButton } from 'src/components/DeleteButton'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import { SearchSelect } from 'src/components/SearchSelect'
import { Select } from 'src/components/Select'
import { Table } from 'src/components/Table'
import { TextInput } from 'src/components/TextInput'
import { usePrevious } from 'src/hooks/usePrevious'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import { CompletionStatusOptions } from 'src/pages/SurvivorView/Learn/types'
import { useLazyGetArticlesQuery } from 'src/store/APIs/articles'
import { ExtendedArticleInfo } from 'src/store/APIs/articles/types'
import { useLazyGetMobileUsersQuery } from 'src/store/APIs/mobileUsers'
import {
  languageOptions,
  MobileUserType,
  SelectOption,
} from 'src/store/APIs/types'
import { clearAddPackageErrorAction } from 'src/store/storage/error'
import { selectAddPackageError } from 'src/store/storage/error/selectors'

import {
  AudienceOptions,
  audienceOptions as defaultAudienceOptions,
} from '../../Articles/types'
import { useInternalIdValidation } from '../../hooks/useInternalIdValidation'
import { useSearchLearnSelect } from '../../hooks/useSearchLearnSelect'
import { AddImage } from './AddImage'
import styles from './addPackage.module.scss'
import { AssignToUserRow } from './AssignToUserRow'
import { columns, TableRow } from './columns'
import { useArticleSelectTable } from './hooks/useArticleSelectTable'
import { strings } from './strings'
import { yupSync } from './validations'

interface AddPackageProps extends DrawerRenderProps {
  fixedUser?: SelectOption
  audienceOptions?: SelectOption[]
  timezone?: string
  fixedLanguage?: string
}

export const AddPackage: React.FC<AddPackageProps> = ({
  fixedUser,
  audienceOptions = defaultAudienceOptions,
  data,
  timezone,
  fixedLanguage,
}) => {
  const form = useFormInstance()
  const selectedUserId = Form.useWatch('assign_to_user', form)
  const audience = Form.useWatch('audience', form)
  const unit = data?.mobile_user_lessons[0]
  const mobileUser = unit?.mobile_user
  const selectedLanguage = fixedLanguage ?? Form.useWatch('language', form)

  let otherFilters
  if (audience === AudienceOptions.Survivor) {
    otherFilters = {
      user_type: [MobileUserType.Survivor],
    }
  } else if (audience === AudienceOptions.CarePartner) {
    otherFilters = {
      user_type: [MobileUserType.CarePartner],
    }
  }
  if (selectedLanguage) {
    otherFilters = {
      ...otherFilters,
      content_language: [selectedLanguage],
    }
  }

  const {
    fetchedFilteredElems: fetchedFilteredElemsUsers,
    optionsSelect: optionsSelectUsers,
  } = useSearchOptionsSelect(
    useLazyGetMobileUsersQuery,
    undefined,
    'full_name',
    otherFilters,
    [audience, selectedLanguage],
  )

  const selectedUser = optionsSelectUsers?.find(
    item => item.value === selectedUserId,
  )

  const user =
    fixedUser ||
    (mobileUser && {
      label: mobileUser?.full_name,
      value: mobileUser?.id,
    })
  const isChild = !!mobileUser

  const isDeployed =
    unit?.status && unit?.status !== CompletionStatusOptions.Scheduled

  const previousAudience = usePrevious(audience)

  const previousLanguage = usePrevious(selectedLanguage)

  const {
    dataSource,
    onDragEnd,
    rowSelection,
    disabledDeleteButton,
    onClickDeleteColumn,
    onOkDeleteSelected,
    loading,
  } = useArticleSelectTable(isChild)

  const { clearAddError: clearAddPackageError } = useInternalIdValidation(
    clearAddPackageErrorAction,
    selectAddPackageError,
  )

  const {
    fetchedFilteredElems: fetchedFilteredElemsArticles,
    optionsSelect: optionsSelectArticles,
    reloadingKey,
  } = useSearchLearnSelect(useLazyGetArticlesQuery, audience, selectedLanguage)

  useEffect(() => {
    if (audience && !user) {
      form.setFieldValue('assign_to_user', undefined)
    }
    if (previousAudience && audience !== previousAudience) {
      form.setFieldValue('articles', [])
    }
  }, [audience])

  useEffect(() => {
    if (selectedLanguage && !user) {
      form.setFieldValue('assign_to_user', undefined)
    }
    if (previousLanguage && selectedLanguage !== previousLanguage) {
      form.setFieldValue('articles', [])
    }
  }, [selectedLanguage])

  return (
    <>
      <Form.Item name="title" rules={[yupSync]}>
        <TextInput
          placeholder={strings.titleLabel}
          label={strings.titleLabel}
          required
        />
      </Form.Item>
      <Form.Item name="internal_name" rules={[yupSync]}>
        <TextInput
          placeholder={strings.internalNameLabel}
          label={strings.internalNameLabel}
          required
        />
      </Form.Item>
      <Row className={styles.row}>
        <Form.Item
          name="audience"
          rules={[yupSync]}
          className={styles.rowInput}>
          <Select
            disabled={isChild}
            options={audienceOptions}
            placeholder={strings.audienceLabel}
            label={strings.audienceLabel}
            required
          />
        </Form.Item>
        <Form.Item
          name="internal_id"
          rules={[yupSync]}
          className={styles.rowInput}>
          <TextInput
            onFocus={clearAddPackageError}
            label={strings.internalIdLabel}
            placeholder={strings.internalIdLabel}
            required
          />
        </Form.Item>
        <Form.Item
          name="language"
          rules={[yupSync]}
          className={styles.rowInput}
          initialValue={fixedLanguage}>
          <Select
            disabled={isChild || !!fixedLanguage}
            options={languageOptions}
            placeholder={strings.languageLabel}
            label={strings.languageLabel}
            required
          />
        </Form.Item>
      </Row>
      <Row>
        <p className={styles.imageLabel}>{strings.imageLabel}</p>
        <p className={styles.optionalLabel}>{strings.optionalLabel}</p>
      </Row>
      <Form.Item name="image">
        <AddImage initialImageURL={data?.image} />
      </Form.Item>
      <Form.Item name="articles" hidden initialValue={[]} />
      <Form.Item name="article_select">
        <SearchSelect
          key={reloadingKey}
          options={optionsSelectArticles}
          fetchOptions={fetchedFilteredElemsArticles}
          placeholder={strings.articlesLabel}
          label={strings.articlesLabel}
          disabled={!selectedLanguage || !audience}
        />
      </Form.Item>
      {loading && <LoadingOutlined className={styles.loading} />}
      {dataSource?.length > 0 && (
        <>
          <DeleteButton
            onOk={onOkDeleteSelected}
            modalTitle={
              isChild
                ? strings.unassignSelectedModalTitle
                : strings.deleteSelectedModalTitle
            }
            disabled={disabledDeleteButton}
            className={styles.deleteButton}
            size="small"
            danger>
            {isChild
              ? strings.unassignSelectedLabel
              : strings.deleteSelectedLabel}
          </DeleteButton>
          <DndContext
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            onDragEnd={onDragEnd}>
            <SortableContext
              items={dataSource?.map((i: ExtendedArticleInfo) => i.internal_id)}
              strategy={verticalListSortingStrategy}>
              <Table
                pagination={false}
                className={styles.table}
                components={{
                  body: {
                    row: TableRow,
                  },
                }}
                rowSelection={rowSelection}
                bordered
                rowKey="internal_id"
                columns={columns(
                  onClickDeleteColumn,
                  !!mobileUser,
                  unit?.status,
                )}
                dataSource={dataSource}
              />
            </SortableContext>
          </DndContext>
        </>
      )}
      <Form.Item name="unit_id" hidden initialValue={unit?.id} />
      <AssignToUserRow
        isDeployed={isDeployed}
        deploymentDate={data?.mobile_user_lessons[0]?.deployment_date}
        fixedUser={user}
        disabledSelectUser={!audience || !selectedLanguage}
        fetchedFilteredElemsUsers={fetchedFilteredElemsUsers}
        optionsSelectUsers={optionsSelectUsers}
        selectedUser={selectedUserId}
        key={reloadingKey}
        timezone={
          timezone || unit?.mobile_user_timezone || selectedUser?.time_zone
        }
      />
    </>
  )
}
