import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  password: yup
    .string()
    .required(strings.emptyPasswordValidation)
    .min(8, strings.passwordMinCharacters)
    .test('contains-uppercase', strings.passwordUppercase, value =>
      value ? /[A-Z]/.test(value) : true,
    )
    .test('contains-lowercase', strings.passwordLowercase, value =>
      value ? /[a-z]/.test(value) : true,
    )
    .matches(/[0-9]/, strings.passwordNumberValidation),
  password_confirmation: yup
    .string()
    .required(strings.passwordConfirmationRequired),
})

export const yupSync: Rule = yupSyncGenerator(schema)
