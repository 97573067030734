import { format } from 'util'

import {
  Builder,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  Tags,
} from '../types'
import {
  Endpoints,
  getSurvivorQuickJotsDeserializer,
  QuickJot,
  quickJotDeserializer,
  QuickJotDetails,
} from './types'

export const getSurvivorQuickJots = (builder: Builder) =>
  builder.query<PaginatedItems<QuickJot>, PaginatedParams>({
    providesTags: () => [{ type: Tags.QuickJots }],
    query: paginatedParams(Endpoints.GetSurvivorsQuickJots),
    transformResponse: getSurvivorQuickJotsDeserializer,
  })

export const getQuickJot = (builder: Builder) =>
  builder.query<QuickJotDetails, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.QuickJots }],
    query: ({ id }) => format(Endpoints.GetQuickJot, id),
    transformResponse: ({ quick_jot }) => quickJotDeserializer(quick_jot),
  })
