const fetchVariable = (varName: string, defaultValue?: string) => {
  if (!process.env[varName] && typeof defaultValue === 'undefined') {
    throw new Error(`Mandatory environment variable ${varName} is not set.`)
  }
  return process.env[varName] || defaultValue
}

const nodeEnv = fetchVariable('NODE_ENV')
const environment = {
  isDevelopment: nodeEnv === 'development',
  isProduction: nodeEnv === 'production',
}

export const constants = {
  addUserEnabled:
    fetchVariable('REACT_APP_ADD_USER_ENABLED', 'false') !== 'false',
  apiBaseURL: fetchVariable(
    'REACT_APP_API_BASE_URL',
    'https://sta.dashboard.kanduhealth.com',
  ),
  environment,
  gleapToken: fetchVariable('REACT_APP_GLEAP_TOKEN'),
  publicURL: fetchVariable('PUBLIC_URL', ''),
}
