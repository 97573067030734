import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    cancelButtonLabel: 'Cancel',
    disabledInputTooltip: 'Please edit this field in Tellescope',
    originatingHospitalName: 'Originating Hospital Name',
    patientMrn: 'Patient MRN',
    primaryPhysicianName: 'Primary Physician Name',
    requiredValidation: 'This field is required',
    updateButtonLabel: 'Update',
  },
})
