import { createSlice } from '@reduxjs/toolkit'
import { articlesApi } from 'src/store/APIs/articles'
import { packagesApi } from 'src/store/APIs/packages'

import {
  clearAddArticleError,
  clearAddPackageError,
  clearAudioError,
  clearError,
  setError,
} from './actions'
import { initialState } from './types'

const errorSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(
      articlesApi.endpoints.getAudio.matchRejected,
      (state, action) => {
        state.audioError = action.payload
      },
    )
    builder.addMatcher(
      articlesApi.endpoints.addArticle.matchRejected,
      (state, action) => {
        state.addArticleError = action.payload
      },
    )
    builder.addMatcher(
      packagesApi.endpoints.addPackage.matchRejected,
      (state, action) => {
        state.addPackageError = action.payload
      },
    )
    builder.addMatcher(
      packagesApi.endpoints.updatePackage.matchRejected,
      (state, action) => {
        state.addPackageError = action.payload
      },
    )
  },
  initialState,
  name: 'error',
  reducers: {
    clearAddArticleErrorAction: clearAddArticleError,
    clearAddPackageErrorAction: clearAddPackageError,
    clearAudioErrorAction: clearAudioError,
    clearErrorAction: clearError,
    setErrorAction: setError,
  },
})

export const {
  actions: {
    clearErrorAction,
    setErrorAction,
    clearAudioErrorAction,
    clearAddArticleErrorAction,
    clearAddPackageErrorAction,
  },
  reducer: errorReducer,
} = errorSlice
