/* eslint-disable sort-keys */
import { baseApi } from 'src/store/APIs'

import {
  assignCarePartner,
  changeUserStatus,
  deleteSurvivor,
  deleteSurvivorsConversationMessage,
  editSurvivorsConversationMessage,
  getAlertsByTab,
  getSurvivor,
  getSurvivorConversation,
  getSurvivorGroups,
  getSurvivorPlanOfCare,
  getSurvivors,
  getSurvivorsAlerts,
  getUnits,
  newSurvivor,
  notifyUncompletedLesson,
  resetPassword,
  sendAppInstallEmail,
  sendAppInstallSMS,
  sendConversationMessage,
  sendGraduationEmail,
  sendSignUpEmail,
  sendSignUpSMS,
  sendWelcomeEmail,
  updateSurvivor,
  updateSurvivorPlanOfCare,
  updateSurvivorsLearnTrack,
} from './actions'

export const survivorsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    assignCarePartner: assignCarePartner(builder),
    changeSurvivorStatus: changeUserStatus(builder),
    deleteSurvivor: deleteSurvivor(builder),
    deleteSurvivorsConversationMessage:
      deleteSurvivorsConversationMessage(builder),
    editSurvivorsConversationMessage: editSurvivorsConversationMessage(builder),
    getSurvivor: getSurvivor(builder),
    getSurvivorGroups: getSurvivorGroups(builder),
    getSurvivorPlanOfCare: getSurvivorPlanOfCare(builder),
    getSurvivors: getSurvivors(builder),
    getSurvivorsAlerts: getSurvivorsAlerts(builder),
    getSurvivorsAlertsByTab: getAlertsByTab(builder),
    getSurvivorsConversation: getSurvivorConversation(builder),
    getSurvivorsUnits: getUnits(builder),
    newSurvivor: newSurvivor(builder),
    notifySurvivorUncompletedLesson: notifyUncompletedLesson(builder),
    resetSurvivorsPassword: resetPassword(builder),
    sendSurvivorGraduationEmail: sendGraduationEmail(builder),
    sendSurvivorWelcomeEmail: sendWelcomeEmail(builder),
    sendSurvivorsAppInstallEmail: sendAppInstallEmail(builder),
    sendSurvivorsAppInstallSMS: sendAppInstallSMS(builder),
    sendSurvivorsConversationMessage: sendConversationMessage(builder),
    sendSurvivorsSignUpEmail: sendSignUpEmail(builder),
    sendSurvivorsSignUpSMS: sendSignUpSMS(builder),
    updateSurvivor: updateSurvivor(builder),
    updateSurvivorLearnTrack: updateSurvivorsLearnTrack(builder),
    updateSurvivorPlanOfCare: updateSurvivorPlanOfCare(builder),
  }),
})

export const {
  useGetSurvivorsQuery,
  useUpdateSurvivorLearnTrackMutation,
  useSendSurvivorsConversationMessageMutation,
  useLazyGetSurvivorsConversationQuery,
  useAssignCarePartnerMutation,
  useNotifySurvivorUncompletedLessonMutation,
  useLazyGetSurvivorsUnitsQuery,
  useGetSurvivorsUnitsQuery,
  useLazyGetSurvivorsAlertsByTabQuery,
  useGetSurvivorsAlertsByTabQuery,
  useUpdateSurvivorMutation,
  useGetSurvivorQuery,
  useLazyGetSurvivorQuery,
  useLazyGetSurvivorsQuery,
  useNewSurvivorMutation,
  useDeleteSurvivorMutation,
  useGetSurvivorPlanOfCareQuery,
  useUpdateSurvivorPlanOfCareMutation,
  useLazyGetSurvivorsAlertsQuery,
  useResetSurvivorsPasswordMutation,
  useChangeSurvivorStatusMutation,
  useSendSurvivorsAppInstallEmailMutation,
  useSendSurvivorsSignUpEmailMutation,
  useSendSurvivorsSignUpSMSMutation,
  useSendSurvivorsAppInstallSMSMutation,
  useLazyGetSurvivorGroupsQuery,
  useSendSurvivorGraduationEmailMutation,
  useSendSurvivorWelcomeEmailMutation,
  useEditSurvivorsConversationMessageMutation,
  useDeleteSurvivorsConversationMessageMutation,
  reducer: survivorsApiReducer,
  reducerPath: survivorsApiReducerPath,
} = survivorsApi
