import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addHealthConditionButton: 'Add Health Condition',
    createdAtColumn: 'Created At',
    deleteModalTitle: 'Are you sure you want to delete this health condition?',
    healthConditionsTitle: 'Health Conditions',
    nameColumn: 'Name',
    resourceName: 'Health Condition',
    updatedAtColumn: 'Updated At',
  },
})
