import { PaperClipOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { formatDateToRender } from 'src/common/types'
import { Button } from 'src/components/Button'
import { Links } from 'src/components/Links'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { CommunicationLog } from 'src/store/APIs/communicationLogs/types'

import styles from './communicationLogs.module.scss'
import { strings } from './strings'

export enum CommLogsDataIndex {
  'updated_at' = 'updated_at',
  'updated_by' = 'updated_by',
  'encounter_date' = 'encounter_date',
  'participants' = 'participants',
  'status' = 'status',
  'summary' = 'summary',
  'title' = 'title',
  'topic' = 'topic',
  'type_of' = 'type_of',
  'escalation' = 'escalation',
  'is_draft' = 'is_draft',
}

export enum VisitStatus {
  'Completed' = 'Completed',
  'DidNotReach' = 'Did not Reach',
  'Rescheduled' = 'Rescheduled',
  'NoShow' = 'No Show',
}

export const generateVisitStatusColor = (status: VisitStatus) => {
  switch (status) {
    case VisitStatus.Completed:
      return 'green'
    case VisitStatus.DidNotReach:
      return 'volcano'
    case VisitStatus.Rescheduled:
      return 'gold'
    case VisitStatus.NoShow:
      return 'orange'
    default:
      return 'default'
  }
}

export const useCommunicationLogsColumns = (
  onClickRow: (id: string) => () => void,
) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const columns: ColumnsType<CommunicationLog> = [
    {
      ...generateColumnTitle(CommLogsDataIndex.title, strings.titleColumnLabel),
      fixed: 'left',
      ...generateColumnSearchProps(CommLogsDataIndex.title),
      filteredValue: filteredInfo.filters?.title || null,
      render(title, record) {
        const titleToShow = (
          <span>
            {title} {record.has_document && <PaperClipOutlined />}
          </span>
        )

        if (record.permissions?.can_read) {
          return (
            <Button
              type="link"
              onClick={onClickRow(record.id)}
              className={styles.summary}>
              {titleToShow}
            </Button>
          )
        }
        return titleToShow
      },
      width: 200,
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.is_draft,
        strings.isDraftColumnLabel,
      ),
      render: draft =>
        draft ? (
          <Tag color="pink">{strings.tagDraftYes}</Tag>
        ) : (
          <Tag color="green">{strings.tagDraftNo}</Tag>
        ),
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.status,
        strings.statusColumnLabel,
      ),
      render: status => (
        <Tag color={generateVisitStatusColor(status)}>{status}</Tag>
      ),
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.participants,
        strings.participantsColumnLabel,
      ),
      render: participants => <Links items={participants} />,
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.summary,
        strings.summaryColumnLabel,
      ),
      ...generateColumnSearchProps(CommLogsDataIndex.summary),
      filteredValue: filteredInfo.filters?.summary || null,
      render: summary =>
        summary && <div className={styles.summary}>{summary}</div>,
      width: 250,
    },
    {
      ...generateColumnTitle(CommLogsDataIndex.topic, strings.topicColumnLabel),
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.type_of,
        strings.typeColumnLabel,
      ),
      render: (_, record) => record.type_of.join(', '),
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.encounter_date,
        strings.encounterDateColumnLabel,
      ),
      render: formatDateToRender,
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.updated_by,
        strings.lastUpdatedByColumnLabel,
      ),
      render: user => user && <UserHyperlink user={user} />,
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.updated_at,
        strings.lastUpdatedAtColumnLabel,
      ),
      render: formatDateToRender,
    },
    {
      ...generateColumnTitle(
        CommLogsDataIndex.escalation,
        strings.escalationColumnLabel,
      ),
      render: escalation =>
        escalation ? (
          <Tag color="blue">{strings.tagYes}</Tag>
        ) : (
          <Tag>{strings.tagNo}</Tag>
        ),
    },
  ]

  return {
    columns,
    filteredInfo,
    ...restFilteredInfo,
  }
}
