import { Button, Divider, Form, FormListFieldData } from 'antd'
import React, { useEffect } from 'react'
import { DeleteButton } from 'src/components/DeleteButton'
import {
  richTextDefaultText,
  RichTextEditor,
} from 'src/components/RichTextEditor'
import { Uploading } from 'src/components/Uploading'
import {
  ExtendedArticleInfo,
  PageResponse,
} from 'src/store/APIs/articles/types'

import { useUploadMedia } from '../../hooks/useUploadMedia'
import { strings } from '../../strings'
import { quillArticleToolbar } from '../../types'
import { validatePage } from '../../validations'
import styles from './paginated.module.scss'

interface PaginatedProps {
  triggerGenerateSsmlMutation: (data: PageResponse[]) => void
  initialPages?: PageResponse[]
  resetContent: () => void
  isLoading: boolean
  article?: ExtendedArticleInfo
}

interface PageProps {
  index: number
  page: FormListFieldData
  remove: (index: number) => void
  pages: PageResponse[]
}

const Page = ({ index, page, remove, pages }: PageProps) => {
  const { quillRef, handlers, isLoadingMedia } = useUploadMedia()
  const pageLabel = `${strings.pageLabel} ${index + 1}`
  return (
    <div key={page.name}>
      <Uploading visible={isLoadingMedia} />
      <Form.Item name={[page.name, 'id']} hidden />
      <Form.Item
        name={[page.name, 'content']}
        rules={[{ validator: validatePage }]}
        className={styles.pageItem}>
        <RichTextEditor
          quillRef={quillRef}
          label={<div className={styles.pageTitle}>{pageLabel}</div>}
          toolbar={{
            ...quillArticleToolbar,
            handlers,
          }}
          className={styles.input}
        />
      </Form.Item>
      <DeleteButton
        cancelText={strings.cancelLabel}
        okText={strings.deleteLabel}
        modalTitle={strings.pageDeleteConfirmation}
        size="small"
        onOk={() => {
          remove(index)
        }}>
        {strings.deletePageLabel}
      </DeleteButton>
      {pages?.length !== index + 1 && (
        <Divider dashed className={styles.divider} />
      )}
    </div>
  )
}

export const Paginated: React.FC<PaginatedProps> = ({
  initialPages,
  triggerGenerateSsmlMutation,
  resetContent,
  isLoading,
  article,
}) => {
  const form = Form.useFormInstance()

  const hasPages = article && article?.pages_attributes?.length !== 0

  const pagesFormName =
    article && article?.pages_attributes?.length === 0
      ? 'pages_attributes_edited'
      : 'pages_attributes'

  const pages = Form.useWatch(pagesFormName, form)

  const generateSsml = () => {
    triggerGenerateSsmlMutation(pages)
  }

  const disabledSsmlButton =
    !pages?.length ||
    pages?.some((p: PageResponse) => p.content === richTextDefaultText)

  useEffect(() => {
    if (pages?.length === 0) {
      resetContent()
    }
  }, [pages])

  const initialValue = hasPages ? article?.pages_attributes : initialPages

  useEffect(() => {
    if (hasPages) {
      form.setFieldValue(pagesFormName, article?.pages_attributes)
    } else {
      form.setFieldValue(pagesFormName, initialPages)
    }
  }, [])

  return (
    <div>
      <Divider className={styles.divider} />
      <div className={styles.paginatedContentHeader}>
        <h3>{strings.contentLabel}</h3>
        <DeleteButton
          onOk={resetContent}
          cancelText={strings.cancelLabel}
          okText={strings.deleteLabel}
          modalTitle={strings.deleteContentConfirmation}>
          {strings.resetContentLabel}
        </DeleteButton>
      </div>
      <Form.Item name="content" hidden />
      {initialValue && (
        <Form.List name={pagesFormName} initialValue={initialValue}>
          {(pageList, { remove }) => {
            return pageList.map((page, index) => {
              return (
                <Page
                  key={page.name}
                  page={page}
                  index={index}
                  remove={remove}
                  pages={pages}
                />
              )
            })
          }}
        </Form.List>
      )}
      <Button
        disabled={disabledSsmlButton}
        loading={isLoading}
        className={styles.ssmlButton}
        type="primary"
        onClick={generateSsml}>
        {strings.generateSsmlLabel}
      </Button>
    </div>
  )
}
