import { Form } from 'antd'
import { FormProviderProps } from 'antd/es/form/context'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { MultipleBlockFooter } from 'src/components/MultipleBlockFooter'
import { SearchSelect } from 'src/components/SearchSelect'
import { Spinner } from 'src/components/Spinner'
import { TextInput } from 'src/components/TextInput'
import { useEditedItems } from 'src/hooks/useEditedItems'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import {
  useGetSurvivorImpairmentsQuery,
  useLazyGetImpairmentsQuery,
  useUpdateSurvivorsImpairmentsMutation,
} from 'src/store/APIs/impairments'
import { GetSurvivorImpairmentsData } from 'src/store/APIs/impairments/types'

import styles from '../survivorView.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

interface ImpairmentsEditProps {
  toggleEditMode: () => void
}

const initialImpairment: Partial<GetSurvivorImpairmentsData> = {
  description: '',
  impairment_id: '',
}

export const ImpairmentsEdit: React.FC<ImpairmentsEditProps> = ({
  toggleEditMode,
}) => {
  const { id } = useParams<{ id: string }>()
  const { data: survivorImpairments } = useGetSurvivorImpairmentsQuery({
    id,
  })
  const {
    optionsSelect: impairmentsOptions,
    fetchedFilteredElems: fetchedFilteredImpairments,
  } = useSearchOptionsSelect(useLazyGetImpairmentsQuery, undefined, 'name')

  const {
    editedItems: editedImpairments,
    addNewEmptyItem: addNewImpairment,
    onPressRemoveItem: onPressRemoveImpairment,
    prepareFormValues,
    lastItemIndex,
  } = useEditedItems(survivorImpairments?.items, initialImpairment)

  const [triggerUpdateSurvivorImpairments, { isSuccess, isLoading }] =
    useUpdateSurvivorsImpairmentsMutation()

  const onFormFinish: FormProviderProps['onFormFinish'] = (_, { forms }) => {
    const { updatedFormItems: impairments } = prepareFormValues(forms)
    triggerUpdateSurvivorImpairments({
      id,
      impairments,
    })
  }

  if (!editedImpairments && !impairmentsOptions) return <Spinner />

  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.title}>{strings.title}</div>
        <Button type="default" onClick={addNewImpairment}>
          {strings.addNewImpairment}
        </Button>
      </div>
      <Form.Provider onFormFinish={onFormFinish}>
        {editedImpairments?.map(
          (item: Partial<GetSurvivorImpairmentsData>, index) => (
            <Form
              key={item.id}
              name={strings.impairmentLabel + index}
              initialValues={item}>
              <Form.Item name="id" hidden />
              {impairmentsOptions && (
                <Form.Item name="impairment_id" rules={[yupSync]}>
                  <SearchSelect
                    required
                    label={strings.impairmentLabel}
                    fetchOptions={fetchedFilteredImpairments}
                    options={impairmentsOptions}
                    placeholder={strings.impairmentLabel}
                  />
                </Form.Item>
              )}
              <Form.Item name="description" rules={[yupSync]}>
                <TextInput label={strings.descriptionLabel} />
              </Form.Item>
              <Form.Item>
                <Button type="dashed" onClick={onPressRemoveImpairment(item)}>
                  {strings.removeLabel}
                </Button>
              </Form.Item>
              <MultipleBlockFooter
                isLoading={isLoading}
                isLast={index === lastItemIndex}
                isSuccess={isSuccess}
                onCancel={toggleEditMode}
                onSuccess={toggleEditMode}
              />
            </Form>
          ),
        )}
        {editedImpairments?.length === 0 &&
          survivorImpairments?.items.length !== 0 && (
            <Form>
              <MultipleBlockFooter
                isLoading={isLoading}
                isLast
                isSuccess={isSuccess}
                onCancel={toggleEditMode}
                onSuccess={toggleEditMode}
              />
            </Form>
          )}
      </Form.Provider>
    </>
  )
}
