import { ReactNode } from 'react'

export enum TabNames {
  account_management = 'Account Management',
  basic_demographics = 'Basic Demographics',
  stroke_details = 'Stroke Details',
  impairments = 'Impairments',
  health_care_team = 'Healthcare Team',
  health_events = 'Health Events',
  health_conditions = 'Health Conditions',
  hospital_info = 'Hospital Info',
  plan_of_care = 'Plan Of Care',
  documents = 'Documents',
  alerts = 'Alerts',
  assessments = 'Assessments',
  group_conversation = '3-Way Chat',
  private_conversation = '2-Way Chat',
  to_do_s = `To-Do's`,
  quick_jots = 'Quick Jots',
  questions_to_ask = `QTA's`,
  purchase_todos = `Purchase To-Do's`,
  learning = 'Learn',
  tacas = 'Take Charge',
  communication_logs = 'Notes',
  navigator_tasks = 'Navigator Tasks',
  groups = 'Community Groups',
}

export interface Tab {
  content: ReactNode
  tabName: TabNames
  editable?: boolean
}

export interface EditableTab {
  editMode: boolean
  toggleEditMode: () => void
}
