import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { validateTimeWithTimezone } from 'src/common/generalValidations'
import { disableDateBeforeToday } from 'src/common/helpers'
import { Button } from 'src/components/Button'
import { useDrawer } from 'src/hooks/useDrawer'
import { ArticlesDrawer } from 'src/pages/Learn/Articles/ArticlesDrawer'
import { PackagesDrawer } from 'src/pages/Learn/Packages/PackagesDrawer'
import { CompletionStatusOptions } from 'src/pages/SurvivorView/Learn/types'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { useUpdatePackageRowMutation } from 'src/store/APIs/packages'
import { MainUnit, Survivor } from 'src/store/APIs/survivors/types'
import { LearnStatus, PaginatedItems, SelectOption } from 'src/store/APIs/types'

import { DateAndTimePicker } from '../DateAndTimePicker'
import { PaginatedTable } from '../PaginatedTable'
import { Select } from '../Select'
import { AssignPackage } from './AssignPackage'
import { useLearnViewColumns } from './columns'
import styles from './learn.module.scss'
import { strings } from './strings'
import { LearnDataIndex, learnTrackOptions, LearnTrackResponse } from './types'

interface LearnViewProps {
  data?: PaginatedItems<MainUnit>
  isLoadingNotify?: boolean
  useLazyQuery: UseLazyQuery<
    QueryDefinition<any, any, any, PaginatedItems<any>, any>
  >
  onClickUncompletedPackage?: () => void
  initialLearnTrack?: LearnTrackResponse
  isLoadingLearnTrack: boolean
  onChangeLearnTrack: (learn_track: LearnTrackResponse) => void
  user?: Survivor | CarePartner
  audienceOptions?: SelectOption[]
  isCarePartner?: boolean
}

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: MainUnit
  timezone: string
}

const EditableCellLearn: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  timezone,
  ...restProps
}) => {
  let childNode = children

  childNode =
    editable && dataIndex === LearnDataIndex.deployment_date ? (
      <div className={styles.deploymentDateRow}>
        <Form.Item
          className={styles.formItem}
          initialValue={record.deployment_date}
          name={dataIndex}
          rules={[
            {
              message: strings.requiredValidation,
              required: true,
            },
            { validator: validateTimeWithTimezone(timezone) },
          ]}>
          <DateAndTimePicker
            disabledDate={date => disableDateBeforeToday(date)}
          />
        </Form.Item>
        {children}
      </div>
    ) : (
      <div>{children}</div>
    )

  return <td {...restProps}>{childNode}</td>
}

export const LearnView: React.FC<LearnViewProps> = ({
  data,
  isLoadingNotify,
  useLazyQuery,
  onClickUncompletedPackage,
  initialLearnTrack,
  isLoadingLearnTrack,
  onChangeLearnTrack,
  audienceOptions,
  user,
  isCarePartner,
}) => {
  const [notifyEnabled, setNotifyEnabled] = useState(false)
  const { open, onCloseDrawer, selectedId, openView, openEdit, drawerState } =
    useDrawer()

  const { open: openAssignPackage, setOpen: setOpenAssignPackage } = useDrawer()
  const {
    open: openArticle,
    onCloseDrawer: onCloseArticle,
    selectedId: selectedIdArticle,
    openView: openViewArticle,
    setSelectedId: setSelectedIdArticle,
  } = useDrawer()

  const openPackageDrawer = (id: string) => () => {
    onCloseArticle()
    openView(id)()
  }

  const [triggerEditPackageRowMutation] = useUpdatePackageRowMutation()

  const [form] = Form.useForm()

  const onUnassignPackage = (lesson: MainUnit) => () => {
    triggerEditPackageRowMutation({
      body: {
        article_ids: lesson.articles.map(article => article.id),
        status: LearnStatus.unassigned,
      },
      id: lesson.unit.id,
    })
  }

  useEffect(() => {
    data?.items.some(
      lesson =>
        lesson.status !== CompletionStatusOptions.Completed &&
        setNotifyEnabled(true),
    )
  }, [data])

  const columnsAndFilters = useLearnViewColumns(
    openView,
    !!isCarePartner,
    form,
    openViewArticle,
    onUnassignPackage,
    openEdit,
    user?.time_zone,
  )

  const components = {
    body: {
      cell: EditableCellLearn,
    },
  }

  return (
    <div className={styles.container}>
      <div className={styles.topButtonsContainer}>
        <div>
          <Button
            type="primary"
            className={styles.assignPackageButton}
            onClick={() => setOpenAssignPackage(true)}>
            {strings.assignPackageLabel}
          </Button>
          <Button
            loading={isLoadingNotify}
            disabled={!notifyEnabled}
            onClick={onClickUncompletedPackage}>
            {strings.uncompletedPackageButton}
          </Button>
        </div>
        <div className={styles.learnTrackContainer}>
          <span className={styles.learnTrackLabel}>
            {strings.learnTrackLabel}
          </span>
          <Select
            options={learnTrackOptions}
            loading={isLoadingLearnTrack}
            onChange={onChangeLearnTrack}
            defaultValue={initialLearnTrack ?? LearnTrackResponse.none}
            className={styles.learnTrackSelect}
          />
        </div>
      </div>
      <AssignPackage
        isCarePartner={isCarePartner}
        setOpen={setOpenAssignPackage}
        open={openAssignPackage}
        audienceOptions={audienceOptions}
        user={user}
      />
      <Form form={form} component={false}>
        <PaginatedTable
          components={components}
          useLazyQuery={useLazyQuery}
          scroll={{ x: 1500 }}
          {...columnsAndFilters}
        />
      </Form>
      <PackagesDrawer
        onCloseDrawer={onCloseDrawer}
        open={open}
        selectedId={selectedId}
        initialDrawerState={drawerState}
      />
      <ArticlesDrawer
        openAnotherDrawer={openPackageDrawer}
        onCloseDrawer={onCloseArticle}
        open={openArticle}
        selectedId={selectedIdArticle}
        setSelectedId={setSelectedIdArticle}
      />
    </div>
  )
}
