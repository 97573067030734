import { Button, Form } from 'antd'
import React from 'react'
import {
  richTextDefaultText,
  RichTextEditor,
} from 'src/components/RichTextEditor'
import { Uploading } from 'src/components/Uploading'

import { useUploadMedia } from '../../hooks/useUploadMedia'
import { strings } from '../../strings'
import { quillArticleToolbar } from '../../types'
import { yupSync } from '../../validations'
import styles from './content.module.scss'

interface ContentProps {
  triggerPaginateContentMutation: (content: string) => void
  isLoading: boolean
}

export const Content: React.FC<ContentProps> = ({
  triggerPaginateContentMutation,
  isLoading,
}) => {
  const form = Form.useFormInstance()
  const content = Form.useWatch('content', form)
  const { quillRef, handlers, isLoadingMedia } = useUploadMedia()
  const paginateContent = () => {
    triggerPaginateContentMutation(content)
  }

  return (
    <div>
      <Uploading visible={isLoadingMedia} />
      <Form.Item name="content" rules={[yupSync]}>
        <RichTextEditor
          quillRef={quillRef}
          label={strings.contentLabel}
          required
          toolbar={{ ...quillArticleToolbar, handlers }}
          className={styles.input}
        />
      </Form.Item>
      <Button
        type="primary"
        disabled={!content || content === richTextDefaultText}
        onClick={paginateContent}
        loading={isLoading}
        className={styles.input}>
        {strings.paginateButtonLabel}
      </Button>
    </div>
  )
}
