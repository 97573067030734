import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addressLabel: 'Address',
    ambassadorLabel: 'Ambassador',
    cityLabel: 'City',
    cmsNavigatorsLabel: 'Navigator',
    dateOfBirthLabel: 'Date of birth',
    disabledInputTooltip: 'Please edit this field in Tellescope',
    emailLabel: 'Email',
    emailValidation: 'Email must be valid',
    emergencyContactLabel: 'Emergency Contact',
    ethnicityLabel: 'Ethnicity',
    familySituationLabel: 'Family Situation',
    firstNameLabel: 'First Name',
    genderIdentityLabel: 'Gender identity',
    homePhoneLabel: 'Home Phone',
    insuranceInfoLabel: 'Insurance Info',
    languageLabel: 'Language',
    lastNameLabel: 'Last Name',
    legalSexLabel: 'Legal sex',
    livingSituationLabel: 'Living Situation',
    mobilePhoneLabel: 'Mobile Phone',
    navigatorsPlaceholder: 'Search and add a navigator',
    onboardingDateLabel: 'Onboarding Date',
    phoneNumberFormatValidation: 'Phone number must be valid',
    phoneNumberLabel: 'Phone Number*',
    phoneNumberSubtitle: 'Enter at least one phone number:',
    preferredNameLabel: 'Preferred Name',
    preferredPhoneLabel: 'Preferred',
    preferredPronounLabel: 'Preferred Pronoun',
    raceLabel: 'Race',
    registrationDateLabel: 'Registration Date',
    requiredValidation: 'This field is required',
    stateLabel: 'State',
    tacasEnabledLabel: 'TACAS Enabled',
    testUserLabel: 'Test User',
    timezoneLabel: 'Time zone: %s',
    undefinedTimezone: 'No time zone recorded',
    zipCodeLabel: 'Zip Code',
  },
})
