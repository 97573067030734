import { createSelector } from '@reduxjs/toolkit'
import { ReduxState } from 'src/store/rootReducer'

import { ErrorState } from './types'

const selectErrorStateTree = (state: ReduxState): ErrorState => state.error

export const selectErrorMessage = createSelector(
  selectErrorStateTree,
  (errorState: ErrorState) => errorState.message,
)

export const selectAudioError = createSelector(
  selectErrorStateTree,
  (errorState: ErrorState) => errorState.audioError,
)

export const selectAddPackageError = createSelector(
  selectErrorStateTree,
  (errorState: ErrorState) => errorState.addPackageError,
)

export const selectAddArticleError = createSelector(
  selectErrorStateTree,
  (errorState: ErrorState) => errorState.addArticleError,
)
