import {
  ExclamationCircleFilled,
  HeartFilled,
  HeartOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Modal } from 'antd'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { hyperlinkConverter } from 'src/common/helpers'
import { GETSTREAM_IMAGE_REGEX } from 'src/common/regex'
import { deserializeExtendedFormattedDate } from 'src/common/types'
import {
  useDeletePostCommentMutation,
  useLikePostCommentMutation,
  useUnlikePostCommentMutation,
} from 'src/store/APIs/communityGroups'
import { Comment } from 'src/store/APIs/communityGroups/types'

import { Button } from '../Button'
import styles from './communityGroupPost.module.scss'
import { strings } from './strings'

interface PostCommentProps {
  comment: Comment
  groupId?: string
  postId?: string
  postForeignId?: string
}

export const PostComment: React.FC<PostCommentProps> = ({
  comment,
  groupId,
  postId,
  postForeignId,
}) => {
  const [triggerUnlikeComment, { isLoading: isLoadingUnlikeComment }] =
    useUnlikePostCommentMutation()
  const [triggerLikeComment, { isLoading: isLoadingLikeComment }] =
    useLikePostCommentMutation()
  const [triggerDeleteComment, { isLoading: isLoadingDeleteComment }] =
    useDeletePostCommentMutation()

  const onClickLike = () => {
    if (groupId && postId && comment) {
      if (comment?.liked_by_user && comment?.like_id) {
        triggerUnlikeComment({
          body: {
            activity_id: postId,
            comment_id: comment.id,
            reaction_id: comment.like_id,
          },
          groupId,
        })
      } else {
        triggerLikeComment({
          body: {
            activity_id: postId,
            comment_id: comment.id,
          },
          groupId,
        })
      }
    }
  }
  const onDeleteComment = () =>
    Modal.confirm({
      cancelText: generalStrings.no,
      icon: <ExclamationCircleFilled />,
      okText: generalStrings.yes,
      okType: 'danger',
      onCancel() {},
      onOk() {
        if (groupId && postId && postForeignId)
          triggerDeleteComment({
            body: { post_comment: { activity_foreign_id: postForeignId } },
            commentId: comment.id,
            groupId,
            postId,
          })
      },
      title: strings.deleteCommentModalTitle,
    })

  const deletedUser = !comment?.actor

  const authorName = deletedUser
    ? strings.userDeleted
    : `${(comment.actor?.first_name || comment.actor?.name) ?? ''} ${
        comment.actor.last_name ?? ''
      }`

  const likesLabel =
    comment?.reactions_counts.like === 1 ? strings.like : strings.likes

  return (
    <>
      <div className={styles.separator} />
      <div className={styles.commentOutsideContainer}>
        <div>
          {comment?.actor?.image ? (
            <Avatar size="large" src={comment?.actor.image} alt="avatar" />
          ) : (
            <Avatar icon={<UserOutlined />} />
          )}
        </div>
        <div className={styles.commentContainer}>
          <div className={styles.postHeaderContainer}>
            <div className={styles.authorMetaInfo}>
              <div className={styles.commentAuthorName}>{authorName}</div>
            </div>
            {comment.permissions?.can_destroy && (
              <Button
                type="text"
                onClick={onDeleteComment}
                loading={isLoadingDeleteComment}
                danger>
                {strings.deleteButtonLabel}
              </Button>
            )}
          </div>
          <div className={styles.commentContent}>
            <pre
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: hyperlinkConverter(comment.object),
              }}
            />
            {comment.attachments && (
              <img
                crossOrigin={
                  comment.attachments.images?.[0]?.match(GETSTREAM_IMAGE_REGEX)
                    ? ''
                    : 'use-credentials'
                }
                className={styles.commentImage}
                alt="Post"
                src={comment.attachments.images?.[0]}
              />
            )}
            <div className={styles.commentReaction}>
              <Button
                disabled={
                  isLoadingUnlikeComment ||
                  isLoadingLikeComment ||
                  !comment?.permissions?.can_like
                }
                icon={
                  comment?.liked_by_user ? (
                    <HeartFilled className={styles.likeIcon} />
                  ) : (
                    <HeartOutlined />
                  )
                }
                onClick={onClickLike}
                type="text"
                className={styles.reactionButton}
              />
              <div>
                {comment.reactions_counts.like ?? 0} {likesLabel}
              </div>
              <div className={styles.commentHour}>
                {deserializeExtendedFormattedDate(comment.time)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
