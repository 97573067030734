import React from 'react'

import styles from './spinner.module.scss'

const Spinner = () => (
  <div className={styles.background}>
    <div className={styles.spinner} />
  </div>
)

export { Spinner }
