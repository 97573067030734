import { Form } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { PhoneNumberTypes } from 'src/common/types'
import { Button } from 'src/components/Button'
import { SurvivorBasicDemographicsForm } from 'src/components/SurvivorBasicDemographicsForm'
import { goToPage, RouteName } from 'src/routes'
import { useNewSurvivorMutation } from 'src/store/APIs/survivors'
import { Survivor } from 'src/store/APIs/survivors/types'

import { TabNames } from '../SurvivorView/types'
import { strings } from './strings'
import styles from './survivorNew.module.scss'

interface BasicDemographicsFormProps {}

export const BasicDemographicsForm: React.FC<
  BasicDemographicsFormProps
> = () => {
  const history = useHistory()
  const [triggerNewSurvivor, { isLoading, data, isSuccess }] =
    useNewSurvivorMutation()

  const handleNewSurvivorSubmit = async (values: any) => {
    const survivorInfo: Survivor = {
      ...values,
      preferred_phone: values.home_phone_preferred
        ? PhoneNumberTypes.Home
        : PhoneNumberTypes.Mobile,
      race: values?.race?.join(','),
      tacas_enabled: true,
    }
    await triggerNewSurvivor(survivorInfo)
  }

  useEffect(() => {
    if (data?.mobile_survivor?.id && isSuccess) {
      goToPage(
        RouteName.SurvivorView,
        { id: data?.mobile_survivor?.id },
        { tab: TabNames.basic_demographics },
      )
    }
  }, [data])

  return (
    <SurvivorBasicDemographicsForm
      formName={TabNames.basic_demographics}
      onFinish={handleNewSurvivorSubmit}>
      <Form.Item>
        <div className={styles.submitButtonContainer}>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            {strings.createButtonLabel}
          </Button>
          <div className={styles.buttonSeparator} />
          <Button htmlType="button" onClick={history.goBack}>
            {strings.cancelButtonLabel}
          </Button>
        </div>
      </Form.Item>
    </SurvivorBasicDemographicsForm>
  )
}
