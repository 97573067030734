import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    changeStatus: 'Change To In Progress',
    deactivateUser: 'Deactivate User',
    disabledTooltip: 'Please perform this action in Tellescope',
    graduationEmailButton: 'Send Graduation Email',
    graduationEmailLabel: 'Graduation Email',
    mobileUserId: 'Mobile User ID',
    resetCarePartnerPasswordButton: "Reset Care Partner's Password",
    resetPassword: 'Reset Password',
    resetSurvivorPasswordButton: "Reset Survivor's Password",
    sendAppInvitation: 'Send App Install Invitation',
    sendAppInvitationEmailButton: 'Send App Install Invitation Via Email',
    sendAppInvitationEmailSMS: 'Send App Install Invitation Via SMS',
    sent: 'Sent',
    signUpMessage: 'Sign Up Message',
    signUpMessageEmailButton: 'Send Sign Up Message Via Email',
    signUpMessageSMSButton: 'Send Sign Up Message Via SMS',
    status: 'Status',
    welcomeEmailButton: 'Send Welcome Email',
    welcomeEmailLabel: 'Welcome Email',
  },
})
