import { Form } from 'antd'
import { FormProviderProps } from 'antd/es/form/context'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { MultipleBlockFooter } from 'src/components/MultipleBlockFooter'
import { SearchSelect } from 'src/components/SearchSelect'
import { Spinner } from 'src/components/Spinner'
import { TextInput } from 'src/components/TextInput'
import { useEditedItems } from 'src/hooks/useEditedItems'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import {
  useGetSurvivorsHealthConditionsQuery,
  useLazyGetHealthConditionsQuery,
  useUpdateSurvivorsHealthConditionsMutation,
} from 'src/store/APIs/healthConditions'
import { SurvivorHealthConditionData } from 'src/store/APIs/healthConditions/types'

import styles from '../survivorView.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

interface HealthConditionsEditProps {
  toggleEditMode: () => void
}

const initialHealthCondition: Partial<SurvivorHealthConditionData> = {
  description: '',
  health_condition_id: '',
}

export const HealthConditionsEdit: React.FC<HealthConditionsEditProps> = ({
  toggleEditMode,
}) => {
  const { id } = useParams<{ id: string }>()
  const { data: survivorHealthConditions } =
    useGetSurvivorsHealthConditionsQuery({
      id,
    })

  const {
    editedItems: editedHealthConditions,
    addNewEmptyItem: addNewHealthCondition,
    onPressRemoveItem: onPressRemoveHealthCondition,
    prepareFormValues,
    lastItemIndex,
  } = useEditedItems(survivorHealthConditions?.items, initialHealthCondition)

  const [triggerUpdateSurvivorHealthConditions, { isSuccess, isLoading }] =
    useUpdateSurvivorsHealthConditionsMutation()

  const onFormFinish: FormProviderProps['onFormFinish'] = (_, { forms }) => {
    const { updatedFormItems: healthConditions } = prepareFormValues(forms)
    triggerUpdateSurvivorHealthConditions({
      health_conditions: healthConditions,
      id,
    })
  }

  const {
    optionsSelect: healthConditionsOptions,
    fetchedFilteredElems: fetchedFilteredHealthConditions,
  } = useSearchOptionsSelect(useLazyGetHealthConditionsQuery, undefined, 'name')

  if (!editedHealthConditions && !healthConditionsOptions) return <Spinner />
  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.title}>{strings.title}</div>
        <Button type="default" onClick={addNewHealthCondition}>
          {strings.addNewHealthCondition}
        </Button>
      </div>
      <Form.Provider onFormFinish={onFormFinish}>
        {editedHealthConditions?.map(
          (item: Partial<SurvivorHealthConditionData>, index) => (
            <Form
              key={item.id}
              name={strings.title + index}
              initialValues={item}>
              <Form.Item name="id" hidden />
              {healthConditionsOptions && (
                <Form.Item name="health_condition_id" rules={[yupSync]}>
                  <SearchSelect
                    required
                    label={strings.healthCondition}
                    options={healthConditionsOptions}
                    placeholder={strings.healthCondition}
                    fetchOptions={fetchedFilteredHealthConditions}
                  />
                </Form.Item>
              )}
              <Form.Item name="description" rules={[yupSync]}>
                <TextInput label={strings.descriptionLabel} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={onPressRemoveHealthCondition(item)}>
                  {strings.removeLabel}
                </Button>
              </Form.Item>
              <MultipleBlockFooter
                isLoading={isLoading}
                isLast={index === lastItemIndex}
                isSuccess={isSuccess}
                onCancel={toggleEditMode}
                onSuccess={toggleEditMode}
              />
            </Form>
          ),
        )}
        {editedHealthConditions?.length === 0 &&
          survivorHealthConditions?.items.length !== 0 && (
            <Form>
              <MultipleBlockFooter
                isLoading={isLoading}
                isLast
                isSuccess={isSuccess}
                onCancel={toggleEditMode}
                onSuccess={toggleEditMode}
              />
            </Form>
          )}
      </Form.Provider>
    </>
  )
}
