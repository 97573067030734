import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    answerLabel: 'Answer (optional)',
    answerPlaceholder: 'Add an answer',
    questionLabel: 'Question',
    questionPlaceholder: 'Add a question',
    requiredValidation: 'This field is required',
  },
})
