import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from '../strings'

const schema = yup.object({
  description: yup.string(),
  retail_product_ids: yup
    .array()
    .min(1, strings.requiredValidation)
    .required(strings.requiredValidation),
  todo: yup.string().required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
