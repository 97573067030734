import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addPurchaseTodoButtonLabel: 'Add Purchase To-Do',
    completedByLabel: 'Completed By',
    completedColumnLabel: 'Completed',
    completedDateColumnLabel: 'Completed Date',
    completedLabel: 'Completed',
    createdByLabel: 'Created By',
    deleteModalTitle: 'Are you sure you want to delete the purchase to-do?',
    descriptionLabel: 'Description',
    descriptionLabelOptional: 'Description (optional)',
    discardedByLabel: 'Discarded By',
    dueDateColumnLabel: `Due Date (Survivor's Time Zone)`,
    reminderDateLabel: 'Reminder Date',
    reminderDateSubtitle: `In survivor's timezone`,
    requiredValidation: 'This field is required',
    resourceName: 'Purchase To Do',
    retailProductsColumnLabel: 'Retail Products',
    tagNo: 'No',
    tagYes: 'Yes',
    todoLabel: 'To Do',
    todosColumnLabel: 'To-Do',
  },
})
