import { useMemo, useState } from 'react'
import { Filters } from 'src/store/APIs/types'

export const useHideChildrenCheckbox = () => {
  const [hideChildren, setHideChildren] = useState(true)

  const toggleHideChildren = () => setHideChildren(prevState => !prevState)
  const hideChildrenFilter: Filters = useMemo(
    () => ({ hide: [hideChildren.toString()] }),
    [hideChildren],
  )

  return {
    hideChildrenFilter,
    hideChildrenIsChecked: hideChildren,
    toggleHideChildren,
  }
}
