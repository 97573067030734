import { ColumnsType } from 'antd/es/table'
import { Dayjs } from 'dayjs'
import React from 'react'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import { SurvivorAlertsData } from 'src/store/APIs/survivors/types'
import { BaseUser } from 'src/store/APIs/types'

import { strings } from './strings'

export enum AlertsDataIndex {
  'created_at' = 'created_at',
  'alert' = 'alert',
  'triggered_by' = 'triggered_by',
  'user_type' = 'user_type',
  'active' = 'active',
}

export const columns: (
  onClickAlert: (alertTabName: string, owner: BaseUser) => () => void,
) => ColumnsType<SurvivorAlertsData> = onClickAlert => [
  {
    ...generateColumnTitle(
      AlertsDataIndex.created_at,
      strings.createdAtColumnLabel,
    ),
    render: (value: Dayjs | undefined) => value?.format('MMMM D, YYYY HH:mm'),
  },
  {
    ...generateColumnTitle(AlertsDataIndex.alert, strings.alertColumnLabel),
    render: (_, record) => (
      <Button type="link" onClick={onClickAlert(record.alert, record.owner)}>
        {record.label}
      </Button>
    ),
  },
  {
    ...generateColumnTitle(
      AlertsDataIndex.triggered_by,
      strings.triggeredByColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      AlertsDataIndex.user_type,
      strings.userTypeColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(AlertsDataIndex.active, strings.activeColumnLabel),
    render(value) {
      return value ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag>{strings.tagNo}</Tag>
      )
    },
  },
]
