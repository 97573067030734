/* eslint-disable no-nested-ternary */
import { UploadOutlined } from '@ant-design/icons'
import { Form, Upload } from 'antd'
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { Button } from 'src/components/Button'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { SearchSelect } from 'src/components/SearchSelect'
import { Spinner } from 'src/components/Spinner'
import { SubHeader } from 'src/components/SubHeader'
import { TextInput } from 'src/components/TextInput'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import { RouteName } from 'src/routes'
import {
  useEditCommunityGroupMutation,
  useLazyGetCommunityGroupQuery,
  useNewCommunityGroupMutation,
} from 'src/store/APIs/communityGroups'
import { CommunityGroupDetailValues } from 'src/store/APIs/communityGroups/types'
import { useLazyGetMobileUsersQuery } from 'src/store/APIs/mobileUsers'
import { useLazyGetNavigatorOptionsQuery } from 'src/store/APIs/navigators'

import styles from './communityGroupEdit.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

interface CommunityGroupEditProps {}

export const CommunityGroupEdit: React.FC<CommunityGroupEditProps> = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const newGroup = !id
  const [triggerGetGroup, { data, isFetching: getGroupIsFetching }] =
    useLazyGetCommunityGroupQuery()
  const [triggerNewGroup, { isLoading: newGroupIsLoading }] =
    useNewCommunityGroupMutation()
  const [triggerEditGroup, { isLoading: editGroupIsLoading }] =
    useEditCommunityGroupMutation()

  useEffect(() => {
    if (id) {
      triggerGetGroup(id)
    }
  }, [data])

  const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
    { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
    { breadcrumbName: 'Community Groups', path: RouteName.CommunityGroups },
    { breadcrumbName: data?.name ?? '', path: RouteName.CommunityGroup },
  ]

  const handleSubmit = async (values: CommunityGroupDetailValues) => {
    const image =
      typeof values?.image === 'string'
        ? values?.image
        : values?.image?.fileList.length !== 0
        ? values?.image?.fileList['0'].originFileObj
        : undefined

    const groupValues = {
      ...values,
      image,
    }

    if (newGroup) {
      triggerNewGroup(groupValues).then(history.goBack)
    } else {
      triggerEditGroup({
        group: groupValues,
        id,
      }).then(history.goBack)
    }
  }

  const {
    optionsSelect: navigatorOptions,
    fetchedFilteredElems: fetchedFilteredNavigators,
    isLoading: getNavigatorsIsLoading,
  } = useSearchOptionsSelect(
    useLazyGetNavigatorOptionsQuery,
    data?.cms_navigators,
  )

  const {
    optionsSelect: mobileUsersOptions,
    fetchedFilteredElems: fetchedFilteredMobileUsers,
    isLoading: getMobileUsersIsLoading,
  } = useSearchOptionsSelect(useLazyGetMobileUsersQuery, data?.mobile_users)

  const isLoading =
    getGroupIsFetching ||
    newGroupIsLoading ||
    editGroupIsLoading ||
    getNavigatorsIsLoading ||
    getMobileUsersIsLoading

  if (isLoading && !newGroup) return <Spinner />

  return (
    <>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        title={newGroup ? strings.newLabel : strings.editLabel}
      />
      <div className={styles.separatorContainer}>
        <div className={styles.separator} />
      </div>
      <div className={styles.container}>
        <Form
          initialValues={newGroup ? undefined : data}
          onFinish={handleSubmit}>
          <Form.Item name="name" rules={[yupSync]}>
            <TextInput required label={strings.nameLabel} />
          </Form.Item>
          <div>{strings.imageLabel}</div>
          <Form.Item name="image">
            <Upload
              defaultFileList={
                data?.image
                  ? [
                      {
                        name: strings.imagePlaceholder,
                        uid: data?.id, // INFO: it's the group id but a group can only have one image so it could work
                        url: data?.image,
                      },
                    ]
                  : undefined
              }
              listType="picture"
              className="upload-list-inline"
              maxCount={1}
              accept="image/*"
              beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>{strings.selectImage}</Button>
            </Upload>
          </Form.Item>
          <div>{strings.descriptionLabel}</div>
          <Form.Item name="description" valuePropName="value">
            <RichTextEditor />
          </Form.Item>
          <Form.Item
            name="mobile_user_ids"
            initialValue={data?.mobile_users?.map(item => item.id)}>
            <SearchSelect
              mode="multiple"
              options={mobileUsersOptions}
              placeholder={strings.mobileUsersPlaceholder}
              fetchOptions={fetchedFilteredMobileUsers}
              label={strings.mobileUsersLabel}
            />
          </Form.Item>
          <Form.Item
            name="cms_navigator_ids"
            rules={[yupSync]}
            initialValue={data?.cms_navigators?.map(item => item.id)}>
            <SearchSelect
              mode="multiple"
              options={navigatorOptions}
              placeholder={strings.navigatorsPlaceholder}
              fetchOptions={fetchedFilteredNavigators}
              label={strings.cmsNavigatorsLabel}
              required
            />
          </Form.Item>
          <Form.Item>
            <div className={styles.submitButtonContainer}>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                {newGroup
                  ? strings.createButtonLabel
                  : strings.updateButtonLabel}
              </Button>
              <div className={styles.buttonSeparator} />
              <Button htmlType="button" onClick={history.goBack}>
                {strings.cancelButtonLabel}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
