import { message } from 'antd'
import Gleap from 'gleap'
import { useEffect } from 'react'
import { constants } from 'src/config/constants'

import { strings } from './strings'

export const useBugTracking = () => {
  useEffect(() => {
    const instance = Gleap.getInstance()
    if (instance.initialized) return
    if (constants.gleapToken) {
      Gleap.initialize(constants.gleapToken)
      Gleap.showFeedbackButton(true)
    } else {
      message.warning(strings.gleapTokenMissing)
    }
  }, [])
}
