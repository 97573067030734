import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { formatDateToRender } from 'src/common/types'
import { Button } from 'src/components/Button'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { generateColumnTitle } from 'src/helpers/utils'
import { SurvivorQTA } from 'src/store/APIs/questionsToAsk/types'
import { UserType } from 'src/store/APIs/types'

import { strings } from './strings'

export enum QTAsDataIndex {
  'question' = 'question',
  'created_by' = 'created_by',
  'answer' = 'answer',
  'answered_date' = 'answered_date',
  'created_at' = 'created_at',
}

export const columns: (
  onClickRow?: (id: string) => () => void,
) => ColumnsType<SurvivorQTA> = onClickRow => [
  {
    ...generateColumnTitle(QTAsDataIndex.question, strings.questionLabel),
    render: (question, record) => {
      return record.permissions?.can_read ? (
        <Button type="link" onClick={onClickRow && onClickRow(record.id)}>
          {question}
        </Button>
      ) : (
        question
      )
    },
  },
  {
    ...generateColumnTitle(
      QTAsDataIndex.created_by,
      strings.createdByColumnLabel,
    ),
    render: (_, record) =>
      record.created_by &&
      record.created_by.user_type !== UserType.cms_navigator ? (
        <UserHyperlink user={record.created_by} />
      ) : (
        record.created_by?.full_name
      ),
  },
  {
    ...generateColumnTitle(QTAsDataIndex.answer, strings.answerColumnLabel),
  },
  {
    ...generateColumnTitle(
      QTAsDataIndex.answered_date,
      strings.answeredDateColumnLabel,
    ),
    render: formatDateToRender,
  },
  {
    ...generateColumnTitle(
      QTAsDataIndex.created_at,
      strings.createdAtColumnLabel,
    ),
    render: formatDateToRender,
  },
]
