import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { SubHeader } from 'src/components/SubHeader'
import { Tabs } from 'src/components/Tabs'
import { RouteName } from 'src/routes'

import { Articles } from './Articles'
import styles from './learn.module.scss'
import { Packages } from './Packages'
import { strings } from './strings'
import { tabBarStyle } from './styles'
import { LearnTab, LearnTabNames } from './types'

interface LearnProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: strings.title, path: RouteName.Learn },
]

export const Learn: React.FC<LearnProps> = () => {
  const items: LearnTab[] = [
    {
      content: <Articles />,
      tabName: LearnTabNames.articles,
    },
    {
      content: <Packages />,
      tabName: LearnTabNames.packages,
    },
  ]

  return (
    <div className={styles.container}>
      <SubHeader
        className={styles.subHeader}
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.Configuration}
        title={strings.title}
      />
      <Tabs
        tabBarStyle={tabBarStyle}
        defaultActiveKey={LearnTabNames.articles}
        items={items.map(item => {
          const key = item.tabName
          return {
            children: (
              <div className={styles.tabInfoContainer}>{item.content}</div>
            ),
            key,
            label: <div>{item.tabName}</div>,
          }
        })}
      />
    </div>
  )
}
