import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    activeColumnLabel: 'Active',
    alertColumnLabel: 'Alert',
    createdAtColumnLabel: 'Created At',
    tagNo: 'No',
    tagYes: 'Yes',
    triggeredByColumnLabel: 'Triggered By',
    userTypeColumnLabel: 'User Type',
  },
})
