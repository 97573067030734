import { Form } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Option } from 'src/common/types'
import { DatePicker } from 'src/components/DatePicker'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import { NumberInput } from 'src/components/NumberInput'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { Select } from 'src/components/Select'
import { TextInput } from 'src/components/TextInput'

import styles from '../navigatorTasks.module.scss'
import { strings } from '../strings'
import { StatusOptions, TypeOptions } from '../types'
import { yupSync } from './validations'

interface AddNavigatorTaskProps extends DrawerRenderProps {}

const statusOptions: Option[] = Object.values(StatusOptions).map(option => ({
  label: option,
  value: option,
}))

const typeOptions: Option[] = Object.values(TypeOptions).map(option => ({
  label: option,
  value: option,
}))

export const AddNavigatorTask: React.FC<AddNavigatorTaskProps> = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <>
      <Form.Item name="description" rules={[yupSync]}>
        <TextInput
          label={strings.taskLabel}
          required
          placeholder={strings.taskPlaceholder}
        />
      </Form.Item>
      <div className={styles.formRow}>
        <Form.Item name="status" className={styles.flexible} rules={[yupSync]}>
          <Select
            label={strings.statusLabel}
            options={statusOptions}
            placeholder={strings.statusPlaceholder}
            required
          />
        </Form.Item>
        <Form.Item
          name="type_of"
          className={`${styles.flexible} ${styles.inputSeparation}`}
          rules={[yupSync]}>
          <Select
            label={strings.typeLabel}
            options={typeOptions}
            required
            placeholder={strings.typePlaceholder}
          />
        </Form.Item>
        <Form.Item
          name="time_spent"
          className={`${styles.flexible} ${styles.inputSeparation}`}
          rules={[yupSync]}>
          <NumberInput min={1} label={strings.timeSpentInputLabel} required />
        </Form.Item>
      </div>
      <div className={styles.formRow}>
        <Form.Item name="goal_date" rules={[yupSync]}>
          <DatePicker label={strings.goalDateLabel} optionalLabel />
        </Form.Item>
        <Form.Item
          name="completed_date"
          rules={[yupSync]}
          className={styles.inputSeparation}>
          <DatePicker label={strings.completedDateLabel} optionalLabel />
        </Form.Item>
      </div>
      <span>
        {strings.notesLabel}
        <span className={styles.optionalLabel}>{strings.optionalLabel}</span>
      </span>
      <Form.Item name="notes" valuePropName="value">
        <RichTextEditor
          className={styles.notesInput}
          placeholder={strings.notesPlaceholder}
        />
      </Form.Item>
      <Form.Item name="mobile_survivor_id" hidden initialValue={id} />
      <Form.Item name="priority" hidden initialValue={0} />
    </>
  )
}
