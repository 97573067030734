import { baseApi } from 'src/store/APIs'

import {
  addHospitalTreatment,
  deleteHospitalTreatment,
  editHospitalTreatment,
  getHospitalTreatment,
  getHospitalTreatments,
  getSurvivorsHospitalTreatments,
  updateSurvivorsHospitalTreatments,
} from './actions'

export const hospitalTreatmentsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addHospitalTreatment: addHospitalTreatment(builder),
    deleteHospitalTreatment: deleteHospitalTreatment(builder),
    editHospitalTreatment: editHospitalTreatment(builder),
    getHospitalTreatment: getHospitalTreatment(builder),
    getHospitalTreatments: getHospitalTreatments(builder),
    getSurvivorsHospitalTreatments: getSurvivorsHospitalTreatments(builder),
    updateSurvivorsHospitalTreatments:
      updateSurvivorsHospitalTreatments(builder),
  }),
})

export const {
  useGetHospitalTreatmentsQuery,
  useGetSurvivorsHospitalTreatmentsQuery,
  useUpdateSurvivorsHospitalTreatmentsMutation,
  useLazyGetHospitalTreatmentsQuery,
  useLazyGetHospitalTreatmentQuery,
  useAddHospitalTreatmentMutation,
  useDeleteHospitalTreatmentMutation,
  useEditHospitalTreatmentMutation,
  reducer: hospitalTreatmentsApiReducer,
  reducerPath: hospitalTreatmentsApiReducerPath,
} = hospitalTreatmentsApi
