/* eslint-disable react/prop-types */
import {
  Select as AntdSelect,
  SelectProps as AntdSelectProps,
  Tooltip,
} from 'antd'
import { OptionProps } from 'antd/es/select'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'

export interface SelectProps extends AntdSelectProps {
  label?: string
  required?: boolean
  tooltipTitle?: string
  optionalLabel?: boolean
}

interface SelectSubComponents {
  Option: React.FC<OptionProps>
}

export const Select: React.FC<SelectProps> & SelectSubComponents = ({
  label,
  required,
  optionalLabel,
  placeholder,
  tooltipTitle = '',
  ...props
}) => {
  return (
    <>
      <div>
        {label}
        {required && '*'}
        {optionalLabel && (
          <span className="optional-label">{generalStrings.optionalLabel}</span>
        )}
      </div>
      <Tooltip title={tooltipTitle}>
        <AntdSelect {...props} placeholder={placeholder ?? label}>
          {props.children}
        </AntdSelect>
      </Tooltip>
    </>
  )
}

export const Option: React.FC<OptionProps> = props => (
  <AntdSelect.Option {...props}>{props.children}</AntdSelect.Option>
)

Select.Option = Option
