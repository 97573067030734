import React, { useEffect } from 'react'
import { mergeArrayInPlace } from 'src/common/helpers'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import { InfoTable } from 'src/components/InfoTable'
import { Spinner } from 'src/components/Spinner'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { useLazyGetQuickJotQuery } from 'src/store/APIs/quickJots'

import { strings } from '../strings'

interface ViewQuickJotProps extends DrawerRenderProps {
  jotId?: string
}

export const ViewQuickJot: React.FC<ViewQuickJotProps> = ({ jotId }) => {
  const [triggerGetQuickJot, { data, isLoading }] = useLazyGetQuickJotQuery()

  useEffect(() => {
    if (jotId) triggerGetQuickJot({ id: jotId })
  }, [jotId])
  let rows = []
  rows = [
    {
      content: data?.jot ?? undefined,
      label: strings.jotLabel,
    },
    {
      content: data?.created_by ? (
        <UserHyperlink user={data.created_by} />
      ) : undefined,
      label: strings.createdByLabel,
    },
    {
      content: data?.deleted_by ? (
        <UserHyperlink user={data.deleted_by} />
      ) : undefined,

      label: strings.discardedByLabel,
    },
  ]

  if (data?.quick_jot_symptoms) {
    const symptoms = data?.quick_jot_symptoms.map(item => ({
      content: item.rate ? item.rate.toString() : undefined,
      label: item.symptom.name,
    }))
    mergeArrayInPlace(rows, symptoms, 1)
  }

  if (!data || isLoading) return <Spinner />
  return <InfoTable rows={rows} />
}
