import { DrawerRenderProps } from 'src/components/DrawerWithState/types'

export enum DocumentsDataIndex {
  name = 'name',
  description = 'description',
  file = 'file',
}

export interface AddDocumentProps extends DrawerRenderProps {}

export interface ViewDocumentProps extends DrawerRenderProps {}
