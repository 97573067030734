import colors from 'src/assets/stylesheets/colors.scss'

export const modalIconStyle = {
  delete: {
    color: colors.red_7,
  },
  disable: {
    color: colors.orange_8,
  },
}

export const buttonStyle = {
  delete: {
    borderColor: colors.red_7,
    color: colors.red_7,
  },
  disable: {
    backgroundColor: colors.transparent,
    borderColor: colors.orange_8,
    color: colors.orange_8,
  },
  regular: {
    borderColor: colors.primary_50,
    color: colors.primary_50,
  },
  save: {
    borderColor: colors.primary_50,
    color: colors.primary_50,
  },
}
