import { Layout, Menu } from 'antd'
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import React, { useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { SubHeader } from 'src/components/SubHeader'
import { RouteName } from 'src/routes'

import { HealthcareTeamRole } from './HealthcareTeamRole'
import { HealthConditions } from './HealthConditions'
import { HealthEvents } from './HealthEvents'
import { Hospitals } from './Hospitals'
import { Impairments } from './Impairments'
import { strings } from './strings'
import { StrokeDetails } from './StrokeDetails'
import styles from './survivorProfile.module.scss'
import { MenuInfo } from './types'

interface SurvivorProfileProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: strings.title, path: RouteName.SurvivorProfile },
]

export const SurvivorProfile: React.FC<SurvivorProfileProps> = () => {
  const items = [
    { key: 0, label: strings.hospitalsSection, render: <Hospitals /> },
    { key: 1, label: strings.strokeDetailsSection, render: <StrokeDetails /> },
    { key: 2, label: strings.impairmentsSection, render: <Impairments /> },
    { key: 3, label: strings.healthEventsSection, render: <HealthEvents /> },
    {
      key: 4,
      label: strings.healthConditionsSection,
      render: <HealthConditions />,
    },
    {
      key: 5,
      label: strings.healthcareRoleSection,
      render: <HealthcareTeamRole />,
    },
  ]

  const [selectedMenu, setSelectedMenu] = useState(0)

  const onClickMenu = (data: MenuInfo) => {
    const selectedItem = items.find(item => item.key.toString() === data.key)
    if (selectedItem) setSelectedMenu(selectedItem.key)
  }

  return (
    <div className={styles.container}>
      <SubHeader
        className={styles.subHeader}
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.Configuration}
        title={strings.title}
      />
      <Layout>
        <Sider className={styles.sideBar} theme="light">
          <Menu
            items={items}
            onClick={onClickMenu}
            className={styles.menu}
            defaultSelectedKeys={['0']}
          />
        </Sider>
        <Content>{items[selectedMenu].render}</Content>
      </Layout>
    </div>
  )
}
