import { MutationDefinition } from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { Survivor } from 'src/store/APIs/survivors/types'
import { UserType } from 'src/store/APIs/types'

export enum AssessmentDetailsDataIndex {
  'title' = 'title',
  'description' = 'description',
  'answer' = 'answer',
}

export enum PromisGlobalScoreDataIndex {
  'category' = 'category',
  'raw' = 'raw',
  't_score' = 't_score',
}

export type SubmitAssessmentHelper = (
  spokeWithValues: {
    [UserType.mobile_survivor]: string | undefined
    [UserType.mobile_caregiver]: string | undefined
  },
  accomplishAssessmentId: string | undefined,
  idAssessment: string | undefined,
  user: Survivor | CarePartner | undefined,
  isAdmin?: boolean,
) => OnAssessmentFormFinish

export type OnAssessmentFormFinish = (
  triggerAnswerAssessment: MutationTrigger<
    MutationDefinition<any, any, any, any>
  >,
  triggerCreateAndAnswerAssessment: MutationTrigger<
    MutationDefinition<any, any, any, any>
  >,
) => (values: any) => any
