import {
  MutationDefinition,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query'
import {
  UseLazyQuery,
  UseMutation,
} from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Form } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CommunityTextInput } from 'src/components/CommunityTextInput/CommunityTextInput'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useUploadImage } from 'src/hooks/useUploadImage'
import { useMeQuery } from 'src/store/APIs/misc'
import {
  Conversation as ConversationTypeDef,
  ConversationType,
  PaginatedItems,
  UserType,
} from 'src/store/APIs/types'

import { ConversationValues, TypeOfMessage } from '../CommunityTextInput/types'
import { useConversationColumns } from './columns'
import styles from './conversations.module.scss'
import { EditableCell } from './EditableCell'
import { strings } from './strings'

interface ConversationProps {
  type: ConversationType
  useLazyGetConversationQuery: UseLazyQuery<
    QueryDefinition<any, any, any, PaginatedItems<any>, any>
  >
  useSendConversationMessageMutation: UseMutation<
    MutationDefinition<any, any, any, any>
  >
  useEditConversationMessageMutation: UseMutation<
    MutationDefinition<any, any, any, any>
  >
  useDeleteConversationMessageMutation: UseMutation<
    MutationDefinition<any, any, any, any>
  >
  isCarePartner?: boolean
}

export const Conversation: React.FC<ConversationProps> = ({
  type,
  useLazyGetConversationQuery,
  useSendConversationMessageMutation,
  useEditConversationMessageMutation,
  useDeleteConversationMessageMutation,
  isCarePartner,
}) => {
  const { id } = useParams<{ id?: string }>()

  const { data } = useMeQuery()

  const [form] = Form.useForm<ConversationValues>()

  const [editMessageForm] = Form.useForm<ConversationTypeDef>()

  const [triggerSendConversationMessageMutation, { isLoading }] =
    useSendConversationMessageMutation()

  const [triggerGetConversationQuery] = useLazyGetConversationQuery()

  const [triggerEditConversationMessage] = useEditConversationMessageMutation()

  const [triggerDeleteConversationMessage] =
    useDeleteConversationMessageMutation()

  const { beforeUpload, imageURL, setPreviewImage } = useUploadImage(form)

  const { columns } = useConversationColumns(
    triggerEditConversationMessage,
    triggerDeleteConversationMessage,
    editMessageForm,
    type,
    id,
    isCarePartner,
  )

  const handleSubmit = async (values: ConversationValues) => {
    await triggerSendConversationMessageMutation({
      body: {
        ...values,
        conversation_type: type,
        image: values?.image?.fileList['0'].originFileObj,
      },
      id,
    })
    form.setFieldsValue({
      image: undefined,
      message: '',
    })
    setPreviewImage(undefined)
    triggerGetConversationQuery({ id, page: 1, type })
  }

  const clearImage = () => {
    form.setFieldValue('image', undefined)
    setPreviewImage(undefined)
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  }

  return (
    <>
      {data?.user.user_type !== UserType.admin && (
        <Form className={styles.form} onFinish={handleSubmit} form={form}>
          <CommunityTextInput
            placeholder={strings.sendPlaceholder}
            previewImage={imageURL}
            isLoading={isLoading}
            beforeUpload={beforeUpload}
            form={form}
            onClearImage={clearImage}
            messageType={TypeOfMessage.ONE_ITEM_AT_TIME}
          />
        </Form>
      )}
      <Form form={editMessageForm} component={false}>
        <PaginatedTable
          columns={columns}
          components={components}
          otherParams={{ type }}
          useLazyQuery={useLazyGetConversationQuery}
          hasBadge={!isCarePartner}
        />
      </Form>
    </>
  )
}
