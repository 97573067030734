import { Form, Spin } from 'antd'
import React, { useEffect } from 'react'
import { Button } from 'src/components/Button'
import { SearchSelect } from 'src/components/SearchSelect'
import { TextInput } from 'src/components/TextInput'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import { TabNames } from 'src/pages/SurvivorView/types'
import { useLazyGetHospitalsQuery } from 'src/store/APIs/hospitals'
import { useUpdateSurvivorMutation } from 'src/store/APIs/survivors'
import { Survivor } from 'src/store/APIs/survivors/types'

import styles from '../survivorView.module.scss'
import { strings } from './strings'

interface HospitalInfoEditProps {
  survivor?: Survivor
  toggleEditMode: () => void
}

export const HospitalInfoEdit: React.FC<HospitalInfoEditProps> = ({
  survivor,
  toggleEditMode,
}) => {
  const [triggerUpdateSurvivor, { isSuccess, isLoading }] =
    useUpdateSurvivorMutation()

  const { fetchedFilteredElems, optionsSelect } = useSearchOptionsSelect(
    useLazyGetHospitalsQuery,
    undefined,
    'name',
  )

  const handleSubmit = async (values: any) => {
    const updatedSurvivorInfo = {
      hospital_id: values.hospital_id.value,
      id: survivor?.id,
      patient_MRN: values.patient_MRN,
      primary_physician_name: values.primary_physician_name,
    }
    await triggerUpdateSurvivor(updatedSurvivorInfo)
  }

  useEffect(() => {
    if (isSuccess) {
      toggleEditMode()
    }
  }, [isSuccess])

  if (!optionsSelect) return <Spin />

  return (
    <Form
      onFinish={handleSubmit}
      name={TabNames.hospital_info}
      initialValues={survivor}>
      <Form.Item initialValue={survivor?.hospital?.id} name="hospital_id">
        <SearchSelect
          required
          tooltipTitle={strings.disabledInputTooltip}
          disabled
          fetchOptions={fetchedFilteredElems}
          options={optionsSelect}
          label={strings.originatingHospitalName}
          placeholder={strings.originatingHospitalName}
        />
      </Form.Item>
      <Form.Item name="primary_physician_name">
        <TextInput label={strings.primaryPhysicianName} />
      </Form.Item>
      <Form.Item name="patient_MRN">
        <TextInput label={strings.patientMrn} />
      </Form.Item>
      <Form.Item>
        <div className={styles.submitButtonContainer}>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            {strings.updateButtonLabel}
          </Button>
          <div className={styles.buttonSeparator} />
          <Button htmlType="button" onClick={toggleEditMode}>
            {strings.cancelButtonLabel}
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}
