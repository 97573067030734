import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddHospitalMutation,
  useDeleteHospitalMutation,
  useLazyGetHospitalQuery,
  useLazyGetHospitalsQuery,
  useUpdateHospitalMutation,
} from 'src/store/APIs/hospitals'

import styles from '../survivorProfile.module.scss'
import { columns } from './columns'
import { strings } from './strings'

export const Hospitals: React.FC = () => {
  const { open, onCloseDrawer, openView, selectedId, showDrawer } = useDrawer()
  const [triggerDeleteHospitalMutation, { isLoading: loadingDelete }] =
    useDeleteHospitalMutation()
  const [triggerAddHospitalMutation, { isLoading: loadingAdd }] =
    useAddHospitalMutation()
  const [triggerEditHospitalMutation, { isLoading: loadingEdit }] =
    useUpdateHospitalMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.hospitalsTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addHospitalButton}
        </Button>
      </div>
      <DrawerWithState
        selectedId={selectedId}
        open={open}
        resourceName={strings.hospitalTitle}
        RenderView={ViewBasicItem}
        RenderAdd={AddBasicItem}
        useLazyGetResource={useLazyGetHospitalQuery}
        onCloseDrawer={onCloseDrawer}
        deleteModalTitle={strings.deleteHospitalModalTitle}
        triggerDeleteResourceMutation={triggerDeleteHospitalMutation}
        triggerAddResourceMutation={triggerAddHospitalMutation}
        triggerEditResourceMutation={triggerEditHospitalMutation}
        isLoadingRightButton={isLoadingRightButton}
        isLoadingLeftButton={loadingDelete}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetHospitalsQuery}
      />
    </div>
  )
}
