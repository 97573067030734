import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SubHeader } from 'src/components/SubHeader'
import { Tabs } from 'src/components/Tabs'
import { RouteName } from 'src/routes/routes'

import { TabNames } from '../SurvivorView/types'
import { BasicDemographicsForm } from './BasicDemographicsForm'
import styles from './survivorNew.module.scss'
import { NewTab } from './types'

interface SurvivorNewProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: 'Survivors', path: RouteName.Survivors },
]

export const SurvivorNew: React.FC<SurvivorNewProps> = () => {
  // INFO: After the integration with Tellescope, this screen will be only be used on STA and RC for testing purposes but
  // the idea is creating new users through Tellescope.
  const newSurvivorTabs: NewTab[] = [
    {
      content: <BasicDemographicsForm />,
      tabName: TabNames.basic_demographics,
    },
  ]

  return (
    <>
      <SubHeader routes={mockedBreadcrumbRoutes} />
      <div className={styles.separatorContainer}>
        <div className={styles.separator} />
      </div>
      <Tabs
        type="card"
        items={newSurvivorTabs?.map(item => {
          const key = item.tabName
          return {
            children: (
              <div className={styles.tabInfoContainer}>{item.content}</div>
            ),
            key,
            label: <div className={styles.tabLabel}>{item.tabName}</div>,
          }
        })}
      />
    </>
  )
}
