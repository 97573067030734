import React from 'react'
import { Drawer } from 'src/components/Drawer'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useDeleteTacasMutation,
  useLazyGetSurvivorsTacasQuery,
} from 'src/store/APIs/tacas'

import { columns } from './columns'
import { strings } from './strings'
import styles from './tacas.module.scss'
import { ViewTacas } from './ViewTacas'

interface TacasProps {}

export const Tacas: React.FC<TacasProps> = () => {
  const [triggerDeleteTacas] = useDeleteTacasMutation()
  const { open, openView, selectedId, onCloseDrawer } = useDrawer()
  const onClickDelete = () => {
    if (selectedId) {
      triggerDeleteTacas({ id: selectedId }).then(onCloseDrawer)
    }
  }

  return (
    <div className={styles.container}>
      <Drawer
        open={open}
        title={strings.tacasLabel}
        onClose={onCloseDrawer}
        leftButtonLabel={strings.deleteLabel}
        leftButtonDanger
        onClickLeftButton={onClickDelete}>
        <ViewTacas tacasId={selectedId} />
      </Drawer>
      <PaginatedTable
        columns={columns(openView)}
        useLazyQuery={useLazyGetSurvivorsTacasQuery}
      />
    </div>
  )
}
