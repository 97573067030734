import {
  PaginatedItems,
  PaginationResponse,
  UserType,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetMobileUsers = '/cms/v1/mobile_users.json',
}

export interface MobileUserResponse {
  full_name: string
  id: string
  user_type: string
  inheritor_id: string
  time_zone?: string
}

export interface MobileUser {
  full_name: string
  id: string
  user_type: string
  inheritor_id: string
  time_zone?: string
}

export interface GetMobileUsersResponse {
  mobile_users: MobileUserResponse[]
  pagination: PaginationResponse
}

export const mobileUserDeserializer = (
  data: MobileUserResponse,
): MobileUser => {
  return {
    full_name: data.full_name,
    id: data.id,
    inheritor_id: data.inheritor_id,
    time_zone: data.time_zone,
    user_type: data.user_type as UserType,
  }
}

export const mobileUsersDeserializer = (
  data: MobileUserResponse[],
): MobileUser[] => data.map(mobileUserDeserializer)

export const getMobileUsersResponseDeserializer = (
  data: GetMobileUsersResponse,
): PaginatedItems<MobileUser> => {
  return {
    items: mobileUsersDeserializer(data.mobile_users),
    pagination: data.pagination,
  }
}
