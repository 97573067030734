import { baseApi } from 'src/store/APIs'

import {
  addHealthcareTeamRole,
  deleteHealthcareTeamRole,
  editHealthcareTeamRole,
  getHealthcareTeamRole,
  getHealthcareTeamRoles,
  getSurvivorHealthcareTeam,
  updateSurvivorsHealthcareTeam,
} from './actions'

export const healthcareTeamApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addHealthcareTeamRole: addHealthcareTeamRole(builder),
    deleteHealthcareTeamRole: deleteHealthcareTeamRole(builder),
    editHealthcareTeamRole: editHealthcareTeamRole(builder),
    getHealthcareTeamRole: getHealthcareTeamRole(builder),
    getHealthcareTeamRoles: getHealthcareTeamRoles(builder),
    getSurvivorHealthcareTeam: getSurvivorHealthcareTeam(builder),
    updateSurvivorsHealthcareTeam: updateSurvivorsHealthcareTeam(builder),
  }),
})

export const {
  useAddHealthcareTeamRoleMutation,
  useUpdateSurvivorsHealthcareTeamMutation,
  useLazyGetSurvivorHealthcareTeamQuery,
  useGetSurvivorHealthcareTeamQuery,
  useLazyGetHealthcareTeamRolesQuery,
  useLazyGetHealthcareTeamRoleQuery,
  useDeleteHealthcareTeamRoleMutation,
  useEditHealthcareTeamRoleMutation,
  reducer: healthcareTeamApiReducer,
  reducerPath: healthcareTeamApiReducerPath,
} = healthcareTeamApi
