import { Button } from 'antd'
import React from 'react'
import { Checkbox } from 'src/components/Checkbox'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import styles from 'src/pages/Learn/learn.module.scss'
import { useLazyGetPackagesQuery } from 'src/store/APIs/packages'

import { useHideChildrenCheckbox } from '../hooks/useHideChildrenCheckbox'
import { areFiltersOn } from '../types'
import { usePackagesColumns } from './columns'
import { PackagesDrawer } from './PackagesDrawer'
import { strings } from './strings'

export const Packages: React.FC = () => {
  const { open, onCloseDrawer, selectedId, showDrawer, openView } = useDrawer()

  const { hideChildrenFilter, hideChildrenIsChecked, toggleHideChildren } =
    useHideChildrenCheckbox()

  const { resetFilters, ...columnsAndFilters } = usePackagesColumns(openView)

  const filtersOn = areFiltersOn(columnsAndFilters.filteredInfo.filters)

  return (
    <>
      <div className={styles.buttonContainer}>
        <Checkbox onChange={toggleHideChildren} checked={hideChildrenIsChecked}>
          {strings.hideChildrenLabel}
        </Checkbox>
        <Button
          className={styles.buttonStyle}
          onClick={resetFilters}
          disabled={!filtersOn}>
          {strings.clearFiltersButtonLabel}
        </Button>
        <Button
          type="primary"
          className={styles.buttonStyle}
          onClick={showDrawer}>
          {strings.addPackageButton}
        </Button>
      </div>
      <PackagesDrawer
        onCloseDrawer={onCloseDrawer}
        open={open}
        selectedId={selectedId}
      />
      <PaginatedTable
        {...columnsAndFilters}
        useLazyQuery={useLazyGetPackagesQuery}
        otherFilters={hideChildrenFilter}
      />
    </>
  )
}
