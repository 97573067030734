import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useEffect } from 'react'
import { Selector, useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store'

import { LearnAddError } from '../Packages/types'

export const useInternalIdValidation = (
  clearAddErrorAction: ActionCreatorWithoutPayload<any>,
  selectAddError: Selector<any, any>,
) => {
  const dispatch = useAppDispatch()
  const error: LearnAddError = useSelector(selectAddError)

  const form = useFormInstance()
  const clearAddError = () => {
    dispatch(clearAddErrorAction())
  }
  useEffect(() => {
    return clearAddError
  }, [])

  useEffect(() => {
    if (error?.data?.errors?.internal_id) {
      form.setFields([
        {
          errors: [error.data.errors.internal_id[0]],
          name: 'internal_id',
        },
      ])
    } else {
      form.setFields([
        {
          errors: [],
          name: 'internal_id',
        },
      ])
    }
  }, [error])
  return {
    clearAddError,
  }
}
