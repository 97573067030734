import { createBrowserHistory } from 'history'
import { constants } from 'src/config/constants'

const history = createBrowserHistory({ basename: constants.publicURL })

enum RouteName {
  Home = 'home',
  NotFound = 'notFound',
  Login = 'login',
  Survivors = 'survivors',
  SurvivorNew = 'survivorNew',
  SurvivorView = 'survivorView',
  SurvivorResetPassword = 'survivorResetPassword',
  CarePartners = 'carePartners',
  CarePartner = 'carePartner',
  CarePartnerResetPassword = 'carePartnerResetPassword',
  CommunityGroups = 'communityGroups',
  CommunityGroup = 'communityGroup',
  CommunityGroupNew = 'communityGroupNew',
  CommunityGroupEdit = 'communityGroupEdit',
  SurvivorAssessmentDetails = 'survivorAssessmentDetails',
  CarePartnerAssessmentDetails = 'carePartnerAssessmentDetails',
  Learn = 'learn',
  SurvivorProfile = 'survivorProfile',
  DefaultNavigatorTasks = 'defaultNavigatorTasks',
}

export enum Paths {
  Home = '/home',
  NotFound = '*',
  Login = '/',
  Survivors = '/survivors',
  SurvivorNew = '/survivors/new',
  SurvivorView = '/survivors/:id',
  SurvivorResetPassword = '/survivors/:id/reset_password',
  CarePartners = '/care_partners',
  CarePartner = '/care_partners/:id',
  CarePartnerResetPassword = '/care_partners/:id/reset_password',
  CommunityGroups = '/groups',
  CommunityGroup = '/groups/:id',
  CommunityGroupNew = '/groups/new',
  CommunityGroupEdit = '/groups/:id/edit',
  SurvivorAssessmentDetails = '/survivors/:id/assessments/:idAssessment',
  CarePartnerAssessmentDetails = '/care_partners/:id/assessments/:idAssessment',
  Learn = '/configuration/learn',
  SurvivorProfile = '/configuration/survivor_profile',
  DefaultNavigatorTasks = '/configuration/default_navigator_tasks',
}

type Route = {
  name: RouteName
  path: string
  exact?: boolean
  pathParams?: string[]
  component?: React.ComponentType
  public?: boolean
}

const PATH_PARAM_REGEX = /:[^/]+/gi
/**
  Analyzes the path defined for `route` and
  returns a copy of the route with a new attribute
  `pathParams` which is a list of strings that correspond to the path params.

  @param {object} route - Object that represents a route.

  @return {object} updated route with the new attribute.

  @example

    determineRouteParams({ name: 'product', path: '/product/:id', component: ProductPage })
*/
const determineRouteParams = (route: Route): Route => {
  const newRoute = { ...route }
  const { path } = newRoute
  // Extract the names of the parameters
  const pathMatch = path.match(PATH_PARAM_REGEX) || []
  const pathParams = pathMatch.map(param => param.slice(1))
  newRoute.pathParams = pathParams || []
  return newRoute
}

const routes = [
  {
    exact: true,
    name: RouteName.Login,
    path: Paths.Login,
    public: true,
  },
  {
    exact: true,
    name: RouteName.Home,
    path: Paths.Home,
  },
  {
    exact: true,
    name: RouteName.Survivors,
    path: Paths.Survivors,
  },
  {
    exact: true,
    name: RouteName.SurvivorNew,
    path: Paths.SurvivorNew,
  },
  {
    exact: true,
    name: RouteName.SurvivorView,
    path: Paths.SurvivorView,
  },
  {
    exact: true,
    name: RouteName.SurvivorResetPassword,
    path: Paths.SurvivorResetPassword,
  },
  {
    exact: true,
    name: RouteName.SurvivorAssessmentDetails,
    path: Paths.SurvivorAssessmentDetails,
  },
  {
    exact: true,
    name: RouteName.CarePartners,
    path: Paths.CarePartners,
  },
  {
    exact: true,
    name: RouteName.CarePartner,
    path: Paths.CarePartner,
  },
  {
    exact: true,
    name: RouteName.CarePartnerResetPassword,
    path: Paths.CarePartnerResetPassword,
  },
  {
    exact: true,
    name: RouteName.CarePartnerAssessmentDetails,
    path: Paths.CarePartnerAssessmentDetails,
  },
  {
    exact: true,
    name: RouteName.CommunityGroups,
    path: Paths.CommunityGroups,
  },
  {
    exact: true,
    name: RouteName.CommunityGroup,
    path: Paths.CommunityGroup,
  },
  {
    exact: true,
    name: RouteName.CommunityGroupNew,
    path: Paths.CommunityGroupNew,
  },
  {
    exact: true,
    name: RouteName.CommunityGroupEdit,
    path: Paths.CommunityGroupEdit,
  },
  {
    exact: true,
    name: RouteName.Learn,
    path: Paths.Learn,
  },
  {
    exact: true,
    name: RouteName.SurvivorProfile,
    path: Paths.SurvivorProfile,
  },
  {
    exact: true,
    name: RouteName.DefaultNavigatorTasks,
    path: Paths.DefaultNavigatorTasks,
  },
  {
    name: RouteName.NotFound,
    path: Paths.NotFound,
    public: true,
  },
].map(determineRouteParams)

export { determineRouteParams, history, RouteName, routes }
