import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { StrokeCauseResponse } from 'src/store/APIs/strokeCauses/types'

import { BasicItemDataIndex } from '../../types'
import { strings } from '../strings'

export const columns: (
  onClickRow?: (id: string) => () => void,
) => ColumnsType<StrokeCauseResponse> = onClickRow => [
  {
    ...generateColumnTitle(BasicItemDataIndex.name, strings.nameColumn),
    render: (title, record) => {
      return record.permissions?.can_read ? (
        <Button type="link" onClick={onClickRow && onClickRow(record.id)}>
          {title}
        </Button>
      ) : (
        <span>{title}</span>
      )
    },
  },
  {
    ...generateColumnTitle(
      BasicItemDataIndex.updated_at,
      strings.updatedAtColumn,
    ),
    render: deserializeShortFormattedDate,
    width: 250,
  },
  {
    ...generateColumnTitle(
      BasicItemDataIndex.created_at,
      strings.createdAtColumn,
    ),
    render: deserializeShortFormattedDate,
    width: 250,
  },
]
