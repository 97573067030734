import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { Drawer } from 'src/components/Drawer'
import { Spinner } from 'src/components/Spinner'
import { Package } from 'src/store/APIs/packages/types'
import { LearnStatus } from 'src/store/APIs/types'

import styles from './drawerWithState.module.scss'
import { strings } from './strings'
import { buttonStyle, modalIconStyle } from './styles'
import { DrawerState, DrawerWithStateProps, getDrawerTitle } from './types'

export const DrawerWithState: React.FC<DrawerWithStateProps> = ({
  RenderEdit,
  RenderAdd,
  RenderView,
  open,
  selectedId,
  onCloseDrawer,
  resourceName = strings.resourcePlaceholder,
  triggerAddResourceMutation,
  triggerEditResourceMutation,
  handleSubmit,
  triggerDeleteResourceMutation,
  isLoadingRightButton,
  isLoadingLeftButton,
  hasSave = false,
  isDraftKey = 'is_draft',
  useLazyGetResource,
  deleteModalTitle,
  disableModalTitle,
  isLearn,
  goBack,
  addData,
  openAnotherDrawer,
  initialDrawerState,
  removeResource,
  ...props
}) => {
  const [form] = Form.useForm()
  const [triggerGetResource, { data, isFetching }] = useLazyGetResource()
  const [drawerState, setDrawerState] = useState<DrawerState>(
    selectedId ? DrawerState.View : DrawerState.Add,
  )

  const [canSubmitForm, setCanSubmitForm] = useState(true)
  const [canSaveDraft, setCanSaveDraft] = useState(true)
  const saveWasPressed = useRef<boolean>(false)

  const closeDrawer = () => {
    form.resetFields()
    onCloseDrawer()
  }

  const onClickSubmit = () => {
    if (drawerState === DrawerState.View) {
      setDrawerState(DrawerState.Edit)
      return
    }
    saveWasPressed.current = false
    form.setFieldValue(isDraftKey, false)
    form.submit()
  }

  const onFinishForm = async (values: any) => {
    const status =
      values.status && values.status !== LearnStatus.draft
        ? values.status
        : LearnStatus.submitted

    const body = isLearn
      ? {
          ...values,
          status,
        }
      : values

    if (drawerState === DrawerState.Add && triggerAddResourceMutation) {
      triggerAddResourceMutation({ body }).then(res => {
        if (!('error' in res) && !saveWasPressed.current) {
          closeDrawer()
        }
      })
    } else if (triggerEditResourceMutation) {
      triggerEditResourceMutation({
        body,
        id: selectedId,
      }).then(res => {
        if (!saveWasPressed.current && !('error' in res)) {
          setDrawerState(DrawerState.View)
          triggerGetResource({ id: selectedId })
        }
      })
    }
  }

  const onClickLeftButton = async () => {
    if (drawerState !== DrawerState.View) {
      if (hasSave) {
        // INFO: We are in Edit or Add mode and we pressed Save button
        form.setFieldValue(isDraftKey, true)
        saveWasPressed.current = true
        form.submit()
      } else {
        // INFO: We are in Edit or Add mode and we pressed Cancel button
        closeDrawer()
      }
      // INFO: If we are in View mode, on the left you can press Delete, Disable or Enable depending on the permissions you have
    } else if (
      triggerDeleteResourceMutation &&
      data?.permissions?.can_destroy
    ) {
      if (deleteModalTitle) {
        Modal.confirm({
          cancelText: generalStrings.cancel,
          icon: <ExclamationCircleOutlined style={modalIconStyle.delete} />,
          okText: generalStrings.delete,
          okType: 'danger',
          onCancel() {},
          onOk() {
            triggerDeleteResourceMutation({ id: selectedId })
            closeDrawer()
          },
          title: deleteModalTitle,
        })
      } else {
        await triggerDeleteResourceMutation({ id: selectedId })
        closeDrawer()
      }
    } else if (triggerEditResourceMutation) {
      if (disableModalTitle && data?.permissions?.can_disable) {
        Modal.confirm({
          cancelButtonProps: {
            style: buttonStyle.regular,
          },
          cancelText: generalStrings.cancel,
          icon: <ExclamationCircleOutlined style={modalIconStyle.disable} />,
          okButtonProps: {
            style: buttonStyle.disable,
          },
          okText: strings.disableLabel,
          onCancel() {},
          onOk() {
            triggerEditResourceMutation({
              body: { ...data, new_status: LearnStatus.disabled },
              id: selectedId,
            })
            closeDrawer()
          },
          title: disableModalTitle,
        })
      } else if (data?.permissions?.can_enable) {
        await triggerEditResourceMutation({
          body: { ...data, new_status: LearnStatus.hasAssignedChildren },
          id: selectedId,
        })
        closeDrawer()
      } else if (
        (data?.permissions?.can_unassign || data?.permissions?.can_remove) &&
        removeResource
      ) {
        if (selectedId) {
          removeResource(
            data?.permissions?.can_unassign,
            closeDrawer,
            (data as Package)?.articles?.map(article => article.id),
          )
        }
      }
      closeDrawer()
    }
  }

  const leftButtonHidden =
    drawerState === DrawerState.View &&
    data?.permissions?.can_destroy === false &&
    data?.permissions?.can_disable === false &&
    data?.permissions?.can_enable === false &&
    data?.permissions?.can_unassign === false &&
    data?.permissions?.can_remove === false

  const rightButtonHidden =
    drawerState === DrawerState.View && data?.permissions?.can_update === false

  const isntViewMode = hasSave && drawerState !== DrawerState.View

  const editionLeftLabel = hasSave ? strings.saveLabel : strings.cancelLabel

  const antDrawerBody = document?.body?.querySelector('.ant-drawer-body')

  const getLeftButtonLabel = (state: DrawerState) => {
    if (state === DrawerState.View) {
      if (data?.permissions?.can_destroy) {
        return strings.deleteLabel
      }
      if (data?.permissions?.can_disable) {
        return strings.disableLabel
      }
      if (data?.permissions?.can_enable) {
        return strings.enableLabel
      }
      if (data?.permissions?.can_unassign) {
        return strings.unassignLabel
      }
      if (data?.permissions?.can_remove) {
        return strings.removeLabel
      }
    }
    return editionLeftLabel
  }

  const getLeftButtonStyle = (state: DrawerState): React.CSSProperties => {
    if (state === DrawerState.View) {
      if (data?.permissions?.can_disable || data?.permissions?.can_enable) {
        return buttonStyle.disable
      }
      if (
        data?.permissions?.can_destroy ||
        data?.permissions?.can_unassign ||
        data?.permissions?.can_remove
      ) {
        return buttonStyle.delete
      }
    }
    return buttonStyle.regular
  }

  useEffect(() => {
    if (data || drawerState === DrawerState.Add) {
      form?.resetFields()
    }
  }, [isFetching, drawerState, open])

  useEffect(() => {
    if (selectedId) {
      // INFO: You have selectedId in View mode, Edit mode and in Add mode after pressing Save
      if (saveWasPressed.current) {
        setDrawerState(DrawerState.Edit)
        saveWasPressed.current = false
      } // INFO: This is needed if you want to open the drawer in Edit mode
      else if (initialDrawerState) {
        setDrawerState(initialDrawerState)
        triggerGetResource({ id: selectedId })
      } else {
        setDrawerState(DrawerState.View)
        triggerGetResource({ id: selectedId })
      }
    } else if (open) {
      setDrawerState(DrawerState.Add)
    }
  }, [selectedId, open])

  useEffect(() => {
    if (antDrawerBody) {
      antDrawerBody.scrollTop = 0
    }
  }, [drawerState])

  return (
    <Drawer
      isLoadingRightButton={isLoadingRightButton}
      isLoadingLeftButton={isLoadingLeftButton}
      destroyOnClose
      title={getDrawerTitle(drawerState, resourceName)}
      open={open}
      leftButtonDanger={drawerState === DrawerState.View}
      onClose={closeDrawer}
      onClickLeftButton={!leftButtonHidden ? onClickLeftButton : undefined}
      rightButtonDisabled={!canSubmitForm && drawerState !== DrawerState.View}
      leftButtonDisabled={
        isntViewMode &&
        !canSaveDraft &&
        (!canSubmitForm || (!data?.is_draft && drawerState !== DrawerState.Add))
      }
      leftButtonLabel={getLeftButtonLabel(drawerState)}
      rightButtonLabel={
        drawerState === DrawerState.View
          ? strings.editLabel
          : strings.submitLabel
      }
      onClickRightButton={!rightButtonHidden ? onClickSubmit : undefined}
      leftButtonStyle={getLeftButtonStyle(drawerState)}
      {...props}>
      {isFetching ? (
        <Spinner />
      ) : (
        <Form
          form={form}
          className={styles.form}
          initialValues={drawerState === DrawerState.Add ? undefined : data}
          onFinish={handleSubmit ?? onFinishForm}>
          <>
            <Form.Item name={isDraftKey} hidden />
            {drawerState === DrawerState.Add && (
              <RenderAdd
                setCanSubmitForm={setCanSubmitForm}
                setCanSaveDraft={setCanSaveDraft}
              />
            )}
            {drawerState === DrawerState.Edit &&
              (RenderEdit ? (
                <RenderEdit
                  data={data || addData}
                  selectedId={selectedId}
                  setCanSaveDraft={setCanSaveDraft}
                  setCanSubmitForm={setCanSubmitForm}
                  {...props}
                />
              ) : (
                <RenderAdd
                  data={data}
                  selectedId={selectedId}
                  setCanSaveDraft={setCanSaveDraft}
                  setCanSubmitForm={setCanSubmitForm}
                  {...props}
                />
              ))}
            {drawerState === DrawerState.View && (
              <RenderView
                data={data}
                goBack={goBack ? onCloseDrawer : undefined}
                openAnotherDrawer={openAnotherDrawer}
              />
            )}
          </>
        </Form>
      )}
    </Drawer>
  )
}
