import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Builder,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from '../types'
import {
  Endpoints,
  getSurvivorTodosDeserializer,
  SurvivorTodosData,
  TodoDetail,
  todoDetailDeserializer,
  TodoModel,
} from './types'

export const addTodo = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: any }>({
    invalidatesTags: () => [{ type: Tags.Todos }],
    query: ({ body }) => ({
      body: {
        task: body,
      },
      method: HttpMethod.Post,
      url: Endpoints.AddTodo,
    }),
    transformResponse: performActionTransformResponse,
  })

export const getSurvivorsTodos = (builder: Builder) =>
  builder.query<PaginatedItems<SurvivorTodosData>, PaginatedParams>({
    providesTags: provideListToTag(Tags.Todos),
    query: paginatedParams(Endpoints.GetSurvivorsTodos),
    transformResponse: getSurvivorTodosDeserializer,
  })

export const getTodo = (builder: Builder) =>
  builder.query<TodoDetail, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Todos }],
    query: ({ id }) => format(Endpoints.GetTodo, id),
    transformResponse: todoDetailDeserializer,
  })

export const updateTodo = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string; body: TodoModel }>({
    invalidatesTags: () => [{ type: Tags.Todos }],
    query: ({ body, id }) => ({
      body: {
        task: body,
      },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateTodo, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteTodo = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.Todos),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteTodo, id),
    }),
    transformResponse: performActionTransformResponse,
  })
