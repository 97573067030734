import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Builder,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from '../types'
import {
  AddQTAData,
  Endpoints,
  getSurvivorQTAsDeserializer,
  QTA,
  qtaDeserializer,
  SurvivorQTA,
} from './types'

export const addQTA = (builder: Builder) =>
  builder.mutation<void, { body: AddQTAData }>({
    invalidatesTags: provideListToTag(Tags.QuestionsToAsk),
    query: ({ body }) => ({
      body: { question: body },
      method: HttpMethod.Post,
      url: Endpoints.AddSurvivorQTA,
    }),
  })

export const getQTA = (builder: Builder) =>
  builder.query<QTA, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.QuestionsToAsk }],
    query: ({ id }) => format(Endpoints.GetSurvivorQTA, id),
    transformResponse: ({ question }) => qtaDeserializer(question),
  })

export const updateQTA = (builder: Builder) =>
  builder.mutation<void, { body: AddQTAData; id: string }>({
    invalidatesTags: () => [{ type: Tags.QuestionsToAsk }],
    query: ({ body, id }) => ({
      body: { question: body },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorQTA, id),
    }),
  })

export const deleteQTA = (builder: Builder) =>
  builder.mutation<void, { id: string }>({
    invalidatesTags: provideListToTag(Tags.QuestionsToAsk),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteSurvivorQTA, id),
    }),
  })

export const getActiveSurvivorsQTAs = (builder: Builder) =>
  builder.query<PaginatedItems<SurvivorQTA>, PaginatedParams>({
    providesTags: provideListToTag(Tags.QuestionsToAsk),
    query: paginatedParams(Endpoints.GetActiveSurvivorsQTAs),
    transformResponse: getSurvivorQTAsDeserializer,
  })

export const getDeletedSurvivorsQTAs = (builder: Builder) =>
  builder.query<PaginatedItems<SurvivorQTA>, PaginatedParams>({
    providesTags: provideListToTag(Tags.QuestionsToAsk),
    query: paginatedParams(Endpoints.GetDeletedSurvivorsQTAs),
    transformResponse: getSurvivorQTAsDeserializer,
  })

export const notifyUncompletedQTA = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    query: ({ id }) => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.NotifyUncompletedQTA, id),
    }),
    transformResponse: performActionTransformResponse,
  })
