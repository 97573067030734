import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { Link } from 'react-router-dom'
import { generateColumnTitle } from 'src/helpers/utils'
import { DocumentsData } from 'src/store/APIs/documents/types'

import { DocumentsDataIndex } from '../../Documents/types'
import { strings } from '../strings'

export const columns: ColumnsType<DocumentsData> = [
  {
    ...generateColumnTitle(DocumentsDataIndex.name, strings.nameLabel),
  },
  {
    ...generateColumnTitle(
      DocumentsDataIndex.description,
      strings.descriptionLabel,
    ),
  },
  {
    ...generateColumnTitle(DocumentsDataIndex.file, strings.fileLabel),
    render(_, record) {
      return (
        <Link to={{ pathname: record.url }} target="_blank">
          {record.fileName}
        </Link>
      )
    },
  },
]
