import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { SubHeader } from 'src/components/SubHeader'
import { constants } from 'src/config/constants'
import { goToPage, RouteName } from 'src/routes'
import {
  useGetSurvivorsQuery,
  useLazyGetSurvivorsQuery,
} from 'src/store/APIs/survivors'

import { useSurvivorColumns } from './columns'
import { strings } from './strings'
import styles from './survivors.module.scss'

interface SurvivorsProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  {
    breadcrumbName: generalStrings.survivorsBreadcrumb,
    path: RouteName.Survivors,
  },
]

export const Survivors: React.FC<SurvivorsProps> = () => {
  const { data } = useGetSurvivorsQuery({ page: 1 })

  const { resetFilters, ...columnsAndFilters } = useSurvivorColumns()

  const enableAddUser = constants.addUserEnabled

  const onClickAddUser = () => goToPage(RouteName.SurvivorNew)

  return (
    <div className={styles.container}>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.Users}
        title={strings.title}
        className={styles.subHeader}>
        <div className={styles.buttonsContainer}>
          <Button onClick={resetFilters}>
            {generalStrings.clearFiltersButtonLabel}
          </Button>
          <Button
            type="primary"
            disabled={!enableAddUser || !data?.model_permissions?.can_create}
            tooltipTitle={!enableAddUser ? strings.disabledAddUserButton : ''}
            onClick={onClickAddUser}>
            {strings.newButtonLabel}
          </Button>
        </div>
      </SubHeader>
      <PaginatedTable
        className={styles.table}
        useLazyQuery={useLazyGetSurvivorsQuery}
        {...columnsAndFilters}
        scroll={{ x: 1600 }}
        preferCacheValue={false}
      />
    </div>
  )
}
