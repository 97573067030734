import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addressLabel: 'Address',
    ambassadorLabel: 'Ambassador',
    carePartnerLabel: 'Care Partner',
    cityLabel: 'City',
    clinicianLabel: 'Clinician',
    dateOfBirthLabel: 'Date Of Birth',
    emailLabel: 'Email',
    emergencyContactLabel: 'Emergency Contact',
    ethnicityLabel: 'Ethnicity',
    familySituationLabel: 'Family Situation',
    firstNameLabel: 'First Name',
    genderIdentityLabel: 'Gender Identity',
    homePhoneLabel: 'Home Phone',
    insuranceInfoLabel: 'Insurance Info',
    languageLabel: 'Language',
    lastNameLabel: 'Last Name',
    legalSexLabel: 'Legal Sex',
    livingSituationLabel: 'Living Situation',
    mobilePhoneLabel: 'Mobile Phone',
    navigatorLabel: 'Navigator',
    onboardingDateLabel: 'Onboarding Date',
    preferredLabel: 'Preferred',
    preferredNameLabel: 'Preferred Name',
    preferredPronounLabel: 'Preferred Pronoun',
    raceLabel: 'Race',
    registrationDateLabel: 'Registration Date',
    stateLabel: 'State',
    tacasEnabledLabel: 'Tacas Enabled',
    tagNo: 'NO',
    tagYes: 'YES',
    testUserLabel: 'Test User',
    timezoneLabel: 'Time zone',
    zipCodeLabel: 'Zip Code',
  },
})
