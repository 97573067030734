import { TagProps } from 'antd'

import { strings } from './strings'

export enum AssessmentsDataIndex {
  'assessment' = 'assessment',
  'due_time' = 'due_time',
  'deployment_date' = 'deployment_date',
  'status' = 'status',
}

export enum AssessmentStatusOptions {
  Scheduled = 'Scheduled',
  Deployed = 'Deployed',
  Completed = 'Completed',
  Overdue = 'Overdue',
}

export interface AssessmentStatusProps {
  title: AssessmentStatusOptions | string
  color: TagProps['color']
}

export const assessmentStatusProps = (
  status: AssessmentStatusOptions,
): AssessmentStatusProps => {
  switch (status) {
    case AssessmentStatusOptions.Completed:
      return {
        color: 'green',
        title: AssessmentStatusOptions.Completed,
      }
    case AssessmentStatusOptions.Scheduled:
      return {
        color: 'black',
        title: AssessmentStatusOptions.Scheduled,
      }
    case AssessmentStatusOptions.Deployed:
      return {
        color: 'blue',
        title: AssessmentStatusOptions.Deployed,
      }
    case AssessmentStatusOptions.Overdue:
      return {
        color: 'red',
        title: AssessmentStatusOptions.Overdue,
      }
    default:
      return {
        color: 'default',
        title: strings.notDefined,
      }
  }
}

export const assessmentsFilters = [
  {
    text: AssessmentStatusOptions.Completed,
    value: AssessmentStatusOptions.Completed,
  },
  {
    text: AssessmentStatusOptions.Scheduled,
    value: AssessmentStatusOptions.Scheduled,
  },
  {
    text: AssessmentStatusOptions.Deployed,
    value: AssessmentStatusOptions.Deployed,
  },
  {
    text: AssessmentStatusOptions.Overdue,
    value: AssessmentStatusOptions.Overdue,
  },
]
