import React from 'react'
import {
  ArticleMode,
  ExtendedArticleInfo,
  PageResponse,
} from 'src/store/APIs/articles/types'

import { Content } from './Content'
import { Paginated } from './Paginated'
import { Ssml } from './Ssml'

interface ContentEditionProps {
  article?: ExtendedArticleInfo
  mode?: ArticleMode
  triggerPaginateContentMutation: (content: string) => void
  initialPages?: PageResponse[]
  triggerGenerateSsmlMutation: (data: PageResponse[]) => void
  resetContent: () => void
  isLoading: boolean
}

export const ContentEdition: React.FC<ContentEditionProps> = props => {
  switch (props.mode) {
    case ArticleMode.Paginated:
      return <Paginated {...props} />
    case ArticleMode.Ssml:
      return <Ssml {...props} />
    default:
      return <Content {...props} />
  }
}
