import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  GetSurvivorHealthcareTeamData,
  getSurvivorHealthcareTeamsDeserializer,
  healthcareRoleDeserializer,
  healthcareRolesDeserializer,
  HealthcareTeamRoleData,
} from './types'

export const getHealthcareTeamRoles = (builder: Builder) =>
  builder.query<PaginatedItems<HealthcareTeamRoleData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.HealthcareTeamRoles),
    query: endpointWithFilters(Endpoints.HealthcareTeamRoles),
    transformResponse: healthcareRolesDeserializer,
  })

export const getSurvivorHealthcareTeam = (builder: Builder) =>
  builder.query<PaginatedItems<GetSurvivorHealthcareTeamData>, PaginatedParams>(
    {
      providesTags: (_, __, { id }) => [{ id, type: Tags.HealthcareTeam }],
      query: paginatedParams(Endpoints.GetSurvivorHealthcareTeam),
      transformResponse: getSurvivorHealthcareTeamsDeserializer,
    },
  )

export const updateSurvivorsHealthcareTeam = (builder: Builder) =>
  builder.mutation<
    void,
    { health_care_team: Partial<GetSurvivorHealthcareTeamData>[]; id: string }
  >({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.HealthcareTeam }],
    query: ({ id, health_care_team }) => ({
      body: { health_care_team },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorHealthcareTeam, id),
    }),
  })

export const addHealthcareTeamRole = (builder: Builder) =>
  builder.mutation<void, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.HealthcareTeamRoles),
    query: ({ body }) => ({
      body: { health_care_role: body },
      method: HttpMethod.Post,
      url: Endpoints.AddHealthcareTeamRole,
    }),
  })

export const getHealthcareTeamRole = (builder: Builder) =>
  builder.query<HealthcareTeamRoleData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.HealthcareTeamRoles }],
    query: ({ id }) => format(Endpoints.GetHealthcareTeamRole, id),
    transformResponse: ({ health_care_role }) =>
      healthcareRoleDeserializer(health_care_role),
  })

export const deleteHealthcareTeamRole = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.HealthcareTeamRoles),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteHealthcareTeamRole, id),
    }),
    transformResponse: performActionTransformResponse,
  })
export const editHealthcareTeamRole = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.HealthcareTeamRoles }],
    query: ({ body, id }) => ({
      body: { health_care_role: body },
      method: HttpMethod.Put,
      url: format(Endpoints.EditHealthcareTeamRole, id),
    }),
    transformResponse: performActionTransformResponse,
  })
