import { RcFile, UploadFile } from 'antd/es/upload'
import { useState } from 'react'
import { getBase64 } from 'src/common/helpers'

export const useImagePreview = () => {
  const [previewImageURL, setPreviewImageURL] = useState<string>('')
  const [previewOpen, setPreviewOpen] = useState(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }
    setPreviewImageURL(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  return {
    handlePreview,
    previewImageURL,
    previewOpen,
    setPreviewImageURL,
    setPreviewOpen,
  }
}
