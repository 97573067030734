import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Builder,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from '../types'
import {
  Endpoints,
  getSurvivorTacasDeserializer,
  Tacas,
  tacasDeserializer,
  TacasDetails,
} from './types'

export const getSurvivorsTacas = (builder: Builder) =>
  builder.query<PaginatedItems<Tacas>, PaginatedParams>({
    providesTags: provideListToTag(Tags.Tacas),
    query: paginatedParams(Endpoints.GetSurvivorTacas),
    transformResponse: getSurvivorTacasDeserializer,
  })

export const getTacas = (builder: Builder) =>
  builder.query<TacasDetails, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Tacas }],
    query: ({ id }) => format(Endpoints.GetTacas, id),
    transformResponse: ({ tacas }) => tacasDeserializer(tacas),
  })

export const deleteTacas = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.Tacas),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteTacas, id),
    }),
    transformResponse: performActionTransformResponse,
  })
