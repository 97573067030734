import { TagProps } from 'antd'

import { strings } from './strings'

export interface StatusProps {
  title: StatusOptions
  color: TagProps['color']
}

export enum StatusOptions {
  NotStarted = 'Not started',
  InProgress = 'In Progress',
  Completed = 'Completed',
  OnHold = 'On Hold',
  DoesNotApply = 'Does Not Apply',
}

export const statusProps = (status: StatusOptions): StatusProps => {
  switch (status) {
    case StatusOptions.Completed:
      return {
        color: 'green',
        title: StatusOptions.Completed,
      }
    case StatusOptions.NotStarted:
      return {
        color: 'geekblue',
        title: StatusOptions.NotStarted,
      }
    case StatusOptions.InProgress:
      return {
        color: 'gold',
        title: StatusOptions.InProgress,
      }
    case StatusOptions.OnHold:
      return {
        color: 'magenta',
        title: StatusOptions.OnHold,
      }
    case StatusOptions.DoesNotApply:
      return {
        color: 'default',
        title: StatusOptions.DoesNotApply,
      }
    default:
      return {
        color: 'default',
        title: StatusOptions.NotStarted,
      }
  }
}

export interface TypeProps {
  title: TypeOptions | string
  color: TagProps['color']
}

export enum TypeOptions {
  Enrollment = 'Enrollment',
  Onboarding = 'Onboarding',
}

export enum TypeLabels {
  Enrollment = 'Registration',
  Onboarding = 'Onboarding',
}

export const typeProps = (type: TypeOptions): TypeProps => {
  switch (type) {
    case TypeOptions.Enrollment:
      return {
        color: 'purple',
        title: TypeLabels.Enrollment,
      }
    case TypeOptions.Onboarding:
      return {
        color: 'blue',
        title: TypeLabels.Onboarding,
      }
    default:
      return {
        color: 'default',
        title: strings.undefinedLabel,
      }
  }
}

export enum NavigatorTasksDataIndex {
  task = 'task',
  created_at = 'created_at',
  completed_date = 'completed_date',
  status = 'status',
  notes = 'notes',
  category = 'category',
  type = 'type_of',
  goal_date = 'goal_date',
  time_spent = 'time_spent',
}
