/* eslint-disable import/no-extraneous-dependencies */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Modal, Upload } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { UploadFile } from 'antd/es/upload'
import ImgCrop from 'antd-img-crop'
import React, { useEffect } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { Button } from 'src/components/Button'
import { deleteModalIcon } from 'src/components/DeleteButton'
import { useUploadImage } from 'src/hooks/useUploadImage'

import styles from '../addPackage.module.scss'
import { strings } from '../strings'

interface AddImageProps {
  initialImageURL?: string
}

const MB = 1000000
const IMG_WIDTH = 335
const IMG_HEIGHT = 115

export const AddImage: React.FC<AddImageProps> = ({ initialImageURL }) => {
  const form = useFormInstance()

  const beforeUploadExtraChecks = (file: UploadFile) => {
    if (file.size && file.size > 10 * MB) {
      message.error(strings.imageSizeError)
      return false
    }
    return true
  }

  const { beforeUpload, imageURL, previewImage, setPreviewImage } =
    useUploadImage(form, beforeUploadExtraChecks, initialImageURL)

  const resetImage = () => {
    Modal.confirm({
      cancelText: generalStrings.cancel,
      icon: deleteModalIcon,
      okText: generalStrings.delete,
      okType: 'danger',
      onOk: () => {
        setPreviewImage(undefined)
        form.setFieldValue('image', undefined)
      },
      title: strings.resetImageModalTitle,
    })
  }

  useEffect(() => {
    if (initialImageURL) {
      form.setFieldValue('image', initialImageURL)
    }
  }, [])

  return (
    <>
      {previewImage && (
        <div>
          <div className={styles.imagePreviewContainer}>
            <div className={styles.overlay}>
              <div className={styles.separator} />
              <Button type="text" shape="circle" onClick={resetImage}>
                <DeleteOutlined className={styles.icon} />
              </Button>
            </div>
            <img
              width={IMG_WIDTH}
              height={IMG_HEIGHT}
              src={imageURL}
              alt="preview"
              className={styles.imagePreview}
            />
          </div>
        </div>
      )}
      {!previewImage && (
        <>
          <ImgCrop aspect={IMG_WIDTH / IMG_HEIGHT}>
            <Upload
              listType="picture-card"
              accept="image/*"
              beforeUpload={beforeUpload}
              className={styles.upload}
              maxCount={1}>
              <div>
                <PlusOutlined />
                <div className={styles.uploadLabel}>{strings.uploadLabel}</div>
              </div>
            </Upload>
          </ImgCrop>
          <p className={styles.optionalLabel}>
            {strings.imageRequirementsLabel}
          </p>
        </>
      )}
    </>
  )
}
