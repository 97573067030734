/* eslint-disable jsx-a11y/media-has-caption */
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Divider, Form, FormListFieldData } from 'antd'
import React, {
  ChangeEventHandler,
  CSSProperties,
  MutableRefObject,
  useEffect,
} from 'react'
import { Button } from 'src/components/Button'
import { DeleteButton } from 'src/components/DeleteButton'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { RichTextEditor } from 'src/components/RichTextEditor'
import { Uploading } from 'src/components/Uploading'
import {
  ExtendedArticleInfo,
  PageResponse,
} from 'src/store/APIs/articles/types'

import { useAudioGeneration } from '../../hooks/useAudioGeneration'
import { useUploadMedia } from '../../hooks/useUploadMedia'
import { strings } from '../../strings'
import { modalIconStyle, textAreaStyle } from '../../styles'
import { quillArticleToolbar, SsmlError } from '../../types'
import { validatePage, validateSsmlPage } from '../../validations'
import styles from './ssml.module.scss'

interface SsmlProps {
  initialPages?: PageResponse[]
  resetContent: () => void
  article?: ExtendedArticleInfo
}

interface SsmlPageProps {
  index: number
  page: FormListFieldData
  error: SsmlError
  validateFirstTime: MutableRefObject<boolean>
  initialValue?: PageResponse[]
  onChangeSsml: ChangeEventHandler<HTMLTextAreaElement>
}

const SsmlPage = ({
  index,
  page,
  onChangeSsml,
  initialValue,
  validateFirstTime,
  error,
}: SsmlPageProps) => {
  const { quillRef, handlers, isLoadingMedia } = useUploadMedia()
  const pageLabel = `${strings.pageLabel} ${index + 1}`
  return (
    <>
      <h4>{pageLabel}</h4>
      <Uploading visible={isLoadingMedia} />
      <div key={page.key} className={styles.row}>
        <Form.Item name={[page.name, 'id']} hidden />
        <Form.Item
          name={[page.name, 'content']}
          rules={[{ validator: validatePage }]}
          className={styles.pageItem}>
          <RichTextEditor
            quillRef={quillRef}
            label={strings.contentLabel}
            className={styles.input}
            toolbar={{ ...quillArticleToolbar, handlers }}
          />
        </Form.Item>
        <Form.Item
          initialValue={
            initialValue ? initialValue[index].ssml_content : undefined
          }
          name={[page.name, 'ssml_content']}
          rules={[
            {
              validator: validateSsmlPage(index, error, validateFirstTime),
            },
          ]}
          className={styles.pageItem}>
          <MultilineTextInput
            onChange={onChangeSsml}
            label={strings.ssmlLabel}
            className={styles.multilineInput}
            style={textAreaStyle as CSSProperties}
            quillHeader
          />
        </Form.Item>
      </div>
    </>
  )
}

export const Ssml: React.FC<SsmlProps> = ({
  initialPages,
  resetContent,
  article,
}) => {
  const hasSsml = article?.pages_attributes?.some(page => page.ssml_content)

  const pagesFormName = hasSsml ? 'pages_attributes' : 'pages_attributes_edited'

  const {
    audio,
    loading,
    onClickGenerateAudio,
    onDelete,
    audioButtonDisabled,
    error,
    validateFirstTime,
    onChangeSsml,
  } = useAudioGeneration(pagesFormName, article)

  const modalIcon = <ExclamationCircleOutlined style={modalIconStyle} />

  const initialValue = hasSsml ? article?.pages_attributes : initialPages

  const form = Form.useFormInstance()

  const articleAudioUrl = audio?.file?.url || article?.audio?.file?.url

  useEffect(() => {
    if (hasSsml) {
      form.setFieldValue(pagesFormName, article?.pages_attributes)
    } else {
      form.setFieldValue(pagesFormName, initialPages)
    }
  }, [])

  return (
    <div>
      <Divider className={styles.divider} />
      <div className={styles.paginatedContentHeader}>
        <h3>{strings.contentLabel}</h3>
        <DeleteButton
          cancelText={strings.cancelLabel}
          okText={strings.deleteLabel}
          onOk={resetContent}
          modalIcon={modalIcon}
          modalTitle={strings.deleteContentConfirmation}>
          {strings.resetContentLabel}
        </DeleteButton>
      </div>
      <Form.Item name="content" hidden />
      {initialValue && (
        <Form.List name={pagesFormName}>
          {pages => {
            return pages.map((page, index) => (
              <SsmlPage
                key={page.name}
                index={index}
                page={page}
                error={error as SsmlError}
                validateFirstTime={validateFirstTime}
                onChangeSsml={onChangeSsml}
                initialValue={initialValue}
              />
            ))
          }}
        </Form.List>
      )}
      <Button
        disabled={audioButtonDisabled && !loading}
        loading={loading}
        title={audioButtonDisabled && strings.audioButtonDisabled}
        type="primary"
        onClick={onClickGenerateAudio}>
        {articleAudioUrl
          ? strings.regenerateAudioLabel
          : strings.generateAudioLabel}
      </Button>
      {articleAudioUrl && (
        <>
          <p>{strings.audioLabel}</p>
          <div className={styles.audioRow}>
            <Form.Item name="article_audio_id" />
            <audio controls src={articleAudioUrl} />
            <DeleteButton
              modalTitle={strings.audioDeleteModalTitle}
              onOk={onDelete}
              className={styles.deleteButton}
              cancelText={strings.cancelLabel}
              okText={strings.deleteLabel}
              modalIcon={modalIcon}>
              {strings.deleteAudioLabel}
            </DeleteButton>
          </div>
        </>
      )}
    </div>
  )
}
