import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from '../types'
import {
  AddPurchaseTodo,
  Endpoints,
  getSurvivorPurchaseTodoDeserializer,
  getSurvivorPurchaseTodosDeserializer,
  RetailProduct,
  retailProductsDeserializer,
  SurvivorPurchaseTodoData,
  SurvivorPurchaseTodoIndexData,
} from './types'

export const getSurvivorsPurchaseTodos = (builder: Builder) =>
  builder.query<PaginatedItems<SurvivorPurchaseTodoIndexData>, PaginatedParams>(
    {
      providesTags: provideListToTag(Tags.PurchaseTodos),
      query: paginatedParams(Endpoints.GetSurvivorsPurchaseTodos),
      transformResponse: getSurvivorPurchaseTodosDeserializer,
    },
  )

export const getPurchaseTodo = (builder: Builder) =>
  builder.query<SurvivorPurchaseTodoData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.PurchaseTodos }],
    query: ({ id }) => format(Endpoints.GetPurchaseTodo, id),
    transformResponse: ({ purchase_task }) =>
      getSurvivorPurchaseTodoDeserializer(purchase_task),
  })

export const getRetailProducts = (builder: Builder) =>
  builder.query<PaginatedItems<RetailProduct>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.RetailProducts),
    query: endpointWithFilters(Endpoints.GetRetailProducts),
    transformResponse: retailProductsDeserializer,
  })

export const addPurchaseTodo = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    {
      body: AddPurchaseTodo
    }
  >({
    invalidatesTags: provideListToTag(Tags.PurchaseTodos),
    query: ({ body }) => ({
      body: {
        purchase_task: {
          ...body,
          additional_details: body.description,
          description: body.todo,
        },
      },
      method: HttpMethod.Post,
      url: Endpoints.AddPurchaseTodo,
    }),
    transformResponse: performActionTransformResponse,
  })

export const updatePurchaseTodo = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { id: string; body: AddPurchaseTodo }
  >({
    invalidatesTags: () => [{ type: Tags.PurchaseTodos }],
    query: ({ body, id }) => ({
      body: {
        purchase_task: {
          ...body,
          additional_details: body.description,
          description: body.todo,
        },
      },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdatePurchaseTodo, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deletePurchaseTodo = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.QuestionsToAsk),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeletePurchaseTodo, id),
    }),
    transformResponse: performActionTransformResponse,
  })
