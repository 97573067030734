import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useEffect, useMemo } from 'react'
import {
  Filters,
  PaginatedItems,
  ParamsWithFilters,
} from 'src/store/APIs/types'

import { strings } from './strings'

// TODO: fix: when used in dropdowns w/ multiple pages of values, only the first page options appear unless a filter term is entered
export const useSearchOptionsSelect = (
  useLazyQuery: UseLazyQuery<
    QueryDefinition<ParamsWithFilters, any, any, PaginatedItems<any>, any>
  >,
  selectedItems?: any[], // INFO: {id: string, 'filterName': string}[]
  filterName: string = strings.filterNameDefaultValue, // INFO: the default value is 'full_name'
  otherFilters?: Filters,
  refreshListDependencies: any[] = [],
) => {
  const [triggerLazyQuery, { data: firstPageElements, isLoading }] =
    useLazyQuery()

  const optionsSelect = useMemo(() => {
    const options = firstPageElements
      ? firstPageElements.items?.map(item => ({
          description: item.description,
          label: item[filterName],
          value: item.id,
          ...item,
        }))
      : undefined

    const firstPageElementsIds = options?.map(item => item.value)

    if (selectedItems) {
      selectedItems.forEach(item => {
        if (!firstPageElementsIds?.includes(item.id)) {
          options?.push({
            description: item.description,
            label: item[filterName],
            value: item.id,
            ...item,
          })
        }
      })
    }

    return options
  }, [firstPageElements, selectedItems])

  const fetchedFilteredElems = (filterValue: string) =>
    triggerLazyQuery({
      filters: { [filterName]: [filterValue], ...otherFilters },
    })
      .unwrap()
      .then(res =>
        res.items.map(item => ({
          description: item.description,
          label: item[filterName],
          value: item.id,
          ...item,
        })),
      )

  useEffect(() => {
    triggerLazyQuery({ filters: otherFilters, page: 1 })
  }, [...refreshListDependencies])

  return { fetchedFilteredElems, isLoading, optionsSelect }
}
