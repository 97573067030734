import {
  AddAssessmentData,
  AssessmentDetail,
  AssessmentDetailResponse,
  assessmentDetailsDeserializer,
  AssessmentsData,
  assessmentsDeserializer,
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { GeneralTagIds, HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Assessment,
  AssessmentAnswerPayload,
  AssessmentCreateAndAnswerPayload,
  AssessmentDraft,
  assessmentDraftDeserializer,
  Endpoints,
} from './types'

export const getAssessments = (builder: Builder) =>
  builder.query<Assessment[], { type: string }>({
    providesTags: provideListToTag(Tags.Assessments),
    query: ({ type }) => format(Endpoints.GetAssessments, `?type=${type}`),
    transformResponse: ({ assessments }) => assessments,
  })

export const getSurvivorsAssessments = (builder: Builder) =>
  builder.query<PaginatedItems<AssessmentsData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Assessments),
    query: endpointWithFilters(Endpoints.GetSurvivorsAssessments),
    transformResponse: assessmentsDeserializer,
  })

export const getSurvivorAssessmentDetails = (builder: Builder) =>
  builder.query<AssessmentDetail, { idUser: string; idAssessment: string }>({
    providesTags: (_, __, { idAssessment }) => [
      { id: idAssessment, type: Tags.Assessments },
      { id: idAssessment, type: Tags.Alerts },
    ],
    query: ({ idUser, idAssessment }) =>
      format(Endpoints.GetSurvivorAssessmentDetails, idUser, idAssessment),
    transformResponse: ({ assign_assessment }) =>
      assessmentDetailsDeserializer(assign_assessment),
  })

export const deleteSurvivorAssessment = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { idUser: string; idElem: string }>({
    invalidatesTags: provideListToTag(Tags.Assessments),
    query: ({ idUser, idElem }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteSurvivorAssessment, idUser, idElem),
    }),
    transformResponse: performActionTransformResponse,
  })

export const assignSurvivorAssessment = (builder: Builder) =>
  builder.mutation<
    AssessmentDetailResponse,
    { assign_assessment: AddAssessmentData; id: string }
  >({
    invalidatesTags: provideListToTag(Tags.Assessments),
    query: ({ assign_assessment, id }) => ({
      body: { assign_assessment },
      method: HttpMethod.Post,
      url: format(Endpoints.AssignSurvivorAssessment, id),
    }),
  })

export const getCarePartnerAssessments = (builder: Builder) =>
  builder.query<PaginatedItems<AssessmentsData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Assessments),
    query: endpointWithFilters(Endpoints.GetCarePartnerAssessments),
    transformResponse: assessmentsDeserializer,
  })

export const assignCarePartnerAssessment = (builder: Builder) =>
  builder.mutation<
    AssessmentDetailResponse,
    { assign_assessment: AddAssessmentData; id: string }
  >({
    invalidatesTags: provideListToTag(Tags.Assessments),
    query: ({ assign_assessment, id }) => ({
      body: { assign_assessment },
      method: HttpMethod.Post,
      url: format(Endpoints.AssignCarePartnerAssessment, id),
    }),
  })

export const getCarePartnerAssessmentDetails = (builder: Builder) =>
  builder.query<AssessmentDetail, { idUser: string; idAssessment: string }>({
    providesTags: (_, __, { idAssessment }) => [
      { id: idAssessment, type: Tags.Assessments },
    ],
    query: ({ idUser, idAssessment }) =>
      format(Endpoints.GetCarePartnerAssessmentDetails, idUser, idAssessment),
    transformResponse: ({ assign_assessment }) =>
      assessmentDetailsDeserializer(assign_assessment),
  })

export const deleteCarePartnerAssessment = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { idUser: string; idElem: string }>({
    invalidatesTags: provideListToTag(Tags.Assessments),
    query: ({ idUser, idElem }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteCarePartnerAssessment, idUser, idElem),
    }),
    transformResponse: performActionTransformResponse,
  })

export const editAssessment = (builder: Builder) =>
  builder.mutation<
    AssessmentsData,
    { id: string; body: Partial<AssessmentsData> }
  >({
    invalidatesTags: () => [{ type: Tags.Assessments }],
    query: ({ id, body }) => ({
      body: { assign_assessment: body },
      method: HttpMethod.Patch,
      url: format(Endpoints.EditAssessment, id),
    }),
  })

export const getAssessmentQuestions = (builder: Builder) =>
  builder.query<AssessmentDraft, string>({
    providesTags: (_, __, id) => [{ id, type: Tags.Assessments }],
    query: id => format(Endpoints.GetAssessmentQuestions, id),
    transformResponse: assessmentDraftDeserializer,
  })

export const answerAssessment = (builder: Builder) =>
  builder.mutation<
    AssessmentsData,
    { id: string; body: AssessmentAnswerPayload }
  >({
    invalidatesTags: () => [{ type: Tags.Assessments }, { type: Tags.Alerts }],
    query: ({ id, body }) => ({
      body: { assign_assessment: body },
      method: HttpMethod.Put,
      url: format(Endpoints.AnswerAssessment, id),
    }),
  })

export const checkScore = (builder: Builder) =>
  builder.mutation<
    { finish: boolean },
    { id: string; body: AssessmentCreateAndAnswerPayload }
  >({
    invalidatesTags: () => [{ type: Tags.Assessments }],
    query: ({ id, body }) => ({
      body: { assign_assessment: body },
      method: HttpMethod.Put,
      url: format(Endpoints.CheckScore, id),
    }),
  })

export const createAndCheckScore = (builder: Builder) =>
  builder.mutation<
    { finish: boolean },
    { body: AssessmentCreateAndAnswerPayload }
  >({
    invalidatesTags: () => [{ type: Tags.Assessments }],
    query: ({ body }) => ({
      body: { assign_assessment: body },
      method: HttpMethod.Put,
      url: Endpoints.CreateAndCheckScore,
    }),
  })

export const createAndAnswerAssessment = (builder: Builder) =>
  builder.mutation<void, { body: AssessmentCreateAndAnswerPayload }>({
    invalidatesTags: () => [
      { id: GeneralTagIds.List, type: Tags.Assessments },
      { type: Tags.Alerts },
    ],
    query: ({ body }) => ({
      body: { assign_assessment: body },
      method: HttpMethod.Post,
      url: Endpoints.CreateAndAnswerAssessment,
    }),
  })
