import { FormInstance } from 'antd'
import { RcFile } from 'antd/es/upload'
import dayjs, { Dayjs } from 'dayjs'
import * as yup from 'yup'
import { OptionalObjectSchema } from 'yup/lib/object'

import { generalStrings } from './generalStrings'
import { PHONE_REGEX } from './regex'
import { Option, PreferredPhoneFields } from './types'
import { textNotEmptyValidator } from './validators'

export const yupSyncGenerator = (schema: OptionalObjectSchema<any>) => ({
  async validator({ field }: any, value: any) {
    schema.validateSyncAt(field, { [field]: value })
  },
})

export const disableDateAfterToday = (current: Dayjs) => {
  return current > dayjs().endOf('day')
}

export const disableDateBeforeToday = (current: Dayjs) => {
  return current.isBefore(dayjs(), 'day')
}

// INFO: Created to compare dates that are converted to UTC
export const disableDateBeforeTodayUTC = (current: Dayjs, tz?: string) => {
  return current.isBefore(dayjs().tz(tz).utc(true), 'minute')
}

export const disableDateBeforeCustomDate = (
  date: Dayjs | null,
  currentDate: Dayjs,
) => {
  if (!date) {
    return true // INFO: Disable all dates if "date" is not defined
  }
  return currentDate && currentDate.isBefore(date, 'day')
}

export const transformPhoneNumber = (value?: string): string | undefined => {
  if (!value) {
    return value
  }
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length
  let newPhoneNumber

  if (cvLength < 4) {
    newPhoneNumber = currentValue
  } else if (cvLength < 7) {
    newPhoneNumber = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
  } else {
    newPhoneNumber = `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6,
    )}-${currentValue.slice(6, 10)}`
  }

  return newPhoneNumber
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export const arrayToOptions = (item: string): Option => ({
  label: item,
  value: item,
})

export const jsonToFormData = (json: Object) => {
  const formData = new FormData()
  Object.entries(json).forEach(([key, value]) => {
    if (value !== undefined) {
      formData.append(key, value)
    }
  })
  return formData
}

export const booleanToString = (value: boolean) => (value ? 'true' : 'false')

export const mergeArrayInPlace = (a: any[], b: any[], i = 0) =>
  a.splice(i, 0, ...b)

export const getUserFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
) => `${firstName} ${lastName}`

export const capitalizeString = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

export const removeUnderscores = (str: string): string => {
  return str.replace('_', ' ')
}

export const modifyPreferredPhoneRadioState = (
  phoneNumberFormValue: string,
  homePhoneFormValue: string,
  form: FormInstance<any | undefined>,
) => {
  if (
    textNotEmptyValidator(phoneNumberFormValue) &&
    !textNotEmptyValidator(homePhoneFormValue)
  ) {
    form.setFieldsValue({
      home_phone_preferred: undefined,
      phone_number_preferred: PreferredPhoneFields.MobilePhonePreferred,
    })
  } else if (
    textNotEmptyValidator(homePhoneFormValue) &&
    !textNotEmptyValidator(phoneNumberFormValue)
  ) {
    form.setFieldsValue({
      home_phone_preferred: PreferredPhoneFields.HomePhonePreferred,
      phone_number_preferred: undefined,
    })
  } else if (
    !textNotEmptyValidator(phoneNumberFormValue) &&
    !textNotEmptyValidator(homePhoneFormValue)
  ) {
    form.setFieldsValue({
      home_phone_preferred: undefined,
      phone_number_preferred: undefined,
    })
  }
}

export const scrollToTheTop = () =>
  window.scrollTo({ behavior: 'auto', top: 0 })

const urlRegex =
  /\b((https?:\/\/)?(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,})(?:[/\w.-]*)*\/?(?:\?[a-z0-9=&%-_.~+]*[#/\w.-]*)?)\b/gi

export const hyperlinkConverter = (text?: string) =>
  text?.replace(
    urlRegex,
    url =>
      `<a href="${
        url.startsWith('http') ? url : `https://${url}`
      }" target="_blank">${url.toLowerCase()}</a>`,
  ) ?? ''

export const phoneValidator = yup
  .string()
  .test('is-valid-phone', generalStrings.phoneNumberFormatValidation, value => {
    if (!value) {
      return true
    }
    let newValue: string | undefined = value
    if (!newValue.includes('(')) {
      newValue = transformPhoneNumber(newValue)
    }
    return PHONE_REGEX.test(newValue ?? value)
  })
  .notRequired()
