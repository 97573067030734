import { baseApi } from 'src/store/APIs'

import {
  addTodo,
  deleteTodo,
  getSurvivorsTodos,
  getTodo,
  updateTodo,
} from './actions'

export const todosApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addTodo: addTodo(builder),
    deleteTodo: deleteTodo(builder),
    getSurvivorsTodos: getSurvivorsTodos(builder),
    getTodo: getTodo(builder),
    updateTodo: updateTodo(builder),
  }),
})

export const {
  useDeleteTodoMutation,
  useUpdateTodoMutation,
  useAddTodoMutation,
  useLazyGetTodoQuery,
  useLazyGetSurvivorsTodosQuery,
  useGetTodoQuery,
} = todosApi
