import { baseApi } from 'src/store/APIs'

import { deleteTacas, getSurvivorsTacas, getTacas } from './actions'

export const tacasApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    deleteTacas: deleteTacas(builder),
    getSurvivorsTacas: getSurvivorsTacas(builder),
    getTacas: getTacas(builder),
  }),
})

export const {
  useLazyGetTacasQuery,
  useLazyGetSurvivorsTacasQuery,
  useDeleteTacasMutation,
} = tacasApi
