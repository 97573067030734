import { Dayjs } from 'dayjs'
import { dateDeserializer, DEFAULT_TIMEZONE } from 'src/common/types'
import {
  CompletionStatusOptions,
  CompletionStatusOptionsValues,
} from 'src/pages/SurvivorView/Learn/types'

import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  Languages,
  LearnStatus,
  PaginatedItems,
  PaginationResponse,
  Permissions,
  UserType,
} from '../types'

export enum Endpoints {
  GetPackages = '/cms/v1/units.json',
  GetPackage = '/cms/v1/units/%s.json',
  AddPackage = '/cms/v1/units.json',
  UpdatePackage = '/cms/v1/units/%s.json',
  DeletePackage = '/cms/v1/units/%s.json',
  AssignPackage = 'cms/v1/mobile_survivors/%s/lessons',
}

export interface PackageInfo {
  id: string
  title: string
  internal_name: string
  internal_id: string
  audience: string
  created_at: string
  status: LearnStatus
}

export type PackageInfoResponse = PackageInfo // INFO: Both types are equal but we decided keeping them and the deserializer in case we need to add new props

export interface GetPackageInfoResponse {
  lessons: PackageInfoResponse[]
  model_permissions: Permissions
  pagination: PaginationResponse
}

export const packageInfoDeserializer = (
  data: PackageInfoResponse,
): PackageInfo => {
  return {
    audience: data.audience,
    created_at: data.created_at,
    id: data.id,
    internal_id: data.internal_id,
    internal_name: data.internal_name,
    status: data.status,
    title: data.title,
  }
}

export const getPackagesDeserializer = (
  data: GetPackageInfoResponse,
): PaginatedItems<PackageInfo> => {
  return {
    items: data.lessons.map(packageInfoDeserializer),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}

export interface ArticlePermissions {
  can_unassign: boolean
  can_update: boolean
  can_destroy: boolean
  can_remove: boolean
}

export interface ArticleAssigned {
  id: string
  internal_id: string
  internal_name: string
  has_audio: boolean
  title: string
  status?: string
  completion_status?: string
  // INFO: this is not on the backend yet but they will send the permissions in this format
  permissions: ArticlePermissions
}

export interface MobileUserLessonBase extends Entity {
  id: string
  created_at: string
  mobile_user_timezone: string
  mobile_user: {
    id: string
    full_name: string
    inheritor_id: string
    user_type: UserType
  }
  status: CompletionStatusOptions
  unit: {
    id: string
    articles: ArticleAssigned[]
    children: any | null // INFO: This prop is sent but it will not be used anymore
    completed_date: string | null
    number_of_articles: number
    number_of_articles_completed: number
    permissions: Permissions
    status: LearnStatus
    title: string
  }
}

export interface MobileUserLesson extends MobileUserLessonBase {
  deployment_date: Dayjs
}
export interface MobileUserLessonResponse extends MobileUserLessonBase {
  deployment_date: string
}

export interface PackageResponse extends Entity {
  id: string
  audience: string
  articles: ArticleAssigned[]
  children: any | null // INFO: This prop is sent but it will not be used anymore
  image: {
    url: string | null
  }
  internal_id: string
  internal_name: string
  mobile_user_lessons: MobileUserLessonResponse[]
  status: LearnStatus
  title: string
  created_at: string
  updated_at?: string
  created_by?: BaseUserResponse
  last_updated_by?: BaseUserResponse
  language: Languages
}

export interface Package extends Entity {
  id: string
  articles?: ArticleAssigned[]
  title: string
  internal_name: string
  internal_id: string
  audience: string
  created_at: string
  status: LearnStatus
  image?: string
  updated_at?: string
  created_by?: BaseUser
  last_updated_by?: BaseUser
  mobile_user_lessons: MobileUserLesson[]
  language: Languages
}

export enum CompletionStatus {
  NotStarted = 'not_started',
  Completed = 'completed',
  Scheduled = 'scheduled',
}

const mobileUserLessonsDeserializer = (
  data: MobileUserLessonResponse[],
): MobileUserLesson[] => {
  return data.map(lesson => {
    return {
      ...lesson,
      deployment_date: dateDeserializer(
        lesson.deployment_date,
        lesson.mobile_user_timezone ?? DEFAULT_TIMEZONE,
      ),
    }
  })
}

export const completionStatusDeserializer = (
  data: string | undefined,
): CompletionStatusOptions | undefined => {
  let status
  switch (data) {
    case CompletionStatusOptionsValues.Completed:
      status = CompletionStatusOptions.Completed
      break
    case CompletionStatusOptionsValues.NotStarted:
      status = CompletionStatusOptions.NotStarted
      break
    case CompletionStatusOptionsValues.Scheduled:
      status = CompletionStatusOptions.Scheduled
      break
    default:
      status = undefined
  }
  return status
}

export const articleAssignedDeserializer = (
  data: ArticleAssigned,
): ArticleAssigned => {
  return {
    ...data,
    completion_status: completionStatusDeserializer(data.completion_status),
  }
}

export const packagePermissionsDeserializer = (
  data: PackageResponse,
): Permissions => ({
  ...data.permissions,
  can_destroy: !!data.permissions?.can_destroy,
  can_disable: !!data.permissions?.can_disable,
  can_enable: !!data.permissions?.can_enable,
  can_read: !!data.permissions?.can_read,
  can_remove: !!data.permissions?.can_remove,
  can_unassign:
    data.mobile_user_lessons && data.mobile_user_lessons.length !== 0
      ? !!data.mobile_user_lessons[0].unit?.permissions?.can_unassign
      : false,
  can_update: !!data.permissions?.can_update,
})

export const packageDeserializer = (data: PackageResponse): Package => {
  return {
    ...data,
    articles: data.articles.map(articleAssignedDeserializer),
    audience: data.audience,
    created_at: data.created_at,
    created_by: data.created_by
      ? baseUserDeserializer(data.created_by)
      : undefined,
    id: data.id,
    image: data.image.url ?? undefined,
    internal_id: data.internal_id,
    internal_name: data.internal_name,
    language: data.language,
    last_updated_by: data.last_updated_by
      ? baseUserDeserializer(data.last_updated_by)
      : undefined,
    mobile_user_lessons: mobileUserLessonsDeserializer(
      data.mobile_user_lessons,
    ),
    permissions: data.permissions
      ? packagePermissionsDeserializer(data)
      : undefined,
    status: data.status,
    title: data.title,
    updated_at: data.updated_at,
  }
}
