import {
  Builder,
  PaginatedParams,
  paginatedParams,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
} from '../types'
import {
  Endpoints,
  HealthConditionData,
  healthConditionDeserializer,
  HealthConditionResponse,
  healthConditionsDeserializer,
  SurvivorHealthConditionData,
  survivorHealthConditionsDeserializer,
} from './types'

export const getHealthConditions = (builder: Builder) =>
  builder.query<PaginatedItems<HealthConditionData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.HealthConditions),
    query: endpointWithFilters(Endpoints.GetHealthConditions),
    transformResponse: healthConditionsDeserializer,
  })

export const getSurvivorsHealthConditions = (builder: Builder) =>
  builder.query<PaginatedItems<SurvivorHealthConditionData>, PaginatedParams>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.HealthConditions }],
    query: paginatedParams(Endpoints.GetSurvivorsHealthConditions),
    transformResponse: survivorHealthConditionsDeserializer,
  })

export const updateSurvivorsHealthConditions = (builder: Builder) =>
  builder.mutation<
    void,
    { health_conditions: Partial<SurvivorHealthConditionData>[]; id: string }
  >({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.HealthConditions }],
    query: ({ id, health_conditions }) => ({
      body: { health_conditions },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorsHealthConditions, id),
    }),
  })

export const getHealthCondition = (builder: Builder) =>
  builder.query<HealthConditionResponse, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.HealthConditions }],
    query: ({ id }) => format(Endpoints.GetHealthCondition, id),
    transformResponse: ({ health_condition }) =>
      healthConditionDeserializer(health_condition),
  })

export const addHealthCondition = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.HealthConditions),
    query: ({ body }) => ({
      body: { health_condition: body },
      method: HttpMethod.Post,
      url: Endpoints.AddHealthCondition,
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteHealthCondition = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.HealthConditions),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteHealthCondition, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const updateHealthCondition = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.HealthConditions }],
    query: ({ body, id }) => ({
      body: { health_condition: { name: body.name } },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateHealthCondition, id),
    }),
    transformResponse: performActionTransformResponse,
  })
