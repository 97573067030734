import React from 'react'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useLazyGetSurvivorsHealthConditionsQuery } from 'src/store/APIs/healthConditions'

import { EditableTab } from '../types'
import { columns } from './columns'
import { HealthConditionsEdit } from './HealthConditionsEdit'

interface HealthConditionsProps extends EditableTab {}

export const HealthConditions: React.FC<HealthConditionsProps> = ({
  editMode,
  toggleEditMode,
}) => {
  if (editMode) {
    return <HealthConditionsEdit toggleEditMode={toggleEditMode} />
  }
  return (
    <PaginatedTable
      columns={columns}
      useLazyQuery={useLazyGetSurvivorsHealthConditionsQuery}
    />
  )
}
