/* eslint-disable react/no-danger */
import { ColumnsType } from 'antd/es/table'
import { Dayjs } from 'dayjs'
import React from 'react'
import { formatDateWithTimeToRender } from 'src/common/types'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import { SurvivorTodosData } from 'src/store/APIs/todos/types'

import { strings } from './strings'
import styles from './todos.module.scss'

export enum TodosDataIndex {
  'todo' = 'todo',
  'completed' = 'completed',
  'completed_date' = 'completed_date',
  'reminder' = 'reminder',
  'repeat_schedule' = 'repeat_schedule',
  'first_reminder_date' = 'first_reminder_date',
}

export const columns: (
  onClickRow: (id: string) => () => void,
) => ColumnsType<SurvivorTodosData> = onClickRow => [
  {
    ...generateColumnTitle(TodosDataIndex.todo, strings.todosColumnLabel),
    render: title => (
      <div
        dangerouslySetInnerHTML={{ __html: title }}
        className={styles.title}
      />
    ),
  },
  {
    ...generateColumnTitle(
      TodosDataIndex.completed,
      strings.completedColumnLabel,
    ),
    render(value) {
      return value ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag>{strings.tagNo}</Tag>
      )
    },
  },
  {
    ...generateColumnTitle(
      TodosDataIndex.completed_date,
      strings.completedDateColumnLabel,
    ),
    render: formatDateWithTimeToRender,
  },
  {
    ...generateColumnTitle(
      TodosDataIndex.reminder,
      strings.reminderColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      TodosDataIndex.repeat_schedule,
      strings.repeatScheduleColumnLabel,
    ),
    render: (data: string | undefined) => data ?? strings.notApplicable,
  },
  {
    ...generateColumnTitle(
      TodosDataIndex.first_reminder_date,
      strings.firstReminderDateColumnLabel,
    ),
    render: (date: Dayjs | undefined) =>
      date ? formatDateWithTimeToRender(date) : strings.notApplicable,
  },
  {
    ...generateColumnTitle(TodosDataIndex.todo, strings.actionsColumnLabel),
    render: (_, record) => (
      <Button
        onClick={onClickRow(record.id)}
        disabled={!record.permissions?.can_read}
        type="link">
        {strings.viewLabel}
      </Button>
    ),
  },
]
