import { baseApi } from 'src/store/APIs'

import {
  addOtherEvent,
  deleteOtherEvent,
  editOtherEvent,
  getOtherEvent,
  getOtherEvents,
  getSurvivorsOtherEvents,
  updateSurvivorsOtherEvents,
} from './actions'

export const otherEventsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addOtherEvent: addOtherEvent(builder),
    deleteOtherEvent: deleteOtherEvent(builder),
    editOtherEvent: editOtherEvent(builder),
    getOtherEvent: getOtherEvent(builder),
    getOtherEvents: getOtherEvents(builder),
    getSurvivorsOtherEvents: getSurvivorsOtherEvents(builder),
    updateSurvivorsOtherEvents: updateSurvivorsOtherEvents(builder),
  }),
})

export const {
  useGetOtherEventsQuery,
  useGetSurvivorsOtherEventsQuery,
  useUpdateSurvivorsOtherEventsMutation,
  useLazyGetOtherEventsQuery,
  useAddOtherEventMutation,
  useLazyGetOtherEventQuery,
  useEditOtherEventMutation,
  useDeleteOtherEventMutation,
  reducer: otherEventsApiReducer,
  reducerPath: otherEventsApiReducerPath,
} = otherEventsApi
