import { Form, FormProps, InputProps } from 'antd'
import { FilterFunc } from 'rc-select/lib/Select'
import React, { useEffect } from 'react'
import colors from 'src/assets/stylesheets/colors.scss'
import { modifyPreferredPhoneRadioState } from 'src/common/helpers'
import {
  PhoneNumberTypes,
  PreferredPhoneFields,
  timezoneFromIANAToOffset,
} from 'src/common/types'
import { Checkbox } from 'src/components/Checkbox'
import { DatePicker } from 'src/components/DatePicker'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { Radio } from 'src/components/Radio'
import { SearchSelect } from 'src/components/SearchSelect'
import { Select } from 'src/components/Select'
import { Spinner } from 'src/components/Spinner'
import { TextInput } from 'src/components/TextInput'
import { constants } from 'src/config/constants'
import { usePhone } from 'src/hooks/usePhone'
import { useSearchOptionsSelect } from 'src/hooks/useSearchOptionsSelect'
import { useLazyGetNavigatorsQuery } from 'src/store/APIs/navigators'
import { Survivor } from 'src/store/APIs/survivors/types'
import { languageOptions } from 'src/store/APIs/types'
import { format } from 'util'

import { selectOptions } from './selectOptions'
import { strings } from './strings'
import styles from './survivorBasicDemographicsForm.module.scss'
import { yupSync } from './validations'

interface SurvivorBasicDemographicsFormProps {
  survivor?: Survivor
  formName: string
  onFinish?: FormProps['onFinish']
  children?: React.ReactNode
}

export const SurvivorBasicDemographicsForm: React.FC<
  SurvivorBasicDemographicsFormProps
> = ({ survivor, formName, onFinish, children }) => {
  const [survivorForm] = Form.useForm<Survivor | undefined>()

  const enableAddUser = constants.addUserEnabled
  const tooltipTitle = !enableAddUser ? strings.disabledInputTooltip : ''

  const { updatePhone } = usePhone(
    survivor?.phone_number,
    survivorForm,
    'phone_number',
  )

  const homePhoneFormValue = Form.useWatch('home_phone', survivorForm)
  const phoneNumberFormValue = Form.useWatch('phone_number', survivorForm)

  const { updatePhone: updateHomePhone } = usePhone(
    survivor?.home_phone,
    survivorForm,
    'home_phone',
  )

  const {
    ethnicityOptions,
    legalSexOptions,
    preferredPronounOptions,
    raceOptions,
    stateOptions,
  } = selectOptions()

  const onChangePhone: InputProps['onChange'] = event => {
    updatePhone(event.target.value)
  }

  const onChangeHomePhone: InputProps['onChange'] = event => {
    updateHomePhone(event.target.value)
  }

  useEffect(() => {
    modifyPreferredPhoneRadioState(
      phoneNumberFormValue,
      homePhoneFormValue,
      survivorForm,
    )
  }, [phoneNumberFormValue, homePhoneFormValue])

  const {
    optionsSelect: navigatorOptions,
    fetchedFilteredElems: fetchedFilteredNavigators,
    isLoading: getNavigatorsIsLoading,
  } = useSearchOptionsSelect(
    useLazyGetNavigatorsQuery,
    survivor?.cms_navigator
      ? [
          {
            full_name: `${survivor?.cms_navigator.name} ${survivor?.cms_navigator.last_name}`,
            id: survivor?.cms_navigator.id,
          },
        ]
      : undefined,
  )

  useEffect(() => {
    if (survivor) {
      survivorForm.validateFields()
    }
  }, [survivorForm])

  const stateFilterOption: FilterFunc<any> = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  if (getNavigatorsIsLoading) return <Spinner />

  return (
    <Form
      name={formName}
      initialValues={survivor ? { ...survivor } : undefined}
      form={survivorForm}
      onFinish={onFinish ?? undefined}>
      <Form.Item name="first_name" rules={[yupSync]}>
        <TextInput
          required
          label={strings.firstNameLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="last_name" rules={[yupSync]}>
        <TextInput
          required
          label={strings.lastNameLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="preferred_name" rules={[yupSync]}>
        <TextInput
          label={strings.preferredNameLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="date_of_birth" rules={[yupSync]}>
        <DatePicker
          required
          label={strings.dateOfBirthLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="post_acute_registration_date" rules={[yupSync]}>
        <DatePicker
          label={strings.registrationDateLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="post_acute_onboarding_date" rules={[yupSync]}>
        <DatePicker
          label={strings.onboardingDateLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="preferred_pronoun" rules={[yupSync]}>
        <Select
          label={strings.preferredPronounLabel}
          options={preferredPronounOptions}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="biological_sex" rules={[yupSync]}>
        <Select
          label={strings.legalSexLabel}
          options={legalSexOptions}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="gender_identity" rules={[yupSync]}>
        <TextInput
          label={strings.genderIdentityLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="race">
        <Select
          mode="multiple"
          options={raceOptions}
          label={strings.raceLabel}
          placeholder={strings.raceLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="ethnicity" rules={[yupSync]}>
        <Select
          label={strings.ethnicityLabel}
          options={ethnicityOptions}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="content_language" rules={[yupSync]}>
        <Select
          label={strings.languageLabel}
          options={languageOptions}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
          required
        />
      </Form.Item>
      <h4>{strings.phoneNumberLabel}</h4>
      <p>{strings.phoneNumberSubtitle}</p>
      <div className={styles.row}>
        <Form.Item
          name="phone_number"
          required
          className={styles.phoneInput}
          rules={[yupSync]}>
          <TextInput
            label={strings.mobilePhoneLabel}
            maxLength={14}
            disabled={!enableAddUser}
            tooltipTitle={tooltipTitle}
            onChange={onChangePhone}
          />
        </Form.Item>
        <Form.Item
          name={PreferredPhoneFields.MobilePhonePreferred}
          className={styles.preferredPhone}>
          <Radio.Group
            defaultValue={
              survivor?.preferred_phone !== PhoneNumberTypes.Home
                ? PreferredPhoneFields.MobilePhonePreferred
                : undefined
            }>
            <Radio value={PreferredPhoneFields.MobilePhonePreferred} disabled>
              <span
                style={{
                  color: !phoneNumberFormValue ? colors.surface_50 : undefined,
                }}>
                {strings.preferredPhoneLabel}
              </span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={styles.row}>
        <Form.Item
          name="home_phone"
          className={styles.phoneInput}
          rules={[yupSync]}>
          <TextInput
            label={strings.homePhoneLabel}
            onChange={onChangeHomePhone}
            maxLength={14}
            disabled={!enableAddUser}
            tooltipTitle={tooltipTitle}
          />
        </Form.Item>
        <Form.Item
          name={PreferredPhoneFields.HomePhonePreferred}
          className={styles.preferredPhone}>
          <Radio.Group
            defaultValue={
              survivor?.preferred_phone === PhoneNumberTypes.Home
                ? PreferredPhoneFields.HomePhonePreferred
                : undefined
            }>
            <Radio value={PreferredPhoneFields.HomePhonePreferred} disabled>
              <span
                style={{
                  color: !homePhoneFormValue ? colors.surface_50 : undefined,
                }}>
                {strings.preferredPhoneLabel}
              </span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <Form.Item name="email" rules={[yupSync]}>
        <TextInput
          required
          label={strings.emailLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="address" rules={[yupSync]}>
        <TextInput
          label={strings.addressLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="city" rules={[yupSync]}>
        <TextInput
          label={strings.cityLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="state">
        <Select
          disabled={!enableAddUser}
          showSearch
          tooltipTitle={tooltipTitle}
          required
          label={strings.stateLabel}
          options={stateOptions}
          placeholder={strings.stateLabel}
          filterOption={stateFilterOption}
        />
      </Form.Item>
      <Form.Item name="zip_code" rules={[yupSync]}>
        <TextInput
          label={strings.zipCodeLabel}
          type="text"
          inputMode="numeric"
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <p>
        {format(
          strings.timezoneLabel,
          survivor?.time_zone
            ? timezoneFromIANAToOffset(survivor.time_zone)
            : strings.undefinedTimezone,
        )}
      </p>
      <Form.Item name="emergency" rules={[yupSync]}>
        <TextInput
          label={strings.emergencyContactLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="primary_insurance_info" rules={[yupSync]}>
        <TextInput
          label={strings.insuranceInfoLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="living_situation" rules={[yupSync]}>
        <MultilineTextInput
          label={strings.livingSituationLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      <Form.Item name="family_situation" rules={[yupSync]}>
        <MultilineTextInput
          label={strings.familySituationLabel}
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
        />
      </Form.Item>
      {navigatorOptions && (
        <Form.Item
          name="cms_navigator_id"
          initialValue={survivor?.cms_navigator?.id}
          rules={[yupSync]}>
          <SearchSelect
            options={navigatorOptions}
            placeholder={strings.navigatorsPlaceholder}
            fetchOptions={fetchedFilteredNavigators}
            label={strings.cmsNavigatorsLabel}
            required
            disabled={!enableAddUser}
            tooltipTitle={tooltipTitle}
          />
        </Form.Item>
      )}
      <Form.Item name="ambassador" valuePropName="checked" rules={[yupSync]}>
        <Checkbox disabled={!enableAddUser} tooltipTitle={tooltipTitle}>
          {strings.ambassadorLabel}
        </Checkbox>
      </Form.Item>
      <Form.Item name="test_user" valuePropName="checked" rules={[yupSync]}>
        <Checkbox
          disabled={!enableAddUser}
          tooltipTitle={tooltipTitle}
          name="Test user">
          {strings.testUserLabel}
        </Checkbox>
      </Form.Item>
      {children}
    </Form>
  )
}
