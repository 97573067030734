import { Form, InputProps } from 'antd'
import { RuleObject } from 'antd/es/form'
import React from 'react'
import { Button } from 'src/components/Button'
import { DatePicker } from 'src/components/DatePicker'
import { MultipleBlockFooter } from 'src/components/MultipleBlockFooter'
import { SearchSelect, SearchSelectProps } from 'src/components/SearchSelect'
import { TextInput } from 'src/components/TextInput'
import { usePhone } from 'src/hooks/usePhone'
import { HealthcareTeamDataIndex } from 'src/pages/SurvivorView/HealthcareTeam/types'
import { GetSurvivorHealthcareTeamData } from 'src/store/APIs/healthcareTeam/types'
import { Identifiable } from 'src/store/APIs/types'

import { strings } from './strings'
import { yupSync } from './validations'

interface HealthcareMemberFormProps {
  doctor: Partial<GetSurvivorHealthcareTeamData>
  index: number
  isLoading: boolean
  isSuccess: boolean
  lastItemIndex: number
  onPressRemoveMember: (item: Identifiable) => () => void
  roles?: SearchSelectProps['options']
  toggleEditMode: () => void
  fetchFilteredHealthcareRoles: SearchSelectProps['fetchOptions']
}

export const HealthcareMemberForm: React.FC<HealthcareMemberFormProps> = ({
  doctor,
  index,
  isLoading,
  isSuccess,
  roles,
  lastItemIndex,
  onPressRemoveMember,
  toggleEditMode,
  fetchFilteredHealthcareRoles,
}) => {
  const [form] = Form.useForm<Partial<GetSurvivorHealthcareTeamData>>()
  const { updatePhone } = usePhone(
    doctor?.phone_number,
    form,
    HealthcareTeamDataIndex.phone_number,
  )

  const onChangePhone: InputProps['onChange'] = event => {
    updatePhone(event.target.value)
  }

  const healthcareRoleValue = Form.useWatch(
    HealthcareTeamDataIndex.health_care_role_id,
    form,
  )
  const otherRoleValue = Form.useWatch(HealthcareTeamDataIndex.other_role, form)

  const validateHealthcareRole = async (_: RuleObject, value: string) => {
    if (!value && !otherRoleValue) {
      throw new Error(strings.requiredMessage)
    }
  }

  const validateOtherRole = async (_: RuleObject, value: string) => {
    if (!value && !healthcareRoleValue) {
      throw new Error(strings.requiredMessage)
    }
  }

  return (
    <Form
      key={doctor.id}
      name={strings.healthcareTeamLabel + index}
      initialValues={doctor}
      form={form}>
      <Form.Item name="id" hidden />
      {roles && (
        <Form.Item
          name={HealthcareTeamDataIndex.health_care_role_id}
          rules={[{ validator: validateHealthcareRole }, yupSync]}
          dependencies={[HealthcareTeamDataIndex.other_role]}>
          <SearchSelect
            options={roles}
            label={strings.healthcareRole}
            fetchOptions={fetchFilteredHealthcareRoles}
            required={!otherRoleValue}
          />
        </Form.Item>
      )}
      <Form.Item
        name={HealthcareTeamDataIndex.other_role}
        rules={[{ validator: validateOtherRole }, yupSync]}
        dependencies={[HealthcareTeamDataIndex.health_care_role_id]}>
        <TextInput label={strings.otherRole} required={!healthcareRoleValue} />
      </Form.Item>
      <Form.Item name={HealthcareTeamDataIndex.first_name} rules={[yupSync]}>
        <TextInput label={strings.firstName} />
      </Form.Item>
      <Form.Item name={HealthcareTeamDataIndex.last_name} rules={[yupSync]}>
        <TextInput label={strings.lastName} required />
      </Form.Item>
      <Form.Item name={HealthcareTeamDataIndex.phone_number} rules={[yupSync]}>
        <TextInput
          label={strings.phoneNumber}
          onChange={onChangePhone}
          maxLength={14}
        />
      </Form.Item>
      <Form.Item
        name={HealthcareTeamDataIndex.affiliated_organization}
        rules={[yupSync]}>
        <TextInput label={strings.affiliatedOrganization} />
      </Form.Item>
      <Form.Item
        name={HealthcareTeamDataIndex.last_visit_date}
        rules={[yupSync]}>
        <DatePicker label={strings.lastVisitDate} />
      </Form.Item>
      <Form.Item
        name={HealthcareTeamDataIndex.last_visit_summary}
        rules={[yupSync]}>
        <TextInput label={strings.lastVisitSummary} />
      </Form.Item>
      <Form.Item
        name={HealthcareTeamDataIndex.additional_comments}
        rules={[yupSync]}>
        <TextInput label={strings.additionalComments} />
      </Form.Item>
      <Form.Item>
        <Button type="dashed" onClick={onPressRemoveMember(doctor)}>
          {strings.removeLabel}
        </Button>
      </Form.Item>
      <MultipleBlockFooter
        isLoading={isLoading}
        isLast={index === lastItemIndex}
        isSuccess={isSuccess}
        onCancel={toggleEditMode}
        onSuccess={toggleEditMode}
      />
    </Form>
  )
}
