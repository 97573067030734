import { createSlice } from '@reduxjs/toolkit'
import { ReduxState } from 'src/store/rootReducer'

export interface HeadersState {
  csrfToken?: string
}

export const initialState: HeadersState = {
  csrfToken: undefined,
}

const headersSlice = createSlice({
  initialState,
  name: 'headers',
  reducers: {
    setCsrfToken: (state, action) => {
      state.csrfToken = action.payload
    },
  },
})

export const selectHeaders = (state: ReduxState): HeadersState => state.headers

export const {
  actions: { setCsrfToken },
  reducer: headersReducer,
} = headersSlice
