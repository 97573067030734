import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addPrefix: 'Add',
    cancelLabel: 'Cancel',
    deleteLabel: 'Delete',
    disableLabel: 'Disable',
    editLabel: 'Edit',
    editPrefix: 'Edit',
    enableLabel: 'Enable',
    removeLabel: 'Remove',
    resourcePlaceholder: 'Resource',
    saveLabel: 'Save',
    submitLabel: 'Submit',
    unassignLabel: 'Unassign',
  },
})
