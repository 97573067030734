enum PreferredPronoun {
  HeHim = 'He/Him',
  SheHer = 'She/Her',
  TheyThem = 'They/Them',
}

enum LegalSex {
  Male = 'Male',
  Female = 'Female',
}

export enum CarePartnerType {
  NEW = 'Create new care partner',
  EXISTING = 'Existing care partner',
}

export const selectOptions = () => {
  const preferredPronounOptions = [
    { label: PreferredPronoun.HeHim, value: PreferredPronoun.HeHim },
    { label: PreferredPronoun.SheHer, value: PreferredPronoun.SheHer },
    { label: PreferredPronoun.TheyThem, value: PreferredPronoun.TheyThem },
  ]
  const legalSexOptions = [
    { label: LegalSex.Female, value: LegalSex.Female },
    { label: LegalSex.Male, value: LegalSex.Male },
  ]
  const carePartnerType = [
    { label: CarePartnerType.NEW, value: CarePartnerType.NEW },
    { label: CarePartnerType.EXISTING, value: CarePartnerType.EXISTING },
  ]

  return {
    carePartnerType,
    legalSexOptions,
    preferredPronounOptions,
  }
}
