import { Collapse } from 'antd'
import React from 'react'
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { ViewDrawerRenderProps } from 'src/components/DrawerWithState/types'
import { InfoTable, InfoTableRow } from 'src/components/InfoTable'
import { Table } from 'src/components/Table'
import { TableTitle } from 'src/components/TableTitle'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import styles from 'src/pages/Learn/Articles/ViewArticle/viewArticle.module.scss'
import {
  CompletionStatusOptions,
  completionStatusProps,
} from 'src/pages/SurvivorView/Learn/types'
import { ArticleMode, ExtendedArticleInfo } from 'src/store/APIs/articles/types'
import { LearnStatus } from 'src/store/APIs/types'

import { learnStatusProps } from '../../types'
import {
  contentColumns,
  contentWithSsmlColumns,
  suggestedQuestionsColumns,
  suggestedTodosColumns,
} from './columns'
import { strings } from './strings'

interface ViewArticleProps extends ViewDrawerRenderProps {
  data?: ExtendedArticleInfo
  goBack?: () => void
  openAnotherDrawer?: (id: string) => () => void
}

export const ViewArticle: React.FC<ViewArticleProps> = ({
  data,
  goBack,
  openAnotherDrawer,
}) => {
  const removedFromPackage =
    data?.previous_unit && data?.status === LearnStatus.hasAssignedChildren

  const isChild = !!data?.assigned_to

  const unitTitle = data?.unit?.title ?? data?.previous_unit?.title

  const onClickPackageLink =
    goBack ||
    (openAnotherDrawer && data?.unit?.id
      ? openAnotherDrawer(data.unit.id)
      : undefined)

  const firstTableRows: InfoTableRow[] = [
    {
      content: data?.title,
      label: strings.titleLabel,
    },
    {
      content: data?.internal_name,
      label: strings.internalNameLabel,
    },
    {
      content: data?.audience,
      label: strings.audienceLabel,
    },
    {
      content: data?.internal_id,
      label: strings.internalIdLabel,
    },
    {
      content: data?.created_at
        ? deserializeShortFormattedDate(data?.created_at)
        : undefined,
      label: strings.createdAtLabel,
    },
    {
      content: data?.updated_at
        ? deserializeShortFormattedDate(data?.updated_at)
        : undefined,
      label: strings.updatedAtLabel,
    },
    {
      content: data?.last_update_by?.email,
      label: strings.lastUpdateByLabel,
    },
    {
      content: removedFromPackage
        ? data?.previous_unit?.title
        : unitTitle && (
            <Button
              type="link"
              onClick={onClickPackageLink}
              className={styles.packageButton}>
              {unitTitle}
            </Button>
          ),
      label: strings.packageLabel,
    },
    {
      content: data?.status ? (
        <Tag color={learnStatusProps(data.status).color}>
          {learnStatusProps(data.status).title}
        </Tag>
      ) : undefined,
      label: strings.status,
    },
    {
      content: data?.completion_status ? (
        <Tag
          color={
            completionStatusProps(
              data.completion_status as CompletionStatusOptions,
            ).color
          }>
          {
            completionStatusProps(
              data.completion_status as CompletionStatusOptions,
            ).title
          }
        </Tag>
      ) : undefined,
      hidden: !isChild,
      label: strings.completionStatus,
    },
    {
      content: data?.assigned_to ? (
        <UserHyperlink
          user={{
            full_name: data.assigned_to.full_name,
            id: data.assigned_to.id,
            permissions: data.assigned_to.permissions,
            user_type: data.assigned_to.user_type,
          }}
        />
      ) : undefined,
      hidden: !isChild,
      label: strings.assignedTo,
    },
    {
      content: data?.theme,
      label: strings.theme,
    },
    {
      content: data?.language,
      label: strings.languageLabel,
    },
    {
      content: data?.number_of_pages,
      hidden: data?.mode === ArticleMode.Content,
      label: strings.numberOfPages,
    },
    {
      content: data?.bookmark ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag>{strings.tagNo}</Tag>
      ),
      hidden: !isChild,
      label: strings.favorite,
    },
    {
      content: data?.link,
      label: strings.redirectLinkLabel,
    },
    {
      content: data?.revision_history ? (
        /* eslint-disable react/no-danger */
        <div dangerouslySetInnerHTML={{ __html: data.revision_history }} />
      ) : null,
      label: strings.revisionHistoryLabel,
    },
  ]

  const bottomRows: InfoTableRow[] = [
    {
      content: data?.audio?.file?.url ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls src={data.audio.file.url}>
          {strings.audioLabel}
        </audio>
      ) : undefined,
      hidden: data?.mode !== ArticleMode.Ssml,
      label: strings.audioLabel,
    },
    {
      content: data?.feedback_disabled ? (
        <Tag color="default">{strings.tagNo}</Tag>
      ) : (
        <Tag color="green">{strings.tagYes}</Tag>
      ),
      label: strings.feedbackEnabledLabel,
    },
    {
      content: data?.discuss_with_navigator ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag>{strings.tagNo}</Tag>
      ),
      hidden: !isChild,
      label: strings.discussWithNavigator,
    },
  ]

  const customExpandIcon = (
    panelProps: any, // INFO: the type is PanelProps but is not exported
  ) => (panelProps.isActive ? <MinusIcon /> : <PlusIcon />)

  const contentStyle = {
    width: '70%',
  }

  const titleRow = (
    <div className={styles.titleRow}>
      {strings.articleDetails}
      {removedFromPackage && (
        <Tag color="red" className={styles.titleTag}>
          {strings.removedFromPackage}
        </Tag>
      )}
    </div>
  )

  return (
    <>
      <InfoTable
        className={styles.table}
        rows={firstTableRows}
        title={titleRow}
        contentStyle={contentStyle}
      />
      {data?.mode === ArticleMode.Content && (
        <Table
          columns={contentColumns}
          pagination={false}
          dataSource={[data]}
          bordered
        />
      )}
      {data?.mode === ArticleMode.Paginated && data?.pages_attributes && (
        <Collapse
          className={styles.collapse}
          accordion
          expandIcon={customExpandIcon}
          key={data.id}>
          {data.pages_attributes.map(item => (
            <Collapse.Panel
              key={item.id!}
              header={`${strings.pageTitle} ${item.page_number + 1}`}>
              <div className={styles.contentTitleContainer}>
                {strings.contentLabel}
              </div>
              {item.content && (
                <div
                  className={styles.collapseContent}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              )}
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
      {data?.mode === ArticleMode.Ssml && data?.pages_attributes && (
        <Collapse
          accordion
          expandIcon={customExpandIcon}
          key={data.id}
          className={styles.collapse}>
          {data.pages_attributes.map(item => (
            <Collapse.Panel
              key={item.id!}
              header={
                <span className={styles.header}>
                  {strings.pageTitle} {item.page_number + 1}
                </span>
              }>
              <Table
                columns={contentWithSsmlColumns}
                pagination={false}
                dataSource={[data?.pages_attributes[item.page_number]]}
                bordered
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
      <InfoTable
        rows={bottomRows}
        contentStyle={{ width: '75%' }}
        className={styles.spaceBwTables}
      />
      <Table
        bordered
        className={styles.spaceBwTables}
        columns={suggestedQuestionsColumns(isChild)}
        pagination={false}
        dataSource={data?.suggested_questions}
        caption={<TableTitle title={strings.suggestedQuestions} />}
      />
      <Table
        bordered
        columns={suggestedTodosColumns(isChild)}
        pagination={false}
        dataSource={data?.suggested_todos}
        caption={<TableTitle title={strings.suggestedTodos} />}
      />
    </>
  )
}
