import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addNewImpairment: '+ Add new survivor impairment',
    clinicalNameColumnLabel: 'Clinical Name',
    descriptionColumnLabel: 'Description',
    descriptionLabel: 'Description',
    impairmentColumnLabel: 'Impairment',
    impairmentLabel: 'Impairment',
    removeLabel: 'Remove',
    requiredValidation: 'This field is required',
    title: 'Survivor impairments',
  },
})
