import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    biographyLabel: 'Biography',
    contentLabel: 'Content',
    createButtonLabel: 'Create a Post',
    deleteButtonLabel: 'Delete',
    descriptionLabel: 'Description',
    drawerTitle: 'Create a Post',
    editButtonLabel: 'Edit',
    imageLabel: 'Image',
    modalTitle: 'Are you sure you want delete this group?',
    nameLabel: 'Name',
    pictureLabel: 'Picture',
    postPlaceholder: 'Share something...',
    resetImageModalTitle: 'Are you sure you want to delete this image?',
    submitButtonText: 'Submit',
    typeLabel: 'Type',
    uploadButtonText: 'Upload',
  },
})
