import { baseApi } from 'src/store/APIs'

import {
  answerAssessment,
  assignCarePartnerAssessment,
  assignSurvivorAssessment,
  checkScore,
  createAndAnswerAssessment,
  createAndCheckScore,
  deleteCarePartnerAssessment,
  deleteSurvivorAssessment,
  editAssessment,
  getAssessmentQuestions,
  getAssessments,
  getCarePartnerAssessmentDetails,
  getCarePartnerAssessments,
  getSurvivorAssessmentDetails,
  getSurvivorsAssessments,
} from './actions'

export const assessmentsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    answerAssessment: answerAssessment(builder),
    assignCarePartnerAssessment: assignCarePartnerAssessment(builder),
    assignSurvivorAssessment: assignSurvivorAssessment(builder),
    checkScore: checkScore(builder),
    createAndAnswerAssessment: createAndAnswerAssessment(builder),
    createAndCheckScore: createAndCheckScore(builder),
    deleteCarePartnerAssessment: deleteCarePartnerAssessment(builder),
    deleteSurvivorAssessment: deleteSurvivorAssessment(builder),
    editAssessment: editAssessment(builder),
    getAssessmentQuestions: getAssessmentQuestions(builder),
    getAssessments: getAssessments(builder),
    getCarePartnerAssessmentDetails: getCarePartnerAssessmentDetails(builder),
    getCarePartnerAssessments: getCarePartnerAssessments(builder),
    getSurvivorAssessmentDetails: getSurvivorAssessmentDetails(builder),
    getSurvivorsAssessments: getSurvivorsAssessments(builder),
  }),
})

export const {
  useCreateAndCheckScoreMutation,
  useLazyGetAssessmentQuestionsQuery,
  useGetAssessmentsQuery,
  useEditAssessmentMutation,
  useLazyGetCarePartnerAssessmentsQuery,
  useAssignCarePartnerAssessmentMutation,
  useLazyGetCarePartnerAssessmentDetailsQuery,
  useDeleteCarePartnerAssessmentMutation,
  useLazyGetSurvivorsAssessmentsQuery,
  useLazyGetSurvivorAssessmentDetailsQuery,
  useDeleteSurvivorAssessmentMutation,
  useAssignSurvivorAssessmentMutation,
  useCheckScoreMutation,
  useAnswerAssessmentMutation,
  useCreateAndAnswerAssessmentMutation,
} = assessmentsApi
