import { generalStrings } from 'src/common/generalStrings'
import { Status } from 'src/common/types'
import { LearnTrackResponse } from 'src/components/LearnView/types'
import { RouteName } from 'src/routes'
import {
  Builder,
  Conversation,
  ConversationMessage,
  ConversationParams,
  EditConversationMessage,
  endpointWithFilters,
  getConversationDeserializer,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  ParamsWithFilters,
  performActionGenericSuccess,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  ResetPasswordPayload,
  Tags,
  triggerEntityNotFound,
  UserGroup,
  userGroupsDeserializer,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  alertsDeserializer,
  Endpoints,
  getSurvivorDeserializer,
  GetSurvivorResponse,
  getSurvivorsDeserializer,
  InfoSurvivorDataSource,
  MainUnit,
  NewSurvivorResponse,
  Survivor,
  SurvivorAlertsData,
  survivorAlertsDeserializer,
  SurvivorPlanOfCare,
  survivorPlanOfCareDeserializer,
  SurvivorPlanOfCareRequest,
  TabAlert,
  unitsDeserializer,
} from './types'

export const getSurvivors = (builder: Builder) =>
  builder.query<PaginatedItems<InfoSurvivorDataSource>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Survivors),
    query: endpointWithFilters(Endpoints.GetSurvivors),
    transformResponse: getSurvivorsDeserializer,
  })

export const getSurvivor = (builder: Builder) =>
  builder.query<Survivor, string | undefined>({
    providesTags: (_, __, id) => [{ id, type: Tags.Survivors }],
    query: id => format(Endpoints.GetSurvivor, id),
    transformErrorResponse: triggerEntityNotFound(
      RouteName.Survivors,
      generalStrings.survivorNotFound,
    ),
    transformResponse: ({ mobile_survivor, tabs_permissions }) =>
      getSurvivorDeserializer({ ...mobile_survivor, tabs_permissions }),
  })

export const updateSurvivor = (builder: Builder) =>
  builder.mutation<GetSurvivorResponse, Partial<Survivor>>({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.Survivors }],
    query: survivor => ({
      body: survivor,
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivor, survivor.id),
    }),
  })

export const newSurvivor = (builder: Builder) =>
  builder.mutation<NewSurvivorResponse, Survivor>({
    invalidatesTags: provideListToTag(Tags.Survivors),
    query: survivor => ({
      body: survivor,
      method: HttpMethod.Post,
      url: Endpoints.NewSurvivor,
    }),
  })

export const deleteSurvivor = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    invalidatesTags: provideListToTag(Tags.Survivors),
    query: id => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteSurvivor, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const getSurvivorPlanOfCare = (builder: Builder) =>
  builder.query<SurvivorPlanOfCare, string>({
    providesTags: () => [{ type: Tags.PlanOfCare }],
    query: id => format(Endpoints.GetSurvivorPlanOfCare, id),
    transformResponse: ({ plan_of_care }) =>
      survivorPlanOfCareDeserializer(plan_of_care),
  })

export const updateSurvivorPlanOfCare = (builder: Builder) =>
  builder.mutation<void, SurvivorPlanOfCareRequest>({
    invalidatesTags: () => [{ type: Tags.PlanOfCare }],
    query: ({ body, id }) => ({
      body,
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorPlanOfCare, id),
    }),
  })

export const getSurvivorsAlerts = (builder: Builder) =>
  builder.query<PaginatedItems<SurvivorAlertsData>, PaginatedParams>({
    providesTags: () => [{ type: Tags.Alerts }],
    query: paginatedParams(Endpoints.GetSurvivorsAlerts),
    transformResponse: survivorAlertsDeserializer,
  })

export const getAlertsByTab = (builder: Builder) =>
  builder.query<TabAlert[], string>({
    providesTags: () => [{ type: Tags.Alerts }],
    query: tab => format(Endpoints.GetAlertsByTab, tab),
    transformResponse: ({ alerts }) => alertsDeserializer(alerts),
  })

export const getUnits = (builder: Builder) =>
  builder.query<PaginatedItems<MainUnit>, ParamsWithFilters>({
    providesTags: () => [{ id: 'UNITS', type: Tags.Survivors }],
    query: endpointWithFilters(Endpoints.GetSurvivorsUnits),
    transformResponse: unitsDeserializer,
  })

export const updateSurvivorsLearnTrack = (builder: Builder) =>
  builder.mutation<void, { learn_track: LearnTrackResponse; id: string }>({
    query: ({ id, learn_track }) => ({
      body: { mobile_survivor: { learn_track } },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivor, id),
    }),
    transformResponse: performActionGenericSuccess,
  })

export const resetPassword = (builder: Builder) =>
  builder.mutation<PerformActionResponse, ResetPasswordPayload>({
    query: args => ({
      body: args.body,
      method: HttpMethod.Patch,
      url: format(Endpoints.ResetPassword, args.id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendSignUpEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendSignUpEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendSignUpSMS = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendSignUpSMS, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendAppInstallEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendAppInvitationEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendAppInstallSMS = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendAppInvitationSMS, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const changeUserStatus = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string; status: Status }>({
    invalidatesTags: () => [{ type: Tags.Survivors }],
    query: ({ id, status }) => ({
      body: { status },
      method: HttpMethod.Patch,
      url: format(Endpoints.ChangeStatus, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const notifyUncompletedLesson = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    query: ({ id }) => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.NotifyUncompletedLesson, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const assignCarePartner = (builder: Builder) =>
  builder.mutation<void, { id: string; mobile_caregiver_ids: string[] }>({
    invalidatesTags: () => [
      { type: Tags.CarePartners },
      { type: Tags.Survivors },
    ],
    query: ({ id, mobile_caregiver_ids }) => ({
      body: { mobile_caregiver_ids },
      method: HttpMethod.Put,
      url: format(Endpoints.AssignCarePartner, id),
    }),
  })

export const getSurvivorGroups = (builder: Builder) =>
  builder.query<PaginatedItems<UserGroup>, PaginatedParams>({
    providesTags: () => [{ type: Tags.Survivors }],
    query: paginatedParams(Endpoints.GetSurvivorsGroups),
    transformResponse: userGroupsDeserializer,
  })

export const getSurvivorConversation = (builder: Builder) =>
  builder.query<PaginatedItems<Conversation>, ConversationParams>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Conversations }],
    query: ({ id, type }) =>
      format(Endpoints.GetSurvivorsConversations, id, type),
    transformResponse: getConversationDeserializer,
  })

export const sendConversationMessage = (builder: Builder) =>
  builder.mutation<void, ConversationMessage>({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.Conversations }],
    query: ({ id, body }) => {
      const formData = new FormData()
      formData.append('message[conversation_type]', body.conversation_type)
      if (body.image) {
        formData.append('message[image]', body.image)
      } else if (body.message) {
        formData.append('message[content]', body.message)
      }
      return {
        body: formData,
        method: HttpMethod.Post,
        url: format(Endpoints.SendConversationMessage, id),
      }
    },
    transformResponse: performActionGenericSuccess,
  })

export const editSurvivorsConversationMessage = (builder: Builder) =>
  builder.mutation<void, EditConversationMessage>({
    invalidatesTags: () => [{ type: Tags.Conversations }],
    query: ({ userId, messageId, body }) => {
      const formData = new FormData()
      formData.append('message[conversation_type]', body.conversation_type)
      formData.append('message[content]', body.message)
      return {
        body: formData,
        method: HttpMethod.Put,
        url: format(Endpoints.EditConversationMessage, userId, messageId),
      }
    },
    transformResponse: performActionGenericSuccess,
  })

export const deleteSurvivorsConversationMessage = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { idUser: string; idElem: string }>({
    invalidatesTags: () => [{ type: Tags.Conversations }],
    query: ({ idUser, idElem }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteConversationMessage, idUser, idElem),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendGraduationEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    invalidatesTags: () => [{ type: Tags.Survivors }],
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendGraduationEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendWelcomeEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    invalidatesTags: () => [{ type: Tags.Survivors }],
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendWelcomeEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })
