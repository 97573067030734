import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    articleDetails: 'Article Details',
    assignedTo: 'Assigned to',
    audienceLabel: 'Audience',
    audioLabel: 'Audio',
    completionStatus: 'Completion Status',
    contentLabel: 'Content',
    createdAtLabel: 'Created At',
    discussWithNavigator: 'Discuss With Navigator',
    favorite: 'Favorite',
    feedbackEnabledLabel: 'Feedback Enabled',
    internalIdLabel: 'Internal ID',
    internalNameLabel: 'Internal Name',
    languageLabel: 'Language',
    lastUpdateByLabel: 'Last Updated By',
    numberOfPages: 'Number of Pages',
    packageLabel: 'Package',
    pageTitle: 'Page',
    questionLabel: 'Question',
    redirectLinkLabel: 'Redirect Link',
    removedFromPackage: 'Removed From Package',
    retailProductLabel: 'Retail Product',
    revisionHistoryLabel: 'Revision History',
    selectedLabel: 'Selected',
    ssmlLabel: 'SSML',
    status: 'Article Status',
    suggestedQuestions: 'Suggested Questions',
    suggestedTodos: `Suggested To-do's`,
    tagNo: 'No',
    tagYes: 'Yes',
    theme: 'Theme',
    titleLabel: 'Title',
    todoLabel: 'To-do',
    updatedAtLabel: 'Updated At',
  },
})
