import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    clinicalNameColumn: 'Clinical Name',
    createdAtColumn: 'Created At',
    nameColumn: 'Name',
    updatedAtColumn: 'Updated At',
  },
})
