import React from 'react'

import { strings } from './strings'
import styles from './unexpectedError.module.scss'

// TODO: Implement this component
export const UnexpectedError = () => (
  <div>
    <h1 className={styles.title}>{strings.unexpectedError}</h1>
  </div>
)
