import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { InfoTable, InfoTableRow } from 'src/components/InfoTable'
import { ImpairmentData } from 'src/store/APIs/impairments/types'

import { strings } from './strings'

interface ViewImpairmentProps {
  data?: ImpairmentData
}

export const ViewImpairment: React.FC<ViewImpairmentProps> = ({ data }) => {
  const contentStyle = { width: '80%' }

  const rows: InfoTableRow[] = [
    {
      content: data?.name,
      label: strings.nameColumn,
    },
    {
      content: data?.clinical_name?.length ? data.clinical_name : undefined,
      label: strings.clinicalNameColumn,
    },
    {
      content: data?.created_at
        ? deserializeShortFormattedDate(data?.created_at)
        : undefined,
      label: strings.createdAtColumn,
    },
    {
      content: data?.updated_at
        ? deserializeShortFormattedDate(data?.updated_at)
        : undefined,
      label: strings.updatedAtColumn,
    },
  ]
  return <InfoTable rows={rows} contentStyle={contentStyle} />
}
