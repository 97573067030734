import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  Tags,
} from 'src/store/APIs/types'

import {
  Endpoints,
  getMobileUsersResponseDeserializer,
  MobileUser,
} from './types'

export const getMobileUsers = (builder: Builder) =>
  builder.query<PaginatedItems<MobileUser>, ParamsWithFilters>({
    providesTags: () => [{ type: Tags.MobileUsers }],
    query: endpointWithFilters(Endpoints.GetMobileUsers),
    transformResponse: getMobileUsersResponseDeserializer,
  })
