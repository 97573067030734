import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { BaseQueryFnType } from '../types'

export enum Endpoints {
  Login = '/admin/login.json',
  GetCsrfToken = '/cms/v1/sessions/csrf_token/',
  Logout = '/admin/logout.json',
  KeepAliveSession = '/cms/v1/sessions/reset_user_clock.json',
  CheckSessionRemainingTime = '/cms/v1/sessions/check_timer.json',
}

export enum Tags {
  Auth = 'Auth',
}

export type Builder = EndpointBuilder<BaseQueryFnType, 'Auth', 'authApi'>

export interface CsrfResponse {
  csrf_token: string
}
export interface AuthResponse {
  message: string
  csrf_token: string
}

export interface CheckTimeResponse {
  time_left: number
}
