import { Form } from 'antd'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { Conversation } from 'src/store/APIs/survivors/types'

import { MultilineTextInput } from '../MultilineTextInput'
import styles from './conversations.module.scss'
import { ConversationDataIndex } from './types'

interface EditableCellProps {
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: Conversation
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  children,
  dataIndex,
  record,
}) => {
  let childNode = children

  childNode =
    editable && dataIndex === ConversationDataIndex.content ? (
      <div className={styles.contentEditable}>
        <Form.Item
          initialValue={record.content}
          name={dataIndex}
          rules={[
            {
              message: generalStrings.requiredFieldValidation,
              required: true,
            },
          ]}>
          <MultilineTextInput />
        </Form.Item>
        {children}
      </div>
    ) : (
      <div>{children}</div>
    )

  return <td>{childNode}</td>
}
