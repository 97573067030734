import { useState } from 'react'
import { scrollToTheTop } from 'src/common/helpers'

export const useToggleEditMode = () => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const toggleEditMode = () => {
    setEditMode(!editMode)
    if (editMode) {
      scrollToTheTop()
    }
  }

  return {
    editMode,
    toggleEditMode,
  }
}
