import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Endpoints,
  GetHospitalsResponse,
  HospitalData,
  HospitalResponse,
  hospitalsDeserializer,
} from './types'

export const getHospitals = (builder: Builder) =>
  builder.query<PaginatedItems<HospitalData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Hospitals),
    query: endpointWithFilters(Endpoints.GetHospitals),
    transformResponse: (response: GetHospitalsResponse) =>
      hospitalsDeserializer(response),
  })

export const getHospital = (builder: Builder) =>
  builder.query<HospitalResponse, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Hospitals }],
    query: ({ id }) => format(Endpoints.GetHospital, id),
    transformResponse: ({ hospital }) => hospital,
  })

export const deleteHospital = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.Hospitals),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteHospital, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const addHospital = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.Hospitals),
    query: ({ body }) => ({
      body: { hospital: body },
      method: HttpMethod.Post,
      url: Endpoints.AddHospital,
    }),
    transformResponse: performActionTransformResponse,
  })

export const updateHospital = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.Hospitals }],
    query: ({ body, id }) => ({
      body: { hospital: { name: body.name } },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateHospital, id),
    }),
    transformResponse: performActionTransformResponse,
  })
