import { Skeleton } from 'antd'
import React from 'react'

import styles from './defaultTaskSkeleton.module.scss'

export const DefaultTaskSkeleton: React.FC = () => {
  return (
    <div className={styles.navigatorTaskCard}>
      <div className={styles.topSection}>
        <div className={styles.positionAndTitle}>
          <Skeleton.Avatar active size="small" />
          <Skeleton.Input
            active
            size="small"
            className={styles.skeletonHeight}
          />
        </div>
      </div>
      <div className={styles.bottomSection}>
        <Skeleton.Input active size="small" className={styles.skeletonHeight} />
        <Skeleton.Input
          active
          className={styles.skeletonHeight}
          style={{
            width: `${200 + 100 * Math.random()}px`,
          }}
        />
      </div>
    </div>
  )
}
