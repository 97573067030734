import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddHospitalTreatmentMutation,
  useDeleteHospitalTreatmentMutation,
  useEditHospitalTreatmentMutation,
  useLazyGetHospitalTreatmentQuery,
  useLazyGetHospitalTreatmentsQuery,
} from 'src/store/APIs/hospitalTreatments'

import styles from '../../survivorProfile.module.scss'
import { strings } from '../strings'
import { columns } from './columns'

export const HospitalTreatments: React.FC = () => {
  const { open, onCloseDrawer, openView, selectedId, showDrawer } = useDrawer()

  const [triggerAddHospitalTreatmentMutation, { isLoading: loadingAdd }] =
    useAddHospitalTreatmentMutation()

  const [triggerDeleteHospitalTreatmentMutation, { isLoading: loadingDelete }] =
    useDeleteHospitalTreatmentMutation()

  const [triggerEditHospitalTreatmentMutation, { isLoading: loadingEdit }] =
    useEditHospitalTreatmentMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.hospitalTreatmentsTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addHospitalTreatmentButton}
        </Button>
      </div>
      <DrawerWithState
        isLoadingRightButton={isLoadingRightButton}
        isLoadingLeftButton={loadingDelete}
        selectedId={selectedId}
        open={open}
        resourceName={strings.hospitalTreatmentResource}
        RenderAdd={AddBasicItem}
        RenderView={ViewBasicItem}
        RenderEdit={AddBasicItem}
        useLazyGetResource={useLazyGetHospitalTreatmentQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddHospitalTreatmentMutation}
        triggerDeleteResourceMutation={triggerDeleteHospitalTreatmentMutation}
        triggerEditResourceMutation={triggerEditHospitalTreatmentMutation}
        deleteModalTitle={strings.deleteHospitalTreatmentModalTitle}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetHospitalTreatmentsQuery}
      />
    </>
  )
}
