import { Button } from 'antd'
import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddHealthcareTeamRoleMutation,
  useDeleteHealthcareTeamRoleMutation,
  useEditHealthcareTeamRoleMutation,
  useLazyGetHealthcareTeamRoleQuery,
  useLazyGetHealthcareTeamRolesQuery,
} from 'src/store/APIs/healthcareTeam'

import styles from '../survivorProfile.module.scss'
import { columns } from './columns'
import { strings } from './strings'

export const HealthcareTeamRole: React.FC = () => {
  const { open, onCloseDrawer, selectedId, showDrawer, openView } = useDrawer()

  const [triggerAddHealthcareTeamRoleMutation, { isLoading: loadingAdd }] =
    useAddHealthcareTeamRoleMutation()

  const [
    triggerDeleteHealthcareTeamRoleMutation,
    { isLoading: loadingDelete },
  ] = useDeleteHealthcareTeamRoleMutation()

  const [triggerEditHealthcareTeamRoleMutation, { isLoading: loadingEdit }] =
    useEditHealthcareTeamRoleMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.healthcareTeamRoleTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addHealthcareTeamRoleButton}
        </Button>
      </div>
      <DrawerWithState
        selectedId={selectedId}
        open={open}
        resourceName={strings.resourceName}
        RenderView={ViewBasicItem}
        RenderAdd={AddBasicItem}
        RenderEdit={AddBasicItem}
        useLazyGetResource={useLazyGetHealthcareTeamRoleQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddHealthcareTeamRoleMutation}
        triggerDeleteResourceMutation={triggerDeleteHealthcareTeamRoleMutation}
        triggerEditResourceMutation={triggerEditHealthcareTeamRoleMutation}
        isLoadingRightButton={isLoadingRightButton}
        isLoadingLeftButton={loadingDelete}
        deleteModalTitle={strings.deleteModalTitle}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetHealthcareTeamRolesQuery}
      />
    </div>
  )
}
