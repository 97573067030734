import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    logoutButton: 'Logout now',
    messagePart1: 'Your session will expire in: ',
    messagePart2: ' do you want to extend your session?',
    modalTitle: 'Session notification',
    stayConnectedButton: 'Stay connected',
  },
})
