import { Dayjs } from 'dayjs'
import { dateDeserializer } from 'src/common/types'
import {
  DefaultNavigatorTask,
  DefaultNavigatorTaskTypeOptions,
} from 'src/pages/DefaultNavigatorTasks/DefaultNavigatorTask/types'
import {
  StatusOptions,
  TypeOptions,
} from 'src/pages/SurvivorView/NavigatorTasks/types'
import { CommunicationLog } from 'src/store/APIs/communicationLogs/types'
import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  PaginatedItems,
  PaginationResponse,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetNavigatorTask = '/cms/v1/navigator_tasks/%s.json',
  AddNavigatorTask = '/cms/v1/navigator_tasks.json',
  UpdateNavigatorTask = '/cms/v1/navigator_tasks/%s.json',
  GetNavigatorTasks = '/cms/v1/mobile_survivors/%s/utilities/navigator_tasks.json',
  DeleteNavigatorTask = '/cms/v1/navigator_tasks/%s.json',
  UpdateGoalDate = '/cms/v1/mobile_survivors/%s/utilities/navigator_tasks/update_goal_date.json',
  GetDefaultNavigatorTasks = '/cms/v1/utilities/default_navigator_tasks.json',
  AddDefaultNavigatorTask = '/cms/v1/utilities/default_navigator_tasks.json',
  ReorderDefaultNavigatorTasks = '/cms/v1/utilities/default_navigator_tasks/reorder.json',
  EditDefaultNavigatorTask = '/cms/v1/utilities/default_navigator_tasks/%s.json',
  DeleteDefaultNavigatorTask = '/cms/v1/utilities/default_navigator_tasks/%s.json',
}

export interface PaginatedNavigatorTasksResponse {
  navigator_tasks: NavigatorTaskResponse[]
  pagination: PaginationResponse
}

export interface NavigatorTaskResponse extends Entity {
  id: string
  category: string | null
  communication_log: CommunicationLog
  completed_date: string | null
  created_at: string
  description: string
  goal_date: string | null
  mobile_survivor: BaseUserResponse
  notes: string
  status: StatusOptions
  time_spent: string
  type_of: string | null
  updated_at: string
}

export interface NavigatorTask extends Entity {
  id: string
  category?: string
  communication_log: CommunicationLog
  completed_date?: Dayjs
  created_at?: Dayjs
  description: string
  goal_date?: Dayjs
  mobile_survivor?: BaseUser
  notes: string
  status: StatusOptions
  time_spent: string
  type_of?: string
  updated_at?: Dayjs
}

export interface NavigatorTaskPayload {
  description: string
  status: StatusOptions
  notes?: string
  goal_date?: string
  completed_date?: string
  type_of: TypeOptions
  time_spent: number
  mobile_survivor_id: string
}

export interface DefaultNavigatorTaskResponse {
  id: string
  description: string
  position: number
  goal_date_type: DefaultNavigatorTaskTypeOptions
  goal_date: number
  default_task_list_id: string
  created_at: string
  updated_at: string
}

export const navigatorTaskDeserializer = (
  data: NavigatorTaskResponse,
): NavigatorTask => ({
  category: data.category ?? undefined,
  communication_log: data.communication_log,
  completed_date: data.completed_date
    ? dateDeserializer(data.completed_date)
    : undefined,
  created_at: data.created_at ? dateDeserializer(data.created_at) : undefined,
  description: data.description,
  goal_date: data.goal_date ? dateDeserializer(data.goal_date) : undefined,
  id: data.id,
  mobile_survivor: data.mobile_survivor
    ? baseUserDeserializer(data.mobile_survivor)
    : undefined,
  notes: data.notes,
  status: data.status,
  time_spent: data.time_spent,
  type_of: data.type_of ?? undefined,
  updated_at: data.created_at ? dateDeserializer(data.updated_at) : undefined,
})

export const getNavigatorTasksDeserializer = (
  data: PaginatedNavigatorTasksResponse,
): PaginatedItems<NavigatorTask> => {
  return {
    items: data.navigator_tasks.map(navigatorTaskDeserializer),
    pagination: data.pagination,
  }
}

export const defaultNavigatorTaskDeserializer = (
  data: DefaultNavigatorTaskResponse[],
): DefaultNavigatorTask[] =>
  data.map(item => ({
    description: item.description,
    goal_date: item.goal_date,
    goal_date_type: item.goal_date_type,
    id: item.id,
    position: item.position,
  }))
