import React from 'react'
import { useParams } from 'react-router-dom'
import { AssessmentDetails } from 'src/components/AssessmentDetails'
import { useLazyGetCarePartnerAssessmentDetailsQuery } from 'src/store/APIs/assessments'
import { useGetCarePartnerQuery } from 'src/store/APIs/carePartners'

interface CarePartnerAssessmentDetailsProps {}

export const CarePartnerAssessmentDetails: React.FC<
  CarePartnerAssessmentDetailsProps
> = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetCarePartnerQuery(id)

  return (
    <AssessmentDetails
      useLazyQuery={useLazyGetCarePartnerAssessmentDetailsQuery}
      user={data}
      isCarePartner
    />
  )
}
