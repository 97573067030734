import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    answer: 'Answer',
    answeredDate: 'Answered Date',
    answersTitle: 'Answers',
    assessmentCompleteModalBody:
      'You have completed this assessment on behalf of the survivor.',
    assessmentCompleteModalTitle: 'Assessment Complete',
    assignedTo: 'Assigned To',
    bothLabel: 'Both Survivor and Care Partner',
    category: 'Category',
    communicatedWith: 'Communicated With',
    communicatedWithBoth: 'Both Survivor and Care Partner',
    completeAssessmentLabel: 'Complete Assessment',
    completedBy: 'Completed By',
    completedDate: 'Completed Date',
    confirmEditPreviousQuestions: 'Proceed & Clear Form',
    dateCompletedLabel: 'Date Completed',
    datePlaceholder: 'MM/DD/YYYY',
    description: 'Description',
    dueDate: 'Due Date',
    editPreviousModalTitle:
      'Are you sure you want to edit the first two questions? This will clear out your answers. ',
    editPreviousQuestionsButton: 'Edit Previous Questions',
    extraPromisGlobalTableTitle: 'Explanation & Recommendation',
    finalScore: 'Final Score',
    nextButton: 'Next',
    patientFacingName: 'Patient Facing Name',
    question: 'Question',
    questionsTitle: 'All of the following assessment questions are required:',
    raw: 'Raw',
    recommendation: 'Recommendation',
    score: 'Score',
    scoreExplanation: 'Score Explanation',
    selectPlaceholder: 'Select',
    spokeToLabel: 'Who are you speaking with to complete this assessment?',
    status: 'Status',
    t_score: 'T-Score',
  },
})
