import { Form } from 'antd'
import { FormProviderProps } from 'antd/es/form/context'
import React, { useEffect, useState } from 'react'
import { PhoneNumberTypes } from 'src/common/types'
import { AddCarePartnerForm } from 'src/components/AddCarePartnerForm'
import { Button } from 'src/components/Button'
import { CarePartnerType } from 'src/components/CarePartnerForm/selectOptions'
import { SurvivorBasicDemographicsForm } from 'src/components/SurvivorBasicDemographicsForm'
import { Tabs } from 'src/components/Tabs'
import {
  useNewCarePartnerMutation,
  useUpdateCarePartnerMutation,
} from 'src/store/APIs/carePartners'
import {
  CarePartner,
  CarePartnerPayload,
} from 'src/store/APIs/carePartners/types'
import {
  useAssignCarePartnerMutation,
  useUpdateSurvivorMutation,
} from 'src/store/APIs/survivors'
import { Survivor } from 'src/store/APIs/survivors/types'

import styles from '../../survivorView.module.scss'
import { BasicDemographicsTab, BasicDemographicsTabNames } from '../types'
import { strings } from './strings'
import { FormNames } from './types'

interface BasicDemographicsEditProps {
  survivor?: Survivor
  toggleEditMode: () => void
  carePartner?: CarePartner
  activeTab?: BasicDemographicsTabNames
  onChangeTab: (tab: string) => void
}

export const BasicDemographicsEdit: React.FC<BasicDemographicsEditProps> = ({
  survivor,
  toggleEditMode,
  carePartner,
  activeTab = BasicDemographicsTabNames.survivor,
  onChangeTab,
}) => {
  const [
    triggerUpdateSurvivor,
    { isSuccess: isSuccessSurvivor, isLoading: updateLoading },
  ] = useUpdateSurvivorMutation()

  const [
    triggerAddNewCarePartner,
    { isLoading: addNewCarePartnerLoading, isSuccess: isSuccessAddNewCP },
  ] = useNewCarePartnerMutation()

  const [
    triggerAssignCarePartner,
    {
      isLoading: assignExistingCarePartnerLoading,
      isSuccess: isSuccessAssignCP,
    },
  ] = useAssignCarePartnerMutation()

  const [
    triggerUpdateCarePartner,
    { isLoading: updateCarePartnerLoading, isSuccess: isSuccessUpdateCP },
  ] = useUpdateCarePartnerMutation()

  const [selectCarePartnerForm] = Form.useForm<{
    select_option: CarePartnerType
    select_existing_care_partner: string
  }>()

  const [carePartnerOptionSelected, setCarePartnerOptionSelected] = useState(
    carePartner?.id,
  )

  const isLoading =
    updateLoading ||
    addNewCarePartnerLoading ||
    assignExistingCarePartnerLoading ||
    updateCarePartnerLoading

  const isSuccess =
    isSuccessSurvivor ||
    isSuccessAddNewCP ||
    isSuccessAssignCP ||
    isSuccessUpdateCP

  const handleSurvivorSubmit = async (values: any) => {
    const survivorInfo: Survivor = {
      ...values,
      preferred_phone: values.home_phone_preferred
        ? PhoneNumberTypes.Home
        : PhoneNumberTypes.Mobile,
      race: values?.race?.join(','),
      tacas_enabled: true,
    }
    await triggerUpdateSurvivor({
      ...survivorInfo,
      id: survivor?.id,
    })
  }

  const handleNewCarePartnerSubmit = async (values: any) => {
    const carePartnerInfo = {
      ...values,
      preferred_phone: values.home_phone_preferred
        ? PhoneNumberTypes.Home
        : PhoneNumberTypes.Mobile,
    }
    await triggerAddNewCarePartner({ mobile_caregiver: carePartnerInfo })
      .unwrap()
      .then(data => {
        if (survivor) {
          triggerAssignCarePartner({
            id: survivor?.id,
            mobile_caregiver_ids: [data.id],
          })
        }
      })
  }
  const handleExistCarePartnerSubmit = async (values: any) => {
    const body: CarePartnerPayload = {
      mobile_caregiver: {
        ...values,
        mobile_survivor_ids: carePartner?.survivors.map(
          survivorItem => survivorItem.id,
        ),
        preferred_phone: values.home_phone_preferred
          ? PhoneNumberTypes.Home
          : PhoneNumberTypes.Mobile,
        race: values?.race?.join(','),
      },
    }

    if (carePartnerOptionSelected) {
      await triggerAssignCarePartner({
        id: survivor!.id,
        mobile_caregiver_ids: [carePartnerOptionSelected],
      })
      await triggerUpdateCarePartner({
        body,
        id: carePartnerOptionSelected,
      })
    }
  }

  const tabs: BasicDemographicsTab[] = [
    {
      content: (
        <SurvivorBasicDemographicsForm
          formName={BasicDemographicsTabNames.survivor}
          survivor={survivor}
        />
      ),
      tabName: BasicDemographicsTabNames.survivor,
    },
    {
      content: (
        <AddCarePartnerForm
          carePartner={carePartner}
          selectCarePartnerForm={selectCarePartnerForm}
          formEditName={FormNames.care_partner_edit}
          formCreateName={FormNames.care_partner_create}
          carePartnerOptionSelected={carePartnerOptionSelected}
          setCarePartnerOptionSelected={setCarePartnerOptionSelected}
        />
      ),
      tabName: BasicDemographicsTabNames.care_partner,
    },
  ]

  const onFormFinish: FormProviderProps['onFormFinish'] = async (
    _,
    { forms },
  ) => {
    try {
      if (forms[FormNames.care_partner_create]) {
        await forms[FormNames.care_partner_create].validateFields()

        handleNewCarePartnerSubmit(
          forms[FormNames.care_partner_create].getFieldsValue(),
        )
      } else if (forms[FormNames.care_partner_edit]) {
        await forms[FormNames.care_partner_edit].validateFields()

        handleExistCarePartnerSubmit(
          forms[FormNames.care_partner_edit].getFieldsValue(),
        )
      }
      if (forms[BasicDemographicsTabNames.survivor]) {
        await forms[BasicDemographicsTabNames.survivor].validateFields()

        handleSurvivorSubmit(
          forms[BasicDemographicsTabNames.survivor].getFieldsValue(),
        )
      }
    } catch (e) {
      throw new Error(strings.validationErrorMessage)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      toggleEditMode()
    }
  }, [isSuccess])

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={onChangeTab}
        type="card"
        items={tabs.map(item => {
          const key = item.tabName
          return {
            children: <div>{item.content}</div>,
            key,
            label: <div className={styles.tabLabel}>{item.tabName}</div>,
          }
        })}
      />
      <Form>
        <Form.Item>
          <div className={styles.submitButtonContainer}>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              {strings.updateButtonLabel}
            </Button>
            <div className={styles.buttonSeparator} />
            <Button htmlType="button" onClick={toggleEditMode}>
              {strings.cancelButtonLabel}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Form.Provider>
  )
}
