import { InputNumber, InputNumberProps } from 'antd'
import React from 'react'

interface NumberInputProps extends InputNumberProps {
  label?: string
}

export const NumberInput: React.FC<NumberInputProps> = ({
  label,
  placeholder,
  required,
  ...props
}) => {
  return (
    <>
      <div>
        {label}
        {required && '*'}
      </div>
      <InputNumber {...props} required={required} placeholder={placeholder} />
    </>
  )
}
