import { Layout as AntdLayout } from 'antd'
import { Header } from 'antd/es/layout/layout'
import React from 'react'
import { NavBar } from 'src/components/NavBar'

enum LayoutType {
  Default = 'Default',
  NoPadding = 'NoPadding',
}

type LayoutProps = {
  children: React.ReactNode
  layoutType: LayoutType
}

const Layout = ({ layoutType, children }: LayoutProps) => {
  switch (layoutType) {
    case LayoutType.Default:
      return (
        <AntdLayout>
          <Header>
            <NavBar />
            {children}
          </Header>
        </AntdLayout>
      )
    case LayoutType.NoPadding:
      return (
        <AntdLayout>
          <Header>
            <NavBar />
          </Header>
          {children}
        </AntdLayout>
      )
    default:
      return null
  }
}

export { Layout, LayoutType }
