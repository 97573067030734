import { Empty } from 'antd'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CommunityGroupPost } from 'src/components/CommunityGroupPost'
import { useLazyGetGroupPostsQuery } from 'src/store/APIs/communityGroups'
import { CommunityGroup } from 'src/store/APIs/communityGroups/types'
import { Me } from 'src/store/APIs/misc/types'

import styles from './communityGroup.module.scss'

interface CommunityGroupPostsProps {
  group?: CommunityGroup
  currentUser?: Me
}

export const CommunityGroupPosts: React.FC<CommunityGroupPostsProps> = ({
  group,
  currentUser,
}) => {
  const { id } = useParams<{ id: string }>()
  const [triggerGetPosts, { data, isLoading }] = useLazyGetGroupPostsQuery()

  useEffect(() => {
    triggerGetPosts(id)
  }, [])

  if (data?.length === 0)
    return (
      <div className={styles.noPosts}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    )

  return (
    <div className={styles.groupPosts}>
      {data?.map(post => (
        <CommunityGroupPost
          key={post?.id}
          post={post}
          groupId={group?.id}
          currentUser={currentUser}
          isLoading={isLoading}
        />
      ))}
    </div>
  )
}
