import React, { useEffect, useState } from 'react'
import { useLazyGetCarePartnerQuery } from 'src/store/APIs/carePartners'
import { Survivor } from 'src/store/APIs/survivors/types'

import { EditableTab } from '../types'
import { BasicDemographicsEdit } from './BasicDemographicsEdit'
import { BasicDemographicsView } from './BasicDemographicsView'
import { BasicDemographicsTabNames } from './types'

interface BasicDemographicsProps extends EditableTab {
  survivor?: Survivor
}

export const BasicDemographics: React.FC<BasicDemographicsProps> = ({
  survivor,
  editMode,
  toggleEditMode,
}) => {
  const [activeTab, setActiveTab] = useState(BasicDemographicsTabNames.survivor)
  const onChangeTab = (tab: string) =>
    setActiveTab(tab as BasicDemographicsTabNames)
  const [triggerGetCarePartnerQuery, { data: carePartner }] =
    useLazyGetCarePartnerQuery()

  useEffect(() => {
    if (survivor?.mobile_caregivers[0]?.id) {
      triggerGetCarePartnerQuery(survivor.mobile_caregivers[0].id)
    }
  }, [survivor])

  if (editMode)
    return (
      <BasicDemographicsEdit
        toggleEditMode={toggleEditMode}
        survivor={survivor}
        carePartner={carePartner}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
      />
    )

  return (
    <BasicDemographicsView
      survivor={survivor}
      carePartner={carePartner}
      onChangeTab={onChangeTab}
      activeTab={activeTab}
    />
  )
}
