import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useEffect, useMemo, useState } from 'react'
import {
  LearnStatus,
  PaginatedItems,
  ParamsWithFilters,
  sortData,
} from 'src/store/APIs/types'

import { AudienceOptions } from '../Articles/types'

export const useSearchLearnSelect = (
  useLazyQuery: UseLazyQuery<
    QueryDefinition<ParamsWithFilters, any, any, PaginatedItems<any>, any>
  >,
  audience?: AudienceOptions,
  language?: string,
) => {
  const [reloadingKey, setReloadingKey] = useState('')

  const audienceFilter = [AudienceOptions.Shared]
  switch (audience) {
    case AudienceOptions.Survivor:
      audienceFilter.push(AudienceOptions.Survivor)
      break
    case AudienceOptions.CarePartner:
      audienceFilter.push(AudienceOptions.CarePartner)
      break
    default:
      break
  }

  const languageFilter = language ? [language] : undefined

  const filters = {
    audience: audienceFilter,
    hide: ['true'],
    language: languageFilter,
    status: [LearnStatus.submitted, LearnStatus.hasAssignedChildren],
  }
  const sortItem = sortData({
    columnKey: 'internal_name',
  })

  const [triggerLazyQuery, { data: firstPageElements, isLoading }] =
    useLazyQuery()

  useEffect(() => {
    triggerLazyQuery({
      filters,
      page: 1,
      sortItem,
    })
  }, [])

  const optionsSelect = useMemo(() => {
    const options = firstPageElements
      ? firstPageElements.items?.map(item => ({
          label: `${item.internal_name} - ${item.internal_id}`,
          value: item.id,
        }))
      : undefined

    return options
  }, [firstPageElements])

  const fetchedFilteredElems = (filterValue: string) =>
    triggerLazyQuery({
      filters: { internal_name_or_id: [filterValue], ...filters },
      sortItem,
    })
      .unwrap()
      .then(res =>
        res.items.map(item => ({
          label: `${item.internal_name} - ${item.internal_id}`,
          value: item.id,
        })),
      )

  useEffect(() => {
    triggerLazyQuery({
      filters: { internal_name_or_id: [], ...filters },
      sortItem,
    }).then(() => setReloadingKey((audience ?? '') + (language ?? '')))
  }, [audience, language])

  return {
    fetchedFilteredElems,
    isLoading,
    optionsSelect,
    reloadingKey,
  }
}
