import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  cms_navigator_ids: yup
    .array()
    .of(yup.string())
    .min(1, strings.navigatorsRequiredMessage)
    .required(strings.requiredValidation),
  description: yup.string().notRequired(),
  name: yup.string().required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
