import { ColumnsType } from 'antd/es/table'
import { generateColumnTitle } from 'src/helpers/utils'
import { SurvivorHealthConditionData } from 'src/store/APIs/healthConditions/types'

import { strings } from './strings'
import { HealthConditionsDataIndex } from './types'

export const columns: ColumnsType<SurvivorHealthConditionData> = [
  {
    ...generateColumnTitle(
      HealthConditionsDataIndex.health_condition,
      strings.healthCondition,
    ),
  },
  {
    ...generateColumnTitle(
      HealthConditionsDataIndex.description,
      strings.descriptionLabel,
    ),
  },
]
