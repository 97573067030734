import { FormInstance, Modal, UploadFile } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/es/upload'
import { useState } from 'react'
import { getBase64 } from 'src/common/helpers'

import { strings } from './strings'

export const useUploadImage = (
  form?: FormInstance<any>,
  beforeUploadExtraChecks?: (file: UploadFile) => boolean,
  initialImageURL?: string,
) => {
  const [previewImage, setPreviewImage] = useState<
    Partial<UploadChangeParam> | string | undefined
  >(initialImageURL)

  // INFO: string when it is the initial value URL, file otherwise
  const imageURL =
    typeof previewImage === 'string'
      ? previewImage
      : previewImage?.file?.preview || previewImage?.file?.url

  const beforeUpload = async (file: UploadFile) => {
    if (beforeUploadExtraChecks) {
      const valid = beforeUploadExtraChecks(file)
      if (!valid) return false
    }

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file as RcFile)
    }

    if (imageURL) {
      Modal.confirm({
        cancelText: strings.imageCancelModalText,
        okText: strings.imageConfirmModalText,
        onCancel() {
          form?.setFieldValue('image', previewImage)
        },
        onOk() {
          setPreviewImage({ ...form?.getFieldValue('image'), file })
          form?.setFieldValue('image', {
            ...form?.getFieldValue('image'),
            file,
          })
        },
        title: strings.imageConfirmModalTitle,
      })
    } else {
      setPreviewImage({ ...form?.getFieldValue('image'), file })
      form?.setFieldValue('image', { ...form?.getFieldValue('image'), file })
    }
    return false
  }
  return { beforeUpload, imageURL, previewImage, setPreviewImage }
}
