import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addGroup: 'Add Group',
    batchActionsButtonLabel: 'Batch Actions',
    closeLabel: 'Close',
    createdAtColumnLabel: 'Created At',
    deleteSelectedLabel: 'Delete selected',
    itemsSelectedLabel: '{0} items selected',
    nameColumnLabel: 'Name',
    title: 'Community Groups',
    updatedAtColumnLabel: 'Updated At',
  },
})
