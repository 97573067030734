import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    disabledInputTooltip: 'Please edit this field in Tellescope',
    emailLabel: 'Email',
    emailValidation: 'Email must be valid',
    firstNameLabel: 'First Name',
    homePhoneLabel: 'Home Phone',
    languageLabel: 'Language',
    lastNameLabel: 'Last Name',
    legalSexLabel: 'Legal Sex',
    limitationsLabel: 'Limitations',
    mobilePhoneLabel: 'Mobile Phone',
    phoneNumberLabel: 'Phone Number*',
    phoneNumberSubtitle: 'Enter at least one phone number:',
    preferredNameLabel: 'Preferred Name',
    preferredPhoneLabel: 'Preferred',
    preferredPronounLabel: 'Preferred Pronoun',
    requiredValidation: 'This field is required',
    testUserLabel: 'Test User',
    timezoneLabel: 'Time zone: %s',
    undefinedTimezone: 'No time zone recorded',
  },
})
