import { StarFilled } from '@ant-design/icons'
import React from 'react'
import colors from 'src/assets/stylesheets/colors.scss'
import { PhoneNumberTypes, timezoneFromIANAToOffset } from 'src/common/types'
import { Button } from 'src/components/Button'
import { InfoTable } from 'src/components/InfoTable'
import { Tag } from 'src/components/Tag'
import { CarePartner } from 'src/store/APIs/carePartners/types'

import styles from '../carePartner.module.scss'
import { EditableTab } from '../types'
import { BasicDemographicsEdit } from './BasicDemographicsEdit'
import { strings } from './strings'

interface BasicDemographicsProps extends EditableTab {
  carePartner?: CarePartner
}

export const BasicDemographics: React.FC<BasicDemographicsProps> = ({
  carePartner,
  editMode,
  toggleEditMode,
}) => {
  const rows = [
    { content: carePartner?.first_name, label: strings.firstNameLabel },
    {
      content: carePartner?.last_name,
      label: strings.lastNameLabel,
    },
    {
      content: carePartner?.preferred_name
        ? carePartner.preferred_name
        : undefined,
      label: strings.preferredNameLabel,
    },
    {
      content: carePartner?.preferred_pronoun
        ? carePartner.preferred_pronoun
        : undefined,
      label: strings.preferredPronounLabel,
    },
    {
      content: carePartner?.biological_sex ?? undefined,
      label: strings.legalSexLabel,
    },
    {
      content: carePartner?.content_language ?? undefined,
      label: strings.languageLabel,
    },
    {
      content: (
        <div className={styles.row}>
          <div className={styles.phone}>{carePartner?.phone_number}</div>
          {carePartner?.preferred_phone === PhoneNumberTypes.Mobile && (
            <Tag color="gold" icon={<StarFilled />}>
              {strings.preferredLabel}
            </Tag>
          )}
        </div>
      ),
      label: strings.mobilePhoneLabel,
    },
    {
      content: carePartner?.home_phone ? (
        <div className={styles.row}>
          <div className={styles.phone}>{carePartner?.home_phone}</div>
          {carePartner?.preferred_phone === PhoneNumberTypes.Home && (
            <Tag color="gold" icon={<StarFilled />}>
              {strings.preferredLabel}
            </Tag>
          )}
        </div>
      ) : undefined,
      label: strings.homePhoneLabel,
    },
    { content: carePartner?.email, label: strings.emailLabel },
    {
      content: timezoneFromIANAToOffset(carePartner?.time_zone),
      label: strings.timezoneLabel,
    },
    {
      content: carePartner?.limitations ?? undefined,
      label: strings.limitationsLabel,
    },
    {
      content: (
        <Tag
          color={carePartner?.test_user ? colors.info_50 : colors.surface_50}>
          {carePartner?.test_user ? strings.tagYes : strings.tagNo}
        </Tag>
      ),
      label: strings.testUserLabel,
    },
  ]

  if (editMode)
    return (
      <BasicDemographicsEdit
        carePartner={carePartner}
        closeEditMode={toggleEditMode}
      />
    )

  return (
    <>
      {carePartner?.permissions?.can_update && (
        <div className={styles.editButtonContainer}>
          <Button type="default" onClick={toggleEditMode}>
            {strings.editButtonLabel}
          </Button>
        </div>
      )}
      <InfoTable rows={rows} />
    </>
  )
}
