import { ColumnsType } from 'antd/es/table'
import { generateColumnTitle } from 'src/helpers/utils'

import { strings } from './strings'
import { AssessmentDetailsDataIndex } from './types'

export const columns: ColumnsType<AssessmentDetailsDataIndex> = [
  {
    ...generateColumnTitle(AssessmentDetailsDataIndex.title, strings.question),
  },
  {
    ...generateColumnTitle(
      AssessmentDetailsDataIndex.description,
      strings.description,
    ),
  },
  {
    ...generateColumnTitle(AssessmentDetailsDataIndex.answer, strings.answer),
  },
]
