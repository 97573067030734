import {
  Builder,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import { documentDeserializer } from './deserializers'
import {
  DocumentData,
  DocumentModel,
  DocumentResponse,
  DocumentsData,
  Endpoints,
  getDocumentsDeserializer,
} from './types'

export const getDocument = (builder: Builder) =>
  builder.query<DocumentData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Documents }],
    query: ({ id }) => format(Endpoints.GetDocument, id),
    transformResponse: ({ document }) => documentDeserializer(document),
  })

export const addDocument = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: DocumentModel }>({
    invalidatesTags: provideListToTag(Tags.Documents),
    query: ({ body }) => {
      const formData = new FormData()
      formData.append('document[mobile_survivor_id]', body.mobile_survivor_id)
      if (body.name) {
        formData.append('document[name]', body.name)
      }
      if (body.description) {
        formData.append('document[description]', body.description)
      }
      if (body.file) {
        formData.append(
          'document[file]',
          body.file.fileList[0].originFileObj as Blob,
        )
      }
      return {
        body: formData,
        method: HttpMethod.Post,
        url: Endpoints.AddDocument,
      }
    },
    transformResponse: performActionTransformResponse,
  })

export const updateDocument = (builder: Builder) =>
  builder.mutation<DocumentData, { id: string; body: DocumentModel }>({
    invalidatesTags: () => [{ type: Tags.Documents }],
    query: ({ body, id }) => {
      const formData = new FormData()
      formData.append('document[mobile_survivor_id]', body.mobile_survivor_id)
      if (body.name) {
        formData.append('document[name]', body.name)
      }
      if (body.description) {
        formData.append('document[description]', body.description)
      }
      if (body.file && body.file.fileList && body.file.fileList.length > 0) {
        formData.append(
          'document[file]',
          body.file.fileList?.[0].originFileObj as Blob,
        )
      }
      return {
        body: formData,
        method: HttpMethod.Put,
        url: format(Endpoints.UpdateDocument, id),
      }
    },
    transformResponse: ({ document }) =>
      documentDeserializer(document as DocumentResponse),
  })

export const deleteDocument = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.Documents),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.UpdateDocument, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const getSurvivorDocuments = (builder: Builder) =>
  builder.query<PaginatedItems<DocumentsData>, PaginatedParams>({
    providesTags: provideListToTag(Tags.Documents),
    query: paginatedParams(Endpoints.GetSurvivorDocuments),
    transformResponse: getDocumentsDeserializer,
  })
