import { Input, InputProps } from 'antd'
import React, { useState } from 'react'

interface PasswordInputProps extends InputProps {
  label?: string
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  placeholder,
  required,
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <>
      <div>
        {label}
        {required && '*'}
      </div>
      <Input.Password
        {...props}
        required={required}
        placeholder={placeholder === undefined ? label : placeholder}
        visibilityToggle={{
          onVisibleChange: setPasswordVisible,
          visible: passwordVisible,
        }}
      />
    </>
  )
}
