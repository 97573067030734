import { Rule, RuleObject } from 'antd/es/form'
import { MutableRefObject } from 'react'
import { yupSyncGenerator } from 'src/common/helpers'
import { richTextDefaultText } from 'src/components/RichTextEditor'
import * as yup from 'yup'

import { strings } from './strings'
import { SsmlError } from './types'

const schema = yup.object({
  audience: yup.string().required(strings.requiredValidation),
  content: yup
    .string()
    .notOneOf([richTextDefaultText], strings.requiredValidation)
    .required(strings.requiredValidation),
  internal_id: yup.string().required(strings.requiredValidation),
  internal_name: yup.string().required(strings.requiredValidation),
  language: yup.string().required(strings.requiredValidation),
  link: yup.string().notRequired(),
  revision_history: yup.string().notRequired(),
  theme: yup.string().required(strings.requiredValidation),
  title: yup.string().required(strings.requiredValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)

export const validatePage = (_: RuleObject, value?: string) => {
  if (!value || value === richTextDefaultText) {
    return Promise.reject(new Error(strings.pageValidation))
  }
  return Promise.resolve()
}

export const validateSsmlPage =
  (
    index: number,
    error?: SsmlError,
    validateFirstTime?: MutableRefObject<boolean>,
  ) =>
  (_: RuleObject, value?: string) => {
    if (!value || value === richTextDefaultText) {
      return Promise.reject(new Error(strings.pageValidation))
    }
    if (
      error?.data?.generation_error_page_number === index &&
      validateFirstTime?.current
    ) {
      validateFirstTime.current = false
      return Promise.reject(new Error(strings.ssmlValidation))
    }
    return Promise.resolve()
  }

type ValidatorFunction = (rule: any, value: any) => Promise<void | string>

export const requiredValidator: ValidatorFunction = (_, value) => {
  return new Promise((resolve, reject) => {
    if (!value || value.trim() === '') {
      reject(strings.requiredValidation)
    } else {
      resolve()
    }
  })
}
