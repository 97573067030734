import React from 'react'
import { LocalStorageKey } from 'src/common/types'
import { AppRedirect, RouteName } from 'src/routes'

import { Login as LoginPage } from './login'

export const Login = () => {
  const isLoggedIn = localStorage.getItem(LocalStorageKey.isLoggedIn)
  return isLoggedIn ? <AppRedirect routeName={RouteName.Home} /> : <LoginPage />
}
