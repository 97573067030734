import { TagProps } from 'antd'
import {
  CompletionStatusOptions as PackageStatusOptions,
  CompletionStatusOptionsValues,
} from 'src/pages/SurvivorView/Learn/types'

import { strings } from './strings'

export interface StatusProps {
  title: StatusOptions
  color: TagProps['color']
}

// TODO: Check if this is needed
export enum StatusOptions {
  NotStarted = 'Not started',
  InProgress = 'In progress',
  Completed = 'Completed',
}

export const statusProps = (status: StatusOptions): StatusProps => {
  switch (status) {
    case StatusOptions.Completed:
      return {
        color: 'green',
        title: StatusOptions.Completed,
      }
    case StatusOptions.NotStarted:
      return {
        color: 'geekblue',
        title: StatusOptions.NotStarted,
      }
    case StatusOptions.InProgress:
      return {
        color: 'cyan',
        title: StatusOptions.InProgress,
      }
    default:
      return {
        color: 'default',
        title: StatusOptions.NotStarted,
      }
  }
}

export enum LearnDataIndex {
  title = 'title',
  created_at = 'created_at',
  completed_date = 'completed_date',
  status = 'status',
  articles = 'articles',
  articles_completed = 'articles_completed',
  deployment_date = 'deployment_date',
  'units.title' = 'units.title',
  'mobile_user_lessons.created_at' = 'mobile_user_lessons.created_at',
  'units.completed_date' = 'units.completed_date',
  'articles.title' = 'articles.title',
}

export enum LearnTrackResponse {
  none = 'none',
  basic = 'basic',
  moderate = 'moderate',
  extended = 'extended',
  basic_spanish = 'basic_spanish',
}

export const learnTracks = {
  [LearnTrackResponse.none]: strings.learnTrackNone,
  [LearnTrackResponse.basic]: strings.learnTrackBasic,
  [LearnTrackResponse.moderate]: strings.learnTrackModerate,
  [LearnTrackResponse.extended]: strings.learnTrackExtended,
  [LearnTrackResponse.basic_spanish]: strings.learnTrackBasicSpanish,
}

export const learnTrackOptions = Object.entries(learnTracks).map(
  ([value, label]) => ({ label, value }),
)

export enum AssignPackageOptions {
  Existing = 'existing',
  New = 'new',
}

export const learnStatusFilters = [
  {
    text: PackageStatusOptions.Completed,
    value: CompletionStatusOptionsValues.Completed,
  },
  {
    text: PackageStatusOptions.Scheduled,
    value: CompletionStatusOptionsValues.Scheduled,
  },
  {
    text: PackageStatusOptions.InProgress,
    value: CompletionStatusOptionsValues.InProgress,
  },
  {
    text: PackageStatusOptions.NotStarted,
    value: CompletionStatusOptionsValues.NotStarted,
  },
]
