import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addButtonText: 'Add Navigator Task',
    completedDateLabel: 'Completed Date',
    createdAtLabel: 'Created At',
    goalDateLabel: 'Goal Date',
    navigatorTask: 'Navigator Task',
    notesLabel: 'Notes',
    notesPlaceholder: 'Enter text',
    onboardingDateLabel: 'Onboarding Date',
    optionalLabel: ' (optional)',
    registrationDateLabel: 'Registration Date',
    requiredValidation: 'This field is required',
    statusLabel: 'Status',
    statusPlaceholder: 'Select Status',
    taskLabel: 'Task',
    taskPlaceholder: 'Task description',
    timeSpentInputLabel: 'Time Spent (minutes)',
    timeSpentLabel: 'Time Spent',
    timeSpentValue: '%s min',
    typeLabel: 'Type',
    typePlaceholder: 'Select Type',
    undefinedLabel: 'Undefined',
    updateGoalDateButtonLabel: 'Update goal date of the navigator tasks',
  },
})
