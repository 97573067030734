import {
  BaseUserResponse,
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  Tags,
} from 'src/store/APIs/types'
import { format } from 'util'

import {
  Endpoints,
  getNavigatorResponseSelectDeserializer,
  getNavigatorsResponseDeserializer,
  Navigator,
  NavigatorDetails,
  navigatorDetailsDeserializer,
} from './types'

export const getNavigators = (builder: Builder) =>
  builder.query<PaginatedItems<Navigator>, ParamsWithFilters>({
    providesTags: () => [{ type: Tags.Navigators }],
    query: endpointWithFilters(Endpoints.GetNavigators),
    transformResponse: getNavigatorsResponseDeserializer,
  })

export const getNavigator = (builder: Builder) =>
  builder.query<NavigatorDetails, string>({
    providesTags: () => [{ type: Tags.Navigators }],
    query: id => format(Endpoints.GetNavigator, id),
    transformResponse: ({ cms_navigator }) =>
      navigatorDetailsDeserializer(cms_navigator),
  })

export const getNavigatorOptions = (builder: Builder) =>
  builder.query<PaginatedItems<BaseUserResponse>, ParamsWithFilters>({
    providesTags: () => [{ type: Tags.Navigators }],
    query: endpointWithFilters(Endpoints.GetNavigatorsSelect),
    transformResponse: getNavigatorResponseSelectDeserializer,
  })
