import { TablePaginationConfig } from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import { useState } from 'react'
import { TableProps } from 'src/components/Table'
import { Filters } from 'src/store/APIs/types'

interface TableFilters {
  pagination?: TablePaginationConfig
  filters?: Filters
  sorter?: SorterResult<any> | SorterResult<any>[]
}

export const useFilteredInfo = () => {
  const [filteredInfo, setFilteredInfo] = useState<TableFilters>({})

  const onChangeFiltered: TableProps['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    setFilteredInfo(prevState => ({
      ...prevState,
      filters,
      pagination,
      sorter,
    }))
  }

  const resetFilters = () => {
    setFilteredInfo({})
  }

  return {
    filteredInfo,
    onChangeFiltered,
    resetFilters,
  }
}
