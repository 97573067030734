import {
  DeleteOutlined,
  PictureOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { Form, FormInstance, Input, Upload } from 'antd'
import { UploadFile } from 'antd/es/upload'
import React from 'react'
import colors from 'src/assets/stylesheets/colors.scss'

import { Button } from '../Button'
import styles from './communityTextInput.module.scss'
import { ConversationValues, TypeOfMessage } from './types'

interface CommunityTextInputProps {
  placeholder: string
  previewImage?: string
  isLoading?: boolean
  beforeUpload?: (file: UploadFile) => Promise<boolean>
  form?: FormInstance<ConversationValues>
  onClearImage?: () => void
  messageType?: TypeOfMessage
}

const TextAndImageComponent: React.FC<{
  placeholder: string
  previewImage?: string
  onClearImage?: () => void
}> = ({ placeholder, previewImage, onClearImage }) => (
  <div className={styles.flexible}>
    <Form.Item name="message" className={styles.formItemTextArea}>
      <Input.TextArea
        size="large"
        bordered={false}
        placeholder={placeholder}
        className={styles.textArea}
        autoSize={{ maxRows: 3, minRows: 1 }}
      />
    </Form.Item>
    {previewImage && (
      <div className={styles.previewImageContainer}>
        <img
          width={250}
          src={previewImage}
          alt="preview"
          className={styles.imagePreview}
        />
        <Button
          onClick={onClearImage}
          danger
          size="small"
          className={styles.deleteImageButton}>
          <DeleteOutlined />
        </Button>
      </div>
    )}
  </div>
)

const TextOrImageComponent: React.FC<{
  placeholder: string
  previewImage?: string
  onClearImage?: () => void
}> = ({ placeholder, previewImage, onClearImage }) =>
  !previewImage ? (
    <Form.Item name="message" className={styles.formItemTextArea}>
      <Input.TextArea
        size="large"
        bordered={false}
        placeholder={placeholder}
        className={styles.textArea}
        autoSize={{ maxRows: 3, minRows: 1 }}
      />
    </Form.Item>
  ) : (
    <div className={styles.flexible}>
      <img
        width={250}
        src={previewImage}
        alt="preview"
        className={styles.imagePreview}
      />
      <Button
        onClick={onClearImage}
        danger
        size="small"
        className={styles.deleteImageButton}>
        <DeleteOutlined />
      </Button>
    </div>
  )

export const CommunityTextInput: React.FC<CommunityTextInputProps> = ({
  placeholder,
  previewImage,
  isLoading = false,
  beforeUpload,
  onClearImage,
  form,
  messageType = TypeOfMessage.MULTIPLE_ITEMS,
}) => {
  const message = Form.useWatch('message', form)
  const uploadDisabled =
    messageType === TypeOfMessage.ONE_ITEM_AT_TIME && !!message
  const sendDisabled = !message && !previewImage

  return (
    <>
      <div className={styles.inputContainer}>
        {messageType === TypeOfMessage.ONE_ITEM_AT_TIME ? (
          <TextOrImageComponent
            placeholder={placeholder}
            previewImage={previewImage}
            onClearImage={onClearImage}
          />
        ) : (
          <TextAndImageComponent
            placeholder={placeholder}
            previewImage={previewImage}
            onClearImage={onClearImage}
          />
        )}
        <Form.Item name="image" className={styles.formItemUploadImage}>
          <Upload
            maxCount={1}
            disabled={uploadDisabled}
            accept="image/*"
            showUploadList={false}
            beforeUpload={beforeUpload}>
            <Button
              type="text"
              className={styles.uploadButton}
              size="large"
              shape="circle"
              icon={
                <PictureOutlined
                  style={{
                    color: uploadDisabled ? colors.surface_50 : undefined,
                  }}
                />
              }
            />
          </Upload>
        </Form.Item>
      </div>
      <div className={styles.sendButtonContainer}>
        <Button
          loading={isLoading}
          disabled={sendDisabled}
          shape="circle"
          type="text"
          size="large"
          htmlType="submit"
          className={styles.sendButton}
          icon={<SendOutlined />}
        />
      </div>
    </>
  )
}
