import React from 'react'
import { formatDateToRender } from 'src/common/types'
import { InfoTable } from 'src/components/InfoTable'
import { Links } from 'src/components/Links'
import { Table } from 'src/components/Table'
import { TableTitle } from 'src/components/TableTitle'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { CommunicationLog } from 'src/store/APIs/communicationLogs/types'

import { generateVisitStatusColor, VisitStatus } from '../columns'
import styles from '../communicationLogs.module.scss'
import { strings } from '../strings'
import { columns } from './columns'

interface ViewCommunicationLogProps {
  data?: CommunicationLog
}

export const ViewCommunicationLog: React.FC<ViewCommunicationLogProps> = ({
  data,
}) => {
  const rows = [
    {
      content: data?.title,
      label: strings.titleColumnLabel,
    },
    {
      content: data?.summary && (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: data?.summary }} />
      ),
      label: strings.summaryColumnLabel,
    },
    {
      content: formatDateToRender(data?.encounter_date),
      label: strings.encounterDateColumnLabel,
    },
    {
      content: <Links items={data?.participants} />,
      label: strings.participantsColumnLabel,
    },
    {
      content: data?.topic,
      label: strings.topicColumnLabel,
    },
    {
      content: data?.type_of,
      label: strings.typeColumnLabel,
    },
    {
      content: data?.status && (
        <Tag color={generateVisitStatusColor(data.status as VisitStatus)}>
          {data.status}
        </Tag>
      ),
      label: strings.statusColumnLabel,
    },
    {
      content: data?.escalation ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag>{strings.tagNo}</Tag>
      ),
      label: strings.escalationColumnLabel,
    },
    {
      content: data?.created_by && <UserHyperlink user={data?.created_by} />,
      label: strings.createdByColumnLabel,
    },
    {
      content: formatDateToRender(data?.created_at),
      label: strings.createdAtColumnLabel,
    },
    {
      content: data?.updated_by && <UserHyperlink user={data?.updated_by} />,
      label: strings.updatedByColumnLabel,
    },
    {
      content: formatDateToRender(data?.updated_at),
      label: strings.updatedAtColumnLabel,
    },
    {
      content: data?.is_draft ? (
        <Tag color="pink">{strings.tagDraftYes}</Tag>
      ) : (
        <Tag color="green">{strings.tagDraftNo}</Tag>
      ),
      label: strings.isDraftColumnLabel,
    },
  ]

  return (
    <>
      <InfoTable rows={rows} />
      <Table
        dataSource={data?.documents}
        columns={columns}
        bordered
        pagination={false}
        rowKey={record => record.id}
        caption={<TableTitle title={strings.documentsTableTitle} />}
        className={styles.table}
      />
    </>
  )
}
