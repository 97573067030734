import { baseApi } from 'src/store/APIs'

import { getQuickJot, getSurvivorQuickJots } from './actions'

export const quickJotsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getQuickJot: getQuickJot(builder),
    getSurvivorsQuickJots: getSurvivorQuickJots(builder),
  }),
})

export const { useLazyGetQuickJotQuery, useLazyGetSurvivorsQuickJotsQuery } =
  quickJotsApi
