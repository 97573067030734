import { DefaultNavigatorTaskTypeOptions } from './DefaultNavigatorTask/types'
import { strings } from './strings'

export const defaultNavigatorTaskTypeOptions = [
  {
    label: strings.registrationBased,
    value: DefaultNavigatorTaskTypeOptions.Enrollment,
  },
  {
    label: strings.onboardingBased,
    value: DefaultNavigatorTaskTypeOptions.Onboarding,
  },
]
