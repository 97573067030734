import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { message } from 'antd'
import { LocalStorageKey } from 'src/common/types'
import { constants } from 'src/config/constants'
import { history, Paths } from 'src/routes/routes'
import { ErrorStatus, prepareHeaders } from 'src/store/helpers'

import { setCsrfToken } from '../storage/headers'
import { CsrfResponse, Endpoints as AuthEndpoints } from './auth/types'
import { Endpoints as MiscEndpoints } from './misc/types'
import { BaseQueryFnType, ReducerPath, Tags } from './types'

export const baseQuery = fetchBaseQuery({
  baseUrl: constants.apiBaseURL,
  credentials: 'include',
  prepareHeaders,
})

export const baseQueryWithReauth: BaseQueryFnType = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === ErrorStatus.Unauthorized) {
    if (api.type === 'mutation') {
      const refreshResult = await baseQuery(
        AuthEndpoints.GetCsrfToken,
        api,
        extraOptions,
      )
      if (refreshResult.data) {
        api.dispatch(
          setCsrfToken((refreshResult.data as CsrfResponse).csrf_token),
        )

        result = await baseQuery(args, api, extraOptions)
      } else if (refreshResult.error) {
        message.error((refreshResult.error?.data as { error: string }).error)
      }
    }
    if (result.error) {
      if (!result.meta?.request.url.includes(MiscEndpoints.Me)) {
        message.error((result.error?.data as { error: string }).error)
      }
      if (result.error.status === ErrorStatus.Unauthorized) {
        localStorage.removeItem(LocalStorageKey.isLoggedIn)
        history.replace(Paths.Login)
      }
    }
  } else if (result.data && (result.data as CsrfResponse).csrf_token) {
    api.dispatch(setCsrfToken((result.data as CsrfResponse).csrf_token))
  }

  return result
}

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  reducerPath: ReducerPath.root,
  tagTypes: [
    Tags.CommunicationLogs,
    Tags.Documents,
    Tags.QuickJots,
    Tags.PurchaseTodos,
    Tags.Tacas,
    Tags.NavigatorTasks,
    Tags.CarePartners,
    Tags.Conversations,
    Tags.CommunityGroups,
    Tags.HealthcareTeamRoles,
    Tags.Assessments,
    Tags.Survivors,
    Tags.HealthConditions,
    Tags.HealthEvents,
    Tags.HospitalTreatments,
    Tags.Impairments,
    Tags.MobileUsers,
    Tags.Navigators,
    Tags.StrokeCauses,
    Tags.HealthcareTeam,
    Tags.OtherEvents,
    Tags.PlanOfCare,
    Tags.RetailProducts,
    Tags.GroupPosts,
    Tags.Articles,
    Tags.Packages,
    Tags.Hospitals,
    Tags.Users,
  ],
})
