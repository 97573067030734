import { useState } from 'react'
import { DrawerState } from 'src/components/DrawerWithState/types'

export const useDrawer = () => {
  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState<string | undefined>()
  const [drawerState, setDrawerState] = useState<DrawerState>(DrawerState.View)

  const showDrawer = () => {
    setOpen(true)
  }

  const onCloseDrawer = () => {
    setOpen(false)
    setSelectedId(undefined)
  }

  const openView = (id?: string) => () => {
    if (id) {
      setSelectedId(id)
    }
    setDrawerState(DrawerState.View)
    showDrawer()
  }

  const openEdit = (id?: string) => () => {
    if (id) {
      setSelectedId(id)
    }
    setDrawerState(DrawerState.Edit)
    showDrawer()
  }

  return {
    drawerState,
    onCloseDrawer,
    open,
    openEdit,
    openView,
    selectedId,
    setOpen,
    setSelectedId,
    showDrawer,
  }
}
