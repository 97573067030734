import { message } from 'antd'
import { generalStrings } from 'src/common/generalStrings'
import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { GeneralTagIds, HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import { baseApi } from '..'
import {
  ArticleInfo,
  AudioResponse,
  ContentPagesResponse,
  deserializeSsmlPages,
  Endpoints,
  ExtendedArticleInfo,
  extendedArticleInfoDeserializer,
  ExtendedArticleInfoResponse,
  FormNewArticle,
  GenerateAudioResponse,
  getArticlesDeserializer,
  Media,
  PageResponse,
  serializeArticle,
  UploadMedia,
} from './types'

const articlesTransformResponse = ({
  article,
}: {
  article: ExtendedArticleInfoResponse
}) => {
  message.success(generalStrings.successMessage)
  return extendedArticleInfoDeserializer(article)
}

export const getArticles = (builder: Builder) =>
  builder.query<PaginatedItems<ArticleInfo>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.Articles),
    query: endpointWithFilters(Endpoints.GetArticles),
    transformResponse: getArticlesDeserializer,
  })

export const getArticle = (builder: Builder) =>
  builder.query<ExtendedArticleInfo, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.Articles }],
    query: ({ id }) => format(Endpoints.GetArticle, id),
    transformResponse: ({ article }) =>
      extendedArticleInfoDeserializer(article),
  })

export const addArticle = (builder: Builder) =>
  builder.mutation<ExtendedArticleInfo, { body: FormNewArticle }>({
    invalidatesTags: provideListToTag(Tags.Articles),
    query: ({ body: article }) => ({
      body: serializeArticle(article),
      method: HttpMethod.Post,
      url: Endpoints.AddArticle,
    }),
    transformResponse: articlesTransformResponse,
  })

export const paginateContent = (builder: Builder) =>
  builder.mutation<ContentPagesResponse, string>({
    query: content => ({
      body: { content },
      method: HttpMethod.Post,
      url: Endpoints.PaginateContent,
    }),
  })

export const generateSsml = (builder: Builder) =>
  builder.mutation<ContentPagesResponse, PageResponse[]>({
    query: pages => ({
      body: { pages },
      method: HttpMethod.Post,
      url: Endpoints.GenerateSsml,
    }),
    transformResponse: deserializeSsmlPages,
  })

export const generateAudio = (builder: Builder) =>
  builder.mutation<GenerateAudioResponse, string[]>({
    query: pages => ({
      body: { pages },
      method: HttpMethod.Post,
      url: Endpoints.GenerateAudio,
    }),
  })

export const getAudio = (builder: Builder) =>
  builder.query<AudioResponse, string>({
    query: id => {
      return format(Endpoints.GetAudio, id)
    },
    transformResponse: (data: Partial<AudioResponse>, query) => ({
      ...data,
      status: query?.response?.status,
    }),
  })

export const deleteAudio = (builder: Builder) =>
  builder.mutation<void, { id: string; idArticle?: string }>({
    onQueryStarted({ id, idArticle }, { dispatch }) {
      // INFO: this type is wrong but if we use articlesApi we get a circular dependency error
      dispatch(
        // @ts-ignore
        baseApi.util.updateQueryData('getArticle', { id: idArticle }, draft => {
          const cleanObject = {
            audio: {
              file: null,
              id: null,
            },
          }
          Object.assign(draft, cleanObject)
        }),
      )
      // INFO: this type is wrong but if we use articlesApi we get a circular dependency error
      dispatch(
        // @ts-ignore
        baseApi.util.updateQueryData('getAudio', id, draft => {
          const cleanObject = {
            error: null,
            file: null,
            id: null,
            status: null,
          }
          Object.assign(draft, cleanObject)
        }),
      )
    },
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteAudio, id),
    }),
  })

export const editArticle = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string; body: FormNewArticle }>(
    {
      invalidatesTags: () => [
        { id: GeneralTagIds.List, type: Tags.Articles },
        { id: GeneralTagIds.Individual, type: Tags.Packages },
        { id: GeneralTagIds.Units, type: Tags.Survivors },
        { id: GeneralTagIds.Units, type: Tags.CarePartners },
      ],
      query: ({ body, id }) => ({
        body: serializeArticle(body),
        method: HttpMethod.Put,
        url: format(Endpoints.EditArticle, id),
      }),
      transformResponse: performActionTransformResponse,
    },
  )

export const uploadMedia = (builder: Builder) =>
  builder.mutation<Media, UploadMedia>({
    query: ({ file }) => {
      const formData = new FormData()
      formData.append('photo[image_data]', file[0])
      return {
        body: formData,
        method: HttpMethod.Post,
        url: Endpoints.UploadPicture,
      }
    },
  })

export const deleteArticle = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: () => [
      { id: GeneralTagIds.List, type: Tags.Articles },
      { id: GeneralTagIds.Individual, type: Tags.Packages },
    ],
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteArticle, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const removeArticle = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: () => [
      { id: GeneralTagIds.List, type: Tags.Articles },
      { id: GeneralTagIds.Individual, type: Tags.Packages },
      { id: GeneralTagIds.Units, type: Tags.Survivors },
      { id: GeneralTagIds.Units, type: Tags.CarePartners },
    ],
    query: ({ id }) => ({
      method: HttpMethod.Put,
      url: format(Endpoints.RemoveArticle, id),
    }),
    transformResponse: performActionTransformResponse,
  })
