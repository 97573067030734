import React from 'react'
import { AddBasicItem } from 'src/components/AddBasicItem'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { ViewBasicItem } from 'src/components/ViewBasicItem'
import { useDrawer } from 'src/hooks/useDrawer'
import {
  useAddStrokeCauseMutation,
  useDeleteStrokeCauseMutation,
  useEditStrokeCauseMutation,
  useLazyGetStrokeCauseQuery,
  useLazyGetStrokeCausesQuery,
} from 'src/store/APIs/strokeCauses'

import styles from '../../survivorProfile.module.scss'
import { strings } from '../strings'
import { columns } from './columns'

export const StrokeCause: React.FC = () => {
  const { open, onCloseDrawer, openView, selectedId, showDrawer } = useDrawer()

  const [triggerAddStrokeCauseMutation, { isLoading: loadingAdd }] =
    useAddStrokeCauseMutation()

  const [triggerEditStrokeCauseMutation, { isLoading: loadingEdit }] =
    useEditStrokeCauseMutation()

  const [triggerDeleteStrokeCauseMutation, { isLoading: loadingDelete }] =
    useDeleteStrokeCauseMutation()

  const isLoadingRightButton = loadingAdd || loadingEdit

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{strings.strokeCauseTitle}</div>
        <Button type="primary" onClick={showDrawer}>
          {strings.addStrokeCauseButton}
        </Button>
      </div>
      <DrawerWithState
        isLoadingRightButton={isLoadingRightButton}
        isLoadingLeftButton={loadingDelete}
        selectedId={selectedId}
        open={open}
        resourceName={strings.strokeCauseTitle}
        RenderAdd={AddBasicItem}
        RenderView={ViewBasicItem}
        RenderEdit={AddBasicItem}
        useLazyGetResource={useLazyGetStrokeCauseQuery}
        onCloseDrawer={onCloseDrawer}
        triggerAddResourceMutation={triggerAddStrokeCauseMutation}
        triggerEditResourceMutation={triggerEditStrokeCauseMutation}
        triggerDeleteResourceMutation={triggerDeleteStrokeCauseMutation}
        deleteModalTitle={strings.deleteStrokeCauseModalTitle}
      />
      <PaginatedTable
        className={styles.table}
        columns={columns(openView)}
        useLazyQuery={useLazyGetStrokeCausesQuery}
      />
    </>
  )
}
