import './index.scss'

import { ConfigProvider } from 'antd'
import React from 'react'
import { Provider } from 'react-redux'
import { ErrorBoundary } from 'src/components/ErrorBoundary'
import { routeConfig } from 'src/route-components'
import { Router } from 'src/routes'

import { Theme } from './assets/stylesheets/theme'
import { OnMountLogic } from './components/OnMountLogic'
import { useBugTracking } from './hooks/useBugTracking'
import { store } from './store'

export const App = () => {
  useBugTracking()
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <ConfigProvider theme={Theme}>
          <OnMountLogic>
            <Router routeConfig={routeConfig} />
          </OnMountLogic>
        </ConfigProvider>
      </ErrorBoundary>
    </Provider>
  )
}
