import { message } from 'antd'
import { generalStrings } from 'src/common/generalStrings'
import {
  Body,
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  buildCommunicationLogPayload,
  CommunicationLog,
  communicationLogDeserializer,
  CommunicationLogOptions,
  communicationLogOptionsDeserializer,
  CommunicationLogPayload,
  CommunicationLogResponse,
  Endpoints,
  getCommunicationLogsDeserializer,
} from './types'

const communicationLogTransformResponse = ({
  communication_log,
}: {
  communication_log: CommunicationLogResponse
}) => {
  message.success(generalStrings.successMessage)
  return communicationLogDeserializer(communication_log)
}

export const getSurvivorsCommunicationLogs = (builder: Builder) =>
  builder.query<PaginatedItems<CommunicationLog>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.CommunicationLogs),
    query: endpointWithFilters(Endpoints.GetSurvivorsCommunicationLogs),
    transformResponse: getCommunicationLogsDeserializer,
  })

export const getCommunicationLog = (builder: Builder) =>
  builder.query<CommunicationLog, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.CommunicationLogs }],
    query: ({ id }) => format(Endpoints.GetCommunicationLog, id),
    transformResponse: ({ communication_log }) =>
      communicationLogDeserializer(communication_log),
  })

export const getCommunicationLogOptions = (builder: Builder) =>
  builder.query<CommunicationLogOptions, { survivorId: string }>({
    query: ({ survivorId }) =>
      format(Endpoints.GetCommunicationLogOptions, survivorId),
    transformResponse: communicationLogOptionsDeserializer,
  })

export const addCommunicationLog = (builder: Builder) =>
  builder.mutation<CommunicationLog, Body<CommunicationLogPayload>>({
    invalidatesTags: provideListToTag(Tags.CommunicationLogs),
    query: communicationLog => {
      return {
        body: buildCommunicationLogPayload(communicationLog.body),
        method: HttpMethod.Post,
        url: Endpoints.AddCommunicationLog,
      }
    },
    transformResponse: communicationLogTransformResponse,
  })

export const editCommunicationLog = (builder: Builder) =>
  builder.mutation<CommunicationLog, Body<CommunicationLogPayload>>({
    invalidatesTags: () => [{ type: Tags.CommunicationLogs }],
    query: ({ body: communicationLog, id }) => {
      return {
        body: buildCommunicationLogPayload({
          ...communicationLog,
          mobile_survivor_id: undefined,
        }),
        method: HttpMethod.Put,
        url: format(Endpoints.EditCommunicationLog, id),
      }
    },
    transformResponse: communicationLogTransformResponse,
  })

export const deleteCommunicationLog = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.CommunicationLogs),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteCommunicationLog, id),
    }),
    transformResponse: performActionTransformResponse,
  })
