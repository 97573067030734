import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Form } from 'antd'
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { getUserFullName } from 'src/common/helpers'
import { Button } from 'src/components/Button'
import { SubHeader } from 'src/components/SubHeader'
import { RouteName } from 'src/routes'

import { BreadcrumbParams } from '../Breadcrumb'
import { PasswordInput } from '../PasswordInput'
import styles from './resetPassword.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

interface ResetPasswordProps {
  isCarePartner?: boolean
  useLazyGetUserQuery: UseLazyQuery<QueryDefinition<any, any, any, any, any>>
  // TODO: type this better
  useResetPassword: any
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  isCarePartner = false,
  useResetPassword,
  useLazyGetUserQuery,
}) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const [triggerGetUserQuery, { data: user }] = useLazyGetUserQuery()

  useEffect(() => {
    if (id) {
      triggerGetUserQuery(id)
    }
  }, [id])

  const [triggerResetPassword, { isLoading, isSuccess }] = useResetPassword()

  const handleSubmit = async (values: any) => {
    const body = {
      new_password: values,
    }
    await triggerResetPassword({ body, id })
  }

  const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
    { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
    {
      breadcrumbName: isCarePartner ? 'Care Partners' : 'Survivors',
      path: isCarePartner ? RouteName.CarePartners : RouteName.Survivors,
    },
    {
      breadcrumbName: getUserFullName(user?.first_name, user?.last_name),
      path: isCarePartner ? RouteName.CarePartner : RouteName.SurvivorView,
    },
  ]

  const breadcrumbParams: BreadcrumbParams = { pathParams: [{}, {}, { id }] }

  useEffect(() => {
    if (isSuccess) {
      history.goBack()
    }
  }, [isSuccess])

  return (
    <>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        params={breadcrumbParams}
        title={strings.title}
      />
      <div className={styles.separatorContainer}>
        <div className={styles.separator} />
      </div>
      <Form onFinish={handleSubmit} name="reset_password">
        <Form.Item name="password" rules={[yupSync]} hasFeedback>
          <PasswordInput required label={strings.passwordLabel} />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          rules={[
            yupSync,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(strings.passwordMatchValidation),
                )
              },
            }),
          ]}
          hasFeedback>
          <PasswordInput required label={strings.passwordConfirmationLabel} />
        </Form.Item>
        <Form.Item>
          <div className={styles.submitButtonContainer}>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              {strings.submitButtonLabel}
            </Button>
            <div className={styles.buttonSeparator} />
            <Button htmlType="button" onClick={history.goBack}>
              {strings.cancelButtonLabel}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}
