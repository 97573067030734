import { MutationDefinition } from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Form, FormInstance, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { HYPERLINK_REGEX } from 'src/common/regex'
import { formatDateWithTimeToRender } from 'src/common/types'
import { generateColumnTitle } from 'src/helpers/utils'
import { AppLink, RouteName } from 'src/routes'
import {
  Conversation,
  ConversationType,
  fullNameDeserializer,
  UserType,
} from 'src/store/APIs/types'
import { format } from 'util'

import { Button } from '../Button'
import { DeleteButton } from '../DeleteButton'
import styles from './conversations.module.scss'
import { strings } from './strings'
import { ConversationDataIndex } from './types'

export const useConversationColumns = (
  triggerEditConversationMessage: MutationTrigger<
    MutationDefinition<any, any, any, any>
  >,
  triggerDeleteConversationMessage: MutationTrigger<
    MutationDefinition<any, any, any, any>
  >,
  form: FormInstance<Conversation>,
  type: ConversationType,
  userId?: string,
  isCarePartner?: boolean,
) => {
  const [editingRowId, setEditingRowId] = useState('')
  const messageContent = Form.useWatch(ConversationDataIndex.content, form)

  const isEditing = (record: Conversation) => editingRowId === record.id

  const onClickEdit = (record: Conversation) => () => {
    setEditingRowId(record.id)
    form.setFieldsValue({ ...record })
  }

  const onClickSave = (record: Conversation) => {
    form
      .validateFields()
      .then(() => {
        setEditingRowId('')
        if (userId)
          triggerEditConversationMessage({
            body: {
              conversation_type: type,
              message: messageContent,
            },
            messageId: record.id,
            userId,
          })
      })
      .catch(() => message.error(generalStrings.requiredFieldValidation))
  }

  const deleteModalMessage = isCarePartner
    ? format(strings.deleteModalTitle, strings.carePartnerLabel)
    : format(strings.deleteModalTitle, strings.survivorLabel)

  const columns: ColumnsType<Conversation> = [
    {
      ...generateColumnTitle(
        ConversationDataIndex.sent_by,
        strings.sentByLabel,
      ),
      render(_, record) {
        const fullName = fullNameDeserializer(record.sent_by)
        const routeName =
          record.sent_by.user_type === UserType.mobile_survivor
            ? RouteName.SurvivorView
            : RouteName.CarePartner
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {record.sent_by.user_type === UserType.cms_navigator ? (
              fullName
            ) : (
              <AppLink
                routeName={routeName}
                pathParams={{
                  id: record.sent_by.id!,
                }}>
                {fullName}
              </AppLink>
            )}
          </>
        )
      },
    },
    {
      ...generateColumnTitle(
        ConversationDataIndex.content,
        strings.contentLabel,
      ),
      onCell: (record: Conversation) => ({
        dataIndex: ConversationDataIndex.content,
        editable: isEditing(record),
        record,
        title: strings.contentLabel,
      }),
      render(_, record) {
        if (isEditing(record))
          return (
            <Button
              className={styles.submitButton}
              type="primary"
              onClick={() => onClickSave(record)}
              disabled={!messageContent}>
              {strings.submitLabel}
            </Button>
          )
        return record.content ? (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: record.content }} />
        ) : null
      },
    },
    {
      ...generateColumnTitle(
        ConversationDataIndex.sent_at,
        strings.sentAtLabel,
      ),
      render: formatDateWithTimeToRender,
    },
    {
      fixed: 'right',
      render(_, record) {
        return (
          <div className={styles.actionButtonContainer}>
            {record.permissions?.can_update &&
              !record.content.match(HYPERLINK_REGEX) && (
                <Button onClick={onClickEdit(record)}>
                  {strings.editButton}
                </Button>
              )}
            {record.permissions?.can_destroy && (
              <DeleteButton
                modalTitle={deleteModalMessage}
                okText={strings.deleteButton}
                onClickYes={triggerDeleteConversationMessage}
                ownerId={userId}
                id={record.id}>
                {strings.deleteButton}
              </DeleteButton>
            )}
          </div>
        )
      },
      title: strings.actionsLabel,
      width: 173,
    },
  ]

  return {
    columns,
  }
}
