import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    actionsColumnLabel: 'Actions',
    addTodoButtonLabel: 'Add To-Do',
    completedByLabel: 'Completed By',
    completedColumnLabel: 'Completed',
    completedDateColumnLabel: 'Completed Date',
    createdByLabel: 'Created By',
    deleteModalTitle: 'Are you sure you want to delete the to-do?',
    descriptionLabel: 'Description',
    discardedByLabel: 'Discarded By',
    firstReminderDateColumnLabel: `First Reminder Date (survivor's time zone)`,
    firstReminderDateLabel: 'First Reminder Date',
    noRadioLabel: 'No',
    notApplicable: 'N/A',
    recurringLabel: 'Recurring',
    reminderColumnLabel: 'Reminder?',
    reminderDateLabel: 'Reminder Date',
    reminderDateSubtitle: `In survivor's timezone`,
    reminderDateValidation: 'Please enter a reminder date',
    reminderQuestion:
      'Would you like the survivor to get a reminder for this to-do?',
    repeatAnnually: 'Repeat Annually',
    repeatDaily: 'Repeat Daily',
    repeatMonthly: 'Repeat Monthly',
    repeatScheduleColumnLabel: 'Repeat Schedule',
    repeatScheduleLabel: 'Repeat Schedule',
    repeatWeekly: 'Repeat Weekly',
    resourceName: 'To-Do',
    tagNo: 'No',
    tagYes: 'Yes',
    titleLabel: 'Title',
    titleNote:
      'Note, the survivor will see this as the title of the to-do in the app',
    titleValidation: 'Please enter a title for the To-Do',
    todoLabel: 'To-Do',
    todosColumnLabel: 'To-Do',
    viewLabel: 'View',
    yesRadioLabel: 'Yes - One time only',
    yesRecurringRadioLabel: 'Yes - Recurring reminder',
  },
})
