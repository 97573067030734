import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  checkbox: yup.bool(),
  email: yup
    .string()
    .required(strings.enterEmailValidation)
    .email(strings.validEmailValidation),
  password: yup.string().required(strings.enterPasswordValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
