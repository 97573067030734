import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    activeQuestionsTabLabel: 'Active Questions',
    addQTAButtonLabel: 'Add Question',
    answerColumnLabel: 'Answer',
    answeredByLabel: 'Answered By',
    answeredDateColumnLabel: 'Answer Date',
    cancel: 'Cancel',
    createdAtColumnLabel: 'Created At',
    createdByColumnLabel: 'Created By',
    deletedQuestionsTabLabel: 'Deleted Questions',
    discardedAtLabel: 'Discarded At',
    notifyUncompletedButton: 'Notify Uncompleted Question To Ask',
    questionLabel: 'Question',
    tagNo: 'No',
    tagYes: 'Yes',
    updatedAtLabel: 'Updated At',
    voiceMessageLabel: 'Voice Message',
  },
})
