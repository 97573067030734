import {
  ExclamationCircleFilled,
  HeartFilled,
  HeartOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Card, Form, Modal, Skeleton } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { hyperlinkConverter } from 'src/common/helpers'
import { GETSTREAM_IMAGE_REGEX } from 'src/common/regex'
import { deserializeExtendedFormattedDate } from 'src/common/types'
import { useUploadImage } from 'src/hooks/useUploadImage'
import {
  useDeleteGroupPostMutation,
  useLazyGetPostCommentsQuery,
  useLikeGroupPostMutation,
  useNewPostCommentMutation,
  useUnlikeGroupPostMutation,
} from 'src/store/APIs/communityGroups'
import { Post } from 'src/store/APIs/communityGroups/types'
import { Me } from 'src/store/APIs/misc/types'

import { Button } from '../Button'
import { CommunityTextInput } from '../CommunityTextInput/CommunityTextInput'
import { ConversationValues, TypeOfMessage } from '../CommunityTextInput/types'
import styles from './communityGroupPost.module.scss'
import { PostComment } from './PostComment'
import { PostReactionsBar } from './PostReactionsBar'
import { strings } from './strings'

interface CommunityGroupPostProps {
  post?: Post
  groupId?: string
  currentUser?: Me
  isLoading: boolean
}

export const CommunityGroupPost: React.FC<CommunityGroupPostProps> = ({
  post,
  groupId,
  currentUser,
  isLoading,
}) => {
  const [triggerLikePost, { isLoading: isLoadingUnlikePost }] =
    useLikeGroupPostMutation()
  const [triggerUnlikePost, { isLoading: isLoadingLikePost }] =
    useUnlikeGroupPostMutation()
  const [triggerDeletePost, { isLoading: isLoadingDeletePost }] =
    useDeleteGroupPostMutation()
  const [triggerNewComment, { isLoading: isLoadingNewComment }] =
    useNewPostCommentMutation()
  const [triggerGetPostComments, { data: postComments }] =
    useLazyGetPostCommentsQuery()

  const [form] = Form.useForm<ConversationValues>()

  const { beforeUpload, imageURL, setPreviewImage } = useUploadImage(form)

  const deletedUser = !post?.actor

  const likeId = useMemo(
    () =>
      post?.likes?.find(like => like.actor?.id === currentUser?.user.id)?.id,
    [post],
  )
  const authorName = deletedUser
    ? strings.userDeleted
    : `${(post?.actor?.first_name || post?.actor?.name) ?? ''} ${
        post?.actor.last_name ?? ''
      }`
  const likesLabel =
    post?.reactions_counts.like === 1 ? strings.like : strings.likes

  const onClickLike = () => {
    if (groupId && post) {
      if (post?.liked_by_user && likeId) {
        triggerUnlikePost({
          body: { activity_id: post?.id, reaction_id: likeId },
          groupId,
        })
      } else {
        triggerLikePost({ body: { activity_id: post?.id }, groupId })
      }
    }
  }

  const postReactions = [
    {
      // TODO: Create a generic "Like" component
      disabledReaction: !post?.permissions?.can_like,
      icon: post?.liked_by_user ? (
        <HeartFilled className={styles.likeIcon} />
      ) : (
        <HeartOutlined />
      ),
      isLoading: isLoadingLikePost || isLoadingUnlikePost,
      key: 1,
      label: `${post?.reactions_counts.like ?? 0} ${likesLabel}`,
      onClick: onClickLike,
    },
  ]

  const handleSubmit = async (values: ConversationValues) => {
    if (groupId && post?.foreign_id) {
      const body = {
        activityForeignId: post.foreign_id,
        image: values?.image?.fileList['0'].originFileObj,
        message: values.message,
      }
      await triggerNewComment({
        body,
        groupId,
        postId: post.id,
      })
    }
    form.setFieldsValue({
      image: undefined,
      message: '',
    })
    setPreviewImage(undefined)
  }

  const onDeletePost = () =>
    Modal.confirm({
      cancelText: generalStrings.no,
      icon: <ExclamationCircleFilled />,
      okText: generalStrings.yes,
      okType: 'danger',
      onCancel() {},
      onOk() {
        if (groupId && post?.id)
          triggerDeletePost({ groupId, postId: post?.id })
      },
      title: strings.deletePostModalTitle,
    })

  const clearImage = () => {
    form.setFieldValue('image', undefined)
    setPreviewImage(undefined)
  }

  const hasImage =
    post?.attachments?.images && post?.attachments?.images?.length > 0

  useEffect(() => {
    if (post?.id && groupId) {
      triggerGetPostComments({ groupId, postId: post.id })
    }
  }, [post, groupId])

  return (
    <Card className={styles.cardContainer} key={post?.id}>
      <Skeleton loading={isLoading} avatar active>
        <div className={styles.postHeaderContainer}>
          <div className={styles.authorMetaInfo}>
            {post?.actor?.image ? (
              <Avatar size="large" src={post?.actor.image} alt="avatar" />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
            <div className={styles.authorInfoContainer}>
              <div className={styles.authorName}>{authorName}</div>
              {post?.time && (
                <div className={styles.authorHour}>
                  {deserializeExtendedFormattedDate(post.time)}
                </div>
              )}
              <div className={styles.authorType}>{post?.actor?.label}</div>
            </div>
          </div>
          {post?.permissions?.can_destroy && (
            <Button
              type="text"
              onClick={onDeletePost}
              loading={isLoadingDeletePost}
              danger>
              {strings.deleteButtonLabel}
            </Button>
          )}
        </div>
        <pre
          className={styles.postContent}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: hyperlinkConverter(post?.content),
          }}
        />
        {hasImage && (
          <img
            crossOrigin={
              post.attachments.images[0]?.match(GETSTREAM_IMAGE_REGEX)
                ? ''
                : 'use-credentials'
            }
            className={styles.postImage}
            alt="example"
            src={post.attachments.images[0]}
          />
        )}
        <PostReactionsBar reactions={postReactions} />
        {post?.permissions?.can_create_comment && (
          <>
            <div className={styles.separator} />
            <Form
              className={styles.commentInputForm}
              onFinish={handleSubmit}
              form={form}>
              <CommunityTextInput
                placeholder={strings.sendCommentPlaceholder}
                previewImage={imageURL}
                isLoading={isLoadingNewComment}
                beforeUpload={beforeUpload}
                form={form}
                onClearImage={clearImage}
                messageType={TypeOfMessage.MULTIPLE_ITEMS}
              />
            </Form>
          </>
        )}
        {postComments?.map(comment => (
          <PostComment
            comment={comment}
            postId={post?.id}
            postForeignId={post?.foreign_id}
            groupId={groupId}
            key={comment?.id}
          />
        ))}
      </Skeleton>
    </Card>
  )
}
