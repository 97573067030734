import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addressLabel: 'Address',
    alertsColumnLabel: 'Alerts',
    biographyLabel: 'Biography',
    carePartnerColumnLabel: 'Care Partner',
    cellPhoneLabel: 'Cell phone',
    emailLabel: 'Email',
    fullNameColumnLabel: 'Full Name',
    fullNameLabel: 'Full Name',
    imageLabel: `Navigator's image`,
    internalStrokeSummary: 'Internal Stroke Summary',
    onboardingDateColumnLabel: 'Onboarding Date',
    originatingHospitalNameColumnLabel: 'Originating Hospital Name',
    personalInfoTitle: 'Personal Information',
    roleLabel: 'Role',
    statusColumnLabel: 'Status',
    subHeaderTitle: 'Dashboard',
    survivorsTitle: 'Survivors',
    tagNo: 'No',
    tagYes: 'Yes',
  },
})
