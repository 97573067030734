import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import {
  CompletionStatusOptions,
  completionStatusProps,
} from 'src/pages/SurvivorView/Learn/types'
import { ArticleAssigned } from 'src/store/APIs/packages/types'

import { strings } from './strings'
import { ArticlesAssignedDataIndex } from './types'

export const articlesInPackageColumns = (
  openView: (id?: string) => () => void,
  isChild: boolean,
) => {
  const articlesAssignedColumns: ColumnsType<ArticleAssigned> = [
    {
      ...generateColumnTitle(
        ArticlesAssignedDataIndex.internal_name,
        strings.internalNameLabel,
      ),
      render(internalName, record) {
        return openView ? (
          <Button type="link" onClick={openView(record.id)}>
            {internalName}
          </Button>
        ) : (
          internalName
        )
      },
    },
    {
      ...generateColumnTitle(
        ArticlesAssignedDataIndex.internal_id,
        strings.internalIdLabel,
      ),
    },
    {
      ...generateColumnTitle(
        ArticlesAssignedDataIndex.has_audio,
        strings.audioLabel,
      ),
      render(_, record) {
        return record?.has_audio ? (
          <Tag color="green">{strings.tagYes}</Tag>
        ) : (
          <Tag color="default">{strings.tagNo}</Tag>
        )
      },
      width: '1%',
    },
  ]

  const childArticlesAssignedColumns: ColumnsType<ArticleAssigned> = [
    {
      ...generateColumnTitle(
        ArticlesAssignedDataIndex.title,
        strings.articlesTitleLabel,
      ),
      render: (title, record) => {
        return openView ? (
          <Button type="link" onClick={openView(record.id)}>
            {title}
          </Button>
        ) : (
          title
        )
      },
    },
    {
      ...generateColumnTitle(
        ArticlesAssignedDataIndex.internal_name,
        strings.internalNameLabel,
      ),
    },
    {
      ...generateColumnTitle(
        ArticlesAssignedDataIndex.completion_status,
        strings.completionStatusLabel,
      ),
      render(_, record) {
        return record?.completion_status ? (
          <Tag
            color={
              completionStatusProps(
                record.completion_status as CompletionStatusOptions,
              ).color
            }>
            {
              completionStatusProps(
                record.completion_status as CompletionStatusOptions,
              ).title
            }
          </Tag>
        ) : undefined
      },
    },
  ]

  return isChild ? childArticlesAssignedColumns : articlesAssignedColumns
}
