import React from 'react'
import { useParams } from 'react-router-dom'
import { formatDateToRender } from 'src/common/types'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { InfoTable } from 'src/components/InfoTable'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import {
  useAddNavigatorTaskMutation,
  useDeleteNavigatorTaskMutation,
  useLazyGetNavigatorTaskQuery,
  useLazyGetNavigatorTasksQuery,
  useUpdateGoalDateMutation,
  useUpdateNavigatorTaskMutation,
} from 'src/store/APIs/navigatorTasks'
import { useGetSurvivorQuery } from 'src/store/APIs/survivors'

import { AddNavigatorTask } from './AddNavigatorTask'
import { columns } from './columns'
import styles from './navigatorTasks.module.scss'
import { strings } from './strings'
import { ViewNavigatorTask } from './ViewNavigatorTask/ViewNavigatorTask'

interface NavigatorTasksProps {}

export const NavigatorTasks: React.FC<NavigatorTasksProps> = () => {
  const { id } = useParams<{ id: string }>()

  const { data: survivorInfo } = useGetSurvivorQuery(id)
  const [triggerUpdateGoalDateMutation, { isLoading: isLoadingUpdateGoal }] =
    useUpdateGoalDateMutation()
  const [triggerAddNavigatorTask, { isLoading: isLoadingAdd }] =
    useAddNavigatorTaskMutation()
  const [triggerUpdateNavigatorTask, { isLoading: isLoadingUpdate }] =
    useUpdateNavigatorTaskMutation()
  const [triggerDeleteNavigatorTask] = useDeleteNavigatorTaskMutation()
  const { open, onCloseDrawer, selectedId, openView, showDrawer } = useDrawer()

  const onClickUpdateGoal = () => {
    triggerUpdateGoalDateMutation({
      id,
    })
  }

  const { filteredInfo, onChangeFiltered } = useFilteredInfo()

  return (
    <>
      <DrawerWithState
        open={open}
        selectedId={selectedId}
        resourceName={strings.navigatorTask}
        useLazyGetResource={useLazyGetNavigatorTaskQuery}
        RenderView={ViewNavigatorTask}
        RenderAdd={AddNavigatorTask}
        RenderEdit={AddNavigatorTask}
        triggerAddResourceMutation={triggerAddNavigatorTask}
        triggerEditResourceMutation={triggerUpdateNavigatorTask}
        triggerDeleteResourceMutation={triggerDeleteNavigatorTask}
        isLoadingRightButton={isLoadingUpdate || isLoadingAdd}
        onCloseDrawer={onCloseDrawer}
      />
      <div className={styles.container}>
        <InfoTable
          rows={[
            {
              content: formatDateToRender(
                survivorInfo?.post_acute_registration_date,
              ),
              label: strings.registrationDateLabel,
            },
            {
              content: formatDateToRender(
                survivorInfo?.post_acute_onboarding_date,
              ),
              label: strings.onboardingDateLabel,
            },
          ]}
        />
        <div className={styles.selectorsContainer}>
          <Button onClick={showDrawer} type="primary">
            {strings.addButtonText}
          </Button>
          <Button
            loading={isLoadingUpdateGoal}
            onClick={onClickUpdateGoal}
            className={styles.updateGoalButton}>
            {strings.updateGoalDateButtonLabel}
          </Button>
        </div>
        <PaginatedTable
          useLazyQuery={useLazyGetNavigatorTasksQuery}
          columns={columns(openView)}
          filteredInfo={filteredInfo}
          onChangeFiltered={onChangeFiltered}
        />
      </div>
    </>
  )
}
