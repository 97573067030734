import { Forms } from 'rc-field-form/es/FormContext'
import { useEffect, useState } from 'react'
import { Identifiable } from 'src/store/APIs/types'

type QueriedItem = Identifiable

export const TEMP_PREFIX = 'temp-'

export const useEditedItems = (
  queriedItems: QueriedItem[] | undefined,
  initialItem: QueriedItem,
) => {
  const [editedItems, setEditedItems] = useState<QueriedItem[] | undefined>(
    undefined,
  )

  useEffect(() => {
    if (queriedItems) setEditedItems(queriedItems)
  }, [queriedItems])

  const addNewEmptyItem = () =>
    setEditedItems([
      { ...initialItem, id: `${TEMP_PREFIX}${Math.random()}` },
      ...(editedItems ?? []),
    ])

  const onPressRemoveItem = (item: QueriedItem) => () =>
    setEditedItems(editedItems?.filter(elem => elem.id !== item.id))

  const prepareFormValues = (forms: Forms) => {
    const updatedFormItems = Object.values(forms).map(item => {
      const formFields: QueriedItem = item.getFieldsValue()
      return {
        ...formFields,
        id: formFields.id?.includes(TEMP_PREFIX) ? undefined : formFields.id,
      }
    })
    return {
      updatedFormItems,
    }
  }

  const lastItemIndex = editedItems ? editedItems.length - 1 : 0

  return {
    addNewEmptyItem,
    editedItems,
    lastItemIndex,
    onPressRemoveItem,
    prepareFormValues,
  }
}
