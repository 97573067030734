import React, { useEffect } from 'react'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useDrawer } from 'src/hooks/useDrawer'
import { AddCommunicationLog } from 'src/pages/SurvivorView/CommunicationLogs/AddCommunicationLog'
import { ViewCommunicationLog } from 'src/pages/SurvivorView/CommunicationLogs/ViewCommunicationLog'
import {
  useAddCommunicationLogMutation,
  useDeleteCommunicationLogMutation,
  useEditCommunicationLogMutation,
  useLazyGetCommunicationLogQuery,
  useLazyGetSurvivorsCommunicationLogsQuery,
} from 'src/store/APIs/communicationLogs'

import { useCommunicationLogsColumns } from './columns'
import styles from './communicationLogs.module.scss'
import { strings } from './strings'

export const CommunicationLogs = () => {
  const {
    open,
    onCloseDrawer,
    showDrawer,
    openView,
    selectedId,
    setSelectedId,
  } = useDrawer()
  const [triggerAddCommunicationLog, { isLoading: isLoadingAdd, data }] =
    useAddCommunicationLogMutation()
  const [triggerEditCommunicationLog, { isLoading: isLoadingEdit }] =
    useEditCommunicationLogMutation()
  const [triggerDeleteCommunicationLog, { isLoading: isLoadingDelete }] =
    useDeleteCommunicationLogMutation()

  const columnsAndFilters = useCommunicationLogsColumns(openView)

  useEffect(() => {
    if (data) {
      setSelectedId(data.id)
    }
  }, [data])

  return (
    <>
      <DrawerWithState
        hasSave
        selectedId={selectedId}
        useLazyGetResource={useLazyGetCommunicationLogQuery}
        RenderAdd={AddCommunicationLog}
        RenderView={ViewCommunicationLog}
        triggerAddResourceMutation={triggerAddCommunicationLog}
        triggerEditResourceMutation={triggerEditCommunicationLog}
        triggerDeleteResourceMutation={triggerDeleteCommunicationLog}
        onCloseDrawer={onCloseDrawer}
        resourceName={strings.resourceName}
        open={open}
        isLoadingRightButton={isLoadingAdd || isLoadingEdit}
        isLoadingLeftButton={isLoadingDelete || isLoadingEdit}
      />
      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          className={styles.addButton}
          onClick={showDrawer}>
          {strings.addButtonLabel}
        </Button>
      </div>
      <PaginatedTable
        useLazyQuery={useLazyGetSurvivorsCommunicationLogsQuery}
        {...columnsAndFilters}
        scroll={{ x: 1600 }}
      />
    </>
  )
}
