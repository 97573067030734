import React from 'react'
import { Tabs } from 'src/components/Tabs'

import { HospitalTreatments } from './HospitalTreatments'
import { OtherEvents } from './OtherEvents'
import { StrokeCause } from './StrokeCauses'
import styles from './strokeDetails.module.scss'
import { StrokeDetailsTab, StrokeDetailsTabNames } from './types'

export const StrokeDetails: React.FC = () => {
  const items: StrokeDetailsTab[] = [
    {
      content: <StrokeCause />,
      tabName: StrokeDetailsTabNames.strokeCause,
    },
    {
      content: <HospitalTreatments />,
      tabName: StrokeDetailsTabNames.hospitalTreatments,
    },
    {
      content: <OtherEvents />,
      tabName: StrokeDetailsTabNames.otherEvents,
    },
  ]

  const tabBarStyle = {
    paddingLeft: 20,
  }

  return (
    <div className={styles.container}>
      <Tabs
        tabBarStyle={tabBarStyle}
        className={styles.tabs}
        defaultActiveKey={StrokeDetailsTabNames.strokeCause}
        items={items.map(item => {
          const key = item.tabName
          return {
            children: (
              <div className={styles.tabInfoContainer}>{item.content}</div>
            ),
            key,
            label: <div>{item.tabName}</div>,
          }
        })}
      />
    </div>
  )
}
