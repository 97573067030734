import { LeftOutlined } from '@ant-design/icons'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { InfoTable, InfoTableRow } from 'src/components/InfoTable'
import { Table } from 'src/components/Table'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { useDrawer } from 'src/hooks/useDrawer'
import styles from 'src/pages/Learn/Packages/ViewPackage/viewPackage.module.scss'
import { completionStatusProps } from 'src/pages/SurvivorView/Learn/types'
import { Package } from 'src/store/APIs/packages/types'

import { ArticlesDrawer } from '../../Articles/ArticlesDrawer'
import { learnStatusProps } from '../../types'
import { articlesInPackageColumns } from './columns'
import { strings } from './strings'

interface ViewPackageProps {
  data?: Package
}
const contentStyle = { width: '75%' }

export const ViewPackage: React.FC<ViewPackageProps> = ({ data }) => {
  const { open, selectedId, setSelectedId, openView, onCloseDrawer } =
    useDrawer()

  const isChild =
    data?.mobile_user_lessons &&
    data?.mobile_user_lessons.length !== 0 &&
    !!data?.mobile_user_lessons[0].mobile_user

  const assignedUser = isChild
    ? {
        ...data.mobile_user_lessons[0].mobile_user,
        id: data.mobile_user_lessons[0].mobile_user.inheritor_id,
        permissions: data.mobile_user_lessons[0].permissions,
      }
    : undefined

  const packageRows: InfoTableRow[] = [
    {
      content: data?.title,
      label: strings.titleLabel,
    },
    {
      content: data?.internal_name,
      label: strings.internalNameLabel,
    },
    {
      content: data?.audience,
      label: strings.audienceLabel,
    },
    {
      content: data?.internal_id,
      label: strings.internalIdLabel,
    },
    {
      content: data?.language,
      label: strings.languageLabel,
    },
    {
      content: data?.image ? (
        <img alt={strings.imageLabel} src={data.image} width={335} />
      ) : undefined,
      label: strings.imageLabel,
    },
    {
      content: data?.created_at
        ? deserializeShortFormattedDate(data?.created_at)
        : undefined,
      label: strings.createdAtLabel,
    },
    {
      content: data?.updated_at
        ? deserializeShortFormattedDate(data?.updated_at)
        : undefined,
      label: strings.updatedAtLabel,
    },
    {
      content: data?.last_updated_by
        ? data?.last_updated_by.email
        : data?.created_by?.email,
      label: strings.lastUpdateByLabel,
    },
    {
      content: assignedUser ? <UserHyperlink user={assignedUser} /> : undefined,
      hidden: !isChild,
      label: strings.assignedToLabel,
    },
    {
      content: data?.status ? (
        <Tag color={learnStatusProps(data.status).color}>
          {learnStatusProps(data.status).title}
        </Tag>
      ) : undefined,
      label: strings.packageStatusLabel,
    },
    {
      content:
        isChild && data?.mobile_user_lessons[0].status ? (
          <Tag
            color={
              completionStatusProps(data.mobile_user_lessons[0].status).color
            }>
            {completionStatusProps(data?.mobile_user_lessons[0].status).title}
          </Tag>
        ) : undefined,
      hidden: !isChild,
      label: strings.completionStatusLabel,
    },
  ]

  const columns = articlesInPackageColumns(openView, !!isChild)

  return (
    <>
      <InfoTable
        title={strings.packageHeader}
        rows={packageRows}
        className={styles.spaceBwTables}
        contentStyle={contentStyle}
      />
      <div className={styles.title}>{strings.articlesHeader}</div>
      <Table
        bordered
        columns={columns}
        pagination={false}
        dataSource={data?.articles}
      />
      <ArticlesDrawer
        goBack
        closeIcon={<LeftOutlined />}
        rootClassName={styles.articlesDrawer}
        mask={false}
        push={false}
        open={open}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        onCloseDrawer={onCloseDrawer}
      />
    </>
  )
}
