import { Rule } from 'antd/es/form'
import { phoneValidator, yupSyncGenerator } from 'src/common/helpers'
import { HealthcareTeamDataIndex } from 'src/pages/SurvivorView/HealthcareTeam/types'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  [HealthcareTeamDataIndex.health_care_role_id]: yup.string().nullable(),
  [HealthcareTeamDataIndex.other_role]: yup.string().nullable(),
  [HealthcareTeamDataIndex.first_name]: yup.string(),
  [HealthcareTeamDataIndex.last_name]: yup
    .string()
    .required(strings.lastNameValidation),
  [HealthcareTeamDataIndex.phone_number]: phoneValidator,
  [HealthcareTeamDataIndex.affiliated_organization]: yup.string().notRequired(),
  [HealthcareTeamDataIndex.last_visit_date]: yup.date().notRequired(),
  [HealthcareTeamDataIndex.last_visit_summary]: yup.string().notRequired(),
  [HealthcareTeamDataIndex.additional_comments]: yup.string().notRequired(),
})

export const yupSync: Rule = yupSyncGenerator(schema)
