import {
  Builder,
  PaginatedParams,
  paginatedParams,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from 'src/store/APIs/types'
import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
} from '../types'
import {
  Endpoints,
  GetHealthEventsResponse,
  healthEventDeserializer,
  HealthEventsData,
  healthEventsDeserializer,
  SurvivorHealthEventData,
  survivorHealthEventsDeserializer,
  SurvivorHealthEventsPayload,
} from './types'

export const getHealthEvents = (builder: Builder) =>
  builder.query<PaginatedItems<HealthEventsData>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.HealthEvents),
    query: endpointWithFilters(Endpoints.GetHealthEvents),
    transformResponse: (response: GetHealthEventsResponse) =>
      healthEventsDeserializer(response),
  })

export const updateSurvivorHealthEvents = (builder: Builder) =>
  builder.mutation<
    void,
    { health_events: Partial<SurvivorHealthEventsPayload>[]; id: string }
  >({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.HealthEvents }],
    query: ({ id, health_events }) => ({
      body: { health_events },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateSurvivorHealthEvents, id),
    }),
  })

export const getSurvivorsHealthEvents = (builder: Builder) =>
  builder.query<PaginatedItems<SurvivorHealthEventData>, PaginatedParams>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.HealthEvents }],
    query: paginatedParams(Endpoints.GetSurvivorsHealthEvents),
    transformResponse: survivorHealthEventsDeserializer,
  })

export const addHealthEvent = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { name: string } }>({
    invalidatesTags: provideListToTag(Tags.HealthEvents),
    query: ({ body }) => ({
      body: { health_event: body },
      method: HttpMethod.Post,
      url: Endpoints.AddHealthEvent,
    }),
    transformResponse: performActionTransformResponse,
  })

export const getHealthEvent = (builder: Builder) =>
  builder.query<HealthEventsData, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.HealthEvents }],
    query: ({ id }) => format(Endpoints.GetHealthEvent, id),
    transformResponse: ({ health_event }) =>
      healthEventDeserializer(health_event),
  })

export const deleteHealthEvent = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: provideListToTag(Tags.HealthEvents),
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteHealthEvent, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const editHealthEvent = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { name: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.HealthEvents }],
    query: ({ body, id }) => ({
      body: { health_event: body },
      method: HttpMethod.Put,
      url: format(Endpoints.EditHealthEvent, id),
    }),
    transformResponse: performActionTransformResponse,
  })
