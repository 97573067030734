import { baseApi } from 'src/store/APIs'

import { getNavigator, getNavigatorOptions, getNavigators } from './actions'

export const navigatorsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getNavigator: getNavigator(builder),
    getNavigatorOptions: getNavigatorOptions(builder),
    getNavigators: getNavigators(builder),
  }),
})

export const {
  useLazyGetNavigatorsQuery,
  useLazyGetNavigatorOptionsQuery,
  useGetNavigatorQuery,
  reducer: navigatorsApiReducer,
  reducerPath: navigatorsApiReducerPath,
} = navigatorsApi
