import { dateDeserializer } from 'src/common/types'

import { baseUserDeserializer } from '../types'
import { DocumentData, DocumentResponse } from './types'

export const documentDeserializer = (data: DocumentResponse): DocumentData => {
  return {
    created_at: dateDeserializer(data.created_at),
    created_by: baseUserDeserializer(data.created_by),
    description: data.description,
    file: data.file,
    id: data.id,
    name: data.name,
    permissions: data.permissions,
  }
}
