import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { AppLink, RouteName } from 'src/routes'
import { CommunityGroup } from 'src/store/APIs/communityGroups/types'

import { strings } from './strings'
import { CommunityGroupsDataIndex } from './types'

export const useCommunityColumns = () => {
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()
  const { generateColumnSearchProps } = useColumnSearch()

  const columns: ColumnsType<CommunityGroup> = [
    {
      ...generateColumnTitle(
        CommunityGroupsDataIndex.name,
        strings.nameColumnLabel,
      ),
      ...generateColumnSearchProps(CommunityGroupsDataIndex.name),
      filteredValue: filteredInfo.filters?.name || null,
      render(_, record) {
        return record.permissions?.can_read ? (
          <AppLink
            routeName={RouteName.CommunityGroup}
            pathParams={{ id: record.id }}>
            {record.name}
          </AppLink>
        ) : (
          <span>{record.name}</span>
        )
      },
      sorter: true,
    },
    {
      ...generateColumnTitle(
        CommunityGroupsDataIndex.created_at,
        strings.createdAtColumnLabel,
      ),
      sorter: true,
    },
    {
      ...generateColumnTitle(
        CommunityGroupsDataIndex.updated_at,
        strings.updatedAtColumnLabel,
      ),
      sorter: true,
    },
  ]

  return {
    columns,
    filteredInfo,
    ...restFilteredInfo,
  }
}
