import { message, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import {
  useKeepAliveSessionMutation,
  useLazyCheckSessionRemainingTimeQuery,
} from 'src/store/APIs/auth'

import { strings } from './strings'

interface SessionTimeoutModalProps {
  titleText?: string
  triggerLogout: () => void
  stayConnectedBtnText?: string
  logoutBtnText?: string
}

const THRESHOLD_IN_S = 10 * 60
const THRESHOLD_IN_MS = THRESHOLD_IN_S * 1000

export const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({
  titleText = strings.modalTitle,
  triggerLogout,
  stayConnectedBtnText = strings.stayConnectedButton,
  logoutBtnText = strings.logoutButton,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState<
    number | undefined
  >(undefined)
  const intervalId = useRef<NodeJS.Timeout | null>(null)
  const warnTimeoutId = useRef<NodeJS.Timeout | null>(null)

  const [triggerCheckSession] = useLazyCheckSessionRemainingTimeQuery()
  const [triggerKeepAliveSession] = useKeepAliveSessionMutation()

  const logOut = () => {
    triggerLogout()
  }

  const checkTimeLeft = () => {
    triggerCheckSession()
      .unwrap()
      .then(data => {
        // INFO: Backend response is in seconds
        const timeLeft = data.time_left - 5

        if (timeLeft > THRESHOLD_IN_S) {
          if (visible) {
            message.info(generalStrings.activeSessionRefresh)
            setVisible(false)
          }
          if (warnTimeoutId.current) {
            clearTimeout(warnTimeoutId.current)
          }
          warnTimeoutId.current = setTimeout(() => {
            checkTimeLeft()
          }, timeLeft * 1000 - THRESHOLD_IN_MS)
        } else if (timeLeft <= 0) {
          setVisible(false)
          logOut()
        } else if (timeLeft <= THRESHOLD_IN_S) {
          if (intervalId.current) {
            clearInterval(intervalId.current)
          }
          intervalId.current = setInterval(() => {
            setTimeLeftInSeconds(prevTimeLeft => {
              return prevTimeLeft && prevTimeLeft > 0
                ? prevTimeLeft - 1
                : timeLeft
            })
          }, 1000)
          setVisible(true)
          setTimeLeftInSeconds(timeLeft)
        }
      })
      .catch(() => {
        message.info(generalStrings.loggedOutOnPurpose)
        logOut()
      })
  }

  useEffect(() => {
    checkTimeLeft()

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
        intervalId.current = null
      }
      if (warnTimeoutId.current) {
        clearTimeout(warnTimeoutId.current)
        warnTimeoutId.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (timeLeftInSeconds === undefined) {
      return
    }

    if (timeLeftInSeconds <= 0 || Number.isNaN(timeLeftInSeconds)) {
      clearInterval(intervalId.current as NodeJS.Timeout)
      clearTimeout(warnTimeoutId.current as NodeJS.Timeout)
      checkTimeLeft()
    }
  }, [timeLeftInSeconds])

  const stayConnected = () => {
    setVisible(false)

    triggerKeepAliveSession()
  }

  if (timeLeftInSeconds === undefined) {
    return null
  }

  const minutesLeft = Math.floor(timeLeftInSeconds / 60)
    .toString()
    .padStart(2, '0')

  const secondsLeft = (timeLeftInSeconds % 60).toString().padStart(2, '0')

  return (
    <Modal
      open={visible}
      onCancel={logOut}
      onOk={stayConnected}
      okText={stayConnectedBtnText}
      cancelText={logoutBtnText}
      title={titleText}
      maskClosable={false}
      closable={false}
      keyboard={false}>
      <p>
        {strings.messagePart1}
        {minutesLeft}:{secondsLeft}
        {strings.messagePart2}
      </p>
    </Modal>
  )
}
