import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { generateColumnTitle } from 'src/helpers/utils'
import { AppLink, RouteName } from 'src/routes'
import { UserGroup } from 'src/store/APIs/types'

import { strings } from './strings'

export enum GroupsDataIndex {
  'name' = 'name',
}

export const columns: ColumnsType<UserGroup> = [
  {
    ...generateColumnTitle(GroupsDataIndex.name, strings.nameColumnLabel),
    render(_, record) {
      return (
        <AppLink
          routeName={RouteName.CommunityGroup}
          pathParams={{ id: record.id }}>
          {record.name}
        </AppLink>
      )
    },
  },
]
