import { baseApi } from 'src/store/APIs'

import {
  addImpairment,
  deleteImpairment,
  getImpairment,
  getImpairments,
  getSurvivorImpairments,
  updateImpairment,
  updateSurvivorsImpairments,
} from './actions'

export const impairmentsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addImpairment: addImpairment(builder),
    deleteImpairment: deleteImpairment(builder),
    getImpairment: getImpairment(builder),
    getImpairments: getImpairments(builder),
    getSurvivorImpairments: getSurvivorImpairments(builder),
    updateImpairment: updateImpairment(builder),
    updateSurvivorsImpairments: updateSurvivorsImpairments(builder),
  }),
})

export const {
  useDeleteImpairmentMutation,
  useAddImpairmentMutation,
  useGetSurvivorImpairmentsQuery,
  useLazyGetSurvivorImpairmentsQuery,
  useUpdateSurvivorsImpairmentsMutation,
  useGetImpairmentsQuery,
  useLazyGetImpairmentsQuery,
  useLazyGetImpairmentQuery,
  useUpdateImpairmentMutation,
  reducer: impairmentsApiReducer,
  reducerPath: impairmentsApiReducerPath,
} = impairmentsApi
