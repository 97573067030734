import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    audioLabel: 'Audio',
    completedByLabel: 'Completed By',
    createdAtLabel: 'Created At',
    deleteLabel: 'Delete',
    imageLabel: 'Image',
    questionLabel: 'Question',
    responseLabel: 'Response',
    tacasLabel: 'Take Charge',
    versionLabel: 'Version',
  },
})
