import React from 'react'
import { InfoTable } from 'src/components/InfoTable'
import { Survivor } from 'src/store/APIs/survivors/types'

import { EditableTab } from '../types'
import { HospitalInfoEdit } from './HospitalInfoEdit'
import { strings } from './strings'

interface HospitalInfoProps extends EditableTab {
  survivor?: Survivor
}

export const HospitalInfo: React.FC<HospitalInfoProps> = ({
  survivor,
  editMode,
  toggleEditMode,
}) => {
  const rows = [
    {
      content: survivor?.hospital?.name,
      label: strings.originatingHospitalName,
    },
    {
      content: survivor?.primary_physician_name,
      label: strings.primaryPhysicianName,
    },
    {
      content: survivor?.patient_MRN,
      label: strings.patientMrn,
    },
  ]

  if (editMode)
    return (
      <HospitalInfoEdit survivor={survivor} toggleEditMode={toggleEditMode} />
    )

  return <InfoTable rows={rows} />
}
