import { StarFilled } from '@ant-design/icons'
import { Form, FormProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import colors from 'src/assets/stylesheets/colors.scss'
import { PhoneNumberTypes, timezoneFromIANAToOffset } from 'src/common/types'
import { AddCarePartnerForm } from 'src/components/AddCarePartnerForm'
import { Button } from 'src/components/Button'
import { InfoTable } from 'src/components/InfoTable'
import { Tag } from 'src/components/Tag'
import { FormNames } from 'src/pages/SurvivorView/BasicDemographics/types'
import {
  useNewCarePartnerMutation,
  useUpdateCarePartnerMutation,
} from 'src/store/APIs/carePartners'
import {
  CarePartner,
  CarePartnerPayload,
} from 'src/store/APIs/carePartners/types'
import {
  useAssignCarePartnerMutation,
  useLazyGetSurvivorQuery,
} from 'src/store/APIs/survivors'

import styles from '../basicDemographicsView.module.scss'
import { CarePartnerType } from './selectOptions'
import { strings } from './strings'

interface CarePartnerTabProps {
  survivorId?: string
  carePartner?: CarePartner
}

const carePartnerInfoRows = (carePartner?: CarePartner) => [
  {
    content: (
      <Link to={`/care_partners/${carePartner?.id}`}>
        {`${carePartner?.first_name} ${carePartner?.last_name}`}
      </Link>
    ),
    label: strings.carePartnerLabel,
  },
  {
    content: carePartner?.first_name,
    label: strings.firstNameLabel,
  },
  {
    content: carePartner?.last_name,
    label: strings.lastNameLabel,
  },
  {
    content: carePartner?.preferred_name
      ? carePartner.preferred_name
      : undefined,
    label: strings.preferredNameLabel,
  },
  {
    content: carePartner?.preferred_pronoun
      ? carePartner.preferred_pronoun
      : undefined,
    label: strings.preferredPronounLabel,
  },
  {
    content: carePartner?.biological_sex ?? undefined,
    label: strings.legalSexLabel,
  },
  {
    content: carePartner?.content_language ?? undefined,
    label: strings.languageLabel,
  },
  {
    content: (
      <div className={styles.row}>
        <div className={styles.phone}>{carePartner?.phone_number}</div>
        {carePartner?.preferred_phone === PhoneNumberTypes.Mobile && (
          <Tag color="gold" icon={<StarFilled />}>
            {strings.preferredLabel}
          </Tag>
        )}
      </div>
    ),
    label: strings.mobilePhoneLabel,
  },
  {
    content: carePartner?.home_phone ? (
      <div className={styles.row}>
        <div className={styles.phone}>{carePartner?.home_phone}</div>
        {carePartner?.preferred_phone === PhoneNumberTypes.Home && (
          <Tag color="gold" icon={<StarFilled />}>
            {strings.preferredLabel}
          </Tag>
        )}
      </div>
    ) : undefined,
    label: strings.homePhoneLabel,
  },
  { content: carePartner?.email, label: strings.emailLabel },
  {
    content: timezoneFromIANAToOffset(carePartner?.time_zone),
    label: strings.timezoneLabel,
  },
  {
    content: carePartner?.limitations ?? undefined,
    label: strings.limitationsLabel,
  },
  {
    content: (
      <Tag color={carePartner?.test_user ? colors.info_50 : colors.surface_50}>
        {carePartner?.test_user ? strings.tagYes : strings.tagNo}
      </Tag>
    ),
    label: strings.testUserLabel,
  },
]

export const CarePartnerTab: React.FC<CarePartnerTabProps> = ({
  survivorId,
  carePartner,
}) => {
  const [triggerGetSurvivorQuery] = useLazyGetSurvivorQuery()
  const [
    triggerAddNewCarePartner,
    { isLoading: isLoadingAddNew, isSuccess: isSuccessAddNew },
  ] = useNewCarePartnerMutation()
  const [
    triggerAssignCarePartner,
    { isLoading: isLoadingAssign, isSuccess: isSuccessAssign },
  ] = useAssignCarePartnerMutation()
  const [triggerUpdateCarePartner] = useUpdateCarePartnerMutation()

  const [selectCarePartnerForm] = Form.useForm<{
    select_option: CarePartnerType
    select_existing_care_partner: string
  }>()
  const optionSelected = Form.useWatch('select_option', selectCarePartnerForm)

  const [carePartnerOptionSelected, setCarePartnerOptionSelected] = useState(
    carePartner?.id,
  )

  const [showDropdown, setShowDropdown] = useState<boolean>(
    carePartner?.id === undefined,
  )

  const onCancel = () => setShowDropdown(false)
  const onPressAddCarePartner = () => setShowDropdown(true)

  const onFormFinish: FormProps['onFinish'] = async (values: any) => {
    if (optionSelected === CarePartnerType.EXISTING && survivorId) {
      const body: CarePartnerPayload = {
        mobile_caregiver: {
          ...values,
          mobile_survivor_ids: carePartner?.survivors.map(
            survivor => survivor.id,
          ),
          preferred_phone: values.home_phone_preferred
            ? PhoneNumberTypes.Home
            : PhoneNumberTypes.Mobile,
          race: values?.race?.join(','),
        },
      }
      if (carePartnerOptionSelected) {
        triggerAssignCarePartner({
          id: survivorId,
          mobile_caregiver_ids: [carePartnerOptionSelected],
        })
        triggerUpdateCarePartner({
          body,
          id: carePartnerOptionSelected,
        })
      }
    } else {
      triggerAddNewCarePartner({
        mobile_caregiver: {
          ...values,
          preferred_phone: values.home_phone_preferred
            ? PhoneNumberTypes.Home
            : PhoneNumberTypes.Mobile,
        },
      })
        .unwrap()
        .then(data => {
          if (survivorId) {
            triggerAssignCarePartner({
              id: survivorId,
              mobile_caregiver_ids: [data.id],
            })
          }
        })
    }
  }

  useEffect(() => {
    if (isSuccessAssign) {
      triggerGetSurvivorQuery(survivorId)
    }
  }, [isSuccessAssign, isSuccessAddNew])

  if (carePartner) return <InfoTable rows={carePartnerInfoRows(carePartner)} />

  if (!showDropdown) {
    return (
      <Button type="primary" size="small" onClick={onPressAddCarePartner}>
        {strings.addCarePartner}
      </Button>
    )
  }

  return (
    <AddCarePartnerForm
      carePartner={carePartner}
      formCreateName={FormNames.care_partner_create}
      formEditName={FormNames.care_partner_edit}
      selectCarePartnerForm={selectCarePartnerForm}
      carePartnerOptionSelected={carePartnerOptionSelected}
      setCarePartnerOptionSelected={setCarePartnerOptionSelected}
      onFormFinish={onFormFinish}>
      {optionSelected && (
        <Form.Item>
          <div className={styles.submitButtonContainer}>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoadingAddNew || isLoadingAssign}>
              {strings.addButtonLabel}
            </Button>
            <div className={styles.buttonSeparator} />
            <Button htmlType="button" onClick={onCancel}>
              {strings.cancelButtonLabel}
            </Button>
          </div>
        </Form.Item>
      )}
    </AddCarePartnerForm>
  )
}
