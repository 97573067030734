import React from 'react'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { useLazyGetSurvivorHealthcareTeamQuery } from 'src/store/APIs/healthcareTeam'

import { EditableTab } from '../types'
import { columns } from './columns'
import { HealthcareTeamEdit } from './HealthcareTeamEdit'

interface HealthcareTeamProps extends EditableTab {}

export const HealthcareTeam: React.FC<HealthcareTeamProps> = ({
  toggleEditMode,
  editMode,
}) => {
  if (editMode) return <HealthcareTeamEdit toggleEditMode={toggleEditMode} />
  return (
    <PaginatedTable
      columns={columns}
      useLazyQuery={useLazyGetSurvivorHealthcareTeamQuery}
    />
  )
}
