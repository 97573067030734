import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addCarePartner: 'Add Care Partner',
    addCarePartnerDisabled: 'Please create new users in Tellescope',
    carePartnerLabel: 'Choose the assign method',
    disabledInputTooltip: 'Please edit this field in Tellescope',
    selectExistingCarePartner: 'Select existing care partner',
  },
})
