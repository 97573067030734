import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { statusProps } from 'src/common/types'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { generateColumnTitle } from 'src/helpers/utils'
import { InfoSurvivorDataSource } from 'src/store/APIs/survivors/types'
import { UserType } from 'src/store/APIs/types'

import { strings } from './strings'

enum SurvivorsDataIndex {
  'full_name' = 'full_name',
  'originating_hospital_name' = 'originating_hospital_name',
  'care_partner' = 'care_partner',
  'status' = 'status',
  'alerts' = 'alerts',
  'post_acute_onboarding_date' = 'post_acute_onboarding_date',
  'stroke_summary' = 'stroke_summary',
  'hospitals.name' = 'hospitals.name',
}

export const columns: ColumnsType<InfoSurvivorDataSource> = [
  {
    ...generateColumnTitle(
      SurvivorsDataIndex.full_name,
      strings.fullNameColumnLabel,
    ),

    render: (_, { full_name, id, permissions }) => (
      <UserHyperlink
        user={{
          full_name,
          id,
          permissions,
          user_type: UserType.mobile_survivor,
        }}
      />
    ),
  },
  {
    ...generateColumnTitle(
      SurvivorsDataIndex.alerts,
      strings.alertsColumnLabel,
    ),

    render(value) {
      return value ? (
        <Tag color="blue">{strings.tagYes}</Tag>
      ) : (
        <Tag>{strings.tagNo}</Tag>
      )
    },
  },
  {
    ...generateColumnTitle(
      SurvivorsDataIndex.status,
      strings.statusColumnLabel,
    ),

    render(value) {
      return (
        <Tag color={statusProps(value).color}>{statusProps(value).title}</Tag>
      )
    },
  },
  {
    ...generateColumnTitle(
      SurvivorsDataIndex.care_partner,
      strings.carePartnerColumnLabel,
    ),

    render(_, { care_partner, care_partner_id, permissions }) {
      return (
        care_partner_id && (
          <UserHyperlink
            user={{
              full_name: care_partner,
              id: care_partner_id,
              permissions,
              user_type: UserType.mobile_caregiver,
            }}
          />
        )
      )
    },
  },
  {
    ...generateColumnTitle(
      SurvivorsDataIndex.post_acute_onboarding_date,
      strings.onboardingDateColumnLabel,
    ),
  },
  {
    ...generateColumnTitle(
      SurvivorsDataIndex['hospitals.name'],
      strings.originatingHospitalNameColumnLabel,
    ),
    render: (_, { hospital }) => hospital?.name,
  },
  {
    ...generateColumnTitle(
      SurvivorsDataIndex.stroke_summary,
      strings.internalStrokeSummary,
    ),
    render(_, record) {
      return record.stroke_summary ? (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: record.stroke_summary }} />
      ) : undefined
    },
  },
]
