import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    addNewHealthEvent: '+ Add New Health Event',
    descriptionLabel: 'Description',
    healthEventLabel: 'Health Event',
    healthEventsColumnLabel: 'Health Events',
    occurrenceDateLabel: 'Occurrence Date',
    otherMajorHealthEventColumnLabel: 'Other Major Health Event',
    removeLabel: 'Remove',
    requiredValidation: 'This field is required',
    title: 'Survivor Health Event',
  },
})
