import { Descriptions, DescriptionsProps } from 'antd'
import React, { ReactElement, ReactNode } from 'react'

import styles from './infoTable.module.scss'
import { strings } from './strings'

export interface InfoTableRow {
  label: string
  content?: ReactElement | ReactNode
  hidden?: boolean
}

interface InfoTableProps extends DescriptionsProps {
  rows: InfoTableRow[]
}

const rowLabelStyle = {
  fontWeight: 600,
}

export const InfoTable: React.FC<InfoTableProps> = ({ rows, ...props }) => {
  const className = `${styles.container} ${props?.className}`
  const contentStyle = props.contentStyle ?? { width: '85%' }
  return (
    <Descriptions
      bordered
      title={props.title}
      column={1}
      contentStyle={contentStyle}
      className={className}>
      {rows.map(({ label, content, hidden = false }) =>
        !hidden ? (
          <Descriptions.Item
            key={label}
            label={label}
            labelStyle={rowLabelStyle}>
            {content !== undefined ? (
              content
            ) : (
              <div className={styles.emptyText}>{strings.emptyLabel}</div>
            )}
          </Descriptions.Item>
        ) : null,
      )}
    </Descriptions>
  )
}
