import { Option } from 'src/common/types'

export enum ArticlesDataIndex {
  'title' = 'title',
  'internal_name' = 'internal_name',
  'theme' = 'theme',
  'internal_id' = 'internal_id',
  'audience' = 'audience',
  'unit' = 'unit',
  'units.title' = 'units.title',
  'created_at' = 'created_at',
  'status' = 'status',
  'audio' = 'audio',
  'has_audio' = 'has_audio',
  'completion_status' = 'completion_status',
}

export enum AudienceOptions {
  Shared = 'Shared',
  Survivor = 'Survivor',
  CarePartner = 'Care Partner',
}

export const audienceFilters = [
  { text: AudienceOptions.CarePartner, value: AudienceOptions.CarePartner },
  { text: AudienceOptions.Survivor, value: AudienceOptions.Survivor },
  { text: AudienceOptions.Shared, value: AudienceOptions.Shared },
]

export const audienceOptions: Option[] = audienceFilters.map(item => ({
  label: item.text,
  value: item.value,
}))

export enum ThemeOptions {
  AcuteRehab = 'Acute Rehab',
  Advocacy = 'Advocacy',
  CausesAndMechanisms = 'Causes and Mechanisms',
  Cognition = 'Cognition',
  ComplicationsAndAssociatedConditions = 'Complications and Associated Conditions',
  DietAndBehaviorChange = 'Diet and Behavior Change',
  DressingAndPersonalGrooming = 'Dressing and Personal Grooming',
  Falls = 'Falls',
  FatigueAndEnergyConservation = 'Fatigue and Energy Conservation',
  FollowUpTests = 'Follow-up Tests',
  FoodAndMedicine = 'Food and Medicine',
  GettingAroundTheHouse = 'Getting Around the House',
  GettingOutAndAbout = 'Getting Out and About',
  Hygiene = 'Hygiene (Bathing, Toileting and Mouth Care)',
  InsuranceAndBenefits = 'Insurance and Benefits',
  ManagingAHousehold = 'Managing a Household',
  MentalHealthAndIdentity = 'Mental Health and Identity',
  MobilityAndExercise = 'Mobility and Exercise',
  ReducingRiskOfAnotherStroke = 'Reducing Risk of Another Stroke',
  RelationshipsAndSocializing = 'Relationships & Socializing',
  Resource = 'Resources',
  SleepAndRecovery = 'Sleep and recovery',
  StartingPoint = 'Starting Point',
  StrokeTypeAndImpact = 'Stroke Type and Impact',
  ThingsToWatchFor = 'Things to Watch For',
  TreatmentAtTheHospital = 'Treatment at the Hospital',
  UnderstandingImpairments = 'Understanding Impairments',
  Welcome = 'Welcome',
  WheelchairFundamentals = 'Wheelchair Fundamentals',
  WorkAndFamilyObligations = 'Work and Family Obligations',
  NoThemeLegacy = 'No Theme - Legacy',
}

export const themeFilters = [
  { text: ThemeOptions.AcuteRehab, value: ThemeOptions.AcuteRehab },
  { text: ThemeOptions.Advocacy, value: ThemeOptions.Advocacy },
  {
    text: ThemeOptions.CausesAndMechanisms,
    value: ThemeOptions.CausesAndMechanisms,
  },
  { text: ThemeOptions.Cognition, value: ThemeOptions.Cognition },
  {
    text: ThemeOptions.ComplicationsAndAssociatedConditions,
    value: ThemeOptions.ComplicationsAndAssociatedConditions,
  },
  {
    text: ThemeOptions.DietAndBehaviorChange,
    value: ThemeOptions.DietAndBehaviorChange,
  },
  {
    text: ThemeOptions.DressingAndPersonalGrooming,
    value: ThemeOptions.DressingAndPersonalGrooming,
  },
  { text: ThemeOptions.Falls, value: ThemeOptions.Falls },
  {
    text: ThemeOptions.FatigueAndEnergyConservation,
    value: ThemeOptions.FatigueAndEnergyConservation,
  },
  { text: ThemeOptions.FollowUpTests, value: ThemeOptions.FollowUpTests },
  { text: ThemeOptions.FoodAndMedicine, value: ThemeOptions.FoodAndMedicine },
  {
    text: ThemeOptions.GettingAroundTheHouse,
    value: ThemeOptions.GettingAroundTheHouse,
  },
  {
    text: ThemeOptions.GettingOutAndAbout,
    value: ThemeOptions.GettingOutAndAbout,
  },
  { text: ThemeOptions.Hygiene, value: ThemeOptions.Hygiene },
  {
    text: ThemeOptions.InsuranceAndBenefits,
    value: ThemeOptions.InsuranceAndBenefits,
  },
  {
    text: ThemeOptions.ManagingAHousehold,
    value: ThemeOptions.ManagingAHousehold,
  },
  {
    text: ThemeOptions.MentalHealthAndIdentity,
    value: ThemeOptions.MentalHealthAndIdentity,
  },
  {
    text: ThemeOptions.MobilityAndExercise,
    value: ThemeOptions.MobilityAndExercise,
  },
  {
    text: ThemeOptions.ReducingRiskOfAnotherStroke,
    value: ThemeOptions.ReducingRiskOfAnotherStroke,
  },
  {
    text: ThemeOptions.RelationshipsAndSocializing,
    value: ThemeOptions.RelationshipsAndSocializing,
  },
  { text: ThemeOptions.Resource, value: ThemeOptions.Resource },
  { text: ThemeOptions.SleepAndRecovery, value: ThemeOptions.SleepAndRecovery },
  { text: ThemeOptions.StartingPoint, value: ThemeOptions.StartingPoint },
  {
    text: ThemeOptions.StrokeTypeAndImpact,
    value: ThemeOptions.StrokeTypeAndImpact,
  },
  { text: ThemeOptions.ThingsToWatchFor, value: ThemeOptions.ThingsToWatchFor },
  {
    text: ThemeOptions.TreatmentAtTheHospital,
    value: ThemeOptions.TreatmentAtTheHospital,
  },
  {
    text: ThemeOptions.UnderstandingImpairments,
    value: ThemeOptions.UnderstandingImpairments,
  },
  { text: ThemeOptions.Welcome, value: ThemeOptions.Welcome },
  {
    text: ThemeOptions.WheelchairFundamentals,
    value: ThemeOptions.WheelchairFundamentals,
  },
  {
    text: ThemeOptions.WorkAndFamilyObligations,
    value: ThemeOptions.WorkAndFamilyObligations,
  },
  {
    text: ThemeOptions.NoThemeLegacy,
    value: ThemeOptions.NoThemeLegacy,
  },
]

export const themeOptions: Option[] = themeFilters.map(item => ({
  label: item.text,
  value: item.value,
}))

export enum RedirectLinkOptions {
  TakeCharge = 'Take Charge',
  QuickJots = 'Quick Jots',
  ToDos = `To-Do's`,
  Assessments = 'Assessments',
  QuestionsToAsk = 'Questions to Ask',
  Profile = 'Profile',
  ConnectPrivateMessage = 'Connect private message',
  ConnectCommunity = 'Connect community',
}

export const redirectLinksOptions: Option[] = [
  {
    label: RedirectLinkOptions.Assessments,
    value: RedirectLinkOptions.Assessments,
  },
  {
    label: RedirectLinkOptions.ConnectCommunity,
    value: RedirectLinkOptions.ConnectCommunity,
  },
  {
    label: RedirectLinkOptions.ConnectPrivateMessage,
    value: RedirectLinkOptions.ConnectPrivateMessage,
  },
  {
    label: RedirectLinkOptions.Profile,
    value: RedirectLinkOptions.Profile,
  },
  {
    label: RedirectLinkOptions.QuestionsToAsk,
    value: RedirectLinkOptions.QuestionsToAsk,
  },
  {
    label: RedirectLinkOptions.QuickJots,
    value: RedirectLinkOptions.QuickJots,
  },
  {
    label: RedirectLinkOptions.TakeCharge,
    value: RedirectLinkOptions.TakeCharge,
  },
  {
    label: RedirectLinkOptions.ToDos,
    value: RedirectLinkOptions.ToDos,
  },
]
