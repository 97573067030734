/* eslint-disable react/no-danger */
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { useParams } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { DeleteButton } from 'src/components/DeleteButton'
import { InfoTable } from 'src/components/InfoTable'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { Spinner } from 'src/components/Spinner'
import { SubHeader } from 'src/components/SubHeader'
import { Tabs } from 'src/components/Tabs'
import { useDrawer } from 'src/hooks/useDrawer'
import { goToPage, RouteName } from 'src/routes'
import {
  useDeleteCommunityGroupMutation,
  useGetCommunityGroupQuery,
  useLazyGetCommunityGroupMembersQuery,
} from 'src/store/APIs/communityGroups'
import { useMeQuery } from 'src/store/APIs/misc'

import { AddCommunityGroupPost } from './AddCommunityGroupPost'
import styles from './communityGroup.module.scss'
import { CommunityGroupPosts } from './CommunityGroupPosts'
import { membersColumns } from './membersColumns'
import { strings } from './strings'
import { CommunityGroupTab, CommunityGroupTabNames } from './types'

interface CommunityGroupProps {}

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: 'Community Groups', path: RouteName.CommunityGroups },
]

export const CommunityGroup: React.FC<CommunityGroupProps> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useGetCommunityGroupQuery(id)
  const [triggerDeleteGroup] = useDeleteCommunityGroupMutation()
  const { data: currentUser } = useMeQuery()
  const { open, onCloseDrawer, openView } = useDrawer()

  const subHeaderTitle = data?.name

  const rows = [
    { content: data?.name, label: strings.nameLabel },
    {
      content: data?.image ? (
        <img
          crossOrigin="use-credentials"
          src={data?.image}
          alt={strings.imageLabel}
          width={350}
        />
      ) : undefined,
      label: strings.imageLabel,
    },
    {
      content: data?.description ? (
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      ) : null,
      label: strings.descriptionLabel,
    },
  ]

  const items: CommunityGroupTab[] = [
    {
      content: <CommunityGroupPosts group={data} currentUser={currentUser} />,
      editable: false,
      tabName: CommunityGroupTabNames.posts,
    },
    {
      content: (
        <PaginatedTable
          useLazyQuery={useLazyGetCommunityGroupMembersQuery}
          columns={membersColumns}
          className={styles.table}
        />
      ),
      editable: false,
      tabName: CommunityGroupTabNames.members,
    },
  ]

  const onClickEditGroup = () => goToPage(RouteName.CommunityGroupEdit, { id })

  if (isLoading) return <Spinner />
  return (
    <>
      <AddCommunityGroupPost
        onCloseDrawer={onCloseDrawer}
        drawerOpen={open}
        groupId={id}
      />
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        title={subHeaderTitle}
        section={SectionNames.CommunityGroups}>
        <div className={styles.buttonContainer}>
          {data?.permissions?.can_update && (
            <Button onClick={onClickEditGroup} className={styles.button}>
              {strings.editButtonLabel}
            </Button>
          )}
          {data?.permissions?.can_destroy && (
            <DeleteButton
              onClickYes={triggerDeleteGroup}
              modalTitle={strings.modalTitle}
              id={id}>
              {strings.deleteButtonLabel}
            </DeleteButton>
          )}
          {data?.permissions?.can_create_post && (
            <Button
              type="primary"
              className={styles.button}
              onClick={openView()}>
              {strings.createButtonLabel}
            </Button>
          )}
        </div>
      </SubHeader>
      <div className={styles.separatorContainer}>
        <div className={styles.separator} />
      </div>
      <InfoTable rows={rows} className={styles.infoTable} />
      <Tabs
        defaultActiveKey={CommunityGroupTabNames.posts}
        type="card"
        items={items.map(item => {
          return {
            children: isLoading ? <Spinner /> : <div>{item.content}</div>,
            key: item.tabName,
            label: (
              <div className={styles.tab}>
                <div className={styles.tabLabel}>{item.tabName}</div>
              </div>
            ),
          }
        })}
      />
    </>
  )
}
