import { baseApi } from 'src/store/APIs'

import {
  addHealthEvent,
  deleteHealthEvent,
  editHealthEvent,
  getHealthEvent,
  getHealthEvents,
  getSurvivorsHealthEvents,
  updateSurvivorHealthEvents,
} from './actions'

export const healthEventsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addHealthEvent: addHealthEvent(builder),
    deleteHealthEvent: deleteHealthEvent(builder),
    editHealthEvent: editHealthEvent(builder),
    getHealthEvent: getHealthEvent(builder),
    getHealthEvents: getHealthEvents(builder),
    getSurvivorsHealthEvents: getSurvivorsHealthEvents(builder),
    updateSurvivorHealthEvents: updateSurvivorHealthEvents(builder),
  }),
})

export const {
  useLazyGetSurvivorsHealthEventsQuery,
  useGetSurvivorsHealthEventsQuery,
  useUpdateSurvivorHealthEventsMutation,
  useGetHealthEventsQuery,
  useLazyGetHealthEventsQuery,
  useAddHealthEventMutation,
  useLazyGetHealthEventQuery,
  useDeleteHealthEventMutation,
  useEditHealthEventMutation,
  reducer: healthEventsApiReducer,
  reducerPath: healthEventsApiReducerPath,
} = healthEventsApi
