import React from 'react'
import { useParams } from 'react-router-dom'
import { LearnView } from 'src/components/LearnView'
import { LearnTrackResponse } from 'src/components/LearnView/types'
import { AudienceOptions } from 'src/pages/Learn/Articles/types'
import {
  useGetCarePartnerQuery,
  useGetCarePartnersUnitsQuery,
  useLazyGetCarePartnersUnitsQuery,
  useNotifyCarePartnerUncompletedLessonMutation,
  useUpdateCarePartnerLearnTrackMutation,
} from 'src/store/APIs/carePartners'

interface LearnProps {}

export const Learn: React.FC<LearnProps> = () => {
  const { id } = useParams<{ id: string }>()
  const [triggerNotifyUncompletedLessonMutation, { isLoading }] =
    useNotifyCarePartnerUncompletedLessonMutation()
  const { data } = useGetCarePartnersUnitsQuery({ id, page: 1 })

  const { data: carePartnerInfo } = useGetCarePartnerQuery(id)
  const [triggerUpdateLearnTrack, { isLoading: isLoadingLearnTrack }] =
    useUpdateCarePartnerLearnTrackMutation()

  const onChangeLearnTrack = (learn_track: LearnTrackResponse) => {
    triggerUpdateLearnTrack({ id, learn_track })
  }

  const audienceOptions = [
    { label: AudienceOptions.CarePartner, value: AudienceOptions.CarePartner },
    { label: AudienceOptions.Shared, value: AudienceOptions.Shared },
  ]
  const onClickUncompletedPackage = () => {
    triggerNotifyUncompletedLessonMutation({
      id,
    })
  }

  return (
    <LearnView
      user={carePartnerInfo}
      data={data}
      audienceOptions={audienceOptions}
      isLoadingNotify={isLoading}
      useLazyQuery={useLazyGetCarePartnersUnitsQuery}
      onClickUncompletedPackage={onClickUncompletedPackage}
      onChangeLearnTrack={onChangeLearnTrack}
      isLoadingLearnTrack={isLoadingLearnTrack}
      initialLearnTrack={carePartnerInfo?.learn_track}
      isCarePartner
    />
  )
}
