import { generalStrings } from 'src/common/generalStrings'
import { Status } from 'src/common/types'
import { LearnTrackResponse } from 'src/components/LearnView/types'
import { RouteName } from 'src/routes'
import {
  Builder,
  Conversation,
  ConversationMessage,
  ConversationParams,
  EditConversationMessage,
  endpointWithFilters,
  getConversationDeserializer,
  PaginatedItems,
  PaginatedParams,
  paginatedParams,
  ParamsWithFilters,
  performActionGenericSuccess,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  ResetPasswordPayload,
  Tags,
  triggerEntityNotFound,
  UserGroup,
  userGroupsDeserializer,
} from 'src/store/APIs/types'
import { GeneralTagIds, HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import { MainUnit, unitsDeserializer } from '../survivors/types'
import {
  CarePartner,
  carePartnerDeserializer,
  CarePartnerInfo,
  CarePartnerPayload,
  CarePartnerResponse,
  Endpoints,
  getCarePartnersDeserializer,
} from './types'

export const getCarePartners = (builder: Builder) =>
  builder.query<PaginatedItems<CarePartnerInfo>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.CarePartners),
    query: endpointWithFilters(Endpoints.GetCarePartners),
    transformResponse: getCarePartnersDeserializer,
  })

export const getCarePartner = (builder: Builder) =>
  builder.query<CarePartner, string>({
    providesTags: (_, __, id) => [{ id, type: Tags.CarePartners }],
    query: id => format(Endpoints.GetCarePartner, id),
    transformErrorResponse: triggerEntityNotFound(
      RouteName.CarePartners,
      generalStrings.carePartnerNotFound,
    ),
    transformResponse: ({ mobile_caregiver, tabs_permissions }) =>
      carePartnerDeserializer({
        ...mobile_caregiver,
        tabs_permissions,
      }),
  })

export const getUnits = (builder: Builder) =>
  builder.query<PaginatedItems<MainUnit>, ParamsWithFilters>({
    // INFO: This is a workaround to avoid re-fetching the units if we mutate the carepartner
    providesTags: () => [{ id: GeneralTagIds.Units, type: Tags.CarePartners }],
    query: endpointWithFilters(Endpoints.GetCarePartnerUnits),
    transformResponse: unitsDeserializer,
  })

export const updateCarePartnerLearnTrack = (builder: Builder) =>
  builder.mutation<void, { learn_track: LearnTrackResponse; id: string }>({
    query: ({ id, learn_track }) => ({
      body: { mobile_caregiver: { learn_track } },
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateCarePartner, id),
    }),
    transformResponse: performActionGenericSuccess,
  })

export const notifyUncompletedLesson = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    query: ({ id }) => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.NotifyUncompletedLesson, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const getCarePartnerGroups = (builder: Builder) =>
  builder.query<PaginatedItems<UserGroup>, PaginatedParams>({
    // INFO: This is a workaround to avoid re-fetching the groups if we mutate the carepartner
    providesTags: () => [{ id: 'GROUPS', type: Tags.CarePartners }],
    query: paginatedParams(Endpoints.GetCarePartnerGroups),
    transformResponse: userGroupsDeserializer,
  })

export const resetPassword = (builder: Builder) =>
  builder.mutation<PerformActionResponse, ResetPasswordPayload>({
    query: args => ({
      body: args.body,
      method: HttpMethod.Patch,
      url: format(Endpoints.ResetPassword, args.id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendSignUpEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendSignUpEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendSignUpSMS = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendSignUpSMS, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendAppInstallEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendAppInvitationEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendAppInstallSMS = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendAppInvitationSMS, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const changeUserStatus = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string; status: Status }>({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.CarePartners }],
    query: ({ id, status }) => ({
      body: { status },
      method: HttpMethod.Patch,
      url: format(Endpoints.ChangeStatus, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const newCarePartner = (builder: Builder) =>
  builder.mutation<CarePartner, CarePartnerPayload>({
    invalidatesTags: () => [{ type: Tags.CarePartners }],
    query: body => ({
      body,
      method: HttpMethod.Post,
      url: format(Endpoints.NewCarePartner),
    }),
    transformResponse: ({ mobile_caregiver }) =>
      carePartnerDeserializer(mobile_caregiver),
  })

export const updateCarePartner = (builder: Builder) =>
  builder.mutation<
    CarePartnerResponse,
    { id: string; body: CarePartnerPayload }
  >({
    invalidatesTags: (_, __, { id }) => [{ id, type: Tags.CarePartners }],
    query: ({ id, body }) => ({
      body,
      method: HttpMethod.Put,
      url: format(Endpoints.UpdateCarePartner, id),
    }),
  })

export const getConversation = (builder: Builder) =>
  builder.query<PaginatedItems<Conversation>, ConversationParams>({
    providesTags: (_, __, { id }) => [
      { id: `CONVERSATION_${id}`, type: Tags.CarePartners },
    ],
    query: ({ id, type }) => format(Endpoints.GetConversations, id, type),
    transformResponse: getConversationDeserializer,
  })

export const sendConversationMessage = (builder: Builder) =>
  builder.mutation<void, ConversationMessage>({
    // INFO: This is a workaround to avoid re-fetching the conversations if we mutate the carepartner
    // and separate the logic
    invalidatesTags: (_, __, { id }) => [
      { id: `CONVERSATION_${id}`, type: Tags.CarePartners },
    ],
    query: ({ id, body }) => {
      const formData = new FormData()
      formData.append('message[conversation_type]', body.conversation_type)
      if (body.image) {
        formData.append('message[image]', body.image)
      } else if (body.message) {
        formData.append('message[content]', body.message)
      }
      return {
        body: formData,
        method: HttpMethod.Post,
        url: format(Endpoints.SendConversationMessage, id),
      }
    },
    transformResponse: performActionGenericSuccess,
  })

export const editCarePartnersConversationMessage = (builder: Builder) =>
  builder.mutation<void, EditConversationMessage>({
    invalidatesTags: () => [{ type: Tags.CarePartners }],
    query: ({ userId, messageId, body }) => {
      const formData = new FormData()
      formData.append('message[conversation_type]', body.conversation_type)
      formData.append('message[content]', body.message)
      return {
        body: formData,
        method: HttpMethod.Put,
        url: format(Endpoints.EditConversationMessage, userId, messageId),
      }
    },
    transformResponse: performActionGenericSuccess,
  })

export const deleteCarePartnersConversationMessage = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { idUser: string; idElem: string }>({
    invalidatesTags: () => [{ type: Tags.CarePartners }],
    query: ({ idUser, idElem }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteConversationMessage, idUser, idElem),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteCarePartner = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    invalidatesTags: provideListToTag(Tags.CarePartners),
    query: id => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.DeleteCarePartner, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendGraduationEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    invalidatesTags: () => [{ type: Tags.CarePartners }],
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendGraduationEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const sendWelcomeEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, string>({
    invalidatesTags: () => [{ type: Tags.CarePartners }],
    query: id => ({
      method: HttpMethod.Patch,
      url: format(Endpoints.SendWelcomeEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })
