import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { generalStrings } from 'src/common/generalStrings'
import { getUserFullName } from 'src/common/helpers'
import { SectionNames, Status, statusProps, TabTypes } from 'src/common/types'
import { DeleteButton } from 'src/components/DeleteButton'
import { Spinner } from 'src/components/Spinner'
import { SubHeader } from 'src/components/SubHeader'
import { Tabs } from 'src/components/Tabs'
import { Tag } from 'src/components/Tag'
import { useTabParams } from 'src/hooks/useTabParams'
import { useToggleEditMode } from 'src/hooks/useToggleEditMode'
import { RouteName } from 'src/routes'
import {
  useDeleteCarePartnerMutation,
  useLazyGetCarePartnerQuery,
} from 'src/store/APIs/carePartners'
import { ConversationType } from 'src/store/APIs/types'

import { AccountManagement } from './AccountManagement'
import { Assessments } from './Assessments'
import { BasicDemographics } from './BasicDemographics'
import styles from './carePartner.module.scss'
import { CarePartnerConversation } from './CarePartnerConversation'
import { Groups } from './Groups'
import { Learn } from './Learn'
import { strings } from './strings'
import { Survivors } from './Survivors'
import { CarePartnerTab, CarePartnerTabNames } from './types'

interface CarePartnerProps {}

// TODO: Replace mockedBreadcrumbRoutes with real routes
const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: 'Care Partners', path: RouteName.CarePartners },
]

export const CarePartner: React.FC<CarePartnerProps> = () => {
  const { id } = useParams<{ id: string }>()

  const [triggerGetCarePartnerQuery, { data, isLoading }] =
    useLazyGetCarePartnerQuery()

  const [triggerDeleteCarePartner, { isSuccess: deleteSuccess }] =
    useDeleteCarePartnerMutation()

  const { editMode, toggleEditMode } = useToggleEditMode()

  const history = useHistory()

  useEffect(() => {
    if (deleteSuccess) {
      history.goBack()
    }
  }, [deleteSuccess])

  useEffect(() => {
    triggerGetCarePartnerQuery(id)
  }, [])

  const subHeaderTitle = getUserFullName(data?.first_name, data?.last_name)

  const tabPermissions = data?.tabs_permissions

  const TabMap: { [key: string]: CarePartnerTab } = {
    [TabTypes.BasicDemographics]: {
      content: (
        <BasicDemographics
          carePartner={data}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
        />
      ),
      editable: true,
      tabName: CarePartnerTabNames.basic_demographics,
    },
    [TabTypes.AccountManagement]: {
      content: (
        <AccountManagement
          userStatus={data?.status}
          mobileUserId={data?.mobile_user_id}
          welcomeEmailSentDate={data?.welcome_mail_date}
          graduationEmailSentDate={data?.graduation_mail_date}
        />
      ),
      tabName: CarePartnerTabNames.account_management,
    },
    [TabTypes.Survivors]: {
      content: <Survivors carePartner={data} />,
      tabName: CarePartnerTabNames.survivors,
    },
    [TabTypes.Learn]: {
      content: <Learn />,
      tabName: CarePartnerTabNames.learn,
    },
    [TabTypes.Assessments]: {
      content: <Assessments />,
      tabName: CarePartnerTabNames.assessments,
    },
    [TabTypes.PrivateConversation]: {
      content: <CarePartnerConversation type={ConversationType.Private} />,
      tabName: CarePartnerTabNames.private_conversation,
    },
    [TabTypes.GroupConversation]: {
      content: <CarePartnerConversation type={ConversationType.Group} />,
      tabName: CarePartnerTabNames.group_conversation,
    },
    [TabTypes.Groups]: {
      content: <Groups />,
      tabName: CarePartnerTabNames.groups,
    },
  }

  const items = tabPermissions?.map(tab => TabMap[tab])

  const { currentTab, onChangeTab: navigateToTab } = useTabParams(
    RouteName.CarePartner,
  )

  const onChangeTab = (activeKey: string) => {
    if (editMode) toggleEditMode()
    navigateToTab(activeKey)
  }

  if (isLoading) return <Spinner />
  return (
    <>
      <SubHeader
        routes={mockedBreadcrumbRoutes}
        title={subHeaderTitle}
        section={SectionNames.CarePartners}>
        <div className={styles.buttonContainer}>
          {data?.permissions?.can_destroy && (
            <DeleteButton
              className={styles.button}
              onClickYes={triggerDeleteCarePartner}
              id={id}
              modalTitle={strings.deleteConfirmationMessage}>
              {strings.deleteButtonLabel}
            </DeleteButton>
          )}
          <Tag color={statusProps(data?.status as Status).color}>
            {statusProps(data?.status as Status).title}
          </Tag>
        </div>
      </SubHeader>
      <div className={styles.separatorContainer}>
        <div className={styles.separator} />
      </div>
      <Tabs
        activeKey={currentTab}
        onChange={onChangeTab}
        type="card"
        items={items?.map(item => {
          const key = item.tabName
          return {
            children: isLoading ? (
              <Spinner />
            ) : (
              <div className={styles.tabInfoContainer}>{item.content}</div>
            ),
            key,
            label: (
              <div className={styles.tab}>
                <div className={styles.tabLabel}>{item.tabName}</div>
              </div>
            ),
          }
        })}
      />
    </>
  )
}
