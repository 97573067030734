import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    assessmentLabel: 'Assessment',
    assessmentPlaceholder: 'Select an assessment',
    cancel: 'Cancel',
    deploymentDateLabel: 'Deployment Date',
    deploymentDateSubLabelCarePartner: `In care partner's timezone`,
    deploymentDateSubLabelSurvivor: `In survivor's timezone`,
    dueDateLabel: 'Due Date',
    dueDateValidation: 'Due date must be after deployment date',
    requiredValidation: 'This field is required',
    selectDatePlaceholder: 'Select date',
    title: 'Assign Assessment',
  },
})
