import { Form } from 'antd'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SearchSelect } from 'src/components/SearchSelect'
import { AudienceOptions } from 'src/pages/Learn/Articles/types'
import { useSearchLearnSelect } from 'src/pages/Learn/hooks/useSearchLearnSelect'
import { useLazyGetPackagesQuery } from 'src/store/APIs/packages'

import { strings } from '../strings'

interface SelectPackageProps {
  isCarePartner?: boolean
  fixedLanguage?: string
}

export const SelectPackage: React.FC<SelectPackageProps> = ({
  isCarePartner,
  fixedLanguage,
}) => {
  const {
    optionsSelect: optionsSelectPackage,
    fetchedFilteredElems: fetchedFilteredElemsPackage,
  } = useSearchLearnSelect(
    useLazyGetPackagesQuery,
    isCarePartner ? AudienceOptions.CarePartner : AudienceOptions.Survivor,
    fixedLanguage,
  )

  if (!optionsSelectPackage) return null

  return (
    <Form.Item
      name="package"
      rules={[
        {
          message: generalStrings.requiredFieldValidation,
          required: true,
        },
      ]}>
      <SearchSelect
        allowClear
        options={optionsSelectPackage}
        fetchOptions={fetchedFilteredElemsPackage}
        placeholder={strings.selectPackageLabel}
        label={strings.selectPackageLabel}
        required
      />
    </Form.Item>
  )
}
