import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Modal } from 'antd'
import React from 'react'
import colors from 'src/assets/stylesheets/colors.scss'
import { generalStrings } from 'src/common/generalStrings'
import { Button } from 'src/components/Button'
import { NumberInput } from 'src/components/NumberInput'
import { Select } from 'src/components/Select'
import { TextInput } from 'src/components/TextInput'
import {
  useAddDefaultNavigatorTaskMutation,
  useDeleteDefaultNavigatorTaskMutation,
  useEditDefaultNavigatorTaskMutation,
} from 'src/store/APIs/navigatorTasks'

import { DefaultNavigatorTask } from '../DefaultNavigatorTask/types'
import { defaultNavigatorTaskTypeOptions } from '../types'
import styles from './addDefaultTask.module.scss'
import { strings } from './strings'
import { yupSync } from './validations'

interface AddDefaultTaskProps {
  onClickCancel: () => void
  onDeleteCallback?: () => void
  initialValues?: Partial<DefaultNavigatorTask>
}

const MAX_DAYS_AFTER = 90

export const AddDefaultTask: React.FC<AddDefaultTaskProps> = ({
  onClickCancel,
  onDeleteCallback,
  initialValues,
}) => {
  const [form] = Form.useForm<DefaultNavigatorTask>()
  const [triggerAddDefaultNavigatorTaskMutation, { isLoading: isLoadingAdd }] =
    useAddDefaultNavigatorTaskMutation()
  const [
    triggerDeleteDefaultNavigatorTaskMutation,
    { isLoading: isLoadingDelete },
  ] = useDeleteDefaultNavigatorTaskMutation()
  const [
    triggerEditDefaultNavigatorTaskMutation,
    { isLoading: isLoadingEdit },
  ] = useEditDefaultNavigatorTaskMutation()
  const onFinishForm = async (values: any) => {
    if (initialValues?.id) {
      await triggerEditDefaultNavigatorTaskMutation(values)
    } else {
      await triggerAddDefaultNavigatorTaskMutation(values)
    }
    onClickCancel()
  }
  const onSubmit = () => {
    form.submit()
  }

  const deleteModalStyle = {
    color: colors.red_7,
  }

  const onDelete = () =>
    Modal.confirm({
      cancelText: strings.cancelDelete,
      icon: <ExclamationCircleOutlined style={deleteModalStyle} />,
      okText: strings.deleteButton,
      okType: 'danger',
      onCancel() {},
      onOk() {
        if (initialValues && initialValues.id) {
          triggerDeleteDefaultNavigatorTaskMutation({
            id: initialValues.id,
          })
          onDeleteCallback!()
        }
      },
      title: strings.deleteModalTitle,
    })

  const isLoading = isLoadingAdd || isLoadingEdit || isLoadingDelete

  return (
    <div className={styles.navigatorTaskCard}>
      <Form
        className={styles.form}
        form={form}
        onFinish={onFinishForm}
        initialValues={initialValues}>
        <div className={styles.topSection}>
          <div className={styles.topSectionInputs}>
            <Form.Item name="id" hidden />
            <Form.Item
              name="position"
              initialValue={initialValues?.position || 1}
              rules={[yupSync]}>
              <TextInput
                type="number"
                className={styles.orderInput}
                label={strings.orderLabel}
                disabled
                required
              />
            </Form.Item>
            <Form.Item
              className={styles.titleInput}
              name="description"
              rules={[yupSync]}>
              <TextInput
                autoFocus
                className={styles.titleInput}
                label={strings.titleLabel}
                required
              />
            </Form.Item>
          </div>
          {initialValues && initialValues.id && (
            <Button
              type="link"
              danger
              className={styles.deleteButtonLabel}
              onClick={onDelete}>
              {strings.deleteLabel}
            </Button>
          )}
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.dateInputs}>
            <Form.Item name="goal_date_type" rules={[yupSync]}>
              <Select
                className={styles.selectType}
                label={strings.referenceDateTypeLabel}
                options={defaultNavigatorTaskTypeOptions}
                required
                placeholder={strings.referenceDateTypeLabel}
              />
            </Form.Item>
            <Form.Item name="goal_date" rules={[yupSync]}>
              <NumberInput
                label={strings.daysFromReferenceLabel}
                placeholder={strings.daysPlaceholder}
                type="number"
                required
                min={0}
                max={MAX_DAYS_AFTER}
              />
            </Form.Item>
          </div>
          <div className={styles.bottomButtons}>
            <Button loading={isLoading} onClick={onSubmit} type="primary">
              {generalStrings.submit}
            </Button>
            <Button onClick={onClickCancel}>{generalStrings.cancel}</Button>
          </div>
        </div>
      </Form>
    </div>
  )
}
