import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    actionsLabel: 'Actions',
    cancelButton: 'Cancel',
    cancelModalText: 'No',
    carePartnerLabel: 'care partner',
    confirmImgTitle: 'Are you sure you want to replace the previous image?',
    confirmModalText: 'Yes',
    contentLabel: 'Content',
    deleteButton: 'Delete',
    deleteModalTitle:
      'Are you sure you want to delete this message? It will be removed for the %s.',
    editButton: 'Edit',
    sendPlaceholder: 'Send new message',
    sentAtLabel: 'Sent At',
    sentByLabel: 'Sent By',
    submitLabel: 'Submit',
    survivorLabel: 'survivor',
  },
})
