import { deserializeFormattedDate } from 'src/common/types'

import { Entity, PaginatedItems, PaginatedModel } from '../types'

export enum Endpoints {
  GetHospitalTreatments = '/cms/v1/global_settings/hospital_treatments.json',
  GetSurvivorsHospitalTreatments = '/cms/v1/mobile_survivors/%s/profile/hospital_treatments',
  UpdateSurvivorsHospitalTreatments = '/cms/v1/mobile_survivors/%s/profile/hospital_treatments/batch_put.json',
  GetHospitalTreatment = '/cms/v1/global_settings/hospital_treatments/%s.json',
  AddHospitalTreatment = '/cms/v1/global_settings/hospital_treatments.json',
  DeleteHospitalTreatment = '/cms/v1/global_settings/hospital_treatments/%s.json',
  EditHospitalTreatment = '/cms/v1/global_settings/hospital_treatments/%s.json',
}

export interface GetHospitalTreatmentsResponse extends PaginatedModel {
  hospital_treatments: HospitalTreatmentsResponse[]
}

export interface HospitalTreatmentsResponse extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export interface HospitalTreatmentsData extends Entity {
  id: string
  name: string
  created_at: string
  updated_at: string
}

export const hospitalTreatmentDeserializer = (
  data: HospitalTreatmentsResponse,
): HospitalTreatmentsData => {
  return {
    ...data,
  }
}

export const hospitalTreatmentsDeserializer = (
  data: GetHospitalTreatmentsResponse,
): PaginatedItems<HospitalTreatmentsData> => ({
  items: data.hospital_treatments.map(hospitalTreatmentDeserializer),
  model_permissions: data.model_permissions,
  pagination: data.pagination,
})

export interface SurvivorHospitalTreatmentsResponse {
  created_at: string
  id: string
  updated_at: string
  hospital_treatment: {
    name: string
    id: string
    created_at: string
    updated_at: string
  }
}

export interface SurvivorHospitalTreatmentData {
  created_at: string
  name: string
  id: string
}

export const survivorHospitalTreatmentDeserializer = (
  data: SurvivorHospitalTreatmentsResponse,
): SurvivorHospitalTreatmentData => {
  return {
    created_at: deserializeFormattedDate(data.created_at),
    id: data.hospital_treatment.id,
    name: data.hospital_treatment.name,
  }
}

export const survivorHospitalTreatmentsDeserializer = (
  data: SurvivorHospitalTreatmentsResponse[],
) => data.map(survivorHospitalTreatmentDeserializer)
