import React from 'react'
import { Link } from 'react-router-dom'
import { formatDateToRender } from 'src/common/types'
import { InfoTable } from 'src/components/InfoTable'

import { strings } from '../strings'
import { ViewDocumentProps } from '../types'

export const ViewDocument: React.FC<ViewDocumentProps> = ({ data }) => (
  <InfoTable
    rows={[
      {
        content: data?.name,
        label: strings.nameLabel,
      },
      {
        content: data?.description,
        label: strings.descriptionLabel,
      },
      {
        content: data?.created_at
          ? formatDateToRender(data?.created_at)
          : undefined,
        label: strings.uploadDateLabel,
      },
      {
        content: (
          <Link to={{ pathname: data?.file.url }} target="_blank">
            {data?.file.name}
          </Link>
        ),
        label: strings.fileLabel,
      },
    ]}
  />
)
