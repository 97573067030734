import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'

import { Breadcrumb, BreadcrumbParams } from '../Breadcrumb'
import styles from './subHeader.module.scss'

interface SubHeaderProps {
  routes?: BreadcrumbItemType[]
  children?: React.ReactNode
  title?: string
  params?: BreadcrumbParams
  className?: string
  section?: string
  subHeaderRef?: React.RefObject<HTMLDivElement>
}

export const SubHeader: React.FC<SubHeaderProps> = ({
  routes,
  children,
  title,
  params,
  className,
  section,
  subHeaderRef,
}) => {
  return (
    <div ref={subHeaderRef} className={`${styles.container} ${className}`}>
      <div>
        {routes && (
          <Breadcrumb
            items={routes}
            params={params}
            className={styles.breadcrumbs}
          />
        )}
      </div>
      <div className={styles.titleContainer}>
        <div>
          {section && <span className={styles.sectionTitle}>{section}</span>}
          {title && <span className={styles.breadcrumbTitle}>{title}</span>}
        </div>
        {children}
      </div>
    </div>
  )
}
