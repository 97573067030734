import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    cancelButtonLabel: 'Cancel',
    deleteButtonLabel: 'Delete',
    deleteConfirmationMessage: 'Are you sure you want to delete this survivor?',
    editButtonLabel: 'Edit',
  },
})
