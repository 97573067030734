import { baseApi } from 'src/store/APIs'

import { getMobileUsers } from './actions'

export const mobileUsersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMobileUsers: getMobileUsers(builder),
  }),
})

export const {
  useLazyGetMobileUsersQuery,
  reducer: mobileUsersApiReducer,
  reducerPath: mobileUsersApiReducerPath,
} = mobileUsersApi
