import { Rule } from 'antd/es/form'
import { phoneValidator, yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  biological_sex: yup.string().nullable().notRequired(),
  content_language: yup.string().required(strings.requiredValidation),
  email: yup
    .string()
    .email(strings.emailValidation)
    .required(strings.requiredValidation),
  first_name: yup.string().required(strings.requiredValidation),
  home_phone: phoneValidator,
  last_name: yup.string().required(strings.requiredValidation),
  limitations: yup.string().nullable(),
  phone_number: phoneValidator,
  preferred_name: yup.string().nullable(),
  preferred_pronoun: yup.string().nullable(),
  select_existing_care_partner: yup.string(),
  test_user: yup.boolean().nullable(),
})

export const yupSync: Rule = yupSyncGenerator(schema)
