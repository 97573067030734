import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    cancelButtonLabel: 'Cancel',
    cmsNavigatorsLabel: 'CMS Navigators',
    createButtonLabel: 'Create',
    deleteButtonLabel: 'Delete',
    descriptionLabel: 'Description',
    editLabel: 'Edit',
    imageLabel: 'Image',
    imagePlaceholder: 'Group image',
    mobileUsersLabel: 'Mobile Users',
    mobileUsersPlaceholder: 'Search and add survivors/care partners',
    nameLabel: 'Name',
    navigatorsPlaceholder: 'Search and add navigators',
    navigatorsRequiredMessage: 'You need to add at least one navigator',
    newLabel: 'New',
    requiredValidation: 'This field is required',
    selectImage: 'Select image',
    updateButtonLabel: 'Update',
  },
})
