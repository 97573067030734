import { LayoutType, withLayout } from 'src/hocs/withLayout'

import { SurvivorAssessmentDetails } from './SurvivorAssessmentDetails'

const WrappedSurvivorAssessmentDetails = withLayout(
  LayoutType.Default,
  SurvivorAssessmentDetails,
)

export { WrappedSurvivorAssessmentDetails as SurvivorAssessmentDetails }
