import { baseApi } from 'src/store/APIs'

import {
  addStrokeCause,
  deleteStrokeCause,
  editStrokeCause,
  getStrokeCause,
  getStrokeCauses,
  getSurvivorsStrokeCauses,
  updateSurvivorsStrokeCauses,
} from './actions'

export const strokeCausesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addStrokeCause: addStrokeCause(builder),
    deleteStrokeCause: deleteStrokeCause(builder),
    editStrokeCause: editStrokeCause(builder),
    getStrokeCause: getStrokeCause(builder),
    getStrokeCauses: getStrokeCauses(builder),
    getSurvivorsStrokeCauses: getSurvivorsStrokeCauses(builder),
    updateSurvivorsStrokeCauses: updateSurvivorsStrokeCauses(builder),
  }),
})

export const {
  useGetSurvivorsStrokeCausesQuery,
  useGetStrokeCausesQuery,
  useLazyGetStrokeCausesQuery,
  useUpdateSurvivorsStrokeCausesMutation,
  useLazyGetStrokeCauseQuery,
  useAddStrokeCauseMutation,
  useEditStrokeCauseMutation,
  useDeleteStrokeCauseMutation,
  reducer: strokeCausesApiReducer,
  reducerPath: strokeCausesApiReducerPath,
} = strokeCausesApi
