import { Rule } from 'antd/es/form'
import { yupSyncGenerator } from 'src/common/helpers'
import { richTextDefaultText } from 'src/components/RichTextEditor'
import * as yup from 'yup'

import { strings } from '../strings'

const schema = yup.object({
  description: yup
    .string()
    .notOneOf([richTextDefaultText], strings.titleValidation)
    .required(strings.titleValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
