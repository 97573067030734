import { Form } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { DrawerRenderProps } from 'src/components/DrawerWithState/types'
import { MultilineTextInput } from 'src/components/MultilineTextInput'
import { TextInput } from 'src/components/TextInput'

import { strings } from './strings'
import { yupSync } from './validations'

interface AddQTAProps extends DrawerRenderProps {}

export const AddQTA: React.FC<AddQTAProps> = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <>
      <Form.Item name="question" rules={[yupSync]}>
        <TextInput
          label={strings.questionLabel}
          required
          placeholder={strings.questionPlaceholder}
        />
      </Form.Item>
      <Form.Item name="answer" rules={[yupSync]}>
        <MultilineTextInput
          label={strings.answerLabel}
          placeholder={strings.answerPlaceholder}
        />
      </Form.Item>
      <Form.Item name="mobile_survivor_id" hidden initialValue={id} />
    </>
  )
}
