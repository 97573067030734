import { Rule } from 'antd/es/form'
import { isNaN } from 'lodash'
import { phoneValidator, yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  address: yup.string().nullable(),
  ambassador: yup.boolean(),
  biological_sex: yup.string().nullable().notRequired(),
  city: yup.string().nullable().notRequired(),
  cms_navigator_id: yup.string().required(strings.requiredValidation),
  content_language: yup.string().required(strings.requiredValidation),
  date_of_birth: yup.date().nullable().required(strings.requiredValidation),
  email: yup
    .string()
    .email(strings.emailValidation)
    .required(strings.requiredValidation),
  emergency: yup.string().nullable(),
  ethnicity: yup.string().nullable(),
  family_situation: yup.string().nullable(),
  first_name: yup.string().required(strings.requiredValidation),
  gender_identity: yup.string().nullable(),
  home_phone: phoneValidator,
  last_name: yup.string().required(strings.requiredValidation),
  living_situation: yup.string().nullable(),
  phone_number: phoneValidator,
  post_acute_onboarding_date: yup.date().nullable(),
  post_acute_registration_date: yup.date().nullable(),
  preferred_name: yup.string().nullable(),
  preferred_pronoun: yup.string().nullable(),
  primary_insurance_info: yup.string().nullable(),
  race: yup.string(),
  tacas_enabled: yup.boolean(),
  test_user: yup.boolean().nullable(),
  zip_code: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .notRequired(),
})

export const yupSync: Rule = yupSyncGenerator(schema)
