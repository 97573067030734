import React from 'react'
import { InfoTable } from 'src/components/InfoTable'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { Spinner } from 'src/components/Spinner'
import { SubHeader } from 'src/components/SubHeader'
import { TableTitle } from 'src/components/TableTitle'
import { useMeQuery } from 'src/store/APIs/misc'
import { useLazyGetSurvivorsQuery } from 'src/store/APIs/survivors'
import { getUserType, UserType } from 'src/store/APIs/types'

import { columns } from './columns'
import styles from './home.module.scss'
import { strings } from './strings'

const Home: React.FC = () => {
  const { data, isLoading } = useMeQuery()

  const rows = [
    {
      content: data?.user?.full_name,
      label: strings.fullNameLabel,
    },
    {
      content: data?.user?.phone_number,
      label: strings.cellPhoneLabel,
    },
    {
      content: data?.user?.address,
      label: strings.addressLabel,
    },
    {
      content: data?.user?.email,
      label: strings.emailLabel,
    },
    {
      content: data?.user ? getUserType(data?.user.user_type) : undefined,
      label: strings.roleLabel,
    },
    {
      content: data?.user?.biography,
      label: strings.biographyLabel,
    },
  ]

  if (!data || isLoading) return <Spinner />
  if (data?.user.user_type !== UserType.cms_navigator) {
    return (
      <div>
        <h1 className={styles.title}>Welcome! This is the homepage user!</h1>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <SubHeader title={strings.subHeaderTitle} className={styles.subHeader} />
      <h2>{strings.personalInfoTitle}</h2>
      <div className={styles.personalInfoContainer}>
        {data?.user?.image ? (
          <img
            crossOrigin="use-credentials"
            src={data?.user.image}
            alt={strings.imageLabel}
            className={styles.image}
            width={330}
          />
        ) : undefined}
        <div className={styles.rightTable}>
          <InfoTable rows={rows} />
        </div>
      </div>
      <div>
        <PaginatedTable
          useLazyQuery={useLazyGetSurvivorsQuery}
          columns={columns}
          caption={<TableTitle title={strings.survivorsTitle} />}
          preferCacheValue={false}
        />
      </div>
    </div>
  )
}

export { Home }
