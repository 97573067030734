import { ReactNode } from 'react'

export enum BasicDemographicsTabNames {
  survivor = 'Survivor',
  care_partner = 'Care Partner',
}

export enum FormNames {
  care_partner_edit = 'Care Partner Edit',
  care_partner_create = 'Care Partner Create',
}

export interface BasicDemographicsTab {
  content: ReactNode
  tabName: BasicDemographicsTabNames
}
