import { TagProps as AntdTagProps } from 'antd'

import styles from './tag.module.scss'

export interface TagProps extends AntdTagProps {}

export const customColor = (color: TagProps['color']) => {
  switch (color) {
    case 'green':
      return styles.green
    case 'gold':
      return styles.gold
    case 'gray':
      return styles.gray
    case 'lime':
      return styles.lime
    case 'cyan':
      return styles.cyan
    case 'magenta':
      return styles.magenta
    case 'volcano':
      return styles.volcano
    case 'geekblue':
      return styles.geekblue
    default:
      return ''
  }
}
