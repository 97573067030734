import { TagProps } from 'antd'

import { strings } from './strings'

export interface StatusProps {
  title: CompletionStatusOptions | string
  color: TagProps['color']
}

export enum CompletionStatusOptions {
  NotStarted = 'Not started',
  InProgress = 'In progress',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
}

export enum CompletionStatusOptionsValues {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed = 'completed',
  Scheduled = 'scheduled',
}

export const completionStatusProps = (
  status: CompletionStatusOptions,
): StatusProps => {
  switch (status) {
    case CompletionStatusOptions.Completed:
      return {
        color: 'green',
        title: CompletionStatusOptions.Completed,
      }
    case CompletionStatusOptions.NotStarted:
      return {
        color: 'geekblue',
        title: CompletionStatusOptions.NotStarted,
      }
    case CompletionStatusOptions.InProgress:
      return {
        color: 'gold',
        title: CompletionStatusOptions.InProgress,
      }
    case CompletionStatusOptions.Scheduled:
      return {
        color: 'black',
        title: CompletionStatusOptions.Scheduled,
      }
    default:
      return {
        color: 'default',
        title: strings.defaultTagLabel,
      }
  }
}
