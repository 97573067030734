export enum StrokeDetailsDataIndex {
  'name' = 'name',
  'cause' = 'cause',
  'event' = 'event',
  'created_at' = 'created_at',
}

export enum StrokeSubtype {
  'SAH' = 'SAH',
  'ICH' = 'ICH',
}
