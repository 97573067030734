import { Form } from 'antd'
import React from 'react'
import { TextInput } from 'src/components/TextInput'

import { strings } from './strings'
import { yupSync } from './validations'

interface AddBasicItemProps {}

export const AddBasicItem: React.FC<AddBasicItemProps> = () => {
  return (
    <Form.Item name="name" rules={[yupSync]}>
      <TextInput label={strings.nameLabel} required />
    </Form.Item>
  )
}
