import LocalizedStrings from 'react-localization'

export const strings = new LocalizedStrings({
  en: {
    cancelButtonLabel: 'Cancel',
    editButtonLabel: 'Edit',
    emailLabel: 'Email',
    firstNameLabel: 'First Name',
    homePhoneLabel: 'Home Phone',
    languageLabel: 'Language',
    lastNameLabel: 'Last Name',
    legalSexLabel: 'Legal sex',
    limitationsLabel: 'Limitations',
    mobilePhoneLabel: 'Mobile Phone',
    preferredLabel: 'Preferred',
    preferredNameLabel: 'Preferred Name',
    preferredPronounLabel: 'Preferred Pronoun',
    tagNo: 'No',
    tagYes: 'Yes',
    testUserLabel: 'Test User',
    timezoneLabel: 'Time zone',
    updateButtonLabel: 'Update',
  },
})
