import { baseApi } from 'src/store/APIs'

import {
  deleteCommunityGroup,
  deleteGroupPost,
  deletePostComment,
  editCommunityGroup,
  getCommunityGroup,
  getCommunityGroupMembers,
  getCommunityGroups,
  getGroupPosts,
  getPostComments,
  likeGroupPost,
  likePostComment,
  newCommunityGroup,
  newGroupPost,
  newPostComment,
  unlikeGroupPost,
  unlikePostComment,
} from './actions'

export const communityGroupsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    deleteCommunityGroup: deleteCommunityGroup(builder),
    deleteGroupPost: deleteGroupPost(builder),
    deletePostComment: deletePostComment(builder),
    editCommunityGroup: editCommunityGroup(builder),
    getCommunityGroup: getCommunityGroup(builder),
    getCommunityGroupMembers: getCommunityGroupMembers(builder),
    getCommunityGroups: getCommunityGroups(builder),
    getGroupPosts: getGroupPosts(builder),
    getPostComments: getPostComments(builder),
    likeGroupPost: likeGroupPost(builder),
    likePostComment: likePostComment(builder),
    newCommunityGroup: newCommunityGroup(builder),
    newGroupPost: newGroupPost(builder),
    newPostComment: newPostComment(builder),
    unlikeGroupPost: unlikeGroupPost(builder),
    unlikePostComment: unlikePostComment(builder),
  }),
})

export const {
  useGetCommunityGroupsQuery,
  useLazyGetCommunityGroupsQuery,
  useGetCommunityGroupQuery,
  useLazyGetCommunityGroupQuery,
  useLazyGetCommunityGroupMembersQuery,
  useNewCommunityGroupMutation,
  useEditCommunityGroupMutation,
  useDeleteCommunityGroupMutation,
  useLazyGetGroupPostsQuery,
  useLikeGroupPostMutation,
  useUnlikeGroupPostMutation,
  useDeleteGroupPostMutation,
  useLazyGetPostCommentsQuery,
  useLikePostCommentMutation,
  useUnlikePostCommentMutation,
  useNewPostCommentMutation,
  useNewGroupPostMutation,
  useDeletePostCommentMutation,
  reducer: communityGroupsApiReducer,
  reducerPath: communityGroupsApiReducerPath,
} = communityGroupsApi
