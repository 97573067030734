import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { AntValidationError } from 'src/common/types'
import { useLazyGetAssessmentQuestionsQuery } from 'src/store/APIs/assessments'
import { CarePartner } from 'src/store/APIs/carePartners/types'
import { Survivor } from 'src/store/APIs/survivors/types'

import { Drawer } from '../Drawer'
import { CompleteAssessmentContent } from './CompleteAssessmentContent'
import { getErrorFields } from './validations'

interface CompleteAssessmentDrawerProps {
  user?: Survivor | CarePartner
  open?: boolean
  onCloseDrawer: () => void
  accomplishAssessmentId?: string
}

export const CompleteAssessmentDrawer: React.FC<
  CompleteAssessmentDrawerProps
> = ({ open, onCloseDrawer, accomplishAssessmentId, user }) => {
  const [form] = useForm()
  const [triggerGetAssessmentsQuestions, { data, isLoading }] =
    useLazyGetAssessmentQuestionsQuery()

  const [errorFields, setErrorFields] = useState<number[]>([])

  const onClickSubmit = async () => {
    try {
      await form.validateFields()
      form.submit()
    } catch (errors) {
      const newErrorFields = getErrorFields(errors as AntValidationError)
      setErrorFields(newErrorFields)
    }
  }

  const onClose = () => {
    form.resetFields()
    form.setFieldValue('responses', [])
    onCloseDrawer()
  }

  const [rightButtonDisabled, setRightButtonDisabled] = useState(false)
  const clearRadioGroupError = (key: number) => () => {
    const newErrorFields = errorFields.filter(item => item !== key)
    setErrorFields(newErrorFields)
  }

  return (
    <Drawer
      destroyOnClose
      title={data?.title}
      open={open}
      onClose={onClose}
      leftButtonLabel={generalStrings.cancel}
      onClickLeftButton={onClose}
      rightButtonDisabled={rightButtonDisabled}
      onClickRightButton={onClickSubmit}>
      <CompleteAssessmentContent
        errorFields={errorFields}
        clearRadioGroupError={clearRadioGroupError}
        user={user}
        data={data}
        isLoading={isLoading}
        triggerGetAssessmentsQuestions={triggerGetAssessmentsQuestions}
        form={form}
        setRightButtonDisabled={setRightButtonDisabled}
        accomplishAssessmentId={accomplishAssessmentId}
        onClose={onClose}
        setErrorFields={setErrorFields}
      />
    </Drawer>
  )
}
