import { baseApi } from 'src/store/APIs'

import {
  addDefaultNavigatorTask,
  addNavigatorTask,
  deleteDefaultNavigatorTask,
  deleteNavigatorTask,
  editDefaultNavigatorTask,
  getDefaultNavigatorTasks,
  getNavigatorTask,
  getNavigatorTasks,
  reorderDefaultNavigatorTasks,
  updateGoalDate,
  updateNavigatorTask,
} from './actions'

export const navigatorTasksApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addDefaultNavigatorTask: addDefaultNavigatorTask(builder),
    addNavigatorTask: addNavigatorTask(builder),
    deleteDefaultNavigatorTask: deleteDefaultNavigatorTask(builder),
    deleteNavigatorTask: deleteNavigatorTask(builder),
    editDefaultNavigatorTask: editDefaultNavigatorTask(builder),
    getDefaultNavigatorTasks: getDefaultNavigatorTasks(builder),
    getNavigatorTask: getNavigatorTask(builder),
    getNavigatorTasks: getNavigatorTasks(builder),
    reorderDefaultNavigatorTasks: reorderDefaultNavigatorTasks(builder),
    updateGoalDate: updateGoalDate(builder),
    updateNavigatorTask: updateNavigatorTask(builder),
  }),
})

export const {
  useAddDefaultNavigatorTaskMutation,
  useUpdateGoalDateMutation,
  useLazyGetNavigatorTaskQuery,
  useAddNavigatorTaskMutation,
  useLazyGetNavigatorTasksQuery,
  useUpdateNavigatorTaskMutation,
  useDeleteNavigatorTaskMutation,
  useGetDefaultNavigatorTasksQuery,
  useReorderDefaultNavigatorTasksMutation,
  useEditDefaultNavigatorTaskMutation,
  useDeleteDefaultNavigatorTaskMutation,
} = navigatorTasksApi
