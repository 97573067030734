import { UploadChangeParam } from 'antd/es/upload'
import {
  arrayToOptions,
  booleanToString,
  jsonToFormData,
} from 'src/common/helpers'
import { CustomDate, dateDeserializer, Option } from 'src/common/types'
import { VisitStatus } from 'src/pages/SurvivorView/CommunicationLogs/columns'
import {
  FILE_FORMNAME_DESCRIPTION,
  FILE_FORMNAME_ID,
  FILE_FORMNAME_NAME,
} from 'src/pages/SurvivorView/CommunicationLogs/UploadDocuments'
import {
  documentDeserializer,
  DocumentsData,
  DocumentsResponse,
} from 'src/store/APIs/documents/types'
import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  PaginatedItems,
  PaginationResponse,
  permissionsDeserializer,
} from 'src/store/APIs/types'

export enum Endpoints {
  GetSurvivorsCommunicationLogs = '/cms/v1/mobile_survivors/%s/utilities/communication_logs.json',
  GetCommunicationLog = '/cms/v1/communication_logs/%s.json',
  GetCommunicationLogOptions = '/cms/v1/communication_logs/communication_log_options?mobile_survivor_id=%s',
  AddCommunicationLog = '/cms/v1/communication_logs',
  EditCommunicationLog = '/cms/v1/communication_logs/%s.json',
  DeleteCommunicationLog = '/cms/v1/communication_logs/%s.json',
}

export interface PaginatedCommunicationLogsResponse {
  communication_logs: CommunicationLogResponse[]
  pagination: PaginationResponse
}

export interface CommunicationLogResponse extends Entity {
  id: string
  created_at?: string
  encounter_date: string
  escalation: boolean
  participants: BaseUserResponse[]
  mobile_survivor?: BaseUserResponse
  status: string
  summary: string
  title: string
  topic: string
  type_of: string[]
  updated_at: string
  created_by: BaseUserResponse
  updated_by?: BaseUserResponse
  documents?: DocumentsResponse[]
  is_draft: boolean
  has_document: boolean
}

export interface FileList {
  name: string
  uid: string
  url: string
  document_name?: string
  document_description?: string
  document_id?: string
}

export interface CommunicationLog extends Entity {
  id: string
  created_at?: CustomDate
  encounter_date: CustomDate
  escalation: boolean
  participants: BaseUser[]
  status: VisitStatus | string
  summary: string
  title: string
  topic: string
  type_of: string[]
  documents: DocumentsData[]
  updated_at: CustomDate
  updated_by?: BaseUser
  created_by?: BaseUser
  survivor?: BaseUser
  is_draft: boolean
  documentsFileList: FileList[]
  has_document: boolean
}

export interface CommunicationLogOptionsResponse {
  participants: { id: string; name: string }[]
  topics: string[]
  types: string[]
  statuses: string[]
}

export interface CommunicationLogOptions {
  participants: Option[]
  topics: Option[]
  types: Option[]
  statuses: Option[]
}

export interface CommunicationLogPayload {
  mobile_survivor_id?: string
  title: string
  encounter_date: CustomDate
  status?: string
  topic: string
  escalation: boolean
  summary: string
  is_draft: boolean
  type_of: string[]
  participants_ids: string[]
  file: UploadChangeParam
}

export enum CommunicationLogFormData {
  mobile_survivor_id = 'mobile_survivor_id',
  title = 'communication_log[title]',
  encounter_date = 'communication_log[encounter_date]',
  status = 'communication_log[status]',
  topic = 'communication_log[topic]',
  escalation = 'communication_log[escalation]',
  summary = 'communication_log[summary]',
  is_draft = 'communication_log[is_draft]',
  type_of = 'communication_log[type_of][]',
  participants = 'communication_log[participant_ids][]',
  document_description = 'communication_log[documents_attributes][][description]',
  document_file = 'communication_log[documents_attributes][][file]',
  document_name = 'communication_log[documents_attributes][][name]',
  document_ids = 'communication_log[document_ids][]',
}

export const communicationLogDeserializer = (
  data: CommunicationLogResponse,
): CommunicationLog => ({
  created_at: data.created_at ? dateDeserializer(data.created_at) : undefined,
  created_by: data.created_by
    ? baseUserDeserializer(data.created_by)
    : undefined,
  documents: data.documents ? data.documents.map(documentDeserializer) : [],
  documentsFileList:
    data.documents?.map(doc => ({
      document_description: doc.description,
      document_id: doc.id,
      document_name: doc.name,
      name: doc.file.name,
      uid: doc.id,
      url: doc.file.url,
    })) ?? [],
  encounter_date: dateDeserializer(data.encounter_date),
  escalation: data.escalation,
  has_document: data.has_document,
  id: data.id,
  is_draft: data.is_draft,
  participants: data.participants.map(baseUserDeserializer),
  permissions: permissionsDeserializer(data.permissions),
  status: data.status,
  summary: data.summary,
  survivor: data.mobile_survivor
    ? baseUserDeserializer(data.mobile_survivor)
    : undefined,
  title: data.title,
  topic: data.topic,
  type_of: data.type_of,
  updated_at: dateDeserializer(data.updated_at),
  updated_by: data.updated_by
    ? baseUserDeserializer(data.updated_by)
    : undefined,
})

export const getCommunicationLogsDeserializer = (
  data: PaginatedCommunicationLogsResponse,
): PaginatedItems<CommunicationLog> => ({
  items: data.communication_logs.map(communicationLogDeserializer),
  pagination: data.pagination,
})

export const communicationLogOptionsDeserializer = (
  data: CommunicationLogOptionsResponse,
): CommunicationLogOptions => ({
  participants: data.participants.map(item => ({
    label: item.name,
    value: item.id,
  })),
  statuses: data.statuses.map(arrayToOptions),
  topics: data.topics.map(arrayToOptions),
  types: data.types.map(arrayToOptions),
})

export const communicationLogSerializer = (data: CommunicationLogPayload) =>
  jsonToFormData({
    [CommunicationLogFormData.mobile_survivor_id]: data.mobile_survivor_id,
    [CommunicationLogFormData.title]: data.title,
    [CommunicationLogFormData.encounter_date]:
      data.encounter_date.toISOString(),
    [CommunicationLogFormData.escalation]: booleanToString(data.escalation),
    [CommunicationLogFormData.status]: data.status,
    [CommunicationLogFormData.topic]: data.topic,
    [CommunicationLogFormData.summary]: data.summary,
    [CommunicationLogFormData.is_draft]: booleanToString(data.is_draft),
  } as { [key in CommunicationLogFormData]: string | undefined })

export const serializeDocumentsFormValues = (
  formData: FormData,
  payload: { [key in string]: any },
  formDataNames: {
    name: string
    description: string
    file: string
    existingIds?: string
  },
) =>
  payload.file.fileList.forEach(
    (item: { uid: string; originFileObj: Blob }) => {
      const documentId = payload[`${FILE_FORMNAME_ID}${item.uid}`]
      if (documentId && formDataNames.existingIds) {
        formData.append(formDataNames.existingIds, documentId)
        return
      }

      const name = payload[`${FILE_FORMNAME_NAME}${item.uid}`]
      const description = payload[`${FILE_FORMNAME_DESCRIPTION}${item.uid}`]
      const file = item.originFileObj

      if (name) {
        formData.append(formDataNames.name, name)
      }
      if (description) {
        formData.append(formDataNames.description, description)
      }
      if (file) {
        formData.append(formDataNames.file, file)
      }
    },
  )

export const buildCommunicationLogPayload = (
  communicationLog: CommunicationLogPayload,
) => {
  const communicationLogFormData = communicationLogSerializer(communicationLog)

  communicationLog.type_of.forEach(item =>
    communicationLogFormData.append(CommunicationLogFormData.type_of, item),
  )

  communicationLog.participants_ids.forEach(item =>
    communicationLogFormData.append(
      CommunicationLogFormData.participants,
      item,
    ),
  )

  if (communicationLog.file && communicationLog.file.fileList.length > 0) {
    serializeDocumentsFormValues(communicationLogFormData, communicationLog, {
      description: CommunicationLogFormData.document_description,
      existingIds: CommunicationLogFormData.document_ids,
      file: CommunicationLogFormData.document_file,
      name: CommunicationLogFormData.document_name,
    })
  } else {
    communicationLogFormData.append(CommunicationLogFormData.document_ids, '')
  }

  return communicationLogFormData
}
