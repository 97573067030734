import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { Tag } from 'src/components/Tag'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { ArticleInfo } from 'src/store/APIs/articles/types'

import { articleStatusFilters, learnStatusProps } from '../types'
import { strings } from './strings'
import { ArticlesDataIndex, audienceFilters, themeFilters } from './types'

export const useArticlesColumns = (
  onClickRow: (id: string) => () => void,
  onClickPackage: (id: string) => () => void,
) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const columns: ColumnsType<ArticleInfo> = [
    {
      ...generateColumnTitle(ArticlesDataIndex.title, strings.titleLabel),
      ...generateColumnSearchProps(ArticlesDataIndex.title),
      filteredValue: filteredInfo.filters?.title || null,
      // TODO: add this in the return: record.permissions?.can_read && onClickRow ? (
      render: (title, record) => {
        return onClickRow ? (
          <Button type="link" onClick={onClickRow(record.id)}>
            {title}
          </Button>
        ) : (
          title
        )
      },
      sorter: true,
    },
    {
      ...generateColumnTitle(
        ArticlesDataIndex.internal_name,
        strings.internalNameLabel,
      ),
      ...generateColumnSearchProps(ArticlesDataIndex.internal_name),
      filteredValue: filteredInfo.filters?.internal_name || null,
      sorter: true,
    },
    {
      ...generateColumnTitle(ArticlesDataIndex.theme, strings.themeLabel),
      filteredValue: filteredInfo.filters?.theme || null,
      filters: themeFilters,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        ArticlesDataIndex.internal_id,
        strings.internalIdLabel,
      ),
      ...generateColumnSearchProps(ArticlesDataIndex.internal_id),
      filteredValue: filteredInfo.filters?.internal_id || null,
      sorter: true,
    },
    {
      ...generateColumnTitle(ArticlesDataIndex.audience, strings.audienceLabel),
      filteredValue: filteredInfo.filters?.audience || null,
      filters: audienceFilters,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        ArticlesDataIndex['units.title'],
        strings.packageLabel,
      ),
      filteredValue: filteredInfo.filters?.['units.title'] || null,
      ...generateColumnSearchProps(ArticlesDataIndex['units.title']),
      render: (unit, record) => {
        return record.unit && onClickPackage ? (
          <Button type="link" onClick={onClickPackage(record.unit.id)}>
            {unit}
          </Button>
        ) : (
          unit
        )
      },
      sorter: true,
    },
    {
      ...generateColumnTitle(ArticlesDataIndex.created_at, strings.createdDate),
      render: deserializeShortFormattedDate,
      sorter: true,
    },
    {
      ...generateColumnTitle(ArticlesDataIndex.status, strings.statusLabel),
      filteredValue: filteredInfo.filters?.status || null,
      filters: articleStatusFilters,
      render(value) {
        return (
          <Tag color={learnStatusProps(value).color}>
            {learnStatusProps(value).title}
          </Tag>
        )
      },
      sorter: true,
    },
  ]

  return {
    filteredInfo,
    ...restFilteredInfo,
    columns,
  }
}
