import { LayoutType, withLayout } from 'src/hocs/withLayout'

import { CommunityGroupEdit } from './CommunityGroupEdit'

const WrappedCommunityGroupEdit = withLayout(
  LayoutType.Default,
  CommunityGroupEdit,
)

export { WrappedCommunityGroupEdit as CommunityGroupEdit }
