import {
  Checkbox as AntdCheckbox,
  CheckboxProps as AntdCheckboxProps,
  Tooltip,
} from 'antd'
import React from 'react'

interface CheckboxProps extends AntdCheckboxProps {
  tooltipTitle?: string
}

export const Checkbox: React.FC<CheckboxProps> = ({
  tooltipTitle = '',
  ...props
}) => (
  <Tooltip title={tooltipTitle}>
    <AntdCheckbox {...props}>{props.children}</AntdCheckbox>
  </Tooltip>
)
