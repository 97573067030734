import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { formatDateToRender } from 'src/common/types'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { Tacas } from 'src/store/APIs/tacas/types'

import { strings } from './strings'

export enum TacasDataIndex {
  version = 'version',
  id = 'id',
  created_at = 'created_at',
}

export const columns: (
  onClickRow?: (id: string) => () => void,
) => ColumnsType<Tacas> = onClickRow => [
  {
    ...generateColumnTitle(TacasDataIndex.version, strings.tacasLabel),
    render: (tacas, record) => {
      return record.permissions?.can_read && onClickRow ? (
        <Button type="link" onClick={onClickRow(record.id)}>
          {tacas}
        </Button>
      ) : (
        tacas
      )
    },
  },
  {
    ...generateColumnTitle(TacasDataIndex.created_at, strings.createdAtLabel),
    render: formatDateToRender,
  },
]
